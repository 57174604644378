import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  DialogProps,
  Box,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
export interface AlertDialogProps extends DialogProps {
  title: string;
  message: string;
  handleClose: () => any;
  action?: () => any;
  justConfirm?: boolean;
}
export const AlertDialog = ({
  handleClose,
  action,
  open,
  title,
  message,
  justConfirm,
  ...rest
}: AlertDialogProps) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={handleClose} {...rest}>
      <Box p={1}>
        <DialogContent>
          <Typography variant='h5' gutterBottom>
            {title}
          </Typography>
          <DialogContentText>
            <Typography variant='body2' component='span'>
              {message}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!justConfirm && (
            <Button onClick={handleClose} color='primary' variant='outlined'>
              {t('dialogs.cancel')}
            </Button>
          )}

          <Button
            onClick={async () => {
              if (!justConfirm && action) {
                await action();
              }
              await handleClose();
            }}
            color='primary'
            variant='contained'
            autoFocus
          >
            {t('dialogs.confirm')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

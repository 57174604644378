import React from 'react';
import {
  Grid,
  Box,
  Typography,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Paper,
  makeStyles,
  Theme,
  IconButton,
  Snackbar,
  Tooltip,
} from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import GetAppIcon from '@material-ui/icons/GetApp';
import SettingsIcon from '@material-ui/icons/Settings';
import clsx from 'clsx';
import { DocumentFile } from '../../models/app.model';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { copyToClipboard } from '../../helpers';
import { AlertDialog } from '../AlertDialog';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const useStyles = makeStyles((theme: Theme) => ({
  Box: {
    'display': 'flex',
    'flexDirection': 'column',
    'padding': theme.spacing(2),
    '&.list': {
      flexDirection: 'row',
      alignItems: 'center',
    },
    '& a.list': {
      flexBasis: '70px',
      marginRight: theme.spacing(2),
    },
  },
  docBox: {
    backgroundColor: theme.palette.grey[200],
  },
  thumbnail: {
    'backgroundColor': theme.palette.common.white,
    'position': 'relative',
    'marginBottom': theme.spacing(2),
    'paddingBottom': '133%',
    'overflow': 'hidden',
    '&.list': {
      marginBottom: 0,
    },
    '& img': {
      position: 'absolute',
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'top',
    },
    '& .visits': {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      background: 'rgba(0,0,0,0.8)',
      color: '#fff',
      opacity: 0,
      transition: `all .5s ease`,
      transform: `scale(1.15)`,
    },
    '&:hover .visits': {
      opacity: 1,
      transform: `scale(1)`,
    },
  },
}));

interface PdfCardProps extends RouteComponentProps {
  index: number;
  doc: DocumentFile;
  onRemove: (doc: DocumentFile) => any;
  layout: string;
}
function PdfCard(props: PdfCardProps) {
  const { doc } = props;
  const link = `https://app.primeleads.de/share/${doc.domain}/${doc.slug}`;
  const classes = useStyles();
  const [open, setOpen] = React.useState<boolean>(false);
  const [confirmRemove, setConfirmRemove] = React.useState<boolean>(false);
  const [copied, setCopied] = React.useState<boolean>(false);
  const anchorRef = React.useRef<any>(null);
  const { t } = useTranslation(['uploadPdf', 'common']);
  const getControls = () => {
    return (
      <Box
        className={'visits'}
        display='flex'
        alignItems='center'
        justifyContent={isList() ? 'flex-start' : 'center'}
        flexDirection={isGrid() ? 'column' : 'row'}
        textAlign='center'
        mt={isList() ? 1 : 0}
      >
        <Box mb={isGrid() ? 2 : 0} mr={isList() ? 2 : 0} display='flex' alignItems='center'>
          <BarChartIcon style={{ marginRight: 10 }} />
          <Typography variant='body2'>{t('visit', { count: doc.stats.displays })}</Typography>
        </Box>
        <Box display='flex' alignItems='center'>
          <GetAppIcon style={{ marginRight: 10 }} />
          <Typography variant='body2'>{t('download', { count: doc.stats.downloads })}</Typography>
        </Box>
      </Box>
    );
  };
  const getListControls = () => {
    return (
      <Box
        className={'visits'}
        display='flex'
        alignItems='center'
        justifyContent='center'
        textAlign='center'
      >
        <SettingsIcon />
      </Box>
    );
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event: any) => {
    if (event && anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const visitSettings = () => {
    props.history.push({ pathname: `/documents/${props.doc._id}`, state: { activeTab: 1 } });
  };
  const copyLink = () => {
    copyToClipboard(link);
    setCopied(true);
    handleClose(null);
  };
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);
  const isList = () => props.layout === 'list';
  const isGrid = () => props.layout === 'grid';

  return (
    <Grid item xs={12} sm={isGrid() && 6} md={isGrid() && 4} lg={isGrid() && 3}>
      <Box
        className={clsx(
          isList() && 'list',
          classes.Box,
          (props.index % 2 !== 0 || isGrid()) && classes.docBox
        )}
      >
        {/* THUMBNAIL */}
        <Link to={`/documents/${doc._id}`} className={clsx(isList() && 'list')}>
          <Box className={clsx(isList() && 'list', classes.thumbnail)}>
            <img src={`/${doc.thumbnailPath}`} alt='Document thumbnail' />
            {isGrid() && getControls()}
            {isList() && getListControls()}
          </Box>
        </Link>
        {/* CONTENT */}
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems={isList() ? 'center' : 'flex-start'}
          flexGrow='1'
          flexWrap='wrap'
        >
          {/* DETAILS */}
          <Box flexGrow='1'>
            <Typography variant='h6'>{doc.title}</Typography>
            <Typography variant='body2'>{doc.description}</Typography>
            <Typography variant='caption' color='textSecondary'>
              {t('addedOn') as string} {new Date(doc.addedAt).toLocaleString()}
            </Typography>
            {isList() && getControls()}
          </Box>
          {/* BUTTONS */}
          <Box display='flex' alignItems='center'>
            <Tooltip title={t('common:copyToClipboard') as string}>
              <IconButton onClick={copyLink}>
                <FileCopyIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('common:options') as string}>
              <IconButton onClick={handleToggle} ref={anchorRef}>
                <MoreVertIcon />
              </IconButton>
            </Tooltip>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList autoFocusItem={open} id='menu-list-grow'>
                        <MenuItem onClick={() => visitSettings()}>
                          <Typography variant='body2'>{t('openSettings')}</Typography>
                        </MenuItem>
                        {/* <MenuItem onClick={copyLink}>
                          <Typography variant='body2'>{t('copyLink')}</Typography>
                        </MenuItem> */}
                        <MenuItem onClick={() => setConfirmRemove(true)}>
                          <Typography variant='body2'>{t('removeDocument')}</Typography>
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Box>
        </Box>
      </Box>
      <AlertDialog
        open={confirmRemove}
        handleClose={() => setConfirmRemove(false)}
        action={() => props.onRemove(props.doc)}
        title={t('removeDocument')}
        message={t('confirmRemove')}
      />
      <Snackbar open={copied} autoHideDuration={3000} onClose={() => setCopied(false)}>
        <Alert severity='info' variant='filled'>
          {t('linkCopied')}
        </Alert>
      </Snackbar>
    </Grid>
  );
}
export default withRouter(PdfCard);

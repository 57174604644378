import React, { Component, RefObject } from "react";
import AppContext from "../../data/AppContext";
import {
  withStyles,
  createStyles,
  Theme,
  Box,
  Typography,
  Grid,
  makeStyles,
  LinearProgress,
  IconButton,
} from "@material-ui/core";
import { IComponentProps, DocumentFile } from "../../models/app.model";
import PostAddIcon from "@material-ui/icons/PostAdd";
import ListIcon from "@material-ui/icons/List";
import AppsIcon from "@material-ui/icons/Apps";
import { useDropzone, DropzoneOptions } from "react-dropzone";

import { withTranslation, useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import PdfCard from "./PdfCard";

const styles = createStyles((theme: Theme) => ({
  docsContainer: {
    padding: theme.spacing(5),
  },
  addBox: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    alignItems: "center",
    justifyContent: "stretch",
    alignContent: "stretch",
    padding: theme.spacing(10, 5),
    textAlign: "center",
  },
  loadingBox: {
    backgroundColor: theme.palette.grey[200],
  },
}));

export interface PdfListProps extends IComponentProps, RouteComponentProps {}
export interface PdfListState {
  documents: DocumentFile[];
  open: boolean;
  uploading: {
    active: boolean;
    filename: string;
  };
  layout: string;
  domain: string;
}
class PdfList extends Component<PdfListProps, PdfListState> {
  static contextType = AppContext;
  state = {
    documents: [] as DocumentFile[],
    open: false,
    uploading: {
      active: false,
      filename: "",
    },
    layout: "list",
  } as PdfListState;
  anchorRef: RefObject<any> = React.createRef<any>();
  componentDidMount = async () => {
    const { key: domainKey } = this.context.getData.domain || {};
    if (domainKey) {
      this.fetchDocuments(domainKey);
    }
  };
  isGrid = () => this.state.layout === "grid";
  isList = () => this.state.layout === "list";

  fetchDocuments = async (domainKey: string) => {
    const documents = await this.context.fetchUserDocuments();
    this.setState({ ...this.state, documents, domain: domainKey });
  };
  componentDidUpdate(_prevProps: PdfListProps, _prevState: PdfListState) {
    if (this.state.open === false && this.anchorRef.current) {
      this.anchorRef.current.focus();
    }
    if (
      this.state.domain &&
      this.state.domain !== this.context.getData.domain.key
    ) {
      this.fetchDocuments(this.context.getData.domain.key);
    }
  }
  removeDocument = async (doc: DocumentFile) => {
    const result = await this.context.removeDocument(doc._id);
    if (result) {
      const { documents } = this.state;
      const filtered = documents.filter((d) => d._id !== doc._id);
      this.setState({ ...this.state, documents: filtered });
    }
  };
  getHeader = () => {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        pb={3}
      >
        <Box>
          <Typography variant="h4">{this.props.t("title")}</Typography>
          <Typography variant="subtitle1">
            {this.props.t("description")}
          </Typography>
        </Box>
        <Box>
          <IconButton
            onClick={() => this.setState({ ...this.state, layout: "list" })}
          >
            <ListIcon />
          </IconButton>
          <IconButton
            onClick={() => this.setState({ ...this.state, layout: "grid" })}
          >
            <AppsIcon />
          </IconButton>
        </Box>
      </Box>
    );
  };
  uploadNewDocument = async (file: File) => {
    if (file) {
      await this.setState({
        ...this.state,
        uploading: {
          active: true,
          filename: file.name,
        },
      });
      const result: DocumentFile | null = await this.context.uploadNewDocument(
        file
      );
      if (result) {
        result.stats = {
          displays: 0,
          downloads: 0,
          visitors: [],
        };
        this.setState({
          ...this.state,
          uploading: {
            active: false,
            filename: "",
          },
          documents: [result, ...this.state.documents],
        });
      }
    }
  };
  displayUploadingPlaceholder = () => {
    return (
      this.state.uploading.active && (
        <Grid
          item
          xs={12}
          sm={this.isGrid() && 4}
          lg={this.isGrid() && 3}
          component={Box}
          display="flex"
          key={0}
        >
          <Box
            display="flex"
            flexDirection="column"
            className={this.props.classes.loadingBox}
            flexGrow={1}
            alignItems="center"
            justifyContent="center"
          >
            <Box p={5}>
              <Typography variant="body2" gutterBottom>
                {this.props.t("uploadingNewFile")}
              </Typography>
              <LinearProgress color="primary" />
            </Box>
          </Box>
        </Grid>
      )
    );
  };
  displayDocuments = () => {
    const mapDocs = () =>
      [this.displayUploadingPlaceholder()].concat(
        this.state.documents.map((doc: DocumentFile, i: number) => (
          <PdfCard
            key={i + 1}
            index={i}
            doc={doc}
            onRemove={() => this.removeDocument(doc)}
            layout={this.state.layout}
          />
        ))
      );
    return this.state.layout === "list" ? (
      <Grid item xs={12} sm={8} lg={9}>
        {mapDocs()}
      </Grid>
    ) : (
      mapDocs()
    );
  };
  render() {
    const { classes } = this.props;
    return (
      <Box className={classes.docsContainer}>
        {this.getHeader()}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4} lg={3}>
            <MyDropzone
              accept=".pdf"
              onDropCallback={this.uploadNewDocument}
              acceptFiles="application/pdf"
            />
          </Grid>

          {this.displayDocuments()}
        </Grid>
      </Box>
    );
  }
}
interface MyDropzoneProps extends DropzoneOptions {
  onDropCallback: (file: File) => Promise<any>;
  acceptFiles: string;
}
const dzstyles = {
  dropBox: {
    outline: "none",
    border: `2px dashed #ddd`,
    cursor: "pointer",
    backgroundColor: "#f2f2f2",
    transition: "all .25s ease",
    "& > div": {
      transition: "all .25s ease",
    },
    "&:hover": {
      backgroundColor: "#fff",
      "& > div": {
        transform: "scale(1.25)",
      },
    },
  },
};
const useStyles = makeStyles(dzstyles);
function MyDropzone(props: MyDropzoneProps) {
  const onDropAccepted = React.useCallback((acceptedFiles: File[]) => {
    props.onDropCallback(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted,
    accept: props.accept,
  });
  const classes = useStyles();
  const { t } = useTranslation(["uploadPdf", "common"]);
  return (
    <Box
      p={7}
      {...getRootProps()}
      className={classes.dropBox}
      display="flex"
      justifyContent="center"
    >
      <input {...getInputProps()} />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <PostAddIcon
          color="primary"
          style={{ marginBottom: 10 }}
          fontSize="large"
        />
        {isDragActive ? (
          <Typography variant="body2" align="center">
            {t("dropFiles")}
          </Typography>
        ) : (
          <>
            <Typography variant="h6" align="center">
              {t("dragDropFiles")}
            </Typography>
            <Typography variant="caption" align="center">
              {t("common:or")}
            </Typography>
            <Typography variant="h6" align="center">
              {t("clickToSelect")}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
}

export default withStyles(styles)(withTranslation(["uploadPdf"])(PdfList));

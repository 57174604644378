import React from 'react';
export const filterURL = (url: string, protocol: boolean, host: boolean, pathname: boolean): string => {
  try {
    if (!url) {
      return '';
    }
    const address = new URL(url);
    let result = '';
    if (protocol) result += address.protocol + '//';
    if (host) result += address.host;
    if (pathname) result += address.pathname;
    return result;
  } catch (err) {
    //console.error(err);
    return '';
  }
};

export const isValidUrl = (domain: string) => {
  try {
    new URL(domain);
  } catch (e) {
    return false;
  }
  return true;
};

export const shortUrl = (url: string) => {
  const address = new URL(url);
  const tooLong = address.pathname.length > 30;
  return address.pathname.slice(0, 30) + (tooLong ? '...' : '');
};

export const getCompanyIcon = (domain: string) => {
  return (
    <img
      src={`https://s2.googleusercontent.com/s2/favicons?domain=${filterURL(
        domain,
        true,
        true,
        false
      )}&s50`}
      onError={(e: any) => {
        e.target.src = `https://s2.googleusercontent.com/s2/favicons?domain=h&s50`;
      }}
      alt='Company logo'
      style={{ marginRight: 10 }}
    />
  );
};

export const isLocalhost = () => {
  return (
    !window ||
    window.location.hostname.includes('localhost') ||
    window.location.hostname.includes('127.0.0.1')
  );
};

import React from 'react';
import {
  Typography,
  TypographyProps,
  Box,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
  },
}));

interface IPopoverProps extends TypographyProps {
  text: any;
}
export const TypoPopover = ({ text, children, ...rest }: IPopoverProps) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef() as any;
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClose}>
        <Box style={{ width: '100%' }}>
          <Typography onMouseEnter={handleToggle} onMouseLeave={handleClose} ref={anchorRef} {...rest}>
            {children}
          </Typography>
          <Popper
            className={classes.popover}
            open={open}
            anchorEl={anchorRef.current}
            transition
            placement='bottom-start'
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Box component={Paper} px={2} py={1} style={{ maxWidth: '30vw' }}>
                  <Typography variant='body2' style={{ width: 'inherit', overflowWrap: 'break-word' }}>
                    {text}
                  </Typography>
                </Box>
              </Grow>
            )}
          </Popper>
        </Box>
      </ClickAwayListener>
    </>
  );
};

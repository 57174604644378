import { Dashboard } from '../langTemplate';

const dashboard: Dashboard = {
  welcome: 'Willkommen im Dashboard',
  explanation:
    'Um Primeleads auf Ihrer Site zu installieren, fügen Sie bitte unser Skript ein, bevor Sie das Body-Tag schließen.',
  howToPaste: 'Wohin soll mein Skript gehen?',
  last5visits: 'Letzte 5 erkannten Unternehmen in 10 tagen',
  lastWeek: 'Statistiken der letzten 10 Tage',
  top10Visitors: 'Top 10 Besucher',
  visitsByDate: 'Besuche nach Datum',
  topVisitorsByType: 'Top-Besucher nach Branchen',
  viewAll: 'Alle ansehen',
  mostVisitedPages: 'Am häufigsten besuchte Seiten',
  errorSubscribed: 'Etwas ist schief gelaufen. Bitte kontaktieren Sie unseren Support.',
  newsletterButton: 'Anmelden',
  newsletter: 'Newsletter',
  newsletterDescription:
    'Melden Sie sich für unseren Newsletter an und erhalten Sie die neuesten Updates und exklusiven Angebote.',
  successSubscribed: 'Vielen Dank. Sie haben sich erfolgreich für unseren Newsletter angemeldet.',
  uniqueVisitor: 'Sie hatten <b>{{count}}</b> eindeutigen Besucher.',
  uniqueVisitor_plural: 'Sie hatten <b>{{count}}</b> eindeutige Besucher.',
  seeAll:
    'Bitte gehen Sie zu den Besuchern und ändern Sie den Filter in <b>alle</b>, um sie alle zu sehen.',
  onBoarding: {
    welcome: 'Willkommen bei Primeleads, vielen Dank, dass Sie sich uns angeschlossen haben',
    subheader: 'Richten Sie innerhalb einer Minute Ihre erste Domain ein und nutzen Sie die App',
    setUpTitle: 'Domain einrichten',
    setUpDescription: 'Bitte geben Sie einen Namen an, der in der Liste aller Domains angezeigt wird',
    installation: 'Skriptinstallation',
    installationDescription: 'Bitte kopieren Sie das Skript und fügen Sie es in Ihre Seite ein.',
    finish: 'Fertig!',
    finishDescription: 'Ihre erste Domain wurde hinzugefügt! Sie können die App jetzt verwenden.',
    forExample: 'Zum Beispiel URL-Adresse oder Firmenname',
    verification: 'Überprüfung',
    verificationDescription:
      'Wenn Sie überprüfen möchten, ob das Skript korrekt installiert ist, führen Sie die folgenden 2 einfachen Schritte aus:',
    step1: 'Öffnen Sie Ihre Website im Browser.',
    step2: 'Dann komm zurück und drücke',
    verify: 'Script überprüfen',
    success: 'Herzlichen Glückwunsch, das Skript wurde erfolgreich installiert!',
    fail:
      'Etwas ist schief gelaufen. Das Skript wurde nicht korrekt installiert oder es gab noch keine Besuche.',
  },
  unternehmen: 'Unternehmen',
  standorte: 'Standorte',
  seitenaufrufe: 'Seitenaufrufe',
  wiederkehrer: 'Wiederkehrer',
  kunden: 'Kunden',
  favoriten: 'Favoriten',
  page: 'Website',
  companies: 'Firmen',
  visits: 'Aufrufe',
  users: 'Kunden',
};
export default dashboard;

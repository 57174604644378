import React, { ChangeEvent } from 'react';
import { withTranslation } from 'react-i18next';
import { FilterDateRangePicker } from '../FilterDateRangePicker';
import styles from './Visitors.style';
import { withStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import {
  TextField,
  IconButton,
  Tooltip,
  Select,
  MenuItem,
  Typography,
  Grid,
  Grow,
  Fab,
} from '@material-ui/core';
//MODELS
import AppContext from '../../data/AppContext';
//ICONS
import ClearIcon from '@material-ui/icons/Clear';
import { VisitorTypes, IFilterState, IComponentProps } from '../../models/app.model';

export interface FilterProps extends IComponentProps {
  onResetFilter: () => any;
  onUpdateFilter: (propsToUpdate: any) => any;
  onUpdateResults: () => any;
  filter: IFilterState;
}

class Filter extends React.Component<FilterProps, { filterModified: boolean }> {
  static contextType = AppContext;
  constructor(props: FilterProps) {
    super(props);
    this.state = {
      filterModified: false,
    };
  }
  async componentDidMount() {
    await this.context.getCompanyTypes();
    this.props.onUpdateFilter({
      companyType: this.context.getData.companyTypes[0],
    });
  }

  handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ ...this.state, filterModified: true });
    this.props.onUpdateFilter({
      [e.target.name]: e.target.value,
    });
  };

  handleTypeChange = async (e: ChangeEvent<{ value: any }>) => {
    this.setState({ ...this.state, filterModified: true });
    await this.props.onUpdateFilter({ type: e.target.value });
  };

  handleSortByChange = async (e: ChangeEvent<{ value: any }>) => {
    this.setState({ ...this.state, filterModified: true });
    await this.props.onUpdateFilter({ sortBy: e.target.value });
  };

  handleSortAsc = async (e: any) => {
    this.setState({ ...this.state, filterModified: true });
    await this.props.onUpdateFilter({
      sortAsc: JSON.parse(e.target.value),
    });
  };

  render() {
    const { t, filter, onUpdateFilter, onResetFilter, onUpdateResults } = this.props;
    const { keyword, sortBy, sortAsc, type } = filter;
    return (
      <Grid container spacing={2} alignItems='flex-end'>
        <Grid item xs={12} sm={4} md={'auto'}>
          <Typography variant='h6' gutterBottom>
            {t('filters.order')}
          </Typography>
          <Select value={sortAsc} variant='outlined' onChange={this.handleSortAsc} fullWidth>
            <MenuItem value='false'>{t('filters.descending')}</MenuItem>
            <MenuItem value='true'>{t('filters.ascending')}</MenuItem>
          </Select>
        </Grid>

        <Grid item xs={12} sm={4} md={'auto'}>
          <Typography variant='h6' gutterBottom>
            {t('filters.sortBy')}
          </Typography>
          <Select value={sortBy} onChange={this.handleSortByChange} variant='outlined' fullWidth>
            <MenuItem value='companyName'>{t('filters.companyName')}</MenuItem>
            <MenuItem value='day'>{t('filters.day')}</MenuItem>
            <MenuItem value='city'>{t('filters.city')}</MenuItem>
            <MenuItem value='time'>{t('filters.time')}</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={4} md={'auto'}>
          <Typography variant='h6' gutterBottom>
            {t('filters.visitorsType')}
          </Typography>
          <Select value={type} variant='outlined' onChange={this.handleTypeChange} fullWidth>
            <MenuItem value={VisitorTypes.All}>{t('filters.all')}</MenuItem>
            <MenuItem value={VisitorTypes.Companys}>{t('filters.companies')}</MenuItem>
            <MenuItem value={VisitorTypes.competitor}>{t('filters.competitors')}</MenuItem>
            <MenuItem value={VisitorTypes.CompanysNotInterested}>
              {t('filters.notInterestedIn')}
            </MenuItem>
            <MenuItem value={VisitorTypes.Customers}>{t('filters.customers')}</MenuItem>
            <MenuItem value={VisitorTypes.isFavorite}>{t('filters.favorite')}</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={8} md={'auto'}>
          <Typography variant='h6' gutterBottom>
            {t('filters.dateRange')}
            <Tooltip title={t('filters.historyLimit') as string} placement='right-start'>
              <IconButton size='small' style={{ margin: '0 10px' }}>
                <HelpIcon fontSize='small' />
              </IconButton>
            </Tooltip>
          </Typography>
          <FilterDateRangePicker
            block={true}
            filter={this.props.filter}
            modifyFilter={async () => this.setState({ ...this.state, filterModified: true })}
            updateFilter={onUpdateFilter}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={'auto'}>
          <Typography variant='h6' gutterBottom>
            {t('filters.keyword')}
          </Typography>
          <TextField
            name='keyword'
            value={keyword}
            placeholder={t('filters.typeToFilter')}
            onChange={this.handleTextChange}
            inputProps={{
              autoComplete: 'off',
            }}
            variant='outlined'
            fullWidth
          />
        </Grid>
        <Grow in={this.state.filterModified} unmountOnExit timeout={500}>
          <Grid item xs={12} sm={4} md={'auto'}>
            <Fab variant='extended' color='primary' size='small' onClick={onUpdateResults}>
              <Typography variant='button' style={{ margin: '0 10px' }}>
                {t('filters.applyFilter')}
              </Typography>
            </Fab>
            <Tooltip title='Reset filter'>
              <IconButton
                color='primary'
                onClick={() => {
                  this.setState({ ...this.state, filterModified: false });
                  onResetFilter();
                }}
              >
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grow>
      </Grid>
    );
  }
}

export default withStyles(styles)(withTranslation('visitors')(Filter));

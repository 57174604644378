import React from 'react';
import { Box, Typography, Fab } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function NoDomain() {
  const { t } = useTranslation();
  return (
    <Box py={10} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
      <Typography variant='h4' gutterBottom>
        {t('noDomain.title') as string}
      </Typography>
      <Typography variant='subtitle1' gutterBottom>
        {t('noDomain.subtitle') as string}
      </Typography>
      <Fab variant='extended' component={Link} color='primary' to='/' style={{ margin: '20px 0' }}>
        {t('noDomain.button') as string}
      </Fab>
    </Box>
  );
}

import React from 'react';
import { Tooltip, Box, IconButton } from '@material-ui/core';
//ICONS
import FavoriteIcon from '@material-ui/icons/Favorite';
import BlockIcon from '@material-ui/icons/Block';
import PersonIcon from '@material-ui/icons/Person';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import AppContext from '../data/AppContext';
import { withRouter, RouteComponentProps } from 'react-router';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface IconsProps extends RouteComponentProps, WithTranslation {
  note?: any;
  companyKey: string;
  onStatusChange:(note:any)=>any;
}
export interface IconsState {
  status: string;
  ipInfoId: string;
  hiddenOn: Date | null;
  hide: boolean;
}

class StatusIcons extends React.Component<IconsProps, IconsState> {
  static contextType = AppContext;
  loaded: boolean;
  constructor(props: IconsProps) {
    super(props);
    this.loaded = false;
  }
  componentDidMount = async () => {
    this.loaded = true;
  };
  setStatus = async (status: string) => {
    let hiddenOn = this.props.note.hiddenOn || null;
    let hide = false;
    if (this.context.getData.readOnly) {
      return;
    }
    if (status === this.props.note.status) {
      status = '';
    }
    if (status === 'notInteresting') {
      if (!this.props.note.hiddenOn) {
        hiddenOn = new Date();
      }
      hide = true;
    }

    console.log("STATUS", status)
    this.props.onStatusChange({
      ...this.props.note,
      status,
      hiddenOn,
      hide,
    });
  };

  componentDidUpdate = async (prevProps: IconsProps, prevState: IconsState) => {
    if (prevProps.note !== this.props.note) {
      await this.setState(this.props.note);
    }
  };

  componentWillUnmount() {
    this.loaded = false;
  }

  render() {
    const { t } = this.props;
    return (
      <Box my={2} display='flex' justifyContent='center'>
        <Tooltip title={t('labels.isFavorite') as string}>
          <IconButton onClick={() => this.setStatus('isFavorite')}>
            <FavoriteIcon color={this.props.note.status === 'isFavorite' ? 'error' : undefined} />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('labels.isCustomer') as string}>
          <IconButton onClick={() => this.setStatus('isCustomer')}>
            <PersonIcon color={this.props.note.status === 'isCustomer' ? 'error' : undefined} />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('labels.competitor') as string}>
          <IconButton onClick={() => this.setStatus('competitor')}>
            <GpsFixedIcon color={this.props.note.status === 'competitor' ? 'error' : undefined} />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('labels.notInteresting') as string}>
          <IconButton onClick={() => this.setStatus('notInteresting')}>
            <BlockIcon color={this.props.note.status === 'notInteresting' ? 'error' : undefined} />
          </IconButton>
        </Tooltip>
      </Box>
    );
  }
}

export default withTranslation('dynamicContent')(withRouter(StatusIcons));

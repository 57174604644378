import React from 'react';
import { Box, Typography, Badge, createStyles, Theme, makeStyles } from '@material-ui/core';
import { ISpyLink } from './Spylink';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import clsx from 'clsx';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    contentTableElement: {
      'cursor': 'pointer',
      '& .duplicate': {
        opacity: 0,
        transition: 'all .25s ease-out'
      },
      '&:hover': {
        'backgroundColor': theme.palette.grey[100],
        '& .duplicate': {
          opacity: 1
        }
      }
    },
    selected: {
      backgroundColor: theme.palette.grey[200]
    },
    online: {
      backgroundColor: theme.palette.success.main
    },
    offline: {
      backgroundColor: theme.palette.error.main
    }
  })
);

export interface SpylinkListProps {
  links: ISpyLink[];
  filter: string;
  selected: number;
  onSelect: (link: ISpyLink) => any;
}
export const SpylinkList = (props: SpylinkListProps) => {
  const classes = styles();
  return (
    <Box>
      {props.links
        .filter((link) => new RegExp(props.filter, 'i').test(link.name))
        .map((link: ISpyLink, i: number) => (
          <Box
            key={i}
            p={3}
            px={2}
            py={1}
            display='flex'
            alignItems='center'
            className={clsx(
              classes.contentTableElement,
              props.selected >= 0 && props.links[props.selected] === link && classes.selected
            )}
            onClick={() => props.onSelect(link)}
          >
            <Box mr={2}>
              <Badge
                variant='dot'
                anchorOrigin={{
                  horizontal: 'right',
                  vertical: 'bottom'
                }}
                classes={{
                  dot: link.disabled ? classes.offline : classes.online
                }}
              >
                <MailOutlineIcon color={link.notification && !link.disabled ? undefined : 'disabled'} />
              </Badge>
            </Box>
            <Box>
              <Typography variant='h6'>{link.name}</Typography>
              <Typography variant='caption' color='textSecondary'>
                {link.mail}
              </Typography>
            </Box>
          </Box>
        ))
        .reverse()}
    </Box>
  );
};

import { NewFeatures } from '../langTemplate';

const newFeatures: NewFeatures = {
  title: 'Neue Features veröffentlicht',
  close: 'Schließen',
  hideUntil: 'Ausblenden, bis neue Features veröffentlicht werden',
  spylink: 'Spylink',
  spylinkDescription:
    'Verfolgen Sie Ihre Links und werden Sie über Benutzerinteraktionen benachrichtigt.',
  userRights: 'Benutzerberechtigungen',
  userRightsDescription:
    'Teilen Sie Ihre Domains mit anderen Benutzern und gewähren Sie ihnen schreibgeschützte oder Schreibzugriffsrechte.',
  companies: 'Unternehmen',
  companiesDescription:
    'Durchsuchen Sie Unternehmen, die Sie kürzlich besucht haben, sortieren und filtern Sie sie nach verschiedenen Kriterien.',
  multipleDynamic: 'Mehrere Kriterien für Dynamic Content',
  multipleDynamicDescription:
    'Mit dieser Funktion können Sie mehrere Bedingungen definieren, die das Unternehmen erfüllen muss, um den Inhalt anzuzeigen.',
  pushNotifications: 'Push Benachrichtungen',
  pushNotificationsDescription:
    'Lassen Sie sich im Browser benachrichtigen, wenn jemand Ihre Website besucht.',
  analytik: 'Analytics für Dynamic Content',
  analytikDescription:
    'Neue Tab im Bereich für Dynamic Content. Identifizieren Sie, wie oft und welche Unternehmen Ihre Content gesehen haben.',
  spylinkAnalytik: 'Analytics für Spylink',
  spylinkAnalytikDescription:
    'Neue Tab im Bereich Spylink.  Sie sich an, wie viele Besucher über Spylink eingetreten sind, und identifizieren Sie Unternehmen unter ihnen.',
  notificationsFrequency: 'Häufigkeit der E-Mail-Benachrichtigungen',
  notificationsFrequencyDescription:
    'Passen Sie für jede bereitgestellte E-Mail an, wie oft Sie einen Unternehmensbericht erhalten möchten.',
  excel: 'Nach Excel exportieren',
  excelDescription:
    'Wir haben eine Funktion veröffentlicht, mit der Besucher in Excel-Dateien exportiert werden können. Es kann einige Zeit dauern, abhängig von der Anzahl der Anfragen, die Ihre Site erreichen.',
  freemium: 'Freemium model',
  freemiumDescription:
    'Ab sofort wird jeder neue Benutzer automatisch für unseren kostenlosen Tarif angemeldet, sodass Sie alle Funktionen mit bestimmten Einschränkungen ausprobieren können. Details zu jedem Plan finden Sie in den Einstellungen.',
  videoTracking: 'Deaktivieren Sie Video-Tracking',
  videoTrackingDescription:
    'Es gibt eine Option zum Deaktivieren der Videoverfolgung für ausgewählte Teile Ihrer Website. Wählen Sie einfach die Domain und geben Sie die URL an, die von der Aufzeichnung ausgeschlossen werden soll.',
  pdfTracking: 'Verfolgung von PDF-Dokumenten',
  pdfTrackingDescription:
    'Wir haben eine neue Funktion veröffentlicht, mit der Sie PDF-Dokumente freigeben und Benutzeraktivitäten verfolgen können. In Kürze besteht die Möglichkeit, diese Funktion an eine benutzerdefinierte Domain Ihrer Wahl anzuhängen.',
};
export default newFeatures;

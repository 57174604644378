import React from 'react';
import {
  Typography,
  Snackbar,
  SnackbarContent,
  Button,
  createStyles,
  Theme,
  Box,
  withStyles,
  IconButton
} from '@material-ui/core';
import { drawerWidth } from './Sidebar';
import AppContext from '../data/AppContext';

import { IComponentProps } from '../models/app.model';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import { WrappedIcon } from '../helpers';
import { Link } from 'react-router-dom';

export interface FeatureProp {
  icon: JSX.Element;
  addedOn: Date;
  title: string;
  link: string | { pathname: string; state: { activeTab: number } };
}
export const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(3),
      borderBottom: `4px solid ${theme.palette.secondary.main}`
    },
    buttons: {
      marginTop: theme.spacing(2)
    },
    snackbar: {
      'minWidth': '300px',
      'maxWidth': '50vw',
      [theme.breakpoints.only('sm')]: {
        maxWidth: '100vw'
      },
      '&.open': {
        transform: `translateX(${drawerWidth}px)`
      },
      '&.close': {
        transform: `translateX(${theme.spacing(9)}px)`
      }
    }
  });

export interface NewFeatureProps extends IComponentProps {
  open: boolean;
  onClose: () => any;
  features: FeatureProp[];
  isNavOpen: boolean;
}
class NewFeatures extends React.Component<NewFeatureProps> {
  static contextType = AppContext;

  hideAndClose = () => {
    localStorage.setItem('featuresSeen', JSON.stringify(new Date().toISOString()));
    this.props.onClose();
  };
  getFeatures = () => {
    const { isNavOpen, t } = this.props;
    return (
      <Snackbar
        open={this.props.open}
        transitionDuration={750}
        TransitionProps={{
          style: {
            transformOrigin: '0 60%',
            transitionTimingFunction: 'cubic-bezier(0.175, 0.885, 0.32, 1.275)'
          }
        }}
        className={clsx(this.props.classes.snackbar, isNavOpen ? 'open' : 'close')}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <SnackbarContent
          classes={{ root: this.props.classes.root }}
          message={
            <>
              <Typography variant='h5' color='secondary' gutterBottom>
                {t('title')}
              </Typography>
              {this.props.features.map((feature: FeatureProp, i: number) => this.getFeature(feature, i))}
              {this.getControls()}
            </>
          }
        />
      </Snackbar>
    );
  };
  getControls = () => {
    return (
      <Box display='flex' justifyContent='flex-end' className={this.props.classes.buttons}>
        <Button
          variant='contained'
          color='secondary'
          style={{ marginRight: 10 }}
          onClick={this.props.onClose}
        >
          {this.props.t('close')}
        </Button>
        <Button variant='outlined' color='secondary' onClick={this.hideAndClose}>
          {this.props.t('hideUntil')}
        </Button>
      </Box>
    );
  };

  getFeature = (feature: FeatureProp, i: number) => {
    return (
      <Box key={i} display='flex' mt={1} pr={2}>
        <Box mr={2}>
          <IconButton component={Link} to={feature.link}>
            <WrappedIcon color='secondary'>{feature.icon}</WrappedIcon>
          </IconButton>
        </Box>
        <Box>
          <Typography variant='caption'>{feature.addedOn.toLocaleDateString()}</Typography>
          <Typography variant='h4'>{this.props.t('' + feature.title)}</Typography>
          <Typography variant='body1' gutterBottom>
            {this.props.t('' + feature.title + 'Description')}
          </Typography>
        </Box>
      </Box>
    );
  };
  render() {
    return this.props.features && this.props.features.length > 0 ? this.getFeatures() : null;
  }
}

export default withStyles(styles)(withTranslation('newFeatures')(NewFeatures));

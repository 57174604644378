import React from 'react';

import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';

function formatDate(date:string):{ day:string,
  month:string,
  year:string,
  hour:string,
  minute:string,
  second:string
} | any {
  try {
    if (!date) {
      throw new Error('Incorrect date');
    }
    const d = new Date(date);
    const { year, month, day, hour, minute, second } = {
      year: ('0' + d.getFullYear()).slice(-2),
      month: d.toLocaleString('default', { month: 'short' }),
      day: ('0' + d.getDate()).slice(-2),
      hour: ('0' + d.getHours()).slice(-2),
      minute: ('0' + d.getMinutes()).slice(-2),
      second: ('0' + d.getSeconds()).slice(-2)
    };
    return {
      day,
      month,
      year,
      hour,
      minute,
      second
    };
  } catch (error) {
    return error;
  }
};

export { formatDate }

export const subtractDays = (entryDate: Date, days: number) => {
  if (isNaN(days) || !days) {
    return entryDate;
  }
  const newDate = new Date(entryDate);
  newDate.setDate(entryDate.getDate() - days);
  return newDate;
};

export const resetClock = (date: Date, start = false) => {
  date.setHours(start ? 0 : 24, 0, 0, 0);
  return date;
};

export const DescribeDate: React.FC<{ date: string }> = ({ date }) => {
  const { i18n } = useTranslation();
  moment.locale(i18n.language);
  return <>{moment(new Date(date)).fromNow()}</>;
};

import React from 'react';

import {
  XAxis,
  YAxis,
  Legend,
  CartesianGrid,
  Tooltip as TooltipCharts,
  ResponsiveContainer,
  Area,
  AreaChart,
  Brush,
} from 'recharts';
import { getRandomColor } from '../../helpers';
import { Typography, useTheme } from '@material-ui/core';
import { WithTranslation, withTranslation } from 'react-i18next';

interface IVisitsPerDayChartProps extends WithTranslation {
  data: any;
  dynamicContents: any;
  hideLegend?: boolean;
}

const VisitsPerDayChart: React.FC<IVisitsPerDayChartProps> = (props) => {
  const { data, dynamicContents, hideLegend, t } = props;
  const theme = useTheme();
  return data && data.length > 0 ? (
    <ResponsiveContainer width='100%' height={250}>
      <AreaChart data={data || []} margin={{ top: 20, left: -40 }}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='date' />
        <YAxis />
        <TooltipCharts />
        {!hideLegend && <Legend verticalAlign='top' align='right' layout='vertical' />}
        {dynamicContents.map((content: any, i: number) => (
          <Area
            key={i}
            type='monotone'
            dataKey={content.name}
            stroke={dynamicContents.length === 1 ? theme.palette.primary.main : getRandomColor()}
            fill={theme.palette.primary.light}
          />
        ))}
        <Brush />
      </AreaChart>
    </ResponsiveContainer>
  ) : (
    <Typography variant='body2' align='center'>
      {t('noData')}
    </Typography>
  );
};

export default withTranslation()(VisitsPerDayChart);

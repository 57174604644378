import { Visitors } from '../langTemplate';

const visitors: Visitors = {
  title: 'Visitors',
  watchActivity: 'Watch activity',
  downloadExcel: 'Export to excel',
  generatingExcel: 'Generating excel file. It may take a moment.',
  exportLimited: 'Data export only within selected date range.',
  limitExceeded:
    'Your current limit is <b>{{limit}} unique companies</b> recognitions per month. To see all the data please upgrade your plan.',
  scale: {
    scale1: '1-10 visits',
    scale2: '11-20 visits',
    scale3: '21-30 visits',
    scale4: '40+ visits'
  },
  tableHeaders: {
    day: 'Date',
    company: 'Company',
    city: 'City',
    target: 'Visited pages',
    visits: 'Number of requests',
    time: 'Time',
    referer: 'Source',
    visitDetails: 'Visit details',
    directEntry: 'Direct entry'
  },
  filters: {
    hideFilters: 'Hide filter',
    showFilters: 'Show filters',
    order: 'Order',
    sortBy: 'Sort by',
    visitorsType: 'Visitors type',
    dateRange: 'Date range',
    keyword: 'Keyword',
    ascending: 'Ascending',
    descending: 'Descending',
    companyName: 'Company name',
    day: 'Date of visit',
    city: 'City',
    time: 'Time',
    companies: 'Companies',
    all: 'All',
    competitors: 'Competitors',
    notInterestedIn: 'Not interested in',
    customers: 'Customers',
    favorite: 'Favorite',
    startDate: 'Start date',
    endDate: 'End date',
    typeToFilter: 'Filter by keyword',
    applyFilter: 'Apply filter',
    resetFilter: 'Reset filter',
    historyLimit: 'Users with free plan, can browse up to 30 days before'
  }
};

export default visitors;

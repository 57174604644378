import { Companies } from '../langTemplate';

const companies: Companies = {
  title: 'Companies',
  description: 'List of all companies that have recently visited you.',
  explanation: 'Displayed statistics includes data only from selected date range',
  details: 'Company details',
  type: 'Branch',
  requests: 'Requests',
  lastDate: 'Last visit',
  showOnlyRecognised: 'Hide unrecognised',
  totalTime: 'Time oa.',
  actions: 'Actions',
  visitPage: 'Visit page',
  visitProfile: 'Visit company profile',
  searchButton: 'Search for companies',
  addDynamic: 'Add dynamic content',
  filter: {
    sortBy: 'Sort by',
    order: 'Sort order',
    keyword: 'Filter companies',
    keywordPlaceholder: 'Type to filter results',
    dateRange: 'Date range',
    numberRequests: 'Number of requests',
    alphabetically: 'Alphabetically',
    lastVisit: 'Last visit',
    ascending: 'Ascending',
    descending: 'Descending',
    lastDays: 'Last {{days}} days',
    location: 'Location',
    totalTime: 'Time spent on website',
  },
  company: {
    details: 'Details',
    visits: 'Visits',
    content: 'Content',
    addContent: 'Add dynamic content',
    companyType: 'Company type',
    address: 'Address',
    phone: 'Phone number',
    website: 'Website',
    notes: 'Notes',
    contentTriggered: 'Dynamic content triggered by',
    numberTriggers: 'Number of triggers',
    numberRequests: 'Number of requests',
    date: 'Date of visit',
    time: 'Time on site',
    visitedSite: 'Visited site',
    device: 'Device',
    video: 'Video',
    source: 'Source of visit',
    copied: 'Company details copied to clipboard',
    createContent: 'Create dynamic content',
    moreDetails: 'More details',
  },
};

export default companies;

import React, { ChangeEvent, KeyboardEvent } from 'react';
import AppContext from '../../../data/AppContext';
import { Box, Typography, IconButton, Fab, Tooltip, Theme, TextField, Button } from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import { DynamicContentSelector } from '../Dynamic';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { TypoPopover } from '../../../helpers';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import { IComponentProps } from '../../../models/app.model';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

const styles = (theme: Theme) => ({
  root: {
    'width': '100%',
    'height': '100%',
    '& .MuiInputBase-input': {
      backgroundColor: theme.palette.common.white
    }
  },
  imagesContainer: {
    '& img': {
      display: 'block',
      width: '60px',
      height: '60px',
      borderRadius: '10%',
      margin: '1rem 0',
      objectFit: 'cover',
      boxShadow: '0 3px 10px -5px #999'
    }
  },
  rootEdit: {
    backgroundColor: theme.palette.grey[50]
  },
  listNoEdit: {
    'display': 'flex',
    'flexWrap': 'wrap' as any,
    '& .MuiListItem-container': {
      margin: theme.spacing(0, 2, 2, 0)
    }
  },
  listItem: {
    'background': theme.palette.grey[100],
    'cursor': 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[200]
    }
  },
  listItemEdit: {
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.grey[200]}`
  },
  listItemNoEdit: {
    maxWidth: 300,
    padding: theme.spacing(1, 2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    boxShadow: `0 5px 10px -10px ${theme.palette.grey[600]} `
  },
  notFound: {
    borderBottom: `3px solid ${theme.palette.error.main}`
  },
  found: {
    borderBottom: `3px solid #6bdb5c`
  },
  uploadInput: {
    display: 'none'
  }
});

export interface DynamicContentSelectorProps extends IComponentProps, RouteComponentProps<any> {
  selectors: DynamicContentSelector[];
  notFound: DynamicContentSelector[];
  editMode?: boolean;
  title?: string;
  id: string;
  onDelete?: (element: DynamicContentSelector) => any;
  onUpdate: (e: any, element: DynamicContentSelector) => any;
  onSave: () => any;
  onSelect: (i: number) => any;
  url?: string;
  activeElement: number;
}

class DcSelectorComponent extends React.Component<DynamicContentSelectorProps, {}> {
  static contextType = AppContext;
  get title() {
    return (
      this.props.title && (
        <Box display='flex' alignItems='center' flexWrap='wrap' p={this.props.editMode ? 2 : 0} pb={1}>
          <Typography variant='h6' style={{ marginRight: 20 }}>
            {this.props.title}
          </Typography>
          {!this.props.editMode && (
            <Box>
              <Tooltip title={this.props.t('editor.editElements') as string}>
                <IconButton
                  component={Link}
                  to={`/dynamic-content/edit/${this.props.id}`}
                  disabled={this.context.getData.readOnly}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
      )
    );
  }

  getSaveIcon = () => {
    return this.props.editMode && this.props.match.params.id ? (
      <Box my={2} mx={3}>
        <Fab variant='extended' color='primary' onClick={this.props.onSave} size='small'>
          {this.props.t('common:saveShort') as string}
        </Fab>
      </Box>
    ) : null;
  };

  getInputLabel = (selector: DynamicContentSelector) => {
    return selector.text ? (
      <TypoPopover variant='h6' text={selector.text} gutterBottom noWrap>
        {selector.text}
      </TypoPopover>
    ) : (
      selector.htmlString
    );
  };

  uploadPicture = async (file: File | null, selector: DynamicContentSelector) => {
    if (!file) {
      return;
    }
    const fd = new FormData();
    fd.append('replaceImage', file);
    try {
      const response = await fetch('/app-api/v2/dynamic-content/uploadImage', {
        method: 'POST',
        body: fd
      });
      const result = await response.text();
      selector.replaceImage = result;
      this.props.onUpdate(null, selector);
    } catch (error) {
      console.error(error);
    }
  };

  getUploadButton = (selector: DynamicContentSelector) => {
    return (
      <Box mt={1}>
        <input
          accept='image/*'
          className={this.props.classes.uploadInput}
          id='contained-button-file'
          type='file'
          onChange={async (e: ChangeEvent<HTMLInputElement>) => {
            await this.uploadPicture(e.target.files ? e.target.files[0] : null, selector);
          }}
        />
        <label htmlFor='contained-button-file'>
          <Button variant='contained' color='primary' size='small' component='span'>
            {this.props.t('uploadImage')}
          </Button>
        </label>
      </Box>
    );
  };

  getImageInfo = (selector: DynamicContentSelector) => {
    console.log(selector.replaceImage);
    return (
      <Box>
        {this.props.editMode &&
          this.props.activeElement === this.props.selectors.indexOf(selector) &&
          this.getUploadButton(selector)}
        <Box display='flex' className={this.props.classes.imagesContainer} alignItems='center'>
          <div dangerouslySetInnerHTML={{ __html: selector.htmlString }} />
          <SwapHorizIcon style={{ margin: '0 1rem' }} />
          {selector.replaceImage && <img src={selector.replaceImage} />}
        </Box>
      </Box>
    );
  };

  getInput = (selector: DynamicContentSelector) => {
    return (
      <Box>
        <Typography variant='caption' color='textSecondary' gutterBottom>
          {this.props.t('editor.replacedWith')}
        </Typography>
        {this.props.editMode && this.props.activeElement === this.props.selectors.indexOf(selector) ? (
          <TextField
            value={selector.replaceText}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              this.props.onUpdate(e, selector);
            }}
            onKeyUp={(e: KeyboardEvent) => {
              this.props.onUpdate(e, selector);
            }}
            name='replaceText'
            variant='outlined'
            fullWidth
          />
        ) : (
          <TypoPopover text={selector.replaceText} variant='body2' noWrap>
            {selector.replaceText}
          </TypoPopover>
        )}
      </Box>
    );
  };

  getIcons = (el: DynamicContentSelector) => {
    return this.props.editMode ? (
      <Tooltip title={this.props.t('editor.deleteElement') as string}>
        <IconButton
          size='small'
          onClick={(e: any) => {
            e.stopPropagation();
            this.props.onDelete && this.props.onDelete(el);
          }}
        >
          <DeleteIcon fontSize='small' />
        </IconButton>
      </Tooltip>
    ) : null;
  };

  getBackButton = () => {
    return (
      this.props.editMode &&
      this.props.match.params.id && (
        <Box px={1}>
          <Tooltip title={this.props.t('common:back') as string}>
            <IconButton
              size='small'
              onClick={() =>
                this.props.history.push({
                  pathname: '/dynamic-content',
                  state: { id: this.props.id || this.props.match.params.id }
                })
              }
            >
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )
    );
  };

  displayElements = (elements: DynamicContentSelector[], notFound?: boolean) =>
    elements
      .map((selector: DynamicContentSelector, i: number) => (
        <Box
          key={i}
          className={clsx(
            this.props.classes.listItem,
            notFound ? this.props.classes.notFound : this.props.classes.found,
            {
              [this.props.classes.listItemEdit]: this.props.editMode,
              [this.props.classes.listItemNoEdit]: !this.props.editMode
            }
          )}
          onClick={() => {
            if (!notFound) {
              this.props.onSelect(i);
            }
          }}
        >
          <Box display='flex' justifyContent='space-between' alignItems='flex-end'>
            <Typography variant='body2' color='primary'>
              {selector.tagName.toLocaleLowerCase()}
            </Typography>

            {this.getIcons(selector)}
          </Box>
          {selector.tagName.toLocaleLowerCase() === 'img' ? (
            this.getImageInfo(selector)
          ) : (
            <>
              <Box>{this.getInputLabel(selector)}</Box>
              <Box>{this.getInput(selector)}</Box>
            </>
          )}
        </Box>
      ))
      .reverse();

  getFoundElements = () => {
    return this.displayElements(this.props.selectors);
  };
  getNotFoundElements = () => {
    return this.displayElements(this.props.notFound, true);
  };

  render() {
    const { classes } = this.props;
    return (
      <Box className={clsx(classes.root, this.props.editMode && classes.rootEdit)} py={2}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          {this.getBackButton()}
          {this.props.editMode && this.getSaveIcon()}
        </Box>
        {this.title}
        <Box display='flex' flexWrap='wrap' alignItems='flex-start'>
          <Box
            className={clsx(classes.root, {
              [classes.listEdit]: this.props.editMode,
              [classes.listNoEdit]: !this.props.editMode
            })}
          >
            {this.getFoundElements()}
          </Box>
          {this.props.notFound.length > 0 && (
            <>
              <Box display='flex' alignItems='center' p={this.props.editMode ? 2 : 0} py={1}>
                <ErrorOutlineIcon color='error' style={{ marginRight: 10 }} />
                <Typography variant='h6' style={{ marginRight: 20 }} color='error'>
                  {this.props.t('editor.notFoundTitle')}
                </Typography>
              </Box>
              <Box
                className={clsx(classes.root, {
                  [classes.listEdit]: this.props.editMode,
                  [classes.listNoEdit]: !this.props.editMode
                })}
              >
                {this.getNotFoundElements()}
              </Box>
            </>
          )}
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(
  withTranslation(['dynamicContent', 'common'])(withRouter(DcSelectorComponent))
);

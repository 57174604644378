import { NewFeatures } from '../langTemplate';

const newFeatures: NewFeatures = {
  title: 'New features released',
  close: 'Close',
  hideUntil: 'Hide until new features are released',
  spylink: 'Spylink',
  spylinkDescription: 'Track your links and get notified about user interactions.',
  userRights: 'User permissions',
  userRightsDescription:
    'Share your domains with other users, and grant them read only or write access.',
  companies: 'Companies',
  companiesDescription:
    'Browse companies that recently visited you, sort and filter them by different criteria.',
  multipleDynamic: 'Multiple criteria for Dynamic Content',
  multipleDynamicDescription:
    'With this feature you can define multiple conditions that company has to fulfill, to see the content.',
  pushNotifications: 'Push notifications',
  pushNotificationsDescription: 'Get notified in the browser, whenever someone visits your site.',
  analytik: 'Analytics for Dynamic Content',
  analytikDescription:
    'New tab in dynamic content section. Identify how many times and which companies have seen your content.',
  spylinkAnalytik: 'Analytics for Spylink',
  spylinkAnalytikDescription:
    'New tab in spylink section. Take a look at how many visitors entered through spylink, and identify companies among them.',
  notificationsFrequency: 'Email notifications frequency',
  notificationsFrequencyDescription:
    'Adjust for each provided email, how often would you like to receive companies visits report.',
  excel: 'Export to excel',
  excelDescription:
    'We have released a feature, allowing to export visitors to excel file. It may take some time, depending on amount of requests reaching your site.',
  freemium: 'Freemium model',
  freemiumDescription:
    'From now on every new user is automatically signed up for our free plan, allowing to try out all the features with certain limitations. Details of each plan you can find in settings.',
  videoTracking: 'Disable video tracking',
  videoTrackingDescription:
    'There is an option to disabled video tracking on chosen parts of your site. Simply choose domain and provide URL to exclude from recording.',
  pdfTracking: 'PDF documents tracking',
  pdfTrackingDescription:
    'We have released new feature, allowing to share PDF documents and track user activities. Soon there will be option to attach this feature to a custom domain of your choice.',
};
export default newFeatures;

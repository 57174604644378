import React from "react";
import {
  Fade,
  createStyles,
  Theme,
  withStyles,
  Box,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";

const styles = (theme: Theme) =>
  createStyles({
    img: {
      transition: "all .5s ease",
      opacity: 0,
      minHeight: 200,
      boxShadow: "0 0 25px -15px rgba(0,0,0,0.5)",
    },
    imgLoaded: {
      opacity: 1,
      minHeight: "auto",
    },
    error: {
      opaicty: 0,
    },
    errorMsg: {
      backgroundColor: theme.palette.grey[100],
      minHeight: 200,
    },
  });
class ImageLoader extends React.Component<
  any,
  { loaded: boolean; error: boolean }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      loaded: false,
      error: false,
    };
  }
  onLoad = () => {
    this.setState(() => ({ loaded: true }));
  };
  onError = () => {
    this.setState(() => ({ loaded: true, error: true }));
  };
  render() {
    const { classes, errorMsg, ...rest } = this.props;
    return (
      <Box position="relative">
        <Fade in={!this.state.loaded} unmountOnExit>
          <Box position="absolute" left={0} right={0}>
            <Skeleton variant="rect" height={200} />
          </Box>
        </Fade>
        <Fade in={this.state.error} unmountOnExit>
          <Box
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
            left={0}
            right={0}
            className={classes.errorMsg}
          >
            <Typography align="center" variant="h6">
              {errorMsg}
            </Typography>
          </Box>
        </Fade>
        <Box
          className={clsx(
            classes.img,
            this.state.loaded && !this.state.error && classes.imgLoaded
          )}
        >
          <img
            src={this.props.src}
            onLoad={this.onLoad}
            onError={this.onError}
            {...rest}
            alt="Screenshot"
          />
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(ImageLoader);

import * as React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import '../assets/styles/app.css';
//DATA
import AppContext from '../data/AppContext';
import DataProvider from '../data/DataProvider';
//THEME
import { MuiThemeProvider } from '@material-ui/core/styles';
import primeLeadsTheme from './theme';
//COMPONENTS
import Container from './Container';
//MODELS
import { IState } from '../models/app.model';
//LOADER
import { AppLoader } from '../helpers';
import SignUp from '../components/signUp/SignUpForm';
import Login from '../components/signUp/LoginForm';

class App extends React.Component<{}, IState> {
  private dataService: DataProvider;
  constructor(props: {}) {
    super(props);
    this.dataService = new DataProvider(this);
  }
  componentDidMount() {
    const script = document.createElement('script');
    script.src = '//code.tidio.co/udmpdmlav3km3viwxpfvquw3j6axq4nq.js';
    script.async = true;
    document.body.appendChild(script);
  }

  render() {
    return (
      <div className='App'>
        <AppContext.Provider value={this.dataService}>
          <MuiThemeProvider theme={primeLeadsTheme}>
            <CssBaseline />
            {this.state.userData ? (
              <Router basename='/v2'>
                <Container />
              </Router>
            ) : this.state.loginFailed ? (
              <Router basename='/'>
                <Switch>
                  <Route exact path='/signup' component={SignUp} />
                  <Route exact path='/login' component={Login} />
                </Switch>
              </Router>
            ) : (
              <AppLoader />
            )}
          </MuiThemeProvider>
        </AppContext.Provider>
      </div>
    );
  }
}

export default App;

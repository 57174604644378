import React from 'react';
import { withTranslation } from 'react-i18next';
//CONTEXT
import AppContext from '../../data/AppContext';
//STYLES
import styles from './Visitors.style';
import GetAppIcon from '@material-ui/icons/GetApp';
//COMPONENTS
import {
  Box,
  Typography,
  withStyles,
  Switch,
  Collapse,
  Button,
  IconButton,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import Filter from './VisitorsFilter';
import VisitorsTable from './visitorsTable/VisitorsTable';
import { handleScroll, subtractDays } from '../../helpers/';
import { IComponentProps, IVisitor, IFilterState, IDomain } from '../../models/app.model';
import { withRouter, RouteComponentProps } from 'react-router';
import { defaultFilter } from '../../data/DataProvider';
import { Alert } from '@material-ui/lab';
import NoDomain from '../NoDomain';
import HelpIcon from '@material-ui/icons/Help';
//MODELS
interface IVisitorsProps extends IComponentProps, RouteComponentProps<any> {}
interface IVisitorsState {
  companies: IVisitor[];
  noMoreResults: boolean;
  filter: IFilterState;
  isFilterVisible: boolean;
  selected: IVisitor | null;
  domain: IDomain;
  excelReport: {
    fetching: boolean;
    range: {
      start: Date;
      end: Date;
    };
    days: number;
  };
  limitsExceeded: boolean;
}

class Visitors extends React.Component<IVisitorsProps, IVisitorsState> {
  static contextType = AppContext;
  mounted: boolean;
  constructor(props: IVisitorsProps) {
    super(props);
    this.mounted = false;
    this.state = {
      companies: [] as IVisitor[],
      filter: defaultFilter,
      limitsExceeded: false,
      isFilterVisible: JSON.parse(localStorage.getItem('isFilterVisible') || 'false'),
      excelReport: {
        fetching: false,
        days: 7,
        range: {
          start: new Date(),
          end: subtractDays(new Date(), 7),
        },
      },
    } as IVisitorsState;
  }
  toggleFilter = async () => {
    if (this.mounted) {
      await this.setState({
        ...this.state,
        isFilterVisible: !this.state.isFilterVisible,
      });
    }

    localStorage.setItem('isFilterVisible', JSON.stringify(this.state.isFilterVisible));
  };
  scrollEvent = () => {
    handleScroll(
      window,
      this.context.getData.fetching.visitors || this.state.noMoreResults,
      async () => {
        await this.updateFilter({
          pagination: {
            ...this.state.filter.pagination,
            pageIndex: this.state.filter.pagination.pageIndex + 1,
          },
        });
        this.loadCompaniesFromContext(this.state.filter, this.state.noMoreResults);
      }
    );
  };

  loadCompaniesFromContext = async (filter: IFilterState, noMoreResults: boolean) => {
    if (noMoreResults) {
      return;
    }
    const { companies: results, limitsExceeded } = await this.context.loadCompanies(filter);
    /*     if (results.limitExceeded && this.mounted) {
      return await this.setState({
        ...this.state,
        noMoreResults: true,
        limitExceeded: true
      });
    } */
    noMoreResults = results.length < filter.pagination.pageSize;
    if (this.mounted) {
      await this.setState({
        ...this.state,
        companies: [...this.state.companies, ...results],
        noMoreResults,
        limitsExceeded,
      });
    }
  };
  componentDidMount = async () => {
    this.mounted = true;
    await this.resetFilter();
    window.addEventListener('scroll', this.scrollEvent);
  };

  componentDidUpdate = async () => {
    if (this.state.domain !== this.context.getData.domain && !this.context.getData.fetching.visitors) {
      this.resetFilter(true);
    }
  };

  componentWillUnmount = async () => {
    this.mounted = false;
    await (this.context.setData = { selected: null });
    window.removeEventListener('scroll', this.scrollEvent);
  };

  resetFilter = async (newDomain?: boolean) => {
    const filter = newDomain ? { ...this.state.filter } : defaultFilter;
    filter.pagination.pageIndex = 0;
    if (this.mounted) {
      await this.setState({
        ...this.state,
        filter,
        limitsExceeded: false,
        domain: this.context.getData.domain,
        companies: [],
        noMoreResults: false,
      });
    }

    this.loadCompaniesFromContext(this.state.filter, this.state.noMoreResults);
  };
  updateFilter = async (propsToUpdate: any) => {
    if (this.mounted) {
      await this.setState({
        ...this.state,
        filter: {
          ...this.state.filter,
          ...propsToUpdate,
        },
      });
    }
  };

  getExcelFile = async () => {
    await this.setState({ ...this.state, excelReport: { ...this.state.excelReport, fetching: true } });
    const response = await fetch(`/app-api/v1/export/visitors/download`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({ ...this.state.filter, domain: this.context.getData.domain.key }),
    });
    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download =
        'primeleads_' +
        this.context.getData.domain.key +
        '_' +
        new Date().toISOString().slice(0, 10) +
        '.xlsx';
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
    this.setState({ ...this.state, excelReport: { ...this.state.excelReport, fetching: false } });
  };

  render() {
    const { classes, t } = this.props;
    return (
      <>
        {this.state.domain && (
          <Box className={classes.root}>
            <Box display='flex' alignItems='center' flexWrap='wrap' py={3} px={5}>
              <Typography variant='h4'>{t('title')}</Typography>
              <Box display='flex' alignItems='center' flexGrow='1' mx={5}>
                <Box mr={2}>
                  <Button
                    variant='contained'
                    color='primary'
                    endIcon={<GetAppIcon />}
                    onClick={this.getExcelFile}
                  >
                    {t('downloadExcel')}
                  </Button>
                  <Tooltip title={t('exportLimited') as string}>
                    <IconButton size='small' style={{ margin: '0 10px' }}>
                      <HelpIcon fontSize='small' />
                    </IconButton>
                  </Tooltip>

                  {this.state.excelReport.fetching && (
                    <Box display='flex' alignItems='center' my={1}>
                      <CircularProgress size={20} thickness={5} style={{ marginRight: 5 }} />
                      <Typography variant='caption'>{t('generatingExcel')}</Typography>
                    </Box>
                  )}
                </Box>
              </Box>
              <Typography variant='h6'>
                {this.state.isFilterVisible ? t('filters.hideFilters') : t('filters.showFilters')}
                <Switch checked={this.state.isFilterVisible} onChange={this.toggleFilter} />
              </Typography>
            </Box>
            {this.state.limitsExceeded && (
              <Box px={5} pb={2}>
                <Alert severity='info'>
                  <Typography
                    variant='body2'
                    dangerouslySetInnerHTML={{
                      __html: t('limitExceeded', {
                        limit: this.context.getData.limits.companyLimit || '-',
                      }),
                    }}
                  />
                </Alert>
              </Box>
            )}
            <Collapse in={this.state.isFilterVisible}>
              <Box px={5} pb={2}>
                <Filter
                  onResetFilter={this.resetFilter}
                  filter={this.state.filter}
                  onUpdateFilter={this.updateFilter}
                  onUpdateResults={async () => {
                    await this.updateFilter({
                      pagination: {
                        ...this.state.filter.pagination,
                        pageIndex: 0,
                      },
                    });
                    await this.setState({
                      ...this.state,
                      limitsExceeded: false,
                      companies: [],
                    });
                    this.loadCompaniesFromContext(this.state.filter, false);
                  }}
                />
              </Box>
            </Collapse>

            <VisitorsTable companies={this.state.companies} full={true} />
          </Box>
        )}
        {!this.context.getData.fetching.domains && !this.state.domain && <NoDomain />}
      </>
    );
  }
}
export default withStyles(styles)(withTranslation('visitors')(withRouter(Visitors)));

import React from 'react';
import {
  Snackbar,
  SnackbarContent,
  makeStyles,
  Typography,
  Box,
  CircularProgress,
  Theme
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const styles = (theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main
  },
  circle: {
    color: '#fff',
    animationDuration: '0.5s',
    marginRight: theme.spacing(1)
  }
});

interface ISaveSnackbar {
  open: boolean;
}

const SaveSnackbar = (props: ISaveSnackbar) => {
  const classes = makeStyles(styles)();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(props.open);
  const handleClose = () => {
    setOpen(false);
  };
  React.useEffect(() => {
    if (props.open) {
      setOpen(props.open);
    }
  }, [props]);
  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      autoHideDuration={2000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
    >
      <SnackbarContent
        className={classes.root}
        message={
          <Box display='flex' alignItems='center'>
            <CircularProgress
              variant='indeterminate'
              disableShrink
              className={classes.circle}
              size={20}
              thickness={5}
            />
            <Typography variant='body2'>{t('saving')}</Typography>
          </Box>
        }
      ></SnackbarContent>
    </Snackbar>
  );
};

export default SaveSnackbar;

import React, { Component, ChangeEvent } from 'react';
import { Box, Tabs, Tab } from '@material-ui/core';
import Companies from './Companies';
import Users from './Users';
import Excluded from './Excluded';
import { AuthUser, ExcludedUrl, DomainInfo } from '../../models/app.model';
import AppContext from '../../data/AppContext';
import IspList from './IspList';
import Logs from './Logs';

interface AdminOption {
  label: string;
}
interface AdminProps {}
interface AdminState {
  activeTab: number;
  users: AuthUser[];
  urls: ExcludedUrl[];
}
export default class Admin extends Component<AdminProps, AdminState> {
  static contextType = AppContext;
  state = {
    activeTab: 0,
    users: [] as AuthUser[],
    urls: [] as ExcludedUrl[],
    domains: [] as DomainInfo[],
  };
  options: AdminOption[] = [
    {
      label: 'Companies',
    },
    {
      label: 'Users',
    },
    {
      label: 'Excluded URLs',
    },
    {
      label: 'ISP list',
    },
    {
      label: 'Logs',
    },
  ];

  getActiveOption = (active: number) => {
    switch (active) {
      case 0:
        return <Companies />;
      case 1:
        return <Users />;
      case 2:
        return <Excluded admin={true} />;
      case 3:
        return <IspList />;
      case 4:
        return <Logs />;
      default:
        return null;
    }
  };
  switchTab = (e: React.ChangeEvent<{}>, i: number) => {
    this.setState({ ...this.state, activeTab: i });
  };
  render() {
    return (
      <Box display='flex' flexDirection='column' minHeight='90vh'>
        <Tabs
          textColor='primary'
          indicatorColor='primary'
          value={this.state.activeTab}
          onChange={this.switchTab}
        >
          {this.options.map((tab: AdminOption, i: number) => (
            <Tab key={i} label={tab.label} />
          ))}
        </Tabs>
        <Box flexGrow='1' style={{ backgroundColor: '#f9f9f9' }}>
          {this.getActiveOption(this.state.activeTab)}
        </Box>
      </Box>
    );
  }
}

import React, { SyntheticEvent } from 'react';
import AppContext from '../../data/AppContext';
import { createStyles, Theme, Box, Grid, Typography, withStyles, Hidden } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

import { IComponentProps } from '../../models/app.model';
import Visits from '../Visits';
const styles = (theme: Theme) =>
  createStyles({
    videoFrame: {
      outline: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
    },
    row: {
      'borderTop': `1px solid ${theme.palette.grey[200]}`,
      'cursor': 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.grey[50],
      },
    },
    tableHeaders: {
      '& .MuiTypography-body2': {
        fontWeight: 700,
      },
    },
  });
export interface VisitsListProps extends IComponentProps {
  requests: any[];
  company: any;
}
export interface VisitsListState {
  selected: number;
  isModalVisible: boolean;
}

const CompanyVisits = withStyles(styles)(withTranslation()(Visits));

class VisitsList extends React.Component<VisitsListProps, VisitsListState> {
  static contextType = AppContext;
  mounted: boolean;
  constructor(props: VisitsListProps) {
    super(props);
    this.state = {
      selected: -1,
      isModalVisible: false,
    };
    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { t, classes } = this.props;

    return this.props.company ? (
      <>
        <Grid container className={classes.tableHeaders}>
          <Hidden smDown>
            <Grid item xs={2} md={2} component={Box} display='flex' py={1} px={2}>
              <Typography variant='body2'>{t('company.date')}</Typography>
            </Grid>
            <Grid item xs={2} md={2} component={Box} display='flex' py={1} px={2}>
              <Typography variant='body2'>{t('company.device')}</Typography>
            </Grid>
            <Grid item xs={2} md={2} component={Box} display='flex' py={1} px={2}>
              <Typography variant='body2'>{`${t('company.video')}&${t('company.time')}`}</Typography>
            </Grid>

            <Grid item xs={12} md={3} component={Box} display='flex' py={1} px={2}>
              <Typography variant='body2'>{t('company.visitedSite')}</Typography>
            </Grid>
            <Grid item xs={12} md={3} component={Box} display='flex' py={1} px={2}>
              <Typography variant='body2'>{t('company.source')}</Typography>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid item xs={12} component={Box} display='flex' py={1} px={2}>
              <Typography variant='body2'>{t('common:visits')}</Typography>
            </Grid>
          </Hidden>
        </Grid>
        <CompanyVisits requests={this.props.requests} company={this.props.company} />
      </>
    ) : null;
  }
}

export default withStyles(styles)(withTranslation(['companies', 'visitors', 'common'])(VisitsList));

import { UploadPdf } from '../langTemplate';

const uploadPdf: UploadPdf = {
  title: 'Documents tracking',
  description: 'Track user activity on the shared pdf documents.',
  tabs: {
    visits: 'Visits',
    settings: 'Settings',
    customDomain: 'Custom domain',
  },
  uploadingNewFile: 'Uploading new file',
  linkCopied: 'Link copied to the clipboard',
  addedOn: 'Added on',
  document: {
    status: 'Document status',
    description: 'Description',
    linkPreview: 'Your link will look like ',
    password: 'Password',
    passwordProtected: 'Password protected',
    protectionDisabled: 'Protection is disabled',
    protectionEnabled: 'Protection is enabled',
    slug: 'Document slug',
    title: 'Document title',
    published: 'Published',
    dateRange: 'Date range active',
    publishDateRange: 'Publish within date range',
    publishedFrom: 'Publish from',
    publishedTill: 'Publish till',
    chooseFrequency: 'Choose frequency',
    frequency: {
      daily: 'Daily',
      visit: 'Every visit',
      weekly: 'Weekly',
    },
    notification: 'Email notifications',
    notificationDisabled: 'Notifications disabled',
    notificationEnabled: 'Notifications enabled',
  },
  steps: {
    setupEndpoint: 'Set up an enpoint',
    setupEndpointDescription: 'How do you url to look like?',
    createFile: 'Create and upload HTML file',
    createFileDescription: `Create a file named <b>{{fileName}}</b>. Include <b><i>primeleads script</i></b> and <b><i>meta tag</i></b> that will make sure <b>referer</b> value sent to server is correct.
      The body should be clear.</br>`,
    uploadFile: 'Upload file to the server',
    uploadFileDescription:
      'Upload the file you have just created into directory <b>{{dirName}}</b> on your server.',
    handleRedirect: 'Redirect to the file',
    handleRedirectDescription:
      'You need to tell the server, that every request matching <b>{{pathName}}</b> pattern should lead to the file that we just created.',
  },
  showActivity: 'Show user actions',
  downloaded: 'Downloads detected',
  notDownloaded: 'No downloads',
  dragDropFiles: 'Drag and drop files',
  dropFiles: 'Drop files here',
  clickToSelect: 'Click to select file',
  noVideos: 'No videos available',
  videos: 'Videos available',
  copyLink: 'Copy link to share',
  openSettings: 'Open settings',
  removeDocument: 'Remove document',
  confirmRemove: 'Please confirm removing document',
  totalTime: 'Total time',
  visit: '{{count}} visit',
  visit_plural: '{{count}} visits',
  download: '{{count}} download',
  download_plural: '{{count}} downloads',
};

export default uploadPdf;

import React from "react";
import { Component } from "react";
import { RouteProps, Route } from "react-router";
import Dashboard from "../components/dashboard/Dashboard";
import AppContext from "../data/AppContext";
import { isAdmin } from "../helpers";

interface MyRouteProps extends RouteProps {
  onlyAdmin: boolean;
}
export class MyRoute extends Component<MyRouteProps, { isAdmin: boolean }> {
  static contextType = AppContext;
  public state = {
    isAdmin: false,
  };
  public componentDidMount = async () => {
    const user = await this.context.getUser();
    const admin = isAdmin(user);
    this.setState({ isAdmin: admin });
  };
  public render() {
    const { onlyAdmin, ...rest } = this.props;
    if (onlyAdmin && !this.state.isAdmin) {
      return <Route exact path="/" component={Dashboard} />;
    }
    return <Route {...rest} />;
  }
}

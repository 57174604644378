import React, { FunctionComponent } from 'react';
import {
  Icon,
  Box,
  CircularProgress,
  Typography,
  IconProps,
  createStyles,
  Theme,
  makeStyles,
} from '@material-ui/core';
import AppContext from '../data/AppContext';
import { AuthUser, IDomain, UserRole } from '../models/app.model';
import { useTranslation } from 'react-i18next';
import Error404 from './404';

export const isAdmin = (user: AuthUser) => {
  return user && user.role === UserRole.Admin;
};

export const WrappedIcon = (props: IconProps) => <Icon {...props} />;
WrappedIcon.muiName = 'Icon';

export const getRandomColor = () => {
  let result = '#';
  const chars = '0123456789abcdef';
  for (let i = 0; i < 6; i++) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
};

export const createRandomString = () => {
  const length = 10;
  let key = '';
  const possible = 'abcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i++) {
    key += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return key;
};
export interface DevModeProps {
  onlyAdmin?: boolean;
  children?: React.ReactNode;
}
const styles = (theme: Theme) =>
  createStyles({
    devMode: {
      'position': 'relative',
      '&::before': {
        content: "'D'",
        display: 'block',
        backgroundColor: theme.palette.error.main,
        color: theme.palette.common.white,
        padding: theme.spacing(1),
        position: 'absolute',
        bottom: '100%',
        left: '10%',
      },
    },
  });
export const DevMode: FunctionComponent<DevModeProps> = ({ onlyAdmin, children, ...rest }): any => {
  const context = React.useContext(AppContext);
  const classes = makeStyles(styles)();
  return isAdmin(context.getData.userData) ? (
    <Box {...rest} className={classes.devMode}>
      {children}
    </Box>
  ) : onlyAdmin ? null : (
    <Error404 />
  );
};

export const isTrial = (user: AuthUser) => {
  if (user.id === 'auth0|5de4f1a2b2658c0e34ea172a') {
    return false;
  }
  const diffTime = Date.now() - new Date(user.date || 0).getTime();
  return (user as any).testDays - Math.ceil(diffTime / (1000 * 60 * 60 * 24)) > 0;
};

export const readOnlyMode = (user: AuthUser, domain: IDomain): boolean => {
  if (isAdmin(user)) {
    return false;
  }
  if (user.domains.some((d: IDomain) => d._id === domain._id)) {
    return false;
  }
  const role = (domain.allowedUsers as any).find((d: any) => d.email === user.emails[0].value).role;
  return role !== 'write';
  //return !user.domains.map(domain => domain._id).includes(domain._id);
};

export function handleScroll(element: Window, fetching: boolean, cb: () => any) {
  if (fetching) return;
  const elementHeight = element.innerHeight;
  const body = document.body;
  const html = document.documentElement;
  const docHeight = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  );
  const windowBottom = elementHeight + window.pageYOffset;
  if (windowBottom >= docHeight) {
    cb();
  }
}

export const copyToClipboard = (str: string) => {
  const el = document.createElement('textarea'); // Create a <textarea> element
  el.value = str; // Set its value to the string that you want copied
  el.setAttribute('readonly', ''); // Make it readonly to be tamper-proof
  el.style.position = 'absolute';
  el.style.left = '-9999px'; // Move outside the screen to make it invisible
  document.body.appendChild(el); // Append the <textarea> element to the HTML document
  const selected =
    (document as any).getSelection().rangeCount > 0 // Check if there is any content selected previously
      ? (document as any).getSelection().getRangeAt(0) // Store selection if found
      : false; // Mark as false to know no selection existed before
  el.select(); // Select the <textarea> content
  (document as any).execCommand('copy'); // Copy - only works as a result of a user action (e.g. click events)
  (document as any).body.removeChild(el); // Remove the <textarea> element
  if (selected) {
    // If a selection existed before copying
    (document as any).getSelection().removeAllRanges(); // Unselect everything on the HTML document
    (document as any).getSelection().addRange(selected); // Restore the original selection
  }
};

export const getCookieByName = (name: string): string | undefined => {
  const value = '; ' + document.cookie;
  const parts = value.split('; ' + name + '=');
  if (parts.length === 2) {
    return (parts as any).pop().split(';').shift();
  } else {
    return '';
  }
};

export const AppLoader = () => {
  const { t } = useTranslation();
  return (
    <Box display='flex' justifyContent='center' alignItems='center' style={{ minHeight: '100vh' }}>
      <Box display='flex' alignItems='center'>
        <CircularProgress color='primary' />
        <Typography variant='h6' style={{ marginLeft: 10 }}>
          {t('connecting')}
        </Typography>
      </Box>
    </Box>
  );
};

import { Spylink } from '../langTemplate';

const spylink: Spylink = {
  title: 'Dynamic Link',
  copied: 'Link was copied to clipboard',
  description: 'Get notified whenever visitors will click your link',
  addSpylink: 'Add new Dynamic Link',
  removeSpylink: 'Remove Dynamic Link',
  confirmRemove: 'Please confirm removing this link',
  details: 'Details',
  linkDescription: 'Please provide a link, that you would like to track.',
  filterLinks: 'Filter dynamic links by name',
  name: 'Name',
  notification: 'Notification email',
  label: 'Link to track',
  linkToCopy: 'Link to copy',
  linkToCopyDescription:
    'You can share this link. Once per hour you will get notified when user clicks it.',
  notificationsEnabled: 'Notifications are enabled',
  notificationsDisabled: 'Notifications are disabled',
  linkEnabled: 'Tracking is enabled',
  linkDisabled: 'Tracking is disabled',
  titleAdding: 'Add new Dynamic Link',
  limitTitle: 'Limit of rules exceeded',
  limitExceeded: 'Please upgrade your plan to enable more rules. Your current limit is {{limit}}.',
  steps: {
    link: 'Link to track',
    linkDescription: 'Please provide a link from your domain, that you would like to track.',
    linkPlaceholder: 'Please type url address',
    notifications: 'Email notifications (Optional)',
    notificationsDescription:
      'This option allows you to get notified when user clicks on the link. Reports are sent once per hour. You can disable this option later.',
    notificationsPlaceholder: 'Please type email address that will get notified',
    summary: 'Summary',
    summaryDescription:
      'Please give this link a name. This is how it will be displayed in the list. After finishing this process, you will find the link ready to copy and share.',
    summaryPlaceholder: 'Please type a name / alias'
  }
};

export default spylink;

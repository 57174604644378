import React, { Component } from 'react';
import { Grid, Typography, Box, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import styles from './Settings.style';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import GroupIcon from '@material-ui/icons/Group';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import MailIcon from '@material-ui/icons/Mail';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import DnsIcon from '@material-ui/icons/Dns';
import Details from './SettingsDetails';
import Upgrade from './SettingsUpgrade';
import Users from './SettingsUsers';
import Domains from './SettingsDomains';
import Notifications from './SettingsNotifications';
import { isAdmin, WrappedIcon } from '../../helpers';
import MyBox from '../LoadingBox';
import { withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { AuthUser, IComponentProps } from '../../models/app.model';
import Excluded from '../admin/Excluded';
import Integration from './SettingsIntegration';
import AppContext from '../../data/AppContext';

export interface SettingsProps extends IComponentProps, RouteComponentProps {}

class Settings extends Component<SettingsProps, { activeOption: number }> {
  static contextType = AppContext;
  private options: any;
  constructor(props: SettingsProps) {
    super(props);
    const locationState = this.props.location.state as any;
    const search = this.props.location.search;
    const parsedQuery = new URLSearchParams(search);
    const isHubspotRedirect = parsedQuery.get('hubspot');
    const planId = parsedQuery.get("planId");
    const activeTab = planId ? (this.isIntegrationEnabled() ? 7 : 6) : 0;
    this.state = {
      activeOption: isHubspotRedirect ? 4 : locationState?.activeTab || activeTab,
    };
    this.options = [
      { name: 'domainsItem', icon: <DnsIcon />, component: <Domains /> },
      {
        name: 'accountItem',
        icon: <AccountBoxIcon />,
        component: <Details upgradeAction={() => this.selectItem(5)} />,
      },
      {
        name: 'usersItem',
        icon: <GroupIcon />,
        component: <Users />,
      },
      {
        name: 'notificationsItem',
        icon: <MailIcon />,
        component: <Notifications />,
      },
      {
        name: 'integrationsItem',
        icon: <SettingsInputComponentIcon />,
        component: <Integration />,
        excluded: this.isIntegrationEnabled()
      },
      {
        name: 'excludedUrls',
        icon: <VideocamOffIcon />,
        component: <Excluded admin={false} />,
      },
      {
        name: 'upgradeItem',
        icon: <NewReleasesIcon color='secondary' />,
        component: <Upgrade />,
      },
    ];
  }
  componentDidUpdate = (prevProps: SettingsProps) => {
    const locationState = (this.props.location as any).state;
    if (
      locationState &&
      locationState.activeTab &&
      locationState.activeTab !== this.state.activeOption
    ) {
      this.setState({ ...this.state, activeOption: locationState.activeTab });
    }
  };

  selectItem = (i: number) => {
    this.props.history.replace({
      state: {
        activeTab: i,
      },
    });
    this.setState({ ...this.state, activeOption: i });
  };
  isAllowed = (user: AuthUser) => {
    const allowed = ['auth0|5efe4e44bb331300139cf613'];
    return allowed.includes(user.user_id);
  };
  isIntegrationEnabled = () =>{
    const { userData = {} } = this.context?.getData || {};
    const {role, stripe = {}} = userData;
    const { hubspot } = stripe;
    return !!hubspot || role === "admin";
  }
  render() {
    const { classes, t } = this.props;
    const { userData } = this.context.getData;
    return (
      <Box m={5} mb={10}>
        <MyBox condition={false} mb={5} empty={false}>
          <Grid container className={classes.root}>
            <Grid item xs={12} md={3} component={Box} className={classes.options}>
              <Typography variant='h4' gutterBottom className={classes.title}>
                {t('title')}
              </Typography>
              <List disablePadding>
                {this.options
                  .filter((option: any) =>
                    option.admin ? isAdmin(userData) || this.isAllowed(userData) : true
                  )
                  .map((option: any, i: number) => (
                    <ListItem
                      button
                      key={i}
                      onClick={() => this.selectItem(i)}
                      selected={i === this.state.activeOption}
                    >
                      <Box px={3} display='flex'>
                        <ListItemIcon>
                          <WrappedIcon color='disabled'>{option.icon}</WrappedIcon>
                        </ListItemIcon>
                        <ListItemText>
                          <Typography variant='body2'>{t(`${option.name}`)}</Typography>
                        </ListItemText>
                      </Box>
                    </ListItem>
                  ))}
              </List>
            </Grid>
            <Grid item xs={12} md={9} component={Box}>
              {this.state.activeOption >= 0 && this.options[this.state.activeOption].component}
            </Grid>
          </Grid>
        </MyBox>
      </Box>
    );
  }
}

export default withStyles(styles)(withTranslation('settings')(Settings));

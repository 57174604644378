import { Common } from '../langTemplate';

const common: Common = {
  saveChanges: 'Save changes',
  createContent: 'Create content',
  scriptCopied: 'Script was copied to the clipboard',
  saveShort: 'Save',
  visits: 'Visits',
  visit: 'Visit',
  date: 'Date',
  noData: 'No data available',
  preview: 'Preview',
  saving: 'Saving changes to database',
  back: 'Back',
  next: 'Next',
  edit: 'Edit',
  duplicate: 'Duplicate',
  remove: 'Remove',
  previous: 'Previous',
  finish: 'Finish',
  selectors: 'selectors',
  availableActions: 'Available actions',
  loading: 'We are analyzing your traffic',
  videoLoading: 'Video is loading',
  requestsLoading: 'Loading requests',
  copyToClipboard: 'Copy to clipboard',
  options: 'Options',
  oldInterface: 'Old interface',
  pleaseChoose: 'Please choose an option',
  noScreenshot: 'Could not get screenshot of the website',
  error: 'There was an error. Please contact support for more information.',
  or: 'or',
  fetchingMore: 'Loading companies...',
  shared: 'Shared',
  purchased:
    'Payment was successfull. Thank you for the purchase. Your domain limit have changed to {{limit}}.',
  newWindow: 'Open in new tab',
  copyOf: 'Copy of ',
  connecting: 'Connecting to database',
  notAvailableInPlan: 'This feature is not available in your plan',
  comingSoon: 'This feature will be soon available',
  optional: 'Optional',
  clear: 'Clear',
  example: 'Example',
  here: 'here',
  previewTab: 'Preview',
  settingsTab: 'Settings',
  analyticsTab: 'Analytics',
  visitsTab: 'Visits',
  days: '{{days}} days',
  rules: '{{rules}} rules',
  companies: '{{companies}} companies',
  unlimited: 'Unlimited',
  upgradePlan: 'Upgrade plan',
  successSubscribe: 'Successfully signed up. Please check your mailbox and confirm subscription.',
  errorSubscribe: 'Something went wrong, please contact our support.',
  analytics: {
    timeline: 'Timeline with all of the visits',
    triggeredBy: 'Triggered by following companies',
    spylinkEntries: 'Entries through spylink',
    allVisits: 'All triggers',
    companyVisits: 'Triggered by companies',
    uniqueCompanies: 'Unique companies',
    userVisits: 'Triggered by private users',
    privateUser: 'Triggered by ISP, equal to visit from private user',
    companies: 'Triggered by company other than ISP',
    isp: '<b>ISP</b> - Internet service provider',
  },
  dialogs: {
    emptyName: 'Empty domain name found',
    domainRemove: 'Remove domains',
    domainLimit: 'Domain limit reached',
    userRemove: 'Remove user',
    cancel: 'Cancel',
    confirm: 'Confirm',
  },
  header: {
    loggedInAs: 'Logged in as',
    changeLanguage: 'Change language',
    availableDomains: 'Available domains',
  },
  noDomain: {
    title: 'No domain found',
    subtitle: "You haven't added any domain yet. It's about time to change it!",
    button: 'Start onboarding',
  },
  featureDisabled: {
    title: "Your plan doesn't include this feature",
    subtitle: 'Upgrade now, and start using it',
    button: 'Upgrade',
  },
  trial: {
    ended: 'Your trial subscribtion has ended. Please choose one of our plans.',
    subscribe: 'Subscribe',
    daysLeft: '{{days}} days left till the end of trial time. ',
    choosePlan: 'Browse plans.',
  },
  nav: {
    'dashboard': 'Dashboard',
    'visitors': 'Visitors',
    'dynamicContent': 'Dynamic content',
    'reminders': 'Reminders',
    'settings': 'Settings',
    'help': 'Help',
    'logout': 'Logout',
    'companies': 'Companies',
    'newFeatures': 'New features',
    'spylink': 'Spylink',
    'shared-documents': 'PDF tracking',
  },
};

export default common;

import React, { Component, ChangeEvent } from 'react';
import AppContext from '../../data/AppContext';
import { IVisitor, IComponentProps, IDomain } from '../../models/app.model';
import {
  Box,
  Typography,
  Grid,
  withStyles,
  createStyles,
  Theme,
  TextField,
  Fab,
  Tooltip,
  IconButton,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import LoadingBox from '../LoadingBox';
import { DescribeDate, getCompanyIcon, handleScroll } from '../../helpers';
import NoDomain from '../NoDomain';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import WebIcon from '@material-ui/icons/Web';
import clsx from 'clsx';

export interface CompanyAllProps extends IComponentProps, RouteComponentProps {}
export interface CompanyAllState {
  companies: IVisitor[];
  filter: {
    sortBy: string;
    sortAsc: number;
    startDate: string;
    endDate: string;
    searchField: string;
    showOnlyRecognised: boolean;
    page: number;
  };
  months: Date[];
  domain: IDomain;
}

const styles = (theme: Theme) =>
  createStyles({
    rowHeader: {
      backgroundColor: theme.palette.grey[200],
    },
    row: {
      'borderTop': `1px solid ${theme.palette.grey[200]}`,
      'backgroundColor': theme.palette.common.white,
      '&.hidden': {
        'cursor': 'no-drop',
        'opacity': '0.5!important',
        '& a': {
          pointerEvents: 'none',
        },
      },
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      },
    },
    blurred: {
      filter: 'blur(4px)',
      color: theme.palette.grey[600],
    },
    companyName: {
      color: theme.palette.primary.main,
    },
    scroll: {
      '& #scroll': {
        width: '1.5rem',
        padding: '0.25rem',
        height: '3rem',
        border: `2px solid ${theme.palette.grey[500]}`,
        borderRadius: '2rem',
      },
      '& #wheel': {
        backgroundColor: theme.palette.grey[400],
        height: '1.5rem',
        width: '100%',
        borderRadius: '2rem',
        animation: 'scroll .5s infinite alternate',
      },
    },
  });

class CompanyAll extends Component<CompanyAllProps, CompanyAllState> {
  static contextType = AppContext;
  private mounted: boolean;
  private table: React.RefObject<HTMLDivElement>;
  constructor(props: CompanyAllProps) {
    super(props);
    const today = new Date();
    const startDate = new Date(today.getFullYear(), today.getMonth(), 1);
    startDate.setUTCHours(24, 0, 0, 0);
    const endDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    endDate.setUTCHours(24, 0, 0, 0);
    this.state = {
      companies: [] as IVisitor[],
      months: [] as Date[],
      filter: {
        sortBy: 'requests',
        sortAsc: 1,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        searchField: '',
        showOnlyRecognised: false,
        page: 0,
      },
    } as CompanyAllState;
    this.mounted = false;
    this.table = React.createRef<HTMLDivElement>();
  }
  async componentDidMount() {
    this.mounted = true;
    window.addEventListener('scroll', this.scrollEvent);
    const { domain } = this.context.getData;
    if (!domain) {
      return;
    }
    await this.setState({
      ...this.state,
      domain,
    });
  }
  scrollEvent = () => {
    handleScroll(window, this.state.filter.page * 50 > this.state.companies.length, async () => {
      this.setState({
        ...this.state,
        filter: { ...this.state.filter, page: this.state.filter.page + 1 },
      });
    });
  };

  async componentDidUpdate(prevProps: CompanyAllProps, prevState: CompanyAllState) {
    if (
      (!prevState.domain && this.state.domain !== prevState.domain) ||
      this.context.getData.domain !== this.state.domain
    ) {
      await this.setState({ ...this.state, domain: this.context.getData.domain });
      this.fetchCompanies();
    }
  }

  monthDiff = (d1: Date, d2: Date) => {
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  };
  countMonthsByDomain = () => {
    const { created } = this.state.domain;
    const start = new Date(created);
    start.setDate(1);
    start.setUTCHours(0, 0, 0, 0);
    const differenceInMonths = this.monthDiff(start, new Date());
    const months = [];
    for (let i = 0; i <= differenceInMonths; i++) {
      months.push(new Date(start));
      start.setMonth(start.getMonth() + 1);
    }
    return months;
  };
  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('scroll', this.scrollEvent);
  }
  updateFilter = (e: ChangeEvent<HTMLInputElement>, checked?: boolean) => {
    if (this.mounted) {
      if (checked) {
        this.setState({
          ...this.state,
          filter: { ...this.state.filter, [e.target.name]: e.target.checked },
        });
      } else {
        this.setState({
          ...this.state,
          filter: { ...this.state.filter, [e.target.name]: e.target.value },
        });
      }
    }
  };
  updateDateRange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const startDate = new Date(e.target.value);
    const endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
    this.setState({
      ...this.state,
      filter: {
        ...this.state.filter,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      },
    });
  };

  fetchCompanies = async () => {
    const { filter } = this.state;
    const months = this.countMonthsByDomain();
    filter.sortAsc = +filter.sortAsc;
    const companies = await this.context.getAllCompanies(filter);
    if (this.mounted) {
      this.setState({ ...this.state, companies, months });
    }
  };
  getFilter = () => {
    const { t, i18n } = this.props;
    return (
      <Box pt={2}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12} sm='auto' component={Box} ml={2}>
            <TextField
              fullWidth
              variant='outlined'
              name='searchField'
              value={this.state.filter.searchField}
              onChange={this.updateFilter}
              placeholder={t('filter.keywordPlaceholder')}
              label={t('filter.keyword')}
              style={{ minWidth: 350 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm='auto' component={Box} ml={2}>
            <TextField
              fullWidth
              variant='outlined'
              select
              value={this.state.filter.sortBy}
              onChange={this.updateFilter}
              name='sortBy'
              label={t('filter.sortBy')}
              SelectProps={{
                native: true,
              }}
            >
              <option value='requests'>{t('filter.numberRequests')}</option>
              <option value='companyName'>{t('filter.alphabetically')}</option>
              <option value='city'>{t('filter.location')}</option>
              <option value='lastDate'>{t('filter.lastVisit')}</option>
              <option value='time'>{t('filter.totalTime')}</option>
            </TextField>
          </Grid>
          <Grid item xs={12} sm='auto' component={Box} ml={2}>
            <TextField
              fullWidth
              variant='outlined'
              select
              value={+this.state.filter.sortAsc}
              name='sortAsc'
              onChange={this.updateFilter}
              label={t('filter.order')}
              SelectProps={{
                native: true,
              }}
            >
              <option value={-1}>{t('filter.ascending')}</option>
              <option value={1}>{t('filter.descending')}</option>
            </TextField>
          </Grid>
          <Grid item xs={12} sm='auto' component={Box} ml={2}>
            <TextField
              fullWidth
              variant='outlined'
              select
              value={this.state.filter.startDate}
              name='dateRange'
              onChange={this.updateDateRange}
              label={t('filter.dateRange')}
              SelectProps={{
                native: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            >
              {this.state.months.map((m: Date, i: number) => {
                return (
                  <option value={m.toISOString()} key={i}>
                    {m.toLocaleString(i18n.language, { month: 'long' })} / {m.getFullYear()}
                  </option>
                );
              })}
            </TextField>
          </Grid>
          <Grid item xs={12} sm='auto' component={Box} ml={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.filter.showOnlyRecognised}
                  name='showOnlyRecognised'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateFilter(e, true)}
                />
              }
              label={t('showOnlyRecognised')}
            />
          </Grid>
          <Grid item xs={12} sm='auto'>
            <Tooltip title={t('searchButton') as string}>
              <Fab color='primary' onClick={this.fetchCompanies}>
                <SearchIcon />
              </Fab>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    );
  };
  getHeaders = () => {
    const { t, classes } = this.props;
    return (
      <Box p={2} className={classes.rowHeader}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <Typography variant='h6'>{t('details')}</Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography variant='h6'>{t('type')}</Typography>
          </Grid>
          <Grid item xs={12} md={1}>
            <Typography variant='h6'>{t('requests')}</Typography>
          </Grid>
          <Grid item xs={12} md={1}>
            <Typography variant='h6'>{t('totalTime')}</Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography variant='h6'>{t('lastDate')}</Typography>
          </Grid>
          <Grid item xs={12} md={'auto'}>
            <Typography variant='h6'>{t('actions')}</Typography>
          </Grid>
        </Grid>
      </Box>
    );
  };
  getCompanyList = () => (
    <div ref={this.table}>
      {this.state.companies
        .filter((company: IVisitor) => (this.state.filter.showOnlyRecognised ? !company.hidden : true))
        .slice(0, Math.min((this.state.filter.page + 1) * 50, this.state.companies.length))
        .map((company: IVisitor, i: number) => (
          <Box
            key={i}
            px={2}
            py={1}
            className={clsx(company.hidden && 'hidden', this.props.classes.row)}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} md={4} component={Box} display='flex'>
                <Box display='flex' alignItems='center'>
                  <Typography variant='h6'>#{i + 1}</Typography>
                  <Box mx={1}>{getCompanyIcon(company.domain)}</Box>
                  <Box className={clsx(company.hidden && this.props.classes.blurred)}>
                    <Typography variant='h6' color='primary'>
                      <Link
                        target='_blank'
                        className={this.props.classes.companyName}
                        to={`/companies/${company.companyKey}`}
                      >
                        {company.companyName}
                      </Link>
                    </Typography>

                    <Typography variant='caption' color='textSecondary'>
                      {company.adress || company.city}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={2} component={Box} display='flex'>
                <Box display='flex' alignItems='center'>
                  <Typography variant='body2' color='textSecondary'>
                    {company.type}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={1} component={Box} display='flex'>
                <Box display='flex' alignItems='center'>
                  <Typography variant='h5'>{company.requests}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={1} component={Box} display='flex'>
                <Box display='flex' alignItems='center' mr={2}>
                  <Typography variant='h5'>{company.time.slice(0, 5)}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={2} component={Box} display='flex'>
                <Box display='flex' justifyContent='center' flexDirection='column'>
                  <Typography variant='body2'>
                    <DescribeDate date={company.lastDate} />
                  </Typography>
                  <Typography component='span' variant='caption' color='textSecondary'>
                    {new Date(company.lastDate).toLocaleString()}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={1} component={Box} display='flex'>
                <Box display='flex' alignItems='center'>
                  {!company.hidden && (
                    <>
                      <Tooltip title={this.props.t('visitPage') as string}>
                        <IconButton href={company.domain} target='_blank'>
                          <WebIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={this.props.t('addDynamic') as string}>
                        <IconButton
                          component={Link}
                          to={`./dynamic-content/add?companyName=${company.companyName}`}
                          target='_blank'
                        >
                          <DynamicFeedIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        ))}
    </div>
  );

  getScrollAnimation = () => {
    return (
      <Box
        p={3}
        display='flex'
        alignItems='center'
        justifyContent='center'
        className={this.props.classes.scroll}
      >
        <div id='scroll'>
          <div id='wheel'></div>
        </div>
      </Box>
    );
  };
  render() {
    const { t } = this.props;
    return (
      <>
        {this.state.domain && (
          <Box m={5} mb={10}>
            <Box
              pb={3}
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              flexWrap='wrap'
            >
              <Box>
                <Typography variant='h4'>{t('title')}</Typography>
                <Typography variant='subtitle1'>{t('description')}</Typography>
              </Box>
              <Box pt={1}>{this.getFilter()}</Box>
            </Box>
            <LoadingBox
              condition={this.context.getData.fetching.companies || !this.state.domain}
              empty={this.state.companies.length === 0}
            >
              {this.getHeaders()}
              {this.getCompanyList()}
            </LoadingBox>
            {(this.state.filter.page + 1) * 50 < this.state.companies.length &&
              this.getScrollAnimation()}
          </Box>
        )}

        {!this.context.getData.fetching.domains && !this.state.domain && <NoDomain />}
      </>
    );
  }
}

export default withStyles(styles)(withTranslation(['companies', 'common'])(withRouter(CompanyAll)));

import React from 'react';
import {
  Box,
  Typography,
  Grid,
  TextField,
  Snackbar,
  Tooltip,
  Link as HLink,
  Fab,
  Theme,
  createStyles,
  withStyles,
  IconButton,
} from '@material-ui/core';
import { filterURL, DevMode } from '../../helpers';
import { withTranslation } from 'react-i18next';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import WebIcon from '@material-ui/icons/Web';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import MuiAlert from '@material-ui/lab/Alert';
import { copyToClipboard, ImageLoader } from '../../helpers';
import SaveSnackbar from '../SaveSnackbar';
import LazyLoad from 'react-lazyload';
import AppContext from '../../data/AppContext';
import RemindersList from '../reminders/RemindersList';
import { Reminder } from '../reminders/Reminders';
import { IComponentProps } from '../../models/app.model';
import ReminderAdd from '../reminders/ReminderAdd';
import { Link } from 'react-router-dom';

export interface DetailsProps extends IComponentProps {
  company: any;
  note: any;
  onSave: any;
  onUpdate: any;
  screenShot: string;
  mini?: boolean;
  reminders: Reminder[];
  toggleStatus: (reminder: Reminder) => any;
  onRemove: (reminder: Reminder) => any;
  addReminder: (reminder: Reminder) => any;
}
export interface DetailsState {
  copied: boolean;
  isp: boolean;
  ispMessage: string;
  note: {
    status: string;
    ipInfoId: string;
    notes: string;
  };
  isSaving: boolean;
  reminderForm: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    thumbnail: {
      display: 'block',
      width: '100%',
    },
    controls: {},
    icon: {
      margin: '0 10px 10px 0',
    },
  });

class Details extends React.Component<DetailsProps, DetailsState> {
  static contextType = AppContext;
  constructor(props: DetailsProps) {
    super(props);
    this.state = {
      copied: false,
      isp: false,
      ispMessage: '',
      note: props.note || {
        status: "",
        ipInfoId: props.company._id,
        notes: '',
      },
      isSaving: false,
      reminderForm: false,
    };
    console.log("PROPS NOTE", props.note)
  }
  componentDidUpdate(prevProps:any){
    if(prevProps.note && this.props.note !== prevProps.note){
      this.setState({ note: {...this.props.note, notes: this.state.note.notes || this.props.note.notes }})
    }
  }

  copyCompanyDetails = () => {
    copyToClipboard(
      (
        this.props.company._id +
        '\n' +
        (this.props.company.name || ',') +
        '\n' +
        (this.props.company.type || ',') +
        '\n' +
        (this.props.company.adress || ',') +
        '\n' +
        (this.props.company.tele || ',') +
        '\n' +
        (this.props.company.domain || ',')
      ).replace(/,\n/g, '')
    );
    this.setState({ ...this.state, copied: true });
  };

  saveNote = async () => {
    console.log("STATE", this.state)
    this.setState({ ...this.state, isSaving: true }, async ()=>{
      await this.props.onUpdate(this.state.note);
      this.props.onSave();
      this.setState({ ...this.state, isSaving: false });
    });
  };
  getControls = () => {
    const { classes, mini, company, t } = this.props;
    return (
      <Box mt={3}>
        <Typography gutterBottom variant='body2' color='textSecondary'>
          {t('common:availableActions')}
        </Typography>
        <Box my={1}>
          <Tooltip title={t('common:copyToClipboard') as string}>
            <Fab color='primary' className={classes.icon} onClick={this.copyCompanyDetails}>
              <FileCopyIcon />
            </Fab>
          </Tooltip>
          {mini && (
            <Tooltip title={t('common:newWindow') as string}>
              <Fab
                color='primary'
                className={classes.icon}
                target='_blank'
                href={'./companies/' + company._id}
              >
                <WebIcon />
              </Fab>
            </Tooltip>
          )}
          <Tooltip title={t('company.addContent') as string}>
            <Fab
              color='primary'
              className={classes.icon}
              component={Link}
              target='_blank'
              to={`/dynamic-content/add?companyName=${company.name}`}
              disabled={this.context.getData.readOnly}
            >
              <DynamicFeedIcon />
            </Fab>
          </Tooltip>
        </Box>
      </Box>
    );
  };
  getCompanyNote = () => {
    const { t } = this.props;
    return (
      <>
        <Typography variant='h6'>{t('company.notes')}</Typography>
        <TextField
          multiline
          rows='4'
          value={this.state.note.notes}
          variant='outlined'
          onChange={(e) =>
            this.setState({
              ...this.state,
              note: {
                ...this.state.note,
                notes: e.target.value,
              },
            })
          }
          fullWidth
          name='note'
          margin='normal'
        />
        <Box my={2}>
          <Fab
            onClick={this.saveNote}
            color='primary'
            variant='extended'
            disabled={this.context.getData.readOnly}
          >
            {t('common:saveChanges') as string}
          </Fab>
        </Box>
      </>
    );
  };
  addReminder = async (reminder: Reminder) => {
    console.log(reminder);
    await this.setState({ ...this.state, isSaving: true });
    await this.props.addReminder(reminder);
    await this.setState({ ...this.state, reminderForm: false, isSaving: false });
  };

  render() {
    const { company, screenShot, t, classes, reminders } = this.props;
    return (
      <Box overflow='hidden'>
        <Grid container component={Box} p={5} spacing={5}>
          <Grid item xs={12} md={6} component={Box}>
            <Typography variant='body2' color='textSecondary'>
              {t('company.companyType')}
            </Typography>
            <Typography variant='h6' gutterBottom>
              {company.type}
            </Typography>
            {company.adress && (
              <>
                <Typography variant='body2' color='textSecondary'>
                  {t('company.address')}
                </Typography>
                <Typography variant='h6' gutterBottom>
                  {company.adress}
                </Typography>
              </>
            )}
            {company.tele && (
              <>
                <Typography variant='body2' color='textSecondary'>
                  {t('company.phone')}
                </Typography>

                <Typography variant='h6' gutterBottom>
                  {company.tele}
                </Typography>
              </>
            )}
            {company.domain && company.domain !== 'null' && (
              <>
                <Typography variant='body2' color='textSecondary'>
                  {t('company.website')}
                </Typography>
                <Typography variant='h6' gutterBottom>
                  <HLink color='secondary' gutterBottom href={company.domain} target='_blank'>
                    {filterURL(company.domain, false, true, false)}
                  </HLink>
                </Typography>
              </>
            )}
            {this.getControls()}
          </Grid>
          <Grid item xs={12} md={6} component={Box}>
            {screenShot && (
              <LazyLoad once>
                <ImageLoader
                  src={screenShot}
                  className={classes.thumbnail}
                  errorMsg={t('common:noScreenshot')}
                />
              </LazyLoad>
            )}
          </Grid>
          <Grid item xs={12}>
            <DevMode onlyAdmin={true}>
              {this.state.reminderForm && (
                <Box mb={3}>
                  <ReminderAdd
                    onSave={this.addReminder}
                    onCancel={() => this.setState({ ...this.state, reminderForm: false })}
                  />
                </Box>
              )}
              <Box display='flex' alignItems='center'>
                <Typography variant='h6' gutterBottom>
                  Reminders related to this company
                </Typography>
                <Tooltip title={t('company.addReminder') as string}>
                  <IconButton
                    className={classes.icon}
                    onClick={() => {
                      this.setState({ ...this.state, reminderForm: true });
                    }}
                  >
                    <AddAlertIcon />
                  </IconButton>
                </Tooltip>
              </Box>

              {reminders.length > 0 ? (
                <>
                  <Box my={2}>
                    <RemindersList
                      reminders={reminders}
                      companyView={true}
                      onUpdate={this.props.toggleStatus}
                      onRemove={this.props.onRemove}
                    />
                  </Box>
                </>
              ) : (
                <Box mb={3}>
                  <Typography>{t('common:noData')}</Typography>
                </Box>
              )}
            </DevMode>

            {this.getCompanyNote()}
          </Grid>
        </Grid>
        <Snackbar
          open={this.state.copied}
          autoHideDuration={3000}
          onClose={() => this.setState({ ...this.state, copied: false })}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <MuiAlert variant='filled' severity='info'>
            {t('company.copied')}
          </MuiAlert>
        </Snackbar>

        <SaveSnackbar open={this.state.isSaving} />
      </Box>
    );
  }
}

export default withStyles(styles)(withTranslation(['companies', 'common'])(Details));

import React, { SyntheticEvent, RefObject } from 'react';
import { withStyles } from '@material-ui/styles';
import styles from './Dynamic.style';
import { Box, TextField, Grid, IconButton, Tooltip, Typography, Link } from '@material-ui/core';
import AppContext from '../../data/AppContext';
import { DynamicType, CompanyStatusType, DynamicContentSelector } from './Dynamic';

import EditIcon from '@material-ui/icons/Edit';
import ContentEditor from './editor/DynamicContentEditor';
import { IComponentProps } from '../../models/app.model';
import { withTranslation } from 'react-i18next';
import { ISpyLink } from '../spylink/Spylink';

interface DynamicEditFormProps extends IComponentProps {
  selected: any;
  onChange: (e: any) => any;
  onDisable: (e: any) => any;
  onEditRules: (i: number) => any;
}
interface DynamicEditFormState {
  key: number;
  defaultUrl: string;
  removeDialog: boolean;
}

class DynamicEditForm extends React.Component<DynamicEditFormProps, DynamicEditFormState> {
  static contextType = AppContext;
  isSaving: boolean;
  mounted: boolean;
  frame: RefObject<HTMLIFrameElement> = React.createRef<HTMLIFrameElement>();
  frameContainer: RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
  constructor(props: any) {
    super(props);
    this.state = {
      key: 0,
      defaultUrl: '',
      removeDialog: false,
    };
    this.isSaving = false;
    this.mounted = false;
  }
  dynamicTypes = [
    { text: 'dynamic-message', value: DynamicType.Message },
    { text: 'dynamic-text', value: DynamicType.Text },
  ];
  CompanyNoteTypes = [
    { label: 'isCustomer', value: CompanyStatusType.isCustomer },
    { label: 'competitor', value: CompanyStatusType.competitor },
    { label: 'isFavorite', value: CompanyStatusType.isFavorite },
    { label: 'notInteresting', value: CompanyStatusType.notInteresting },
  ];
  async componentDidMount() {
    this.mounted = true;
  }
  async componentDidUpdate(prevProps: DynamicEditFormProps) {
    if (prevProps !== this.props && this.mounted) {
      await this.reloadPreview();
    }
  }
  componentWillUnmount() {
    this.mounted = false;
  }

  private get proxyUrl(): string {
    const url = this.props.selected.website || this.state.defaultUrl;
    const link = btoa(url);
    console.log(link, url);
    return `/proxy/${link}?dc_id=${this.props.selected._id}`;
  }

  reloadPreview = () => {
    if (this.mounted) {
      this.setState({ ...this.state, key: this.state.key + 1 });
    }
  };

  preventClick = (e: SyntheticEvent) => {
    e.preventDefault();
    return;
  };

  getRuleValue = (key: string, rule: any) => {
    switch (key) {
      case 'status':
        return rule.value && this.props.t('labels.' + rule.value);
      case 'location':
        return rule.value.city;
      case 'spylink':
        const link: ISpyLink = this.context.getData.spylinks.find(
          (spylink: ISpyLink) => spylink.linkId === rule.value
        );
        if (!link && this.props.selected.rules) {
          return '';
        }
        const url = new URL(link.linkUrl || 'https://primeleads.de');
        url.searchParams.append('sl', link.linkId);
        return (
          <Link color='secondary' href={url.href} target='_blank'>
            {url.href}
          </Link>
        );
      default:
        return rule.value;
    }
  };
  render() {
    const { classes, selected, onChange, t } = this.props;
    return (
      (selected && (
        <Box p={{ xs: 2, md: 5 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                value={selected.name}
                name='name'
                onChange={onChange}
                label={t('labels.ruleName')}
                variant='outlined'
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                fullWidth
                variant='outlined'
                label={t('labels.contentType')}
                value={selected.dynamicType}
                name='dynamicType'
                onChange={onChange}
                SelectProps={{
                  native: true,
                }}
              >
                {this.dynamicTypes.map((type, i) => (
                  <option key={i} value={type.value}>
                    {t('labels.' + type.text)}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label={t('labels.website')}
                fullWidth
                value={selected.website}
                name='website'
                onChange={onChange}
                placeholder={t('typeUrl')}
                variant='outlined'
                required
              />
            </Grid>
            {selected.dynamicType === DynamicType.Message &&
              selected.dynamicType === DynamicType.Message && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    value={selected.content}
                    onChange={onChange}
                    name='content'
                    multiline
                    rows='5'
                    label={t('labels.content')}
                    variant='outlined'
                  />
                </Grid>
              )}
            <Grid item xs={12}>
              <Box display='flex' alignItems='center'>
                <Typography variant='h6' style={{ marginRight: 20 }}>
                  {t('visibleOnly')}
                </Typography>
                <Tooltip title={t('editRules') as string}>
                  <IconButton onClick={() => this.props.onEditRules(1)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Box>

              <Grid container component={Box} mt={1}>
                {this.props.selected.rules &&
                  Object.entries(this.props.selected.rules)
                    .filter((entry: any) => entry[1] && entry[1].length > 0)
                    .map((entry: any, j: number) => {
                      return (
                        <Grid item xs={12} sm={6} md={6} key={j}>
                          <Box mb={2}>
                            <Typography variant='caption'>{t('labels.' + entry[0])}</Typography>
                            {entry[1]
                              .filter(
                                (rule: any) =>
                                  (entry[0] === 'location' && rule.value.city) ||
                                  (entry[0] !== 'location' && rule.value)
                              )
                              .map((rule: any, i: number, arr: any) => {
                                return (
                                  <Box mr={2} key={i}>
                                    <Typography variant='subtitle2' color='primary'>
                                      {this.getRuleValue(entry[0], rule)}
                                    </Typography>
                                    {i !== arr.length - 1 && (
                                      <Typography variant='caption' color='textSecondary'>
                                        {t('common:form.or')}
                                      </Typography>
                                    )}
                                  </Box>
                                );
                              })}
                          </Box>
                        </Grid>
                      );
                    })}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {(selected.website || this.state.defaultUrl) &&
              selected.dynamicType === DynamicType.Text ? (
                <ContentEditor
                  website={selected.website}
                  selectors={selected.selectors}
                  refreshKey={this.state.key}
                  editMode={false}
                  onChange={(selectors: DynamicContentSelector[]) => {}}
                  onDelete={() => ''}
                  id={selected._id}
                  onSave={() => ''}
                />
              ) : (
                <div className={classes.frame} ref={this.frameContainer}>
                  <iframe
                    className={classes.preview}
                    src={`${selected.website}?dc_id=${this.props.selected._id}`}
                    title={t('common:preview')}
                    onClick={this.preventClick}
                    ref={this.frame}
                  />
                </div>
              )}
            </Grid>
          </Grid>
        </Box>
      )) ||
      null
    );
  }
}

export default withStyles(styles)(withTranslation(['dynamicContent', 'common'])(DynamicEditForm));

import React from 'react';
import AppContext from '../../../data/AppContext';
import { DynamicContentSelector } from '../Dynamic';
import DcSelectorComponent from './DynamicSelector';
import { Grid, Box, Typography, createStyles, Theme, withStyles } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { withTranslation } from 'react-i18next';
import { IComponentProps } from '../../../models/app.model';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      minHeight: '60vh',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column'
    },
    frame: {
      width: '100%',
      height: '60vh',
      border: 'none',
      overflow: 'hidden'
    },
    preview: {
      width: '200%',
      transform: 'scale(0.5)',
      transformOrigin: '0 0',
      height: '200%',
      border: `1px solid ${theme.palette.grey[300]}`
    }
  });

//const allowedSelectors = "a,p,h1,h2,h3,h4,h5,h6,b,strong,i,span,div";

export interface ContentEditorProps extends IComponentProps {
  selectors: DynamicContentSelector[];
  website: string;
  onChange: (selectors: DynamicContentSelector[]) => any;
  editMode: boolean;
  id?: string;
  onSave: () => any;
  onDelete: (el: DynamicContentSelector) => any;
  refreshKey?: any;
}
export interface ContentEditorState {
  selectors: DynamicContentSelector[];
  notFound: DynamicContentSelector[];
  isRemoved: boolean;
  refreshKey: string;
  selected: number;
}
class ContentEditor extends React.Component<ContentEditorProps, ContentEditorState> {
  private iframeRef: React.RefObject<HTMLIFrameElement> = React.createRef<HTMLIFrameElement>();
  private iframeContainer: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
  private selectedElements: HTMLElement[] = [];
  private notFoundElements: DynamicContentSelector[] = [];
  private activeSelector = 0;
  static contextType = AppContext;
  constructor(props: ContentEditorProps) {
    super(props);
    this.state = {
      selectors: [],
      notFound: [],
      isRemoved: false,
      refreshKey: '',
      selected: -1
    };
  }

  componentDidMount() {
    this.iframe.onload = async () => {
      try {
        this.notFoundElements = [];
        this.selectedElements = [];
        const selectors: DynamicContentSelector[] = await this.setUpStoredDataInIframe();

        await this.setState({
          ...this.state,
          selectors,
          notFound: this.notFoundElements,
          selected: this.selectedElements.length > 0 ? this.selectedElements.length - 1 : -1
        });
        await this.setUpframeSelector();
        this.highlightSelectedElements();
      } catch (error) {
        console.log(error);
      }
    };
  }

  async componentDidUpdate(prevProps: any) {
    try {
      if (!this.props.editMode && this.props.website !== prevProps.website) {
        this.iFrameContent.location.replace(this.proxyUrl);
      }
      if (
        !this.props.editMode &&
        this.props.selectors !== prevProps.selectors &&
        this.props.website === prevProps.website
      ) {
        this.iFrameContent.location.reload();
      }
    } catch (e) {}
  }

  private get iframe(): HTMLIFrameElement {
    return this.iframeRef.current as any;
  }

  private deleteSelector = (elementToRemove: DynamicContentSelector) => {
    const selectors = this.state.selectors;
    const index = selectors.indexOf(elementToRemove);
    selectors.splice(index, 1);
    const notFound = this.state.notFound;
    notFound.splice(notFound.indexOf(elementToRemove));
    this.selectedElements.splice(index, 1);

    this.setState({
      ...this.state,
      selectors,
      notFound
    });
    this.props.onDelete(elementToRemove);
    this.iFrameContent.location.reload();
  };

  /**
   * set up the selectors in the iframe
   * filter not found selectors (when content has hanged)
   *
   * TODO when element is not found highlight it in the DcSelectorComponent istead of just removing it
   */
  private setUpStoredDataInIframe(): DynamicContentSelector[] {
    return (this.props.selectors || [])
      .filter((selector: DynamicContentSelector) => selector.replaceText || selector.replaceImage)
      .filter((selector: DynamicContentSelector, i: number) => {
        const classNames = (selector.classList || [])
          .filter((className) => !!className && !className.includes(":"))
          .map((className) => '.' + className).join("");

        const queryString = `${selector.tagName.toLocaleLowerCase()}${classNames}`;
        let foundEl;
        try {
          foundEl = Array.from(this.iFrameContent.querySelectorAll(queryString)).find((el) => {
            return el.tagName.toLocaleLowerCase() === 'img'
            ? this.compareImages(el as HTMLImageElement, selector.htmlString)
            : this.compareString((el as HTMLElement).textContent || '', selector.text)
          }
        ) as HTMLElement;
        } catch (error) {
          console.error(error)
          foundEl = null
        }
        
        if (foundEl) {
          this.selectedElements.push(foundEl);
          if (foundEl.tagName.toLocaleLowerCase() !== 'img') {
            foundEl.textContent = selector.replaceText;
          } else {
            foundEl.setAttribute('src', selector.replaceImage || '');
            foundEl.removeAttribute('srcset');
          }

          return true;
        } else {
          this.notFoundElements.push(selector);
          this.setState({ ...this.state, isRemoved: true });
          return false;
        }
      });
  }
  private compareImages = (el1: HTMLImageElement, el2: string) => {
    return el1.outerHTML === el2;
  };
  private compareString(a: string, b: string) {
    const removeWhiteSpace = (el: string) => {
      return el
        .replace(/(\r\n|\n|\r)/gm, ' ')
        .replace(/\s+/g, ' ')
        .trim();
    };
    return removeWhiteSpace(a) === removeWhiteSpace(b);
  }

  private get currentSelector() {
    return this.state.selectors[this.activeSelector];
  }

  private replacePreview(index: number) {
    this.activeSelector = index;
    const selectors = this.state.selectors;
    selectors[index].replaceText = this.selectedElements[index].textContent || '';
    this.setState({ ...this.state, selectors });
    this.props.onChange(this.state.selectors);
  }

  private activateInput = (i: number) => {
    if (!this.props.editMode) return;
    this.activeSelector = i;
    this.setState({ ...this.state, selected: i });
    this.selectedElements[i].classList.add('selected-element-fix');
    this.selectedElements[i].setAttribute('contentEditable', 'true');
    this.selectedElements[i].addEventListener('keyup', (e: any) => {
      e.stopPropagation();
      this.replacePreview(i);
    });
  };

  private get iFrameContent(): Document {
    console.log(this.iframe.contentDocument)
    return this.iframe.contentDocument as any;
  }

  private removeClasses(className: string) {
    Array.from(this.iFrameContent.getElementsByClassName(className)).forEach((el) => {
      el.classList.remove(className);
    });
  }

  private setUpframeSelector() {
    const iframeEl = this.iFrameContent;
    const styleTag = document.createElement('style');
    styleTag.innerHTML = `
        .selected-element{
          outline:none;
          position:relative;
        }
        .selected-element::before{
          content:"";
          position:absolute;
          top:-1em; bottom:-1em; right:-1em; left:-1em;
          border:3px solid #00ccff;
          pointer-events:none;
        }
        .selected-element-fix{
          outline:none;
          border:3px solid #6bdb5c!important;
          border-width:3px!important;
          white-space: pre-wrap;
        }`;
    iframeEl.head.appendChild(styleTag);
    // disable click
    iframeEl.addEventListener('click', (event: any) => event.preventDefault());
    // disable right click
    iframeEl.addEventListener('contextmenu', (event) => event.preventDefault());
    iframeEl.addEventListener('mouseover', (e) => {
      if (!this.props.editMode) return;
      const element = e.target as HTMLElement;
      if (element.classList.contains('selected-element-fix')) return;
      this.removeClasses('selected-element');
      element.addEventListener('mouseout', () => {
        element.classList.remove('selected-element');
      });
    });

    //select item
    iframeEl.addEventListener('mousedown', (event) => {
      if (!this.props.editMode) return;
      const element = event.target as HTMLElement;
      console.log("SELECTED ELEMENT", element)
      // editing the same element with multiple inputs needs to be blocked!
      // todo => not done yet
      const foundElementIndex = this.selectedElements.findIndex((se) => se === element);
      //ELEMENT ALREADY IN SELECTORS
      if (foundElementIndex > -1) {
        this.activateInput(foundElementIndex);
        return;
      }

      // if element is already selected and changed
      if (
        this.currentSelector &&
        (this.currentSelector.replaceText || this.currentSelector.replaceImage) &&
        foundElementIndex < 0
      ) {
        this.activeSelector = this.selectedElements.length;
      }

      this.selectedElements[this.activeSelector] = element;
      const selectors = this.state.selectors;
      selectors[this.activeSelector] = {
        htmlString: element.outerHTML,
        classList: element.className
          .replace('selected-element', '')
          .replace('selected-element-fix', '')
          .split(' ')
          .map((name) => name.trim()),
        text: element.textContent || '',
        tagName: element.tagName,
        replaceText: ''
      };
      this.setState({ ...this.state, selectors });
      this.removeClasses('selected-element-fix');
      // highlight all elements which are in the selector array
      this.activateInput(this.activeSelector);
      this.highlightSelectedElements();
    });
  }

  private highlightSelectedElements() {
    this.selectedElements.forEach((el) => {
      el.classList.add('selected-element-fix');
      const style = el.getAttribute('style') || '';
      const newStyle = style.concat('border-width:3px !important;');
      el.setAttribute('style', newStyle);
    });
  }

  /**
   * this proxy url works only if used with a redirect
   * and needs to be served on the same domain
   */
  private get proxyUrl(): string {
    const link = btoa(this.props.website);
    return `/proxy/${link}`;
  }

  updateInput = async (e: any, selector: DynamicContentSelector) => {
    let value;
    if (e) {
      e.stopPropagation();
    }
    if (selector.tagName.toLocaleLowerCase() === 'img') {
      const selectors = [...this.state.selectors];
      const i = selectors.indexOf(selector);
      this.selectedElements[i].setAttribute('src', selector.replaceImage || '');
      this.selectedElements[i].removeAttribute('srcset');
      await this.replacePreview(i);
      this.props.onChange(this.state.selectors);
    } else {
      value = e.target.value;
      if (e.keyCode) {
        if (e.keyCode === 32) {
          value = selector.replaceText;
        } else {
          return;
        }
      }
      const selectors = [...this.state.selectors];
      const i = selectors.indexOf(selector);
      this.selectedElements[i].innerText = value;
      await this.replacePreview(i);
      this.props.onChange(selectors);
    }
  };

  private get selectorComponent() {
    return (
      <DcSelectorComponent
        title={this.props.t('editor.title')}
        selectors={this.state.selectors}
        notFound={this.state.notFound}
        editMode={this.props.editMode}
        onDelete={this.deleteSelector}
        onSave={this.props.onSave}
        onUpdate={this.updateInput}
        onSelect={this.activateInput}
        id={this.props.id || ''}
        url={this.props.website}
        activeElement={this.state.selected}
      />
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Grid item xs={12} md={this.props.editMode ? 3 : 12} component={Box} order={2}>
          {this.selectorComponent}
        </Grid>
        <Grid item xs={12} md={this.props.editMode ? 9 : 12} className={classes.container}>
          {!this.props.editMode && (
            <Box mb={3}>
              <Typography variant='h6'>{this.props.t('common:preview')}</Typography>
            </Box>
          )}
          <Box flexGrow='1'>
            <div className={classes.frame} ref={this.iframeContainer}>
              <iframe
                title='Content Editor'
                ref={this.iframeRef}
                className={classes.preview}
                id='content-frame'
                src={this.proxyUrl}
              />
            </div>
            <Snackbar
              open={this.state.isRemoved}
              autoHideDuration={5000}
              onClose={() => this.setState({ ...this.state, isRemoved: false })}
            >
              <MuiAlert variant='filled' color='error' severity='error'>
                {this.props.t('editor.notFound')}
              </MuiAlert>
            </Snackbar>
          </Box>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(withTranslation(['dynamicContent', 'common'])(ContentEditor));

import React, { Component, ChangeEvent, FormEvent } from 'react';
import { IComponentProps, ICompany, IpMapInterface, AuthUser } from '../../models/app.model';
import {
  withStyles,
  createStyles,
  Theme,
  Box,
  Grid,
  IconButton,
  Typography,
  Button,
  TextField,
  Switch,
  Checkbox,
  FormControlLabel,
  Link,
  CircularProgress,
} from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import AppContext from '../../data/AppContext';

import EditIcon from '@material-ui/icons/Edit';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SaveSnackbar from '../SaveSnackbar';
import clsx from 'clsx';
import { isValidUrl } from '../../helpers';
import { Alert } from '@material-ui/lab';

const styles = createStyles((theme: Theme) => ({
  optionButton: {
    'marginLeft': 10,
    '&.success': {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
    },
    '&.error': { backgroundColor: theme.palette.error.main, color: theme.palette.error.contrastText },
  },
  blacklisted: {
    borderLeft: '5px solid #000',
  },
  proven: {
    borderLeft: `5px solid ${theme.palette.success.main}`,
  },
  excluded: {
    borderLeft: `5px solid ${theme.palette.error.main}`,
  },
  info: {
    borderLeft: `5px solid ${theme.palette.info.main}`,
  },
  reason: {
    color: theme.palette.error.main,
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
}));

export interface AdminCompany extends ICompany {
  ipMaps: IpMapInterface[];
  toMerge: string[];
  selected: string;
  blackListName: boolean;
  blackListReason: string;
  blackListDomain: string;
}
export interface CompaniesFilter {
  keyword: string;
  pageIndex: number;
  pageSize: number;
  hideproven: boolean;
  hideblack: boolean;
  sort: string | null;
  helper: boolean;
  total: number;
  usersWithPlan: boolean;
  premiumUser: string;
  user: string;
}
interface CompaniesProps extends IComponentProps {}
interface CompaniesState {
  companies: AdminCompany[];
  filter: CompaniesFilter;
  error: string;
  success: boolean;
  selected: string[];
  editMode: string[];
  modified: string[];
  ip: {
    current: string;
    address: string;
    companyName: string;
    manual: Array<{ ip: string; companyName: string }>;
  };
  fetching: boolean;
  isSaving: boolean;
}
class Companies extends Component<CompaniesProps, CompaniesState> {
  static contextType = AppContext;
  state = {
    companies: [] as AdminCompany[],
    selected: [] as string[],
    editMode: [] as string[],
    modified: [] as string[],
    error: '',
    success: false,
    filter: this.resetFilter(),
    ip: {
      current: '',
      address: '',
      companyName: '',
      manual: [] as Array<{ ip: string; companyName: string }>,
    },
    fetching: false,
    isSaving: false,
  };
  async componentDidMount() {
    const companies = await this.fetchCompanies();

    this.context.getCompanyTypes();
    await this.setState({
      ...this.state,
      companies,
    });
    const manualIps = await this.context.fetchManualIps();
    await this.setState({
      ...this.state,
      ip: {
        ...this.state.ip,
        current: manualIps.currentIp,
        manual: manualIps.ips,
      },
    });
  }
  saveCompanies = async () => {
    await this.setState({ ...this.state, isSaving: true });
    const toUpdate = this.state.modified.map((id: string) =>
      this.state.companies.find((c: AdminCompany) => c._id === id)
    );
    const response = await this.context.updateCompanies(toUpdate);
    if (response.error) {
      const { error } = response;
      this.setState({ ...this.state, isSaving: false, error });
    } else {
      await this.setState({
        ...this.state,
        filter: {
          ...this.state.filter,
          pageIndex: 0,
        },
        isSaving: false,
        companies: [],
        success: true,
      });
      const companies = await this.fetchCompanies();
      await this.setState({
        ...this.state,
        error: '',
        companies,
        modified: [] as string[],
        selected: [],
      });
    }
  };
  resetFilter() {
    return {
      keyword: '',
      pageIndex: 0,
      pageSize: 25,
      hideproven: true,
      hideblack: false,
      sort: 'date',
      helper: false,
      total: 0,
      usersWithPlan: true,
      premiumUser: '',
      user: '',
    } as CompaniesFilter;
  }
  fetchCompanies = async () => {
    await this.setState({ ...this.state, fetching: true });
    const { companies, filter } = this.state;
    if (filter.pageSize * filter.pageIndex < companies.length) {
      this.setState({ ...this.state, fetching: false });
      return [];
    }
    const newCompanies = await this.context.getAdminCompanies(filter);
    this.setState({ ...this.state, fetching: false });
    return newCompanies.map((company: AdminCompany) => ({ ...company, toMerge: [] }));
  };
  getHelperDataFromSelectedCompanies = async () => {
    const { companies, selected } = this.state;
    if (selected.length > 5) {
      return alert(
        'Please select up to 5 companies, fetching too many companeis can cause timeout error'
      );
    }
    const result = await this.context.getHelperData(selected);
    const updatedCompanies = companies.map((c) => {
      const match = result.find((r: AdminCompany) => r._id === c._id);
      if (!match) {
        return c;
      } else {
        return { ...c, ...match };
      }
    });
    this.setState({ ...this.state, companies: updatedCompanies });
  };
  fetchDataFromGoogleApi = async () => {
    const { selected, companies } = this.state;
    const result = await this.context.fetchGoogleApiData(selected);
    const updatedCompanies = companies.map((c) => {
      const match = result.find((r: AdminCompany) => r._id === c._id);
      if (!match) {
        return c;
      } else {
        return { ...c, ...match };
      }
    });
    this.setState({ ...this.state, companies: updatedCompanies });
    // DO SOMETHING WITH RESULT
  };
  markAs = (status: string) => {
    const { companies, selected, modified } = this.state;
    companies
      .filter((c: AdminCompany) => selected.includes(c._id))
      .forEach((c: AdminCompany) => {
        (c as any)[status] = true;
        if (!modified.includes(c._id)) {
          modified.push(c._id);
        }
      });
    this.setState({ ...this.state, companies });
  };
  toggleId = (prop: string, companyId: string) => {
    const copy = (this.state as any)[prop] as string[];
    if (copy.includes(companyId)) {
      const index = copy.indexOf(companyId);
      copy.splice(index, 1);
    } else {
      copy.push(companyId);
    }
    this.setState({ ...this.state, [prop]: copy });
  };
  getUrl = (company: AdminCompany) => {
    try {
      const url: URL | null =
        company.domain && isValidUrl(company.domain)
          ? new URL(company.domain)
          : company.helperData?.website && isValidUrl(company.helperData?.website)
          ? new URL(company.helperData?.website)
          : null;
      return url ? (
        <Typography variant='body2'>
          <Link target='_blank' href={url.href}>
            {url.href}
          </Link>
        </Typography>
      ) : null;
    } catch (error) {
      console.error(error);
    }
  };
  getAllCompanies = () => {
    const { classes } = this.props;
    return this.state.companies
      .slice(
        this.state.filter.pageIndex * this.state.filter.pageSize,
        (this.state.filter.pageIndex + 1) * this.state.filter.pageSize
      )
      .map((company: AdminCompany, i: number) => {
        return (
          <Box
            className={clsx(
              'company',
              i % 2 && 'grey',
              company.blackListName
                ? classes.blacklisted
                : company.proven
                ? classes.proven
                : company.exclude
                ? classes.excluded
                : classes.info
            )}
            style={{ backgroundColor: i % 2 === 0 ? '#f2f2f2' : 'fff' }}
            key={i}
            p={1}
          >
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Box display='flex' justifyContent='space-between' flexGrow='1' alignItems='center'>
                <Box display='flex' alignItems='center'>
                  <Checkbox
                    checked={this.state.selected.includes(company._id)}
                    onChange={() => this.toggleId('selected', company._id)}
                  />
                  <Box>
                    <Typography variant='body2'>
                      {company.name}{' '}
                      <Typography variant='inherit' color='textSecondary' component='span'>
                        {` / ` + company.type}
                        {` / ` + company.date}
                      </Typography>
                    </Typography>
                    <Typography variant='caption' color='textSecondary'>
                      {company.adress || 'no address'}
                    </Typography>
                    {company.blackListName && (
                      <Typography variant='body2' className={classes.reason}>
                        {company.blackListReason}
                      </Typography>
                    )}
                    {this.getUrl(company)}
                  </Box>
                </Box>
                <Box>
                  <FormControlLabel
                    label='Proven'
                    control={
                      <Switch
                        name='proven'
                        checked={company.proven || false}
                        onChange={(e) => this.updateCompanyInput(e, company, true)}
                      />
                    }
                  />
                  <FormControlLabel
                    label='Excluded'
                    control={
                      <Switch
                        value={company.exclude}
                        checked={company.exclude || false}
                        onChange={(e) => this.updateCompanyInput(e, company, true)}
                        name='exclude'
                      />
                    }
                  />
                </Box>
              </Box>

              <Box>
                <IconButton onClick={() => this.toggleId('editMode', company._id)}>
                  <EditIcon />
                </IconButton>
              </Box>
            </Box>
            {this.state.editMode.includes(company._id) && this.getCompanyForm(company)}
          </Box>
        );
      });
  };
  updateFilter = (e: ChangeEvent<HTMLInputElement>, checkbox?: boolean) => {
    const { filter } = this.state;
    (filter as any)[e.target.name] = checkbox ? e.target.checked : e.target.value;
    this.setState({ ...this.state, filter });
  };
  resetAndFetch = async () => {
    const { filter } = this.state;
    await this.setState({
      ...this.state,
      filter: { ...filter, pageIndex: 0, keyword: filter.keyword, premiumUser: filter.premiumUser },
      companies: [],
      editMode: [],
    });
    const companies = await this.fetchCompanies();
    this.setState({
      ...this.state,
      companies,
    });
  };
  sortUsers = (u1: AuthUser, u2: AuthUser) => u2.domains.length - u1.domains.length;
  getSearchBox = () => {
    return (
      <>
        <Box mb={2}>
          <TextField
            value={this.state.filter.keyword}
            variant='outlined'
            fullWidth
            name='keyword'
            label='Search by company name'
            onChange={this.updateFilter}
          />
        </Box>
        {this.state.filter.usersWithPlan && (
          <Box mb={2}>
            <TextField
              value={this.state.filter.premiumUser}
              variant='outlined'
              fullWidth
              name='premiumUser'
              label='Search by specific premium user'
              onChange={this.updateFilter}
              select
              SelectProps={{ native: true }}
              InputLabelProps={{
                shrink: true,
              }}
            >
              <option value=''>Choose premium user</option>
              {this.context.getData.allUsers
                .sort(this.sortUsers)
                .filter((u: AuthUser) => u.stripe && u.stripe.plan_id)
                .map((u: AuthUser, i: number) => (
                  <option key={i} value={u.id}>
                    {u.displayName} ({u.domains.length})
                  </option>
                ))}
            </TextField>
          </Box>
        )}
        {!this.state.filter.usersWithPlan && (
          <Box mb={2}>
            <TextField
              value={this.state.filter.user}
              variant='outlined'
              fullWidth
              name='user'
              label='Search by any user'
              onChange={this.updateFilter}
              select
              SelectProps={{ native: true }}
              InputLabelProps={{
                shrink: true,
              }}
            >
              <option value=''>Choose any user</option>
              {this.context.getData.allUsers.sort(this.sortUsers).map((u: AuthUser, i: number) => (
                <option key={i} value={u.id}>
                  {u.displayName} ({u.domains.length})
                </option>
              ))}
            </TextField>
          </Box>
        )}
        <Box>
          <FormControlLabel
            label='Show only companies from users with plan'
            control={
              <Switch
                checked={this.state.filter.usersWithPlan}
                name='usersWithPlan'
                onChange={(e) => this.updateFilter(e, true)}
              />
            }
          />
        </Box>
        <Box>
          <FormControlLabel
            label='Helper'
            control={
              <Switch
                checked={this.state.filter.helper}
                name='helper'
                onChange={(e) => this.updateFilter(e, true)}
              />
            }
          />
        </Box>
        <Box>
          <FormControlLabel
            label='Hide blacklisted / excluded'
            control={
              <Switch
                checked={this.state.filter.hideblack}
                name='hideblack'
                onChange={(e) => this.updateFilter(e, true)}
              />
            }
          />
        </Box>
        <Box>
          <FormControlLabel
            label='Hide proven by admin'
            control={
              <Switch
                checked={this.state.filter.hideproven}
                name='hideproven'
                onChange={(e) => this.updateFilter(e, true)}
              />
            }
          />
        </Box>
        <Box my={2}>
          <Button variant='contained' color='primary' onClick={this.resetAndFetch}>
            Fetch
          </Button>
        </Box>
      </>
    );
  };
  updateCompanyInput = async (
    e: ChangeEvent<HTMLInputElement>,
    company: AdminCompany,
    checkbox?: boolean
  ) => {
    const { companies, modified } = this.state;
    const match = companies.find((c: AdminCompany) => c === company);
    if (e.target.name === 'proven') {
      (match as AdminCompany).version = e.target.checked ? 'proven-by-admin' : 'v2';
    }
    (match as any)[e.target.name] = checkbox ? e.target.checked : e.target.value;
    if (!modified.includes(company._id)) {
      modified.push(company._id);
    }
    await this.setState({ ...this.state, companies, modified });
  };
  getCompanyForm = (company: AdminCompany) => {
    const { classes } = this.props;
    return (
      <Box py={3} px={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} component={Box} display='flex'>
            <Box display='flex' flexDirection='column' justifyContent='flex-end' flexGrow='1'>
              <TextField value={company._id} variant='outlined' fullWidth disabled label='Company id' />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} component={Box} display='flex'>
            <Box display='flex' flexDirection='column' justifyContent='flex-end' flexGrow='1'>
              <TextField
                value={company.type}
                variant='outlined'
                fullWidth
                name='type'
                onChange={(e: ChangeEvent<HTMLInputElement>) => this.updateCompanyInput(e, company)}
                label='Company type'
                className={classes.marginBottom}
              />
              <TextField
                defaultValue={''}
                variant='outlined'
                fullWidth
                name='type'
                onChange={(e: ChangeEvent<HTMLInputElement>) => this.updateCompanyInput(e, company)}
                label='Helper types'
                select
                SelectProps={{ native: true }}
                InputLabelProps={{ shrink: true }}
              >
                <option value=''>Please select company type to change</option>
                {this.context.getData.companyTypes.map((type: string, j: number) => (
                  <option key={j} value={type}>
                    {type}
                  </option>
                ))}
              </TextField>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} component={Box} display='flex'>
            <Box display='flex' flexDirection='column' justifyContent='flex-end' flexGrow='1'>
              <TextField
                defaultValue={company.name}
                variant='outlined'
                fullWidth
                name='name'
                onChange={(e: ChangeEvent<HTMLInputElement>) => this.updateCompanyInput(e, company)}
                label='Company name'
                inputProps={{
                  autoComplete: 'new-name',
                }}
                className={classes.marginBottom}
              />
              <TextField
                value={company.helperData?.name || ''}
                variant='outlined'
                fullWidth
                label='Helper name'
                inputProps={{
                  autoComplete: 'new-name',
                }}
                onChange={() => {}}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} component={Box} display='flex'>
            <Box display='flex' flexDirection='column' justifyContent='flex-end' flexGrow='1'>
              <TextField
                defaultValue={company.adress}
                variant='outlined'
                fullWidth
                name='adress'
                onChange={(e: ChangeEvent<HTMLInputElement>) => this.updateCompanyInput(e, company)}
                label='Company address'
                inputProps={{
                  autoComplete: 'new-adress',
                }}
                className={classes.marginBottom}
              />
              <TextField
                value={company.helperData?.adress || ''}
                variant='outlined'
                fullWidth
                label='Helper address'
                inputProps={{
                  autoComplete: 'new-adress',
                }}
                onChange={() => {}}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} component={Box} display='flex'>
            <Box display='flex' flexDirection='column' justifyContent='flex-end' flexGrow='1'>
              <TextField
                defaultValue={company.tele}
                variant='outlined'
                fullWidth
                name='tele'
                onChange={(e: ChangeEvent<HTMLInputElement>) => this.updateCompanyInput(e, company)}
                label='Company phone'
                inputProps={{
                  autoComplete: 'new-tele',
                }}
                className={classes.marginBottom}
              />
              <TextField
                value={company.helperData?.tele || ''}
                variant='outlined'
                fullWidth
                label='Helper phone'
                inputProps={{
                  autoComplete: 'new-tele',
                }}
                onChange={() => {}}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} component={Box} display='flex'>
            <Box display='flex' flexDirection='column' justifyContent='flex-end' flexGrow='1'>
              <TextField
                defaultValue={company.domain || ''}
                variant='outlined'
                fullWidth
                name='domain'
                onChange={(e: ChangeEvent<HTMLInputElement>) => this.updateCompanyInput(e, company)}
                label='Company homepage'
                inputProps={{
                  autoComplete: 'new-domain',
                }}
                className={classes.marginBottom}
              />
              <TextField
                value={company.helperData?.website || ''}
                variant='outlined'
                fullWidth
                label='Helper homepage'
                inputProps={{
                  autoComplete: 'new-domain',
                }}
                onChange={() => {}}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='caption'>{company.ipMaps.length} IP MAPS</Typography>
            {company.ipMaps &&
              company.ipMaps
                .slice(0, company.ipMaps.length > 5 ? 3 : 5)
                .map((ip: IpMapInterface, j: number) => (
                  <Typography key={j} variant='h6'>
                    {ip.ip}
                  </Typography>
                ))}
          </Grid>
          <Grid item xs={12}>
            <Typography variant='caption' gutterBottom>
              Select companies to merge with this one:
            </Typography>
            <Box display='flex'>
              <TextField
                variant='outlined'
                select
                SelectProps={{ native: true }}
                onChange={(e) => this.selectCompanyToMerge(company, e.target.value)}
              >
                <option value=''>Select company to merge</option>
                {this.state.companies
                  .filter((c: AdminCompany) => c._id !== company._id)
                  .map((c: AdminCompany, j: number) => {
                    return (
                      <option key={j} value={c._id}>
                        {c.name}
                      </option>
                    );
                  })}
              </TextField>
              <IconButton onClick={() => this.addCompanyToMerge(company)}>
                <AddCircleOutlineIcon />
              </IconButton>
            </Box>

            {company.toMerge.map((companyKey: string, j: number) => {
              return (
                <Box key={j} p={1} display='flex' alignItems='center'>
                  <Typography variant='h6'>
                    {(this.state.companies.find((c) => c._id === companyKey) as AdminCompany).name}
                  </Typography>
                  <IconButton size='small' onClick={() => this.removeCompanyToMerge(company, j)}>
                    <DeleteForeverIcon fontSize='small' />
                  </IconButton>
                </Box>
              );
            })}
            {company.toMerge.length > 0 && (
              <Button variant='contained' color='primary' onClick={() => this.mergeCompanies(company)}>
                Merge
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  };
  mergeCompanies = async (company: AdminCompany) => {
    this.context.mergeCompanies(company._id, company.toMerge);
    const { toMerge } = company;
    const { modified, selected, companies } = this.state;
    (companies.find((c) => c === company) as AdminCompany).toMerge = [];
    await this.setState({
      ...this.state,
      modified: modified.filter((c) => !toMerge.includes(c)),
      selected: selected.filter((c) => !toMerge.includes(c)),
      companies: companies.filter((c) => !toMerge.includes(c._id)),
    });
  };
  selectCompanyToMerge = (company: AdminCompany, companyKey: string) => {
    const { companies } = this.state;
    (companies.find((c: AdminCompany) => c === company) as AdminCompany).selected = companyKey;
    this.setState({ ...this.state, companies });
  };
  addCompanyToMerge = (company: AdminCompany) => {
    const { companies } = this.state;
    const match = companies.find((c: AdminCompany) => c === company) as AdminCompany;
    if (match.toMerge.includes(match.selected)) {
      return;
    }
    if (!match.selected) {
      return;
    }
    match.toMerge.push(match.selected);
    this.setState({ ...this.state, companies });
  };
  removeCompanyToMerge = (company: AdminCompany, i: number) => {
    const { companies } = this.state;
    const match = companies.find((c: AdminCompany) => c === company) as AdminCompany;
    match.toMerge.splice(i, 1);
    this.setState({ ...this.state, companies });
  };
  nextPage = async () => {
    const { filter } = this.state;
    const { pageIndex } = filter;
    // LOGIC TO GET
    await this.setState({
      ...this.state,
      filter: {
        ...this.state.filter,
        pageIndex: pageIndex + 1,
      },
    });
    const newCompanies: AdminCompany[] = await this.fetchCompanies();
    this.setState({ ...this.state, companies: [...this.state.companies, ...newCompanies] });
  };
  prevPage = async () => {
    const { pageIndex } = this.state.filter;
    // LOGIC TO GET
    if (pageIndex > 0)
      await this.setState({
        ...this.state,
        filter: {
          ...this.state.filter,
          pageIndex: pageIndex - 1,
        },
      });
  };
  getOptions = () => {
    const { classes } = this.props;
    return (
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        flexWrap='wrap'
        position='sticky'
        top='0'
        zIndex='1000'
        style={{ backgroundColor: '#eee' }}
        p={1}
      >
        <Box display='flex' alignItems='center'>
          <IconButton onClick={this.prevPage}>
            <NavigateBeforeIcon />
          </IconButton>
          <Typography variant='body2'>{`${this.state.filter.pageIndex + 1}`}</Typography>
          <IconButton onClick={this.nextPage}>
            <NavigateNextIcon />
          </IconButton>
        </Box>
        <Box>
          <Button variant='contained' onClick={this.fetchDataFromGoogleApi}>
            Fetch google api data
          </Button>
          <Button variant='contained' onClick={this.getHelperDataFromSelectedCompanies}>
            Fetch helper data
          </Button>
          <Button
            variant='contained'
            onClick={() => this.markAs('proven')}
            className={clsx(classes.optionButton, 'success')}
          >
            Mark as proven
          </Button>
          <Button
            variant='contained'
            onClick={() => this.markAs('exclude')}
            className={clsx(classes.optionButton, 'error')}
          >
            Mark as hidden
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={this.saveCompanies}
            className={clsx(classes.optionButton)}
          >
            Save
          </Button>
        </Box>
        {this.state.error && (
          <Box flexGrow={1}>
            <Alert severity='error'>
              <Typography variant='body2'>{this.state.error}</Typography>
            </Alert>
          </Box>
        )}
      </Box>
    );
  };
  addIpToCompany = async (e: FormEvent) => {
    console.log('LOCAL IS RUNNING');
    e.preventDefault();
    await this.setState({ ...this.state, isSaving: true });
    try {
      const { address, companyName } = this.state.ip;
      const result = await this.context.assignIpToCompany(address, companyName);
      this.setState({
        ...this.state,
        isSaving: false,
        ip: {
          ...this.state.ip,
          manual: [
            {
              ip: result.ip,
              companyName: result.companyName,
            },
            ...this.state.ip.manual,
          ].filter(
            (
              entry: { ip: string; companyName: string },
              i: number,
              arr: Array<{ ip: string; companyName: string }>
            ) => {
              return i === arr.findIndex((value) => value.ip === entry.ip);
            }
          ),
        },
      });
    } catch (error) {
      this.setState({ ...this.state, isSaving: false });
    }
  };
  getIpForm = () => {
    return (
      <Box mb={2}>
        <form onSubmit={this.addIpToCompany}>
          <Typography variant='h6' gutterBottom>{`Current ip is ${this.state.ip.current}`}</Typography>
          <Typography variant='body2' gutterBottom>
            Here you can assign any ip to any company
          </Typography>
          <Typography variant='caption' component='p'>
            If company already exist in our database, please paste it's name
          </Typography>
          <Typography variant='caption' component='p'>
            If company doesn't exist, please paste company name from google results
          </Typography>

          <Box my={2}>
            <TextField
              variant='outlined'
              label='Company'
              fullWidth
              value={this.state.ip.companyName}
              InputLabelProps={{ shrink: true }}
              onChange={(e) =>
                this.setState({ ...this.state, ip: { ...this.state.ip, companyName: e.target.value } })
              }
              required
            />
          </Box>
          <Box my={2}>
            <TextField
              variant='outlined'
              label='IP address'
              fullWidth
              value={this.state.ip.address}
              InputLabelProps={{ shrink: true }}
              onChange={(e) =>
                this.setState({ ...this.state, ip: { ...this.state.ip, address: e.target.value } })
              }
              required
            />
          </Box>
          <Button variant='contained' color='primary' type='submit'>
            Assign
          </Button>
        </form>
      </Box>
    );
  };

  removeManualIp = async (ip: string) => {
    await this.setState({
      ...this.state,
      isSaving: true,
    });
    await this.context.removeManualIp(ip);
    const { manual } = this.state.ip;
    this.setState({
      ...this.state,
      isSaving: false,
      ip: {
        ...this.state.ip,
        manual: manual.filter((mip: { ip: string; companyName: string }) => mip.ip !== ip),
      },
    });
  };
  getCurrentIpInfo = () => {
    return (
      <>
        <Box my={3}>
          <Typography variant='h6'>IP's assigned manually to companies</Typography>
          {this.state.ip.manual.map((ip: { ip: string; companyName: string }, i: number) => (
            <Box
              key={i}
              p={1}
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              style={{ backgroundColor: i % 2 === 0 ? '#f5f5f5' : '#fff' }}
            >
              <Typography variant='body2'>
                {ip.ip} / {ip.companyName}
              </Typography>
              <IconButton size='small' onClick={() => this.removeManualIp(ip.ip)}>
                <DeleteForeverIcon fontSize='small' />
              </IconButton>
            </Box>
          ))}
        </Box>
      </>
    );
  };
  render() {
    return (
      <Box p={5}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={9}>
            {this.getOptions()}
            {this.state.fetching && (
              <Box py={2} display='flex' alignItems='center'>
                <Typography variant='body2' style={{ marginRight: 10 }}>
                  Loading
                </Typography>
                <CircularProgress size={24} thickness={6} />
              </Box>
            )}
            {this.getAllCompanies()}
          </Grid>
          <Grid item xs={12} md={3}>
            <Box mb={5}>{this.getSearchBox()}</Box>
            <Box mb={5}>{this.getIpForm()}</Box>
            <Box mb={5}>{this.getCurrentIpInfo()}</Box>
          </Grid>
        </Grid>
        <SaveSnackbar open={this.state.isSaving} />
      </Box>
    );
  }
}
export default withStyles(styles)(withTranslation()(Companies));

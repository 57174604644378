import React, { Component, FormEvent } from 'react';
import { Box, Grid, TextField, Switch, FormControlLabel, Fab, Typography } from '@material-ui/core';
import { ReminderFilter } from './Reminders';
import SearchIcon from '@material-ui/icons/Search';

export interface RemindersFilterProps {
  filter: ReminderFilter;
  onUpdate: (name: string, value: any) => any;
  onSubmit: (e: FormEvent) => any;
}
export default class RemindersFilter extends Component<RemindersFilterProps> {
  sortBy = [{ label: 'companyName', value: 'companyName' }];
  render() {
    const { onUpdate, onSubmit } = this.props;
    const { keyword, order, sortBy, hideCompleted, createdBy, showOnlyImportant } = this.props.filter;
    return (
      <form onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              variant='outlined'
              fullWidth
              label='Keyword'
              name='keyword'
              placeholder='Search by title or content'
              value={keyword}
              onChange={(e: any) => onUpdate(e.target.name, e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              select
              variant='outlined'
              fullWidth
              label='Sort by'
              SelectProps={{
                native: true
              }}
              name='sortBy'
              value={sortBy}
              InputLabelProps={{ shrink: true }}
              onChange={(e: any) => onUpdate(e.target.name, e.target.value)}
            >
              <option value='validTill'>Expiration date</option>
              <option value='companyName'>Company name</option>
              <option value='title'>Action title</option>
              <option value='status'>Status</option>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={'auto'}>
            <TextField
              select
              variant='outlined'
              fullWidth
              label='Order'
              name='order'
              value={order}
              SelectProps={{
                native: true
              }}
              InputLabelProps={{ shrink: true }}
              onChange={(e: any) => onUpdate(e.target.name, e.target.value)}
            >
              <option value={1}>Asc</option>
              <option value={-1}>Desc</option>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              select
              variant='outlined'
              fullWidth
              label='Created by'
              value={createdBy}
              name='createdBy'
              SelectProps={{
                native: true
              }}
              InputLabelProps={{ shrink: true }}
              onChange={(e: any) => onUpdate(e.target.name, e.target.value)}
            >
              <option value=''>Anyone</option>
              <option value='own'>Me</option>
              <option value='shared'>Shared with me</option>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={'auto'} component={Box} display='flex'>
            <Box display='flex' alignItems='center'>
              <FormControlLabel
                control={
                  <Switch
                    checked={hideCompleted}
                    onChange={(e: any) => onUpdate(e.target.name, e.target.checked)}
                    name='hideCompleted'
                  />
                }
                label={<Typography variant='caption'>Hide completed</Typography>}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={'auto'} component={Box} display='flex'>
            <Box display='flex' alignItems='center'>
              <FormControlLabel
                control={
                  <Switch
                    checked={showOnlyImportant}
                    onChange={(e: any) => onUpdate(e.target.name, e.target.checked)}
                    name='showOnlyImportant'
                  />
                }
                label={<Typography variant='caption'>Show only important</Typography>}
              />
            </Box>
          </Grid>
          <Grid item xs={'auto'}>
            <Fab type='submit' color='primary'>
              <SearchIcon />
            </Fab>
          </Grid>
        </Grid>
      </form>
    );
  }
}

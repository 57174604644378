import React from 'react';
import { TextField, Box, IconButton, Tooltip, Typography, CircularProgress } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { Alert } from '@material-ui/lab';
import { withTranslation, WithTranslation } from 'react-i18next';

interface ResetPasswordProps extends WithTranslation {}
class ResetPassword extends React.Component<ResetPasswordProps> {
  state = {
    email: '',
    sent: false,
    success: false,
    error: ''
  };
  resetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await this.setState({ ...this.state, sent: true });
      const response = await fetch('/app-api/v2/reset-password', {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({ email: this.state.email })
      });
      if (!response.ok) {
        const { error } = await response.json();
        console.log(error);
        throw new Error(error);
      }
      this.setState({ ...this.state, sent: false, success: true });
    } catch (error:any) {
      this.setState({ ...this.state, error: error.message, sent: false });
    }
  };
  render() {
    const { t } = this.props;
    return (
      <form onSubmit={this.resetPassword}>
        <Box mt={4} mb={2}>
          <Typography variant='body2' gutterBottom>
            {t('pleaseConfirm')}
          </Typography>
        </Box>

        <Box display='flex' alignItems='center'>
          <TextField
            variant='outlined'
            fullWidth
            label={t('confirmEmail')}
            style={{ marginRight: 10 }}
            type='email'
            required
            value={this.state.email}
            onChange={(e) => this.setState({ ...this.state, email: e.target.value })}
          />
          <Tooltip title={t('getLink') as string}>
            <IconButton type='submit'>
              <SendIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Box>
          {this.state.sent && <CircularProgress size={20} thickness={5} style={{ marginLeft: 10 }} />}
          {this.state.success && (
            <Box my={2}>
              <Alert severity='success'>{t('emailSent')}</Alert>
            </Box>
          )}
          {this.state.error && (
            <Box my={2}>
              <Alert severity='error'>{t('errors.' + this.state.error)}</Alert>
            </Box>
          )}
        </Box>
      </form>
    );
  }
}

export default withTranslation(['signup'])(ResetPassword);

import React from 'react';
import { withStyles, createStyles } from '@material-ui/styles';
import { withTranslation } from 'react-i18next';
import { Theme, Box, Typography, Grid, withTheme, WithTheme } from '@material-ui/core';
import { IComponentProps } from '../../models/app.model';
import { ISpyLinkAnalytics } from './Spylink';
import VisitsPerDayTotal from '../charts/visitsPerDayTotal';
import BusinessIcon from '@material-ui/icons/Business';
import PersonIcon from '@material-ui/icons/Person';
import { Link } from 'react-router-dom';

const styles = createStyles((theme: Theme) => ({}));

interface SpylinkAnalyticsState {}
interface SpylinkAnalyticsProps extends IComponentProps, WithTheme {
  data: ISpyLinkAnalytics;
}

class SpylinkAnalytics extends React.Component<SpylinkAnalyticsProps, SpylinkAnalyticsState> {
  displayCompanies = (companies: any) => {
    const { t, theme } = this.props;
    if (!companies || Object.keys(companies).length === 0) {
      return (
        <Box py={2}>
          <Typography variant='body2'>{t('common:noData')}</Typography>
        </Box>
      );
    }
    return companies.map((company: any, i: number) => {
      return (
        <Box key={i} py={1} style={{ backgroundColor: i % 2 === 1 ? theme.palette.grey[100] : 'auto' }}>
          <Link to={`/companies/${company.key}`} style={{ textDecoration: 'none' }} target='_blank'>
            <Box display='flex' alignItems='center'>
              <Box mr={1}>{company.blackListed ? <PersonIcon /> : <BusinessIcon />}</Box>
              <Typography color='primary' variant='body2'>
                {company.companyName} {`(${company.visits} `}
                {`${company.visits === 1 ? t('common:visit') : t('common:visits')})`}
              </Typography>
            </Box>
          </Link>
        </Box>
      );
    });
  };
  render() {
    const { t } = this.props;
    const sumCompaniesVisits =
      this.props.data.companies.reduce((acc, curr) => {
        return curr.blackListed ? acc : acc + curr.visits;
      }, 0) || 0;
    return (
      <Box p={5}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography variant='h6' gutterBottom>
              {t('common:analytics.timeline')}
            </Typography>
            <VisitsPerDayTotal data={this.props.data.visitsByDate} />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant='h6'>{t('common:analytics.triggeredBy')}</Typography>
            {this.displayCompanies(this.props.data.companies)}
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant='h6'>{t('common:analytics.allVisits')}</Typography>
            <Typography variant='h4' gutterBottom>
              {this.props.data.totalTriggered}
            </Typography>
            <Typography variant='h6'>{t('common:analytics.userVisits')}</Typography>
            <Typography variant='h4' gutterBottom>
              {this.props.data.totalTriggered - sumCompaniesVisits}
            </Typography>
            <Typography variant='h6'>{t('common:analytics.companyVisits')}</Typography>
            <Typography variant='h4' gutterBottom>
              {sumCompaniesVisits}
            </Typography>
            <Typography variant='h6'>{t('common:analytics.uniqueCompanies')}</Typography>
            <Typography variant='h4' gutterBottom>
              {this.props.data.companies.filter((r) => !r.blackListed).length || 0}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default withStyles(styles)(withTheme(withTranslation(['spylink', 'common'])(SpylinkAnalytics)));

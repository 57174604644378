import React, { Component, ChangeEvent } from 'react';
//PROPS, TYPES
import { withStyles } from '@material-ui/styles';
import { IComponentProps } from '../../models/app.model';
import { RouteComponentProps, withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
//STYLES
import styles from './Company.style';
//CONTEXT
import AppContext from '../../data/AppContext';
//COMPONENTS
import { Box, Typography, Tabs, Tab, IconButton, Fade, Fab, Snackbar } from '@material-ui/core';
import CompanyDetails from './CompanyDetails';
import StatusIcons from '../StatusIcons';
import CompanyContent from './CompanyContent';
import CompanyVisits from './CompanyVisits';
import { Skeleton } from '@material-ui/lab';
import MuiAlert from '@material-ui/lab/Alert';
//ICONS
import CloseIcon from '@material-ui/icons/Close';
import { isAdmin } from '../../helpers';
import { Reminder } from '../reminders/Reminders';

const tabs = [{ name: 'details' }, { name: 'visits' }, { name: 'content' }];

export interface CompanyProps extends IComponentProps, RouteComponentProps<any> {
  company?: any;
  mini?: boolean;
  onClose?: () => any;
}

export interface ICompanyState {
  company?: any;
  activeTab?: number;
  requests?: any;
  note?: any;
  screenShot: string;
  requestsDetails: any[];
  requestsPage: number;
  reminders: Reminder[];
  ispMessage: string;
  ispSnackbar: boolean;
}

class Company extends Component<CompanyProps, ICompanyState> {
  static contextType = AppContext;
  mounted: boolean;
  container: any;
  constructor(props: CompanyProps) {
    super(props);
    this.state = {
      activeTab: 0,
      company: null,
      requests: [],
      requestsDetails: [],
      requestsPage: 0,
      dynamicContent: [],
      screenShot: '',
      note: null,
      reminders: [],
      ispMessage: '',
      ispSnackbar: false,
    } as ICompanyState;
    this.mounted = false;
    this.container = React.createRef<HTMLElement>();
  }

  componentDidMount() {
    this.mounted = true;
    this.loadCompany();
  }
  async componentDidUpdate(prevProps: CompanyProps, prevState: ICompanyState) {
    if (
      this.mounted &&
      (this.props.company !== prevProps.company ||
        this.props.match.params.key !== prevProps.match.params.key)
    ) {
      await this.setState({ ...this.state, requests: [] });
      await this.loadCompany();
    }
    if (
      (this.state.activeTab === 1 &&
        prevState.activeTab !== 1 &&
        this.state.requestsDetails.length === 0) ||
      (this.state.activeTab === 1 &&
        prevState.activeTab === 1 &&
        this.props.company !== prevProps.company &&
        !this.context.getData.fetching)
    ) {
      await this.loadRequests();
    }
    if (
      this.state.requestsPage !== prevState.requestsPage &&
      this.state.activeTab === 1 &&
      this.state.requestsDetails.length > 0
    ) {
      await this.loadMoreRequests();
    }
    if (this.container.current && !this.container.current.onscroll) {
      this.container.current.addEventListener('scroll', async (e: any) => {
        //CHECK IF SCROLLED
        if (
          this.state.activeTab === 1 &&
          e.target.scrollTop === e.target.scrollHeight - e.target.offsetHeight &&
          this.state.requests.length > this.state.requestsPage * 25 &&
          this.state.requests.length > 25 &&
          !this.context.getData.fetching.requests
        ) {
          await this.setState({
            ...this.state,
            requestsPage: this.state.requestsPage + 1,
          });
        }
      });
    }
  }
  loadRequests = async () => {
    const requestsDetails = await this.context.getRequestsDetails(
      this.state.requests,
      this.state.requestsPage
    );
    if (this.mounted) {
      await this.setState({
        requestsDetails: requestsDetails.data || [],
      });
    }
  };
  loadMoreRequests = async () => {
    const requestsDetails = await this.context.getRequestsDetails(
      this.state.requests,
      this.state.requestsPage
    );
    if (this.mounted) {
      await this.setState({
        ...this.state,
        requestsDetails: [...this.state.requestsDetails, ...requestsDetails.data],
      });
    }
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  loadCompany = async () => {
    if (!this.props.company && this.props.match.params && !this.props.match.params.key) {
      return;
    }
    this.setState({
      ...this.state,
      company: null,
      requestsDetails: [],
      screenShot: '',
    });
    let companyKey = '';
    if (this.props.company) {
      companyKey = (this.props.company as any).companyKey;
    } else {
      companyKey = this.props.match.params.key;
    }
    const { requests, company } = await this.context.getCompany(companyKey);
    const note = await this.context.getNote(companyKey);
    const reminders = await this.context.getReminders(null, companyKey);
    const screenShot = (company && company.domain && this.context.getImage(company.domain)) || '';
    const passedState = this.props.location.state as any;
    let activeTab = this.state.activeTab;
    if (passedState && passedState.activeTab) {
      activeTab = passedState.activeTab as number;
    }
    if (this.mounted) {
      await this.setState({
        ...this.state,
        company,
        requests,
        requestsPage: 0,
        note,
        activeTab,
        screenShot,
        reminders,
      });
    }

    if (activeTab === 1) {
      await this.loadRequests();
    }
  };

  updateNote = (note: any) => {
    return new Promise((res)=>{
      console.log("UPDATE NOTE", this.state.note)
      this.setState({
        ...this.state,
        note,
      },()=>{
        res(true)
      });
    })
    
  };
  saveNote = () => {
    console.log("SAVE NOTE", this.state.note)
    this.context.setNote(this.state.note);
  };

  switchTab = (e: ChangeEvent<any>, i: number) => {
    this.setState({ ...this.state, activeTab: i });
  };
  onClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
    this.setState({ activeTab: 0 });
  };
  getIspButton = (company: any) =>
    isAdmin(this.context.getData.userData) && (
      <Fab
        color='secondary'
        onClick={async () => {
          try {
            const message = await this.context.markCompanyISP(company._id);
            await this.setState({
              ...this.state,
              ispMessage: `Company ${message.adjustment.name} marked as ISP`,
              ispSnackbar: true,
            });
          } catch (error) {
            alert(this.state.ispMessage);
          }
        }}
      >
        ISP
      </Fab>
    );
  getCompanyView = () => (
    <div ref={this.container} style={{ overflowY: 'scroll', maxHeight: '100vh' }}>
      {this.state.company && (
        <>
          <Box className={this.props.classes.sticky}>
            <Box className={this.props.classes.header} px={5}>
              <Box display='flex' alignItems='center' py={3}>
                {this.props.onClose && (
                  <IconButton onClick={this.onClose}>
                    <CloseIcon />
                  </IconButton>
                )}
                <Typography variant='h4' style={{ margin: '0 10px' }}>
                  {this.state.company.name}
                </Typography>
                <Box>{this.getIspButton(this.state.company)}</Box>
              </Box>
              <Box display='flex' alignItems='center' mx={5}>
                <StatusIcons note={this.state.note} companyKey={this.state.company._id} onStatusChange={async (note)=>{
                  await this.updateNote(note);
                  this.saveNote()
                  }} />
              </Box>
            </Box>
            <Tabs
              textColor='primary'
              indicatorColor='primary'
              value={this.state.activeTab || 0}
              onChange={this.switchTab}
              className={this.props.classes.tabs}
            >
              {tabs.map((tab, i) => (
                <Tab
                  key={i}
                  label={`${this.props.t('company.' + tab.name)}${
                    tab.name === 'visits'
                      ? this.state.requests && ` (${this.state.requests.length})`
                      : ''
                  }`}
                />
              ))}
            </Tabs>
          </Box>
          <Box className={this.props.classes.content}>
            <Box>
              {this.state.activeTab === 0 && (
                <CompanyDetails
                  onUpdate={this.updateNote}
                  onSave={this.saveNote}
                  company={this.state.company}
                  note={this.state.note}
                  screenShot={this.state.screenShot}
                  mini={this.props.mini}
                  reminders={this.state.reminders}
                  toggleStatus={this.toggleReminderStatus}
                  onRemove={this.removeReminder}
                  addReminder={this.addReminder}
                />
              )}
              {this.state.activeTab === 1 && (
                <CompanyVisits requests={this.state.requestsDetails} company={this.state.company} />
              )}
              {this.state.activeTab === 2 && (
                <CompanyContent company={this.state.company} companyKey={this.state.company._id} />
              )}
            </Box>
          </Box>
        </>
      )}
    </div>
  );

  toggleReminderStatus = (reminder: Reminder) => {
    const reminders = [...this.state.reminders];
    const foundReminder = reminders.find((rem: Reminder) => rem === reminder);
    (foundReminder as any).status = (foundReminder as any).status === 'open' ? 'done' : 'open';
    this.setState({ ...this.state, reminders });
    this.context.updateReminder([foundReminder]);
  };
  removeReminder = async (reminder: Reminder) => {
    const reminders = [...this.state.reminders].filter((rem: Reminder) => rem !== reminder);
    this.setState({ ...this.state, reminders });
    await this.context.removeReminder(reminder);
  };
  addReminder = async (reminder: Reminder) => {
    await this.context.addReminder({
      ...reminder,
      company: { companyKey: this.state.company._id },
    });
    const reminders = await this.context.getReminders(null, this.state.company._id);
    this.setState({ ...this.state, reminders });
  };

  render() {
    return (
      <>
        <Fade in={!!this.state.company} exit={true} timeout={500}>
          {this.getCompanyView()}
        </Fade>
        <Fade in={!this.state.company} exit={true} timeout={500} unmountOnExit>
          <Skeleton variant='rect' height={this.state.company ? '0' : '100%'} width="100%">
            <Box p={5}>
              <Typography variant='h6'>{this.props.t('common:fetchingMore')}</Typography>
            </Box>
          </Skeleton>
        </Fade>
        <Snackbar
          open={this.state.ispSnackbar}
          autoHideDuration={3000}
          onClose={() => this.setState({ ...this.state, ispSnackbar: false })}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <MuiAlert variant='filled' severity='info'>
            {this.state.ispMessage}
          </MuiAlert>
        </Snackbar>
      </>
    );
  }
}

export default withStyles(styles)(withTranslation(['companies', 'common'])(withRouter(Company)));

import { UploadPdf } from '../langTemplate';

const uploadPdf: UploadPdf = {
  title: 'Dokumentenverfolgung',
  description: 'Verfolgen Sie die Benutzeraktivität in den freigegebenen PDF-Dokumenten.',
  tabs: {
    visits: 'Besuche',
    settings: 'Einstellungen',
    customDomain: 'Custom domain',
  },
  uploadingNewFile: 'Neue Datei hochladen',
  linkCopied: 'Link kopiert',
  addedOn: 'Hinzugefügt zu',
  document: {
    description: 'Beschreibung',
    status: 'Status',
    linkPreview: 'Ihr Link wird so aussehen ',
    password: 'Passwort',
    passwordProtected: 'Passwortgeschützt',
    protectionDisabled: 'Der Schutz ist deaktiviert',
    protectionEnabled: 'Der Schutz ist aktiv',
    slug: 'Dokument slug',
    title: 'Dokumenttitel',
    published: 'Veröffentlicht',
    dateRange: 'Datumsbereich aktiv',
    publishDateRange: 'Innerhalb des Datumsbereichs veröffentlichen',
    publishedFrom: 'Veröffentlichen von',
    publishedTill: 'Veröffentlichen bis',
    chooseFrequency: 'Wählen Sie die Frequenz',
    frequency: {
      daily: 'Täglich',
      visit: 'Jeder Besuch',
      weekly: 'Wöchentlich',
    },
    notification: 'E-Mail Benachrichtigungen',
    notificationDisabled: 'Benachrichtigungen deaktiviert',
    notificationEnabled: 'Benachrichtigungen aktiviert',
  },
  steps: {
    setupEndpoint: '',
    setupEndpointDescription: '',
    createFile: '',
    createFileDescription: '',
    uploadFile: '',
    uploadFileDescription: '',
    handleRedirect: '',
    handleRedirectDescription: '',
  },
  showActivity: 'Benutzeraktionen anzeigen',
  downloaded: 'Downloads erkannt',
  notDownloaded: 'Downloads nicht erkannt',
  dragDropFiles: 'Dateien per Drag & Drop verschieben',
  clickToSelect: 'Klicken Sie, um die Datei auszuwählen',
  dropFiles: 'Dateien hier ablegen',
  noVideos: 'Keine Videos verfügbar',
  videos: 'Videos verfügbar',
  copyLink: 'Link zum Teilen kopieren',
  openSettings: 'Einstellungen',
  removeDocument: 'Dokument entfernen',
  confirmRemove: 'Bitte bestätigen Sie das Entfernen des Dokuments',
  totalTime: 'Gesamtzeit',
  visit: '{{count}} besuch',
  visit_plural: '{{count}} besuche',
  download: '{{count}} Herunterladen',
  download_plural: '{{count}} Herunterladen',
};

export default uploadPdf;

import React, { SyntheticEvent } from 'react';
import {
  Typography,
  Box,
  TextField,
  InputAdornment,
  makeStyles,
  Fab,
  Tooltip,
  IconButton,
  Badge
} from '@material-ui/core';
import styles from './Dynamic.style';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';
import { ContentRule } from './Dynamic';
import AppContext from '../../data/AppContext';

export interface IDynamicContentProps {
  onSelect: (element: any) => void;
  selected: any;
  filter: string;
  data: any;
  filterChange: (e: SyntheticEvent) => void;
  duplicateRule: (i: number) => void;
}

const ContentTable = (props: IDynamicContentProps) => {
  const classes = makeStyles(styles)();
  const { t } = useTranslation(['dynamicContent', 'common']);
  const context = React.useContext(AppContext);
  return (
    <>
      <Box p={2} display='flex' alignItems='center'>
        <TextField
          placeholder={t('filterRules')}
          value={props.filter}
          variant='outlined'
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            )
          }}
          onChange={props.filterChange}
          className={classes.filterInput}
        />
      </Box>
      {props.data
        .filter(
          (content: any) =>
            content &&
            (content.name || '').toLocaleLowerCase().includes(props.filter.toLocaleLowerCase())
        )
        .map((content: any, i: number) => (
          <Box
            display='flex'
            px={2}
            py={1}
            alignItems='center'
            onClick={() => props.onSelect(content)}
            key={i}
            position='relative'
            className={clsx(
              classes.contentTableElement,
              props.selected && props.selected === content && classes.selected
            )}
          >
            <Box mr={2}>
              <Badge
                variant='dot'
                classes={{
                  dot: content.disabled ? classes.offline : classes.online
                }}
                anchorOrigin={{
                  horizontal: 'right',
                  vertical: 'bottom'
                }}
              >
                {content.dynamicType === 'dynamic-text' ? <DynamicFeedIcon /> : <TextFieldsIcon />}
              </Badge>
            </Box>

            <Box flexGrow='1'>
              <Typography variant='h6'>{content.name}</Typography>
              <Typography variant='caption' color='textSecondary'>
                {content.rules &&
                  Object.entries(content.rules)
                    .map((rules: any, i: number) =>
                      rules[1]
                        .map((rule: any) => {
                          const label =
                            (rules[0] === 'location' && rule.value.city) ||
                            (rules[0] === 'status' ? t('labels.' + rule.value) : rule.value);
                          return label + ', ';
                        })
                        .join('')
                    )
                    .join('')
                    .slice(0, -2)}
              </Typography>
            </Box>
            <Box position='absolute' right='10px' className='duplicate'>
              {context.getData.readOnly ? (
                <IconButton
                  onClick={() => props.duplicateRule(i)}
                  className={classes.copyIcon}
                  disabled={context.getData.readOnly}
                >
                  <FileCopyIcon fontSize='small' />
                </IconButton>
              ) : (
                <Tooltip title={t('duplicate') as string}>
                  <IconButton
                    onClick={() => props.duplicateRule(i)}
                    className={classes.copyIcon}
                    disabled={context.getData.readOnly}
                  >
                    <FileCopyIcon fontSize='small' />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
        ))
        .reverse()}
      {props.data.length === 0 && (
        <Box px={5} py={2}>
          <Fab
            variant='extended'
            color='primary'
            component={Link}
            size='small'
            to='/dynamic-content/add'
          >
            {t('addRule') as string}
          </Fab>
        </Box>
      )}
    </>
  );
};
export default ContentTable;

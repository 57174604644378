import React, { ChangeEvent } from 'react';
import {
  Box,
  Typography,
  Fab,
  withStyles,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
} from '@material-ui/core';
import styles from './Settings.style';
import FaceIcon from '@material-ui/icons/Face';
import HomeIcon from '@material-ui/icons/Home';
import BusinessIcon from '@material-ui/icons/Business';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import { WrappedIcon } from '../../helpers';
import { withTranslation } from 'react-i18next';
import { IComponentProps, AuthUser } from '../../models/app.model';
import AppContext from '../../data/AppContext';
import { isLocalhost } from '../../helpers/urlUtils';
import { InjectedCheckoutForm } from './StripeCheckout/InjtectedForm';
import { Elements } from '@stripe/react-stripe-js';
import { StripeProvider } from 'react-stripe-elements';
import { AlertDialog } from '../AlertDialog';
import { RouteComponentProps, withRouter } from 'react-router-dom';
const pk_key = isLocalhost()
  ? 'pk_test_RIGXzPMApBJZbqOrRs4u78pV00q1KCjBWX'
  : 'pk_live_IXIjaaSFzf33LXyRkMdW6vH600REzZng0P';
//const stripePromise = loadStripe(pk_key);

export interface PaymentBody {
  stripe: object;
  plan: string;
  affiliate: string;
  domainLimit: number;
}


export interface UpgradeProps extends IComponentProps, RouteComponentProps {}
export interface UpgradeState {
  code: {
    value: string;
    valid: boolean;
    discount: number;
  };
  plan_id: string;
  stripe: any;
  downgradeDialog: boolean;
  customPlan?:PlanOption;
  fetchingCustomPlan: boolean;
}

interface PlanOption {
  title: string;
  price: number;
  content: number;
  domains: number;
  spylink: number;
  history: number;
  companyLimit: number;
  pdf: number;
  description: string;
  color: 'primary' | 'secondary' | 'inherit' | undefined;
  plan_id: string;
  icon: React.ReactElement;
}

class Upgrade extends React.Component<UpgradeProps, UpgradeState> {
  static contextType = AppContext;
  isLoaded: boolean;
  user: AuthUser;
  constructor(props: UpgradeProps) {
    super(props);
    this.state = {
      code: {
        value: '',
        valid: false,
        discount: 1,
      },
      plan_id: '',
      downgradeDialog: false,
      fetchingCustomPlan:false,
    } as UpgradeState;
    this.isLoaded = false;
    this.user = {} as AuthUser;
  }
  features = ['domains', 'content', 'spylink', 'history', 'companyLimit', 'pdf', 'price'];
  options: PlanOption[] = [
    {
      title: 'free',
      price: 0,
      domains: 1,
      content: 1,
      spylink: 3,
      history: 30,
      companyLimit: 50,
      pdf: 1,
      description: 'basicDescription',
      color: 'primary',
      plan_id: 'free',
      icon: <MoneyOffIcon className={this.props.classes.bigIcon} />,
    },
    /* {
      title: 'pdf',
      price: 100,
      domains: 50,
      content: 0,
      spylink: 0,
      history: -1,
      companyLimit: -1,
      pdf: -1,
      description: 'pdfDescription',
      color: 'primary',
      plan: 'price_1HKM8JC35bzaxYgjKwiFKfUe',
      icon: <PictureAsPdfIcon className={this.props.classes.bigIcon} />,
    }, */
    {
      title: 'basic',
      price: 99,
      domains: 1,
      content: 1,
      spylink: -1,
      history: -1,
      companyLimit: 100,
      pdf: 3,
      description: 'basicDescription',
      color: 'primary',
      plan_id: 'plan-m',
      icon: <FaceIcon className={this.props.classes.bigIcon} />,
    },
    {
      title: 'premium',
      price: 299,
      domains: 5,
      content: -1,
      spylink: -1,
      history: -1,
      companyLimit: 500,
      pdf: 10,
      description: 'premiumDescription',
      color: 'secondary',
      plan_id: 'plan-l',
      icon: <HomeIcon className={this.props.classes.bigIcon} />,
    },
    {
      title: 'enterprise',
      price: 699,
      domains: 100,
      content: -1,
      spylink: -1,
      history: -1,
      companyLimit: 1000,
      pdf: -1,
      description: 'enterpriseDescription',
      color: 'primary',
      plan_id: 'plan-xl',
      icon: <BusinessIcon className={this.props.classes.bigIcon} />,
    },
  ];
  componentDidMount = async () => {
    this.user = this.context.getData.userData;
    this.isLoaded = true;
    const search = this.props.location.search;
    const parsedQuery = new URLSearchParams(search);
    const planId = parsedQuery.get('planId');
    const checkout = parsedQuery.get('checkout')==="true";
    if(planId){
      this.setState(()=>({ fetchingCustomPlan: true}),async ()=> {
        const customPlan= await this.context.getCustomPlanInfo(planId);
        this.setState(()=>({ customPlan, fetchingCustomPlan:false, plan_id:checkout? planId:"" }));
      })
    }
    if (window.Stripe) {
      this.setState({ ...this.state, stripe: window.Stripe(pk_key) });
    } else {
      (document as any).querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({ ...this.state, stripe: window.Stripe(pk_key) });
      });
    }
  };
  applyCode = async () => {
    //update discount in case it is valid
    const discount = await this.context.verifyVoucher(this.state.code.value);
    await this.setState({
      ...this.state,
      code: {
        ...this.state.code,
        ...discount,
      },
    });
  };
  subscribe = async (planType: string, free?: boolean) => {
    if (!planType) {
      return;
    }
    try {
      const pk_key = isLocalhost()
        ? 'pk_test_RIGXzPMApBJZbqOrRs4u78pV00q1KCjBWX'
        : 'pk_live_IXIjaaSFzf33LXyRkMdW6vH600REzZng0P';
      const stripe = (window as any).Stripe(pk_key);
      await this.context.paymentClicked(planType);
      const sessionId = await this.context.getStripeSession(planType);
      await stripe.redirectToCheckout({ sessionId });
    } catch (e) {
      this.isLoaded = true;
      alert(this.props.t('common:error'));
    }
  };
  subscribeNew = async (planType: string, free?: boolean) => {
    try {
      if (planType === 'free') {
        this.setState({ ...this.state, downgradeDialog: true });
        return;
      }
      await this.setState({
        ...this.state,
        plan_id: planType,
      });
      await this.context.paymentClicked(this.state.plan_id);
    } catch (e) {
      this.isLoaded = true;
      alert(this.props.t('common:error'));
    }
  };
  updateCode = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      code: {
        ...this.state.code,
        value: e.target.value,
      },
    });
  };
  cancelCheckout = () => {
    this.setState({
      ...this.state,
      plan_id: '',
    });
  };
  getOptionsDetails = (prop: string) => {
    const {t} = this.props;
    return this.options.map((option: PlanOption, i: number) => {
      if (prop === 'price') {
        return (
          <TableCell key={i}>
            <Typography variant='h4' color={option.color}>
              {Math.round((option as any)[prop] * +this.state.code.discount).toFixed(2)}
            </Typography>
            <Typography component='span' variant='caption' color='textSecondary'>
              {t('upgrade.perMonth')} *
            </Typography>
          </TableCell>
        );
      }
      const value = (option as any)[prop];
      return (
        <TableCell key={i}>
          <Typography variant='h6'>
            {value > 0 ? (
              <>{`${value} ${prop === 'history' ? t('upgrade.days') : ''}`}</>
            ) : value === 0 ? (
              t('upgrade.notAvailable')
            ) : (
              t('upgrade.unlimited')
            )}
          </Typography>
        </TableCell>
      );
    });
  };
  getPlansTable = () => {
    const {t} = this.props; 
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {this.options.map((option: PlanOption, i: number) => (
              <TableCell key={i}>
                <WrappedIcon color={option.color}>{option.icon}</WrappedIcon>
                <Typography variant='h4' color={option.color}>
                  {t(`upgrade.plans.${option.title}`)}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {this.features.map((feature: string, i: number) => (
            <TableRow key={i}>
              <TableCell>{t('upgrade.features.' + feature)}</TableCell>
              {this.getOptionsDetails(feature)}
            </TableRow>
          ))}
          <TableRow>
            <TableCell></TableCell>
            {this.options.map((option: PlanOption, i: number) => (
              <TableCell key={i}>
                <Fab
                  variant='extended'
                  color={option.color}
                  onClick={() => this.subscribeNew(option.plan_id)}
                >
                  {t('upgrade.upgrade') as string}
                </Fab>
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  get customPlan() {
    const {customPlan, fetchingCustomPlan} = this.state;
    const { t } = this.props;

    if(fetchingCustomPlan){
      return <p>Loading</p>
    }
    if(!customPlan){
      return <></>
    }

    const { title, price, plan_id, ...rest} = customPlan;
    return <Box p={5}>
    <Typography variant="h4" gutterBottom>Ihr individueller Plan wartet auf Sie</Typography>
    <Typography variant="h5" color="secondary" gutterBottom>{title}</Typography>
    <Grid container component={Box} my={2}>
      {Object.keys(rest).filter(key=> (rest as any)[key] !==false).map((key:string)=>{
        const value = (rest as any)[key];
        console.log(key, value)
        const formattedValue = value && typeof value==="boolean" ? t("upgrade.included"): value >=0 ? value : t("upgrade.unlimited") 
        return <Grid item xs={12} sm={6} md={4} key={key} component={Box} mb={1}>
          <Typography variant="body2">{t("upgrade.features."+key)}</Typography>
      <Typography variant="h6" gutterBottom>{formattedValue}</Typography>
        </Grid>
      })}
      
    </Grid>
    <Fab variant="extended" color="primary" onClick={() => this.subscribeNew(plan_id)}>{t("upgrade.upgrade") as string}</Fab>
    </Box>;
  }
  getTestButtons = () => {
    return isLocalhost() && !this.state.plan_id ? (
      <Box m={5}>
        <Button
          variant='contained'
          color='primary'
          onClick={() => this.subscribe('plan_F6fLyv5QhSCSdv')}
        >
          Test Checkout (only admin locally)
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={() => this.subscribeNew('price_1HKM8JC35bzaxYgjKwiFKfUe')}
        >
          Test PaymentIntent / Stripe Elements (only admin locally)
        </Button>
      </Box>
    ) : null;
  };
  render() {
    const { t } = this.props;
    return (
      <Box>
        {this.state.plan_id ? (
          <Box p={5}>
            <StripeProvider apiKey={pk_key}>
              <Elements stripe={this.state.stripe}>
                <InjectedCheckoutForm plan={this.state.plan_id} onCancel={this.cancelCheckout} />
              </Elements>
            </StripeProvider>
          </Box>
        ) : (
          <>
            {this.customPlan}
            <Box p={5} pb={2}>
              <Typography variant='h4'>{t('upgrade.choosePlan')}</Typography>
            </Box>
            <Box className={this.props.classes.optionsTable}>{this.getPlansTable()}</Box>
          </>
        )}
        {this.getTestButtons()}
        {!this.state.plan_id && (
          <Box p={5}>
            <Typography variant='subtitle1' color='textSecondary' gutterBottom>
              {t('upgrade.otherMethods')}
            </Typography>
            <Typography variant='body2' component='p' color='textSecondary'>
              * {t('upgrade.allPrices')}
            </Typography>
          </Box>
        )}
        <AlertDialog
          justConfirm={true}
          open={this.state.downgradeDialog}
          title={t('downgradeTitle')}
          message={t('downgrade')}
          handleClose={() => this.setState({ ...this.state, downgradeDialog: false })}
        />
      </Box>
    );
  }
}
export default withStyles(styles)(withTranslation('settings')(withRouter(Upgrade)));

import React, { Component, ChangeEvent } from 'react';
import AppContext from '../../data/AppContext';
import {
  Box,
  Grid,
  Typography,
  Fab,
  TextField,
  InputAdornment,
  Tooltip,
  IconButton,
  createStyles,
  Theme,
  withStyles,
  Tabs,
  Tab,
} from '@material-ui/core';
import { SpylinkList } from './SpylinkList';
import SpylinkAnalytics from './SpylinkAnalytics';
import LoadingBox from '../LoadingBox';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import SpylinkForm from './SpylinkForm';
import SaveSnackbar from '../SaveSnackbar';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TuneIcon from '@material-ui/icons/Tune';
import { AlertDialog } from '../AlertDialog';
import { IComponentProps, IDomain } from '../../models/app.model';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import NoDomain from '../NoDomain';
import SpylinkVisits from './SpylinkVisits';

export interface ISpyLink {
  _id: string;
  name: string;
  disabled: boolean;
  linkId: string;
  linkUrl: string;
  notification: boolean;
  mail: string;
  domain: string;
  lastNotification: string;
  analytics: any;
}
export interface ISpyLinkAnalytics {
  totalTriggered: number;
  visitsByDate: number;
  companies: any[];
}
export type k = keyof ISpyLink;
export interface SpylinkProps extends IComponentProps {}
export interface SpylinkState {
  links: ISpyLink[];
  filter: string;
  selected: number;
  defaultUrl: string;
  websites: string[];
  isSaving: boolean;
  removeDialog: boolean;
  copySnackbar: boolean;
  domain?: IDomain;
  activeTab: number;
  analyticsData?: ISpyLinkAnalytics;
  limitDialog: boolean;
  rendered: boolean;
}

const styles = createStyles((theme: Theme) => ({
  contentBlock: {
    position: 'sticky',
    top: 0,
    maxHeight: '100vh',
  },
  contentScroll: {
    overflowY: 'scroll',
    maxHeight: '75vh',
  },
}));

class Spylink extends Component<SpylinkProps, SpylinkState> {
  static contextType = AppContext;
  private mounted: boolean;
  constructor(props: SpylinkProps) {
    super(props);
    this.state = {
      links: [],
      filter: '',
      selected: -1,
      defaultUrl: '',
      websites: [],
      isSaving: false,
      removeDialog: false,
      limitDialog: false,
      copySnackbar: false,
      activeTab: 0,
      rendered: false,
    };
    this.mounted = false;
  }
  tabOptions = [
    { title: 'settingsTab', icon: <TuneIcon />, tab: 0 },
    { title: 'analyticsTab', icon: <EqualizerIcon />, tab: 1 },
    { title: 'visitsTab', icon: <VisibilityIcon />, tab: 2 },
  ];

  initSpyLinks = async () => {
    await this.setState({ ...this.state, rendered: false });
    await this.context.getSpylinks();
    const links = this.context.getData.spylinks;
    const urls = links.filter((link: ISpyLink) => link.linkUrl).map((link: ISpyLink) => link.linkUrl);

    const defaultUrl = await this.context.getData.detectedUrl;
    if (this.mounted) {
      await this.setState({
        ...this.state,
        links,
        domain: this.context.getData.domain,
        selected: links.length > 0 ? links.length - 1 : -1,
        websites: [defaultUrl, ...urls]
          .filter((link: string, i: number, arr: string[]) => arr.indexOf(link) === i)
          .sort(),
        rendered: true,
      });
    }
    this.context.setData = {
      fetching: {
        ...this.context.getData.fetching,
        domains: false,
        spylinks: false,
      },
    };
  };

  async componentDidMount() {
    this.mounted = true;
    this.initSpyLinks();
  }
  async componentDidUpdate(prevProps: SpylinkProps, prevState: SpylinkState) {
    if (
      this.state.domain &&
      this.state.domain.key !== this.context.getData.domain.key &&
      !this.context.getData.fetching.spylinks
    ) {
      this.initSpyLinks();
    }
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  showRemoveDialog = () => {
    this.setState({ ...this.state, removeDialog: true });
  };
  removeLink = async () => {
    const linkToRemove = [...this.state.links].find(
      (link: ISpyLink, i: number) => i === this.state.selected
    );
    this.setState({
      links: this.state.links.filter((link) => link !== linkToRemove),
      selected: this.state.links.length > 1 ? this.state.links.length - 2 : -1,
    });
    await this.context.removeSpylink(linkToRemove);
  };
  saveSpylink = async () => {
    await this.setState({ ...this.state, isSaving: true });
    await (this.context.setData = { spylinks: this.state.links });
    await this.context.setSpylink(this.state.links);
    this.setState({ ...this.state, isSaving: false });
  };
  updateField = (e: ChangeEvent<HTMLInputElement>) => {
    this.updateLink({ [e.target.name]: e.target.value });
  };
  selectLink = (link: ISpyLink) => {
    this.setState({ ...this.state, selected: this.state.links.indexOf(link) });
  };
  toggleDisable = (e: ChangeEvent<any>) => {
    const { spylink: spylinkLimit } = this.context.getData.limits;
    if (e.target.checked && spylinkLimit !== -1) {
      // ONLY THEN WE BOTHER TO CHECK
      const activeSpylinks = this.state.links.filter((s: ISpyLink) => !s.disabled).length;
      if (activeSpylinks >= spylinkLimit) {
        return this.setState({ ...this.state, limitDialog: true });
      }
    }
    const link = this.state.links[this.state.selected];
    this.updateLink({ [e.target.name]: !(link as any)[e.target.name] });
  };
  updateLink = (newValue: any) => {
    const links = this.state.links;
    links[this.state.selected] = {
      ...links[this.state.selected],
      ...newValue,
    };
    this.setState({ ...this.state, links });
  };

  getFilter = () => {
    return (
      <Box p={2}>
        <TextField
          variant='outlined'
          fullWidth
          value={this.state.filter}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            this.setState({ ...this.state, filter: e.target.value })
          }
          placeholder={this.props.t('filterLinks')}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
    );
  };
  getHeader = () => {
    return (
      <Box display='flex' alignItems='center' justifyContent='space-between' flexWrap='wrap' pb={3}>
        <Box>
          <Typography variant='h4'>{this.props.t('title')}</Typography>
          <Typography variant='subtitle1'>{this.props.t('description')}</Typography>
        </Box>
        <Box>
          {this.context.getData.readOnly ? (
            <Fab
              color='primary'
              component={Link}
              to='/spylink/add'
              disabled={this.context.getData.readOnly}
            >
              <AddIcon />
            </Fab>
          ) : (
            <Tooltip title={this.props.t('addSpylink') as string}>
              <Fab
                color='secondary'
                component={Link}
                to='/spylink/add'
                disabled={this.context.getData.readOnly}
              >
                <AddIcon />
              </Fab>
            </Tooltip>
          )}
        </Box>
      </Box>
    );
  };
  changeTab = (value: number) => {
    this.setState({ ...this.state, activeTab: value });
  };
  getContent = () => {
    const { classes } = this.props;
    return (
      <Box m={5}>
        {this.getHeader()}
        <Grid container spacing={5} component={Box} minHeight='100vh'>
          <Grid item xs={12} md={4}>
            <LoadingBox
              condition={this.context.getData.fetching.spylinks || !this.state.rendered}
              empty={this.state.links.length === 0 && this.state.rendered}
            >
              {this.getFilter()}
              <SpylinkList
                links={this.state.links}
                filter={this.state.filter}
                selected={this.state.selected}
                onSelect={this.selectLink}
              />
            </LoadingBox>
          </Grid>
          <Grid item xs={12} md={8} component={Box} className={classes.contentBlock}>
            <LoadingBox
              condition={this.context.getData.fetching.spylinks || !this.state.rendered}
              position='relative'
              empty={this.state.selected < 0 && this.state.rendered}
            >
              <>
                <Box>
                  <Tabs
                    value={this.state.activeTab}
                    classes={{ root: classes.tabs }}
                    onChange={(e: any, value: number) => {
                      this.changeTab(value);
                    }}
                  >
                    {this.tabOptions.map((option: any, i: number) => (
                      <Tab
                        key={i}
                        label={
                          <Box display='flex' alignItems='center'>
                            {option.icon}
                            <Typography variant='body2' style={{ marginLeft: 5 }}>
                              {this.props.t('common:' + option.title)}
                            </Typography>
                          </Box>
                        }
                      />
                    ))}
                  </Tabs>
                  <Box className={classes.contentScroll}>
                    {this.state.activeTab === 0 && (
                      <SpylinkForm
                        link={this.state.links[this.state.selected] || {}}
                        websites={this.state.websites}
                        onInputUpdate={this.updateField}
                        onSwitchUpdate={this.toggleDisable}
                        onRemove={this.showRemoveDialog}
                        onSave={this.saveSpylink}
                        updateLinkUrl={(linkUrl: string) => this.updateLink({ linkUrl })}
                      />
                    )}
                    {this.state.activeTab === 1 && (
                      <SpylinkAnalytics data={this.state.links[this.state.selected].analytics} />
                    )}
                    {this.state.activeTab === 2 && (
                      <SpylinkVisits
                        requests={this.state.links[this.state.selected].analytics.requests || []}
                      />
                    )}
                  </Box>
                </Box>
                {this.getControls()}
              </>
            </LoadingBox>
          </Grid>
        </Grid>
        <AlertDialog
          open={this.state.removeDialog}
          title={this.props.t('removeSpylink')}
          message={this.props.t('confirmRemove')}
          handleClose={() => this.setState({ ...this.state, removeDialog: false })}
          action={() => this.removeLink()}
        />
        <AlertDialog
          justConfirm={true}
          open={this.state.limitDialog}
          title={this.props.t('limitTitle')}
          message={this.props.t('limitExceeded', {
            limit: this.context.getData.limits.spylink,
          })}
          handleClose={() => this.setState({ ...this.state, limitDialog: false })}
        />
        <SaveSnackbar open={this.state.isSaving} />
      </Box>
    );
  };
  getControls = () => {
    return (
      <Box
        position='absolute'
        top='100%'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        mt={1}
        width='100%'
      >
        <Fab
          color='primary'
          variant='extended'
          size='small'
          type='submit'
          disabled={this.context.getData.readOnly}
        >
          {this.props.t('common:saveShort') as string}
        </Fab>
        <Box>
          {this.context.getData.readOnly ? (
            <IconButton disabled={this.context.getData.readOnly}>
              <DeleteIcon />
            </IconButton>
          ) : (
            <Tooltip
              title={this.props.t('removeSpylink') as string}
              disableHoverListener={this.context.getData.readOnly}
            >
              <IconButton onClick={this.showRemoveDialog} disabled={this.context.getData.readOnly}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
    );
  };
  submitForm = (e: React.FormEvent) => {
    e.preventDefault();
    this.saveSpylink();
  };
  render() {
    return (
      <>
        {this.context.getData.domain && <form onSubmit={this.submitForm}>{this.getContent()}</form>}
        {!this.context.getData.fetching.spylinks &&
          !this.context.getData.fetching.domains &&
          !this.state.domain && <NoDomain />}
      </>
    );
  }
}

export default withStyles(styles)(withTranslation(['spylink', 'common'])(Spylink));

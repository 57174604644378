import React, { Component, SyntheticEvent } from 'react';
import {
  withStyles,
  createStyles,
  Theme,
  Box,
  Grid,
  Typography,
  Modal,
  Fade,
  Backdrop,
  IconButton,
  Tooltip,
  Link as HLink,
} from '@material-ui/core';
import AppContext from '../../data/AppContext';
import { IComponentProps, DocVisitor } from '../../models/app.model';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Video from '../video/Video';
import VideocamIcon from '@material-ui/icons/Videocam';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GetAppIcon from '@material-ui/icons/GetApp';
import { getCompanyIcon } from '../../helpers';

const styles = createStyles((theme: Theme) => ({
  videoFrame: {
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
  },
  visitor: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.common.white,
    },
  },
  rrow: {
    'display': 'flex',
    'alignItems': 'center',
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.common.white,
    },
  },
}));

export interface PdfVisitsProps extends IComponentProps, RouteComponentProps {
  visitors: DocVisitor[];
}
export interface PdfVisitsState {
  isModalVisible: boolean;
  selected: number;
  selectedReq: number;
  company: any;
  expanded: number[];
}

class PdfVisits extends Component<PdfVisitsProps, PdfVisitsState> {
  static contextType = AppContext;
  state = {
    isModalVisible: false,
    selected: -1,
    selectedReq: -1,
    company: null,
    expanded: [] as number[],
  } as PdfVisitsState;

  toggleModal = async (e: any, vi: number, i: number) => {
    if (i >= 0) {
      const company = await this.context.getDocCompany(this.props.visitors[vi].companyKey);
      return this.setState({
        ...this.state,
        selected: vi,
        selectedReq: i,
        isModalVisible: !this.state.isModalVisible,
        company,
      });
    }
    this.setState({
      ...this.state,
      selected: vi,
      selectedReq: i,
      isModalVisible: !this.state.isModalVisible,
      company: null,
    });
  };
  getFormattedDate = (fullDate: Date) => {
    const stringDate = new Date(fullDate).toLocaleString().split(',');
    const date = stringDate[0].trim();
    const time = stringDate[1].trim();
    return (
      <>
        <Typography variant='h6' component='h6'>
          {time}
        </Typography>
        <Typography variant='caption' color='textSecondary' component='p'>
          {date}
        </Typography>
      </>
    );
  };
  toggleVisits = (index: number) => {
    const { expanded } = this.state;
    if (expanded.includes(index)) {
      this.setState({ ...this.state, expanded: expanded.filter((e) => e !== index) });
    } else {
      this.setState({ ...this.state, expanded: [index, ...expanded] });
    }
  };

  render() {
    const { classes, t } = this.props;
    return (
      <Box>
        {this.props.visitors.length === 0 && (
          <Box p={5}>
            <Typography variant='body2'>{t('common:noData')}</Typography>
          </Box>
        )}
        {this.props.visitors.map((v, i) => {
          return (
            <Box className={classes.visitor} key={i}>
              <Grid container alignItems='center'>
                <Grid item xs={12} sm={2}>
                  <Box display='flex' alignItems='center' flexDirection='column' p={2}>
                    {this.getFormattedDate(v.date)}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box display='flex' alignItems='center'>
                    <Box p={1}>
                      {getCompanyIcon(
                        v.companyUrl.startsWith('http') ? v.companyUrl : 'http://' + v.companyUrl
                      )}
                    </Box>
                    <Box display='flex' justifyContent='center' flexDirection='column' mx={1} py={1}>
                      <Typography variant='h6'>
                        <Link to={`/companies/${v.companyKey}`}>{v.companyName}</Link>
                      </Typography>
                      <Typography variant='body2' color='textSecondary'>
                        {v.companyAdress}
                      </Typography>
                      <Box display='flex' alignItems='center'>
                        <Typography variant='caption'>
                          {`${t('visit', {
                            count: v.visits,
                          })} / ${t('totalTime')} ${v.time.slice(0, 5)}`}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Box display='flex' alignItems='center' justifyContent='flex-end' mx={1}>
                    <Box display='flex' alignItems='center' mx={2}>
                      <Tooltip title={v.downloaded ? t('downloaded') as string : t('notDownloaded') as string}>
                        <GetAppIcon color={v.downloaded ? 'secondary' : 'disabled'} />
                      </Tooltip>
                    </Box>
                    <Box display='flex' alignItems='center' mx={2}>
                      <Tooltip title={v.hasVideo ? t('videos') as string : t('noVideos') as string}>
                        <VideocamIcon color={v.hasVideo ? 'secondary' : 'disabled'} />
                      </Tooltip>
                    </Box>

                    <Box display='flex' alignItems='center' mx={2}>
                      <IconButton onClick={() => this.toggleVisits(i)}>
                        <ExpandMoreIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Grid>
                {this.state.expanded.includes(i) &&
                  v.requests.map((vr, j) => (
                    <Grid item xs={12} className={this.props.classes.rrow} key={j}>
                      <Grid container alignItems='center'>
                        <Grid item xs={4} md={2}>
                          <Box p={2} display='flex' alignItems='center' justifyContent='center'>
                            <Typography variant='body2' align='center'>
                              {new Date(vr.date).toLocaleTimeString()}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={4} md={6}>
                          <Box p={2}>
                            <HLink href={vr.referer} variant='body2'>
                              {vr.referer}
                            </HLink>
                          </Box>
                        </Grid>
                        <Grid item xs={4} md={4}>
                          <Box display='flex' alignItems='center' justifyContent='flex-end' px={2}>
                            {vr.downloaded && (
                              <Tooltip title={t('downloaded') as string}>
                                <IconButton>
                                  <GetAppIcon color='secondary' />
                                </IconButton>
                              </Tooltip>
                            )}
                            <Typography variant='body2' style={{ margin: '0 10px' }}>
                              {vr.time}
                            </Typography>
                            {vr.hasVideo ? (
                              <Tooltip title={t('showActivity') as string}>
                                <IconButton onClick={(e) => this.toggleModal(e, i, j)}>
                                  <PlayCircleFilledIcon color='secondary' />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <IconButton disabled>
                                <PlayCircleFilledIcon color='disabled' />
                              </IconButton>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
            </Box>
          );
        })}
        <Modal
          open={this.state.isModalVisible}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          // onClose={() => this.setState({ ...this.state, isModalVisible: false })}
        >
          <Fade in={this.state.isModalVisible}>
            <Box className={classes.videoFrame}>
              {this.state.selected >= 0 && (
                <Video
                  req={this.props.visitors[this.state.selected].requests}
                  selected={this.state.selectedReq}
                  onClose={(e: SyntheticEvent) => this.toggleModal(e, -1, -1)}
                  company={this.state.company}
                  doc={true}
                />
              )}
            </Box>
          </Fade>
        </Modal>
      </Box>
    );
  }
}

export default withStyles(styles)(withTranslation(['uploadPdf', 'common'])(withRouter(PdfVisits)));

import React from 'react';
import clsx from 'clsx';
import { Switch, Route } from 'react-router-dom';
//STYLES
import { Theme } from '@material-ui/core/styles';
import { drawerWidth } from '../components/Sidebar';
//COMPONENTS
import { Box, makeStyles } from '@material-ui/core';
import Visitors from '../components/visitors/Visitors';
import Dashboard from '../components/dashboard/Dashboard';
import DynamicContent from '../components/dynamicContent/Dynamic';
import Notifications from '../components/settings/SettingsNotifications';
import Header from '../components/Header';
import Company from '../components/company/Company';
import CompanyAll from '../components/company/CompanyAll';
import Settings from '../components/settings/Settings';
import AddForm from '../components/dynamicContent/DynamicAddForm';
import { error404 } from '../helpers';
import Sidebar from '../components/Sidebar';
import EditorView from '../components/dynamicContent/editor/EditorView';
//CONTEXT
import AppContext from '../data/AppContext';
//MODELS
import Spylink from '../components/spylink/Spylink';
import SpylinkAdd from '../components/spylink/SpylinkAdd';
import Reminders from '../components/reminders/Reminders';
import PdfList from '../components/uploadPdf/PdfList';
import Admin from '../components/admin/Admin';
import PdfDetails from '../components/uploadPdf/PdfDetails';
import CustomDomainInfo from '../components/uploadPdf/CustomDomainInfo';
import { MyRoute } from './Route';

const styles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  open: {
    [theme.breakpoints.only('sm')]: {
      marginLeft: theme.spacing(9),
    },

    [theme.breakpoints.up('md')]: {
      marginLeft: `${drawerWidth}px`,
    },
  },
  close: {
    [theme.breakpoints.only('sm')]: {
      marginLeft: theme.spacing(9),
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(9),
    },
  },
}));

const Container: React.FC = () => {
  const classes = styles();
  const context = React.useContext(AppContext);
  const { isNavOpen } = context.getData;
  return (
    <Box
      className={clsx(classes.root, {
        [classes.open]: isNavOpen,
        [classes.close]: !isNavOpen,
      })}
    >
      <Sidebar />
      <Header />

      <Switch>
        <Route path='/visitors' component={Visitors} />
        <Route exact path='/dynamic-content' component={DynamicContent} />
        <Route exact path='/dynamic-content/add' component={AddForm} />
        <Route path='/dynamic-content/add/:id' component={AddForm} />
        <Route path='/dynamic-content/edit/:id' component={EditorView} />
        <Route exact path='/notifications' component={Notifications} />
        <Route exact path='/documents' component={PdfList} />
        <Route exact path='/documents/custom-domain' component={CustomDomainInfo} />
        <Route exact path='/documents/:id' component={PdfDetails} />
        <Route exact path='/reminders' component={Reminders} />
        <Route exact path='/companies' component={CompanyAll} />
        <Route exact path='/companies/:key' component={Company} />
        <Route exact path='/settings' component={Settings} />
        <Route exact path='/spylink' component={Spylink} />
        <Route exact path='/spylink/add' component={SpylinkAdd} />
        <MyRoute onlyAdmin={true} exact path='/admin' component={Admin} />
        <Route exact path='/' component={Dashboard} />
        <Route component={error404} />
      </Switch>
    </Box>
  );
};

export default Container;

import React, { Component } from 'react';
import { TextField } from '@material-ui/core';
import StripeInput from './StripeInput';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  IbanElement
} from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';

class StripeTextField extends React.PureComponent<any> {
  render() {
    const { InputLabelProps, stripeElement, InputProps, ...other } = this.props;

    return (
      <TextField
        fullWidth
        InputLabelProps={{
          ...InputLabelProps,
          shrink: true
        }}
        required
        InputProps={{
          ...InputProps,
          inputProps: {
            component: stripeElement
          },
          inputComponent: StripeInput
        }}
        {...other}
      />
    );
  }
}

export function StripeTextFieldNumber(props: any) {
  const { t } = useTranslation(['settings']);
  return <StripeTextField label={t('cardNumber')} stripeElement={CardNumberElement} {...props} />;
}

export function StripeTextFieldExpiry(props: any) {
  const { t } = useTranslation(['settings']);
  return <StripeTextField label={t('expires')} stripeElement={CardExpiryElement} {...props} />;
}

export function StripeTextFieldCvc(props: any) {
  const { t } = useTranslation(['settings']);
  return <StripeTextField label={t('cvcCode')} stripeElement={CardCvcElement} {...props} />;
}
const MyIbanElement = (props: any) => {
  return <IbanElement options={{ supportedCountries: ['SEPA'], placeholderCountry: 'DE' }} {...props} />;
};
export function StripeTextFieldBankNumber(props: any) {
  const { t } = useTranslation(['settings']);
  //  options={{ supportedCountries: ['SEPA'], placeholderCountry: 'DE' }}
  return <StripeTextField label={t('bankAccount')} stripeElement={MyIbanElement} {...props} />;
}

import React, { Component, ChangeEvent } from 'react';
import {
  Box,
  Typography,
  TextField,
  IconButton,
  Fab,
  Link,
  Switch,
  withStyles,
  createStyles,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { ExcludedUrl, DomainInfo, AuthUser, IDomain, IComponentProps } from '../../models/app.model';
import AppContext from '../../data/AppContext';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import groupArray from 'group-array';
import { withTranslation } from 'react-i18next';
import SaveSnackbar from '../SaveSnackbar';
import { AlertDialog } from '../AlertDialog';

const styles = createStyles((theme: Theme) => {});

interface ExcludedProps extends IComponentProps {
  admin: boolean;
}
interface ExcludedState {
  newUrls: ExcludedUrl[];
  urls: ExcludedUrl[];
  users: AuthUser[];
  domains: DomainInfo[];
  isSaving: boolean;
  removeDialog: boolean;
  toRemove: ExcludedUrl;
}
class Excluded extends Component<ExcludedProps, ExcludedState> {
  static contextType = AppContext;
  state = {
    newUrls: [] as ExcludedUrl[],
    urls: [] as ExcludedUrl[],
    users: [] as AuthUser[],
    domains: [] as DomainInfo[],
    isSaving: false,
    removeDialog: false,
    toRemove: {} as ExcludedUrl,
  };
  async componentDidMount() {
    const urls = await this.context.getExcludedUrls();
    const users = await this.context.getData.allUsers;
    const domains = this.props.admin
      ? users.reduce((domains: DomainInfo[], user: AuthUser) => {
          const domainKeys = user.domains
            .filter((d: IDomain) => !d.disabled)
            .map((d: IDomain) => ({
              domainKey: d.key,
              name: d.name,
            }));
          domains = domains.concat(domainKeys as DomainInfo[]);
          return domains;
        }, [] as DomainInfo[])
      : this.context.getData.userData.domains.map((domain: IDomain) => {
          return {
            domainKey: domain.key,
            name: domain.name,
          };
        });
    this.setState({
      ...this.state,
      urls: urls.filter((url: ExcludedUrl) =>
        domains.some((d: DomainInfo) => d.domainKey === url.domain)
      ),
      users,
      domains,
    });
  }
  updateUrl = (url: ExcludedUrl, e?: ChangeEvent<HTMLInputElement> | undefined) => {
    const urls = this.state.urls;
    const match = urls.find((u: ExcludedUrl) => u === url) as any;
    if (e) {
      const name = e.target.name;
      if (name === 'wildcard') {
        match[e.target.name] = e.target.checked;
      } else {
        match[e.target.name] = e.target.value;
      }
    } else {
      match.editMode = !match.editMode;
    }
    this.setState({ ...this.state, urls });
  };
  removeUrl = async (url: ExcludedUrl) => {
    await this.setState({ ...this.state, isSaving: true });
    const urls = this.state.urls.filter((u: ExcludedUrl) => u !== url);
    this.context.removeExcludedUrl(url._id);
    this.setState({ ...this.state, urls });
    await this.setState({ ...this.state, isSaving: false });
  };
  fetchUrls = async () => {
    const urls = await this.context.getExcludedUrls();
    this.setState({
      ...this.state,
      urls: urls.filter((url: ExcludedUrl) =>
        this.state.domains.some((d: DomainInfo) => d.domainKey === url.domain)
      ),
    });
  };
  addUrl = () => {
    this.setState({
      ...this.state,
      newUrls: [
        ...this.state.newUrls,
        {
          url: '',
          domain: this.context.getData.domain.key,
          userId: this.context.getData.userData.id,
        } as ExcludedUrl,
      ],
    });
  };
  removeNewUrl = (i: number) => {
    const newUrls = this.state.newUrls;
    newUrls.splice(i, 1);
    this.setState({ ...this.state, newUrls });
  };
  saveUrls = async (e: React.FormEvent) => {
    e.preventDefault();
    await this.setState({ ...this.state, isSaving: true });
    await Promise.all(
      this.state.urls
        .filter((url: ExcludedUrl) => url.editMode)
        .map(async (url: ExcludedUrl) => {
          await this.context.updateExcludedUrl(url);
          this.updateUrl(url);
        })
    );
    await this.context.saveExcludedUrls(this.state.newUrls);
    await this.fetchUrls();
    this.setState({ ...this.state, newUrls: [] as ExcludedUrl[], isSaving: false });
  };
  changeInput = (i: number, e: ChangeEvent<HTMLInputElement>) => {
    const newUrls = this.state.newUrls;
    (newUrls[i] as any)[e.target.name] = e.target.value;
    this.setState({ ...this.state, newUrls });
  };
  editMode = (url: ExcludedUrl) => {
    url.editMode = true;
  };
  cloneUrl = (url: ExcludedUrl) => {
    this.setState({
      ...this.state,
      newUrls: [
        ...this.state.newUrls,
        { url: url.url, domain: url.domain, userId: this.context.getData.userData.id },
      ] as ExcludedUrl[],
    });
  };
  getDomainName = (key: string) => {
    const match = this.state.domains.find((d: DomainInfo) => d.domainKey === key) as DomainInfo;
    return match ? match.name : key;
  };
  getUrlsGroupedByDomain = () => {
    const { t } = this.props;
    const grouped = groupArray(this.state.urls, 'domain');
    return Object.entries(grouped).map((entry: any, k: number) => {
      return (
        <Box key={k} mb={2}>
          <Typography variant='h6' gutterBottom>
            Domain:{' '}
            <Typography component='span' variant='inherit' color='secondary'>
              {this.getDomainName(entry[0])}
            </Typography>
          </Typography>

          {entry[1]
            .map((url: ExcludedUrl, i: number) => {
              return (
                <Box
                  key={i}
                  p={1}
                  style={{ backgroundColor: i % 2 === 0 ? '#fff' : '#f3f3f3' }}
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Box display='flex' alignItems='center' px={1} flexGrow='1' flexWrap='wrap'>
                    {url.editMode ? (
                      <>
                        <Box flexBasis='100%' display='flex' mb={1}>
                          <TextField
                            value={url.url}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => this.updateUrl(url, e)}
                            fullWidth
                            label='Url'
                            name='url'
                            variant='outlined'
                            style={{ marginRight: 10 }}
                          />
                          {this.props.admin && (
                            <TextField
                              value={url.userId}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => this.updateUrl(url, e)}
                              fullWidth
                              label='User'
                              name='userId'
                              variant='outlined'
                              style={{ marginRight: 10 }}
                              select
                              SelectProps={{ native: true }}
                            >
                              {this.state.users.map((user: AuthUser, j: number) => (
                                <option key={j} value={user.id}>
                                  {user.displayName}
                                </option>
                              ))}
                            </TextField>
                          )}
                          <TextField
                            value={url.domain}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => this.updateUrl(url, e)}
                            fullWidth
                            name='domain'
                            label='Domain'
                            variant='outlined'
                            style={{ marginRight: 10 }}
                            select
                            SelectProps={{ native: true }}
                          >
                            {this.state.domains.map((domain: DomainInfo, j: number) => (
                              <option key={j} value={domain.domainKey}>
                                {domain.name}
                              </option>
                            ))}
                          </TextField>
                        </Box>
                        <Typography variant='body2'>{t('excluded.excludeSubPaths')}</Typography>
                        <Switch
                          checked={url.wildcard || false}
                          name='wildcard'
                          onChange={async (e) => {
                            await this.updateUrl(url, e);
                            await this.context.updateExcludedUrl(url);
                          }}
                        />
                      </>
                    ) : (
                      <Box
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                        flexGrow='1'
                      >
                        <Link href={url.url} target='_blank'>
                          {url.url}
                        </Link>
                        {url.wildcard && (
                          <Typography variant='body2'>{`* ${t(
                            'excluded.excludedSubPaths'
                          )}`}</Typography>
                        )}
                      </Box>
                    )}
                  </Box>
                  <Box display='flex' alignItems='center'>
                    {url.editMode ? (
                      <Tooltip title={t('common:saveChanges') as string}>
                        <IconButton
                          onClick={async () => {
                            this.setState({ ...this.state, isSaving: true });
                            await this.context.updateExcludedUrl(url);
                            this.updateUrl(url);
                            this.setState({ ...this.state, isSaving: false });
                          }}
                        >
                          <SaveIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title={t('common:edit') as string}>
                        <IconButton onClick={() => this.updateUrl(url)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title={t('common:duplicate') as string}>
                      <IconButton onClick={() => this.cloneUrl(url)}>
                        <FileCopyIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title={t('common:remove') as string}>
                      <IconButton
                        onClick={() =>
                          this.setState({ ...this.state, removeDialog: true, toRemove: url })
                        }
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              );
            })
            .reverse()}
        </Box>
      );
    });
  };
  render() {
    const { t } = this.props;
    return (
      <form onSubmit={this.saveUrls}>
        <Box p={5}>
          <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
            <Box>
              <Typography variant='h4'>{t('excluded.excludedTitle')}</Typography>
              <Typography variant='subtitle1'>{t('excluded.excludedDescription')}</Typography>
            </Box>

            <Box>
              <Tooltip title={t('excluded.add') as string}>
                <Fab onClick={this.addUrl} color='secondary' style={{ marginRight: 10 }}>
                  <AddIcon />
                </Fab>
              </Tooltip>

              <Tooltip title={t('common:saveChanges') as string}>
                <Fab type='submit' color='primary'>
                  <SaveIcon />
                </Fab>
              </Tooltip>
            </Box>
          </Box>
          <Box my={5}>
            {this.state.newUrls
              .map((newUrl: ExcludedUrl, i: number) => {
                return (
                  <Box display='flex' key={i} my={2} alignItems='center'>
                    <TextField
                      fullWidth
                      variant='outlined'
                      value={newUrl.url}
                      required
                      label={t('excluded.url')}
                      type='url'
                      name='url'
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.changeInput(i, e)}
                      style={{ marginRight: 10 }}
                    />
                    {this.props.admin && (
                      <TextField
                        fullWidth
                        variant='outlined'
                        value={newUrl.userId}
                        required
                        type='text'
                        name='userId'
                        label='Owner of domain'
                        select
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.changeInput(i, e)}
                        SelectProps={{ native: true }}
                        style={{ marginRight: 10 }}
                      >
                        {this.state.users.map((user: AuthUser, j: number) => (
                          <option key={j} value={user.id}>
                            {user.displayName}
                          </option>
                        ))}
                      </TextField>
                    )}

                    <TextField
                      fullWidth
                      variant='outlined'
                      value={newUrl.domain}
                      required
                      type='text'
                      label={t('excluded.domain')}
                      name='domain'
                      select
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.changeInput(i, e)}
                      SelectProps={{ native: true }}
                      style={{ marginRight: 10 }}
                    >
                      {this.state.domains.map((domain: DomainInfo, j: number) => (
                        <option key={j} value={domain.domainKey}>
                          {domain.name}
                        </option>
                      ))}
                    </TextField>
                    <Tooltip title={t('common:duplicate') as string}>
                      <IconButton onClick={() => this.cloneUrl(newUrl)}>
                        <FileCopyIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title={t('common:remove') as string}>
                      <IconButton onClick={() => this.removeNewUrl(i)}>
                        <DeleteForeverIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                );
              })
              .reverse()}
          </Box>

          <Box>{this.getUrlsGroupedByDomain()}</Box>
        </Box>
        <AlertDialog
          open={this.state.removeDialog}
          title={t('excluded.confirmRemove')}
          message={t('excluded.pleaseConfirm')}
          handleClose={() => this.setState({ ...this.state, removeDialog: false })}
          action={() => this.removeUrl(this.state.toRemove)}
        />
        <SaveSnackbar open={this.state.isSaving} />
      </form>
    );
  }
}

export default withStyles(styles)(withTranslation(['settings', 'common'])(Excluded));

import React, { Component, ChangeEvent, FormEvent } from 'react';
import { AuthUser, IDomain, DomainInfo } from '../../models/app.model';
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  IconButton,
  Checkbox,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import AppContext from '../../data/AppContext';
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import groupArray from 'group-array';
import SaveSnackbar from '../SaveSnackbar';

interface UsersFilter {
  keyword: string;
  pageIndex: number;
  pageSize: number;
  total: number;
}
interface UsersProps {}
interface UsersState {
  activeUsersLimit: number;
  filter: UsersFilter;
  users: AuthUser[];
  domains: DomainInfo[];
  editMode: string[];
  modified: string[];
  selected: string[];
  isSaving: boolean;
  transfer: {
    domainKey: string;
    toUser: string;
    result: string;
  };
  domainFilter: string;
  domainsToUpdate: string[];
  rename: {
    oldKey: string;
    newKey: string;
  };
}
export default class Users extends Component<UsersProps, UsersState> {
  static contextType = AppContext;
  state = {
    activeUsersLimit: 10,
    filter: {
      keyword: '',
      pageIndex: 0,
      pageSize: 20,
      total: 0,
    },
    users: [] as AuthUser[],
    domains: [] as DomainInfo[],
    editMode: [] as string[],
    modified: [] as string[],
    selected: [] as string[],
    isSaving: false,
    transfer: {
      domainKey: '',
      toUser: '',
      result: '',
    },
    domainFilter: '',
    domainsToUpdate: [] as string[],
    rename: {
      oldKey: '',
      newKey: '',
    },
  };

  componentDidMount = async () => {
    const users = this.context.getData.allUsers;
    const domains = users
      .reduce((domains: DomainInfo[], user: AuthUser) => {
        const domainKeys = user.domains
          .filter((d: IDomain) => !d.disabled)
          .map((d: IDomain) => {
            const temp = {
              domainKey: d.key,
              name: d.name,
            } as DomainInfo;
            if (d.disabledSetTimeApi) {
              temp.disabledSetTimeApi = true;
            }
            if (d.disabledVideoApi) {
              temp.disabledVideoApi = true;
            }
            return temp;
          });
        domains = domains.concat(domainKeys as DomainInfo[]);
        return domains;
      }, [] as DomainInfo[])
      .sort((d1: DomainInfo, d2: DomainInfo) => (d1.name > d2.name ? 1 : -1));
    this.setState({
      ...this.state,
      users,
      domains,
      filter: {
        ...this.state.filter,
        total: Math.ceil(users.length / 20),
      },
    });
  };
  toggleEditMode = (user: AuthUser) => {
    const { editMode } = this.state;
    if (editMode.includes(user.user_id)) {
      const index = editMode.indexOf(user.user_id);
      editMode.splice(index, 1);
    } else {
      editMode.push(user.user_id);
    }
    this.setState({ ...this.state, editMode });
  };
  toggleSelected = (userId: string) => {
    const selected = this.state.selected;
    if (selected.includes(userId)) {
      selected.splice(selected.indexOf(userId), 1);
    } else {
      selected.push(userId);
    }
    this.setState({ ...this.state, selected });
  };
  getAllUsers = () => {
    return this.state.users
      .sort(this.sortByNewest)
      .filter(this.filterByKeyword)
      .slice(
        this.state.filter.pageIndex * this.state.filter.pageSize,
        this.state.filter.pageSize * (this.state.filter.pageIndex + 1)
      )
      .map((user: AuthUser, i: number) => {
        return (
          <Box key={i} px={1} style={{ backgroundColor: i % 2 === 0 ? '#f5f5f5' : 'fff' }}>
            <Box display='flex' alignItems='center' justifyContent='space-between'>
              <Box display='flex' alignItems='center'>
                <Checkbox onChange={() => this.toggleSelected(user.user_id)} />
                <Typography variant='body2'>{user.displayName}</Typography>
              </Box>

              <Box display='flex' alignItems='center'>
                <IconButton onClick={() => this.copyUserDetails(user)}>
                  <FileCopyIcon />
                </IconButton>
                <IconButton onClick={() => this.toggleEditMode(user)}>
                  <EditIcon />
                </IconButton>
              </Box>
            </Box>
            {this.state.editMode.includes(user.user_id) && this.getUserForm(user)}
          </Box>
        );
      });
  };
  getDeactivatedUsers = () => {
    const deactivated = this.state.users.filter((u: AuthUser) => u.deactivated);
    return (
      <Box>
        <Typography variant='h6'>Deactivated users {deactivated.length}</Typography>
        {deactivated.map((u: AuthUser, i: number) => {
          return (
            <Typography variant='body2' key={i}>
              {u.displayName}
            </Typography>
          );
        })}
      </Box>
    );
  };
  getRecentlyActiveUsers = (limit: number) => {
    return (
      <>
        {this.state.users
          .sort(this.sortByActivity)
          .slice(0, limit)
          .map((user: AuthUser, i: number) => {
            return (
              <Box key={i}>
                <Typography variant='body2'>{user.displayName}</Typography>
                <Typography variant='caption' color='textSecondary'>
                  {moment(new Date(user.lastActivity)).fromNow()}
                </Typography>
              </Box>
            );
          })}
        <Button
          variant='contained'
          color='primary'
          onClick={this.loadMoreActive}
          style={{ margin: '10px 0' }}
        >
          Get more
        </Button>
      </>
    );
  };
  getUsersWithPlan = () => {
    const grouped = this.state.users.filter(this.filterByPlans).map((user: AuthUser) => ({
      ...user,
      product: this.context.getProductFromPlan(user.stripe.plan_id),
    }));
    return Object.entries(groupArray(grouped, 'product')).map((entry: any, i: number) => {
      return (
        <Box key={i}>
          <Typography color='secondary'>{entry[0]}</Typography>
          {entry[1].map((user: AuthUser, j: number) => (
            <Box key={j}>
              <Typography variant='body2'>{user.displayName}</Typography>
              <Typography variant='caption' color='textSecondary'>
                {user.stripe.plan_id}
              </Typography>
            </Box>
          ))}
        </Box>
      );
    });
  };
  loadMoreActive = () => {
    this.setState({ ...this.setState, activeUsersLimit: this.state.activeUsersLimit + 10 });
  };
  sortByActivity = (a: AuthUser, b: AuthUser) => {
    if (!a.lastActivity) {
      a.lastActivity = new Date(0).toISOString();
    }
    if (!b.lastActivity) {
      b.lastActivity = new Date(0).toISOString();
    }
    return a.lastActivity < b.lastActivity ? 1 : -1;
  };
  sortByNewest = (a: AuthUser, b: AuthUser) => {
    return a.date < b.date ? 1 : -1;
  };
  filterByKeyword = (user: AuthUser) => {
    const keyword = this.state.filter.keyword;
    const email = user.emails.some((email: { value: string }) => email.value.includes(keyword));
    const id = user.id.includes(keyword);
    const domains = user.domains.some(
      (domain: IDomain) => domain.key.includes(keyword) || domain.name.includes(keyword)
    );
    const phone = user.phone ? user.phone.includes(keyword) : false;
    return email || domains || phone || id;
  };
  filterByPlans = (user: AuthUser) => {
    return !!user?.stripe?.plan_id;
  };
  getSearchInput = () => {
    return (
      <>
        <TextField
          variant='outlined'
          fullWidth
          placeholder='Search by email, domains, user id or phone '
          value={this.state.filter.keyword}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            this.setState({ ...this.state, filter: { ...this.state.filter, keyword: e.target.value } })
          }
        />
      </>
    );
  };
  saveUsers = async () => {
    await this.setState({ ...this.state, isSaving: true });
    const users = this.state.modified.map((id: string) =>
      this.state.users.find((user: AuthUser) => user.user_id === id)
    );
    // SEND THOSE USERS TO SERVER WITH PATCH REQUEST
    await this.context.updateUsers(users);
    this.setState({ ...this.state, modified: [] as string[], isSaving: false });
  };
  copyUserDetails = (user: AuthUser) => {
    console.log('Copy details to clipboard');
  };
  updateInput = (e: ChangeEvent<HTMLInputElement>, user: AuthUser) => {
    const { users, modified } = this.state;
    const match = users.find((u: AuthUser) => u === user) as AuthUser;
    (match as any)[e.target.name] = e.target.value;
    if (e.target.name === 'plan' && !e.target.value) {
      match.stripe.plan_id = "";
    }
    if (!modified.includes(match.user_id)) {
      modified.push(user.user_id);
    }
    this.setState({ ...this.state, users, modified });
  };
  removeUser = async (user: AuthUser) => {
    const confirm = window.confirm('Are you sure?');
    if (!confirm) {
      return;
    }
    const result = await this.context.removeUser(user.id);
    if (!result.error) {
      const users = this.state.users.filter((u) => u !== user);
      this.setState({ ...this.state, users });
    } else {
      console.error(result.error);
    }
  };
  getUserForm = (user: AuthUser) => {
    return (
      <Box py={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <TextField fullWidth variant='outlined' label='user_id' disabled value={user.user_id} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              variant='outlined'
              label='displayName'
              name='displayName'
              value={user.displayName}
              onChange={(e: ChangeEvent<HTMLInputElement>) => this.updateInput(e, user)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              variant='outlined'
              label='email'
              disabled
              value={user.emails[0].value}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              variant='outlined'
              label='plan'
              name='plan'
              value={user.stripe.plan_id}
              select
              SelectProps={{ native: true }}
              InputLabelProps={{ shrink: true }}
              onChange={(e: ChangeEvent<HTMLInputElement>) => this.updateInput(e, user)}
            >
              <option value=''>No plan</option>
              {Object.entries(this.context.getData.plans)
                .map((plan: any) => plan[1].map((p: string) => ({ id: p, product: plan[0] })))
                .flat()
                .map((plan: any, i: number) => {
                  return (
                    <option key={i} value={plan.id}>
                      {`${plan.id} / ${plan.product}`}
                    </option>
                  );
                })}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              variant='outlined'
              label='domainLimit'
              name='domainLimit'
              value={user.stripe ? user.stripe.domains : 1}
              onChange={(e: ChangeEvent<HTMLInputElement>) => this.updateInput(e, user)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              variant='outlined'
              label='stripe'
              disabled
              value={user.stripe ? user.stripe.customer_id : 'Not customer'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              variant='outlined'
              label='company'
              name='company'
              value={user.company}
              onChange={(e: ChangeEvent<HTMLInputElement>) => this.updateInput(e, user)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              variant='outlined'
              label='street'
              name='street'
              value={user.street}
              onChange={(e: ChangeEvent<HTMLInputElement>) => this.updateInput(e, user)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              variant='outlined'
              label='city'
              name='city'
              value={user.city}
              onChange={(e: ChangeEvent<HTMLInputElement>) => this.updateInput(e, user)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              variant='outlined'
              label='plz'
              name='plz'
              value={user.plz}
              onChange={(e: ChangeEvent<HTMLInputElement>) => this.updateInput(e, user)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              variant='outlined'
              label='phone'
              name='phone'
              value={user.phone}
              onChange={(e: ChangeEvent<HTMLInputElement>) => this.updateInput(e, user)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Button variant='contained' color='secondary' onClick={() => this.removeUser(user)}>
              Remove user
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  };
  prevPage = () => {
    const { pageIndex } = this.state.filter;
    if (pageIndex > 0) {
      this.setState({
        ...this.state,
        filter: {
          ...this.state.filter,
          pageIndex: pageIndex - 1,
        },
      });
    }
  };
  nextPage = () => {
    const { pageIndex, total } = this.state.filter;
    if (pageIndex < total - 1) {
      this.setState({
        ...this.state,
        filter: {
          ...this.state.filter,
          pageIndex: pageIndex + 1,
        },
      });
    }
  };
  getOptions = () => {
    return (
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        position='sticky'
        top='0'
        zIndex='1000'
        style={{ backgroundColor: '#eee' }}
        p={1}
      >
        <Box display='flex' alignItems='center'>
          <IconButton onClick={this.prevPage}>
            <NavigateBeforeIcon />
          </IconButton>
          <Typography variant='body2'>{`${this.state.filter.pageIndex + 1} / ${
            this.state.filter.total
          }`}</Typography>
          <IconButton onClick={this.nextPage}>
            <NavigateNextIcon />
          </IconButton>
        </Box>
        <Box>
          <Button variant='contained' color='primary' onClick={this.saveUsers}>
            Save
          </Button>
        </Box>
      </Box>
    );
  };
  getExtraOptions = () => {
    return (
      <>
        <Typography variant='body2' gutterBottom>
          Reset last_notification property for every email from selected users (if nobody selected it
          will update all users)
        </Typography>
        <Button
          variant='contained'
          color='primary'
          onClick={() => this.context.resetLastNotifications(this.state.selected)}
        >
          Reset
        </Button>
      </>
    );
  };
  transferInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      transfer: {
        ...this.state.transfer,
        [e.target.name]: e.target.value,
        result: '',
      },
    });
  };
  transferDomain = async () => {
    const result = await this.context.transferDomain(
      this.state.transfer.domainKey,
      this.state.transfer.toUser
    );
    this.setState({
      ...this.state,
      transfer: {
        ...this.state.transfer,
        domainKey: '',
        toUser: '',
        result,
      },
    });
  };
  getDomainTransferBox = () => {
    return (
      <Box mb={5}>
        <Typography variant='h6' gutterBottom>
          Transfer domain
        </Typography>
        <Box py={2} display='flex' justifyContent='space-around' alignItems='center'>
          <Box flexGrow='1'>
            <TextField
              fullWidth
              variant='outlined'
              name='domainKey'
              select
              value={this.state.transfer.domainKey}
              onChange={this.transferInputChange}
              SelectProps={{ native: true }}
              label='Choose domain'
              InputLabelProps={{ shrink: true }}
            >
              <option value=''>Choose domain</option>
              {this.state.domains.map((d: DomainInfo, j: number) => (
                <option key={j} value={d.domainKey}>
                  {d.name}
                </option>
              ))}
            </TextField>
          </Box>
          <Box flexGrow='1' mx={1}>
            <TextField
              variant='outlined'
              label='Target user'
              select
              name='toUser'
              value={this.state.transfer.toUser}
              onChange={this.transferInputChange}
              SelectProps={{ native: true }}
              InputLabelProps={{ shrink: true }}
              fullWidth
            >
              <option value=''>Choose user to transfer to</option>
              {this.state.users
                .sort((u1: AuthUser, u2: AuthUser) => (u1.displayName > u2.displayName ? 1 : -1))
                .map((d: AuthUser, j: number) => (
                  <option key={j} value={d.id}>
                    {d.displayName}
                  </option>
                ))}
            </TextField>
          </Box>
          <Box flexGrow='0'>
            <Button variant='contained' color='primary' onClick={this.transferDomain}>
              Transfer
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };
  getRenameDomainBox = () => {
    return (
      <Box mb={5}>
        <form onSubmit={this.renameDomainKey}>
          <Typography variant='h6'>Choose domain to rename</Typography>
          <Box display='flex' py={2} justifyContent='space-around' alignItems='center'>
            <Box flexGrow='1'>
              <TextField
                value={this.state.rename.oldKey}
                select
                SelectProps={{ native: true }}
                required
                variant='outlined'
                fullWidth
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    rename: { ...this.state.rename, oldKey: e.target.value },
                  })
                }
              >
                <option value=''>Choose domain</option>
                {this.state.domains
                  .sort((d1: DomainInfo, d2: DomainInfo) => (d1.domainKey > d2.domainKey ? 1 : -1))
                  .map((d: DomainInfo, j: number) => (
                    <option key={j} value={d.domainKey}>
                      {d.domainKey}
                    </option>
                  ))}
              </TextField>
            </Box>
            <Box flexGrow='1' mx={1}>
              <TextField
                value={this.state.rename.newKey}
                required
                variant='outlined'
                fullWidth
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    rename: { ...this.state.rename, newKey: e.target.value },
                  })
                }
              />
            </Box>
            <Box flexGrow='0'>
              <Button variant='contained' color='primary' type='submit'>
                Rename
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    );
  };
  renameDomainKey = async (e: FormEvent) => {
    e.preventDefault();
    const confirm = window.confirm('Please confirm');
    if (!confirm) {
      return;
    }
    const { oldKey, newKey } = this.state.rename;
    const success = await this.context.renameDomain(oldKey, newKey);
    if (success) {
      alert(success ? 'Domain renamed' : 'Something went wrong');
    }
  };
  getDisableApiOnDomainsBox = () => {
    const enabledOrSearched = (d: DomainInfo) => {
      return (
        (this.state.domainFilter
          ? d.name.toLocaleLowerCase().includes(this.state.domainFilter.toLocaleLowerCase()) ||
            d.domainKey.toLocaleLowerCase().includes(this.state.domainFilter.toLocaleLowerCase())
          : false) ||
        d.disabledSetTimeApi ||
        d.disabledVideoApi ||
        this.state.domainsToUpdate.includes(d.domainKey)
      );
    };
    const updateDomain = (e: React.ChangeEvent<HTMLInputElement>, d: DomainInfo) => {
      const { domains, domainsToUpdate } = this.state;
      const match = domains.find((domain) => domain.domainKey === d.domainKey);
      if (match) {
        if (!domainsToUpdate.includes(match.domainKey)) {
          domainsToUpdate.push(match.domainKey);
        }
        (match as any)[e.target.name] = e.target.checked;
        this.setState({ ...this.state, domains, domainsToUpdate });
      }
    };

    return (
      <Box mb={5}>
        <Typography variant='h6' gutterBottom>
          Disable video or set-time api on domain
        </Typography>

        <Box my={2}>
          <TextField
            fullWidth
            variant='outlined'
            value={this.state.domainFilter}
            onChange={(e) => this.setState({ ...this.state, domainFilter: e.target.value })}
            label='Type domain name'
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        {this.state.domains.filter(enabledOrSearched).map((d, i) => {
          return (
            <Box display='flex' justifyContent='space-around' alignItems='center' key={i}>
              <Box flexBasis='30%'>{d.name}</Box>
              <Box flexBasis='20%'>{d.domainKey}</Box>
              <Box flexBasis='25%'>
                <FormControlLabel
                  label='set-time disabled?'
                  name='disabledSetTimeApi'
                  control={
                    <Switch
                      checked={d.disabledSetTimeApi || false}
                      onChange={(e) => updateDomain(e, d)}
                    />
                  }
                />
              </Box>
              <Box flexBasis='25%'>
                <FormControlLabel
                  label='video disabled?'
                  name='disabledVideoApi'
                  control={
                    <Switch checked={d.disabledVideoApi || false} onChange={(e) => updateDomain(e, d)} />
                  }
                />
              </Box>
            </Box>
          );
        })}

        <Box my={1}>
          <Button variant='contained' color='primary' onClick={this.updateDomains}>
            Update domain
          </Button>
        </Box>
      </Box>
    );
  };
  updateDomains = async () => {
    const { domains } = this.context.getData;
    const toUpdate = domains
      .filter((d: IDomain) => this.state.domainsToUpdate.includes(d.key))
      .map((d: IDomain) => {
        const match = this.state.domains.find((di) => di.domainKey === d.key);
        if (match?.disabledSetTimeApi) {
          d.disabledSetTimeApi = match?.disabledSetTimeApi;
        } else {
          delete d.disabledSetTimeApi;
        }
        if (match?.disabledVideoApi) {
          d.disabledVideoApi = match?.disabledVideoApi;
        } else {
          delete d.disabledVideoApi;
        }
        const { _id, key, disabledVideoApi, disabledSetTimeApi } = d;
        return { _id, key, disabledVideoApi, disabledSetTimeApi };
      });
    const result = await this.context.updateAdminDomains(toUpdate);
    if (result) {
      this.setState({ ...this.state, domainsToUpdate: [] });
    }
  };
  render() {
    return (
      <Box p={5}>
        <Grid container component={Box} spacing={5}>
          <Grid item xs={12} md={8}>
            {this.getOptions()}
            <Box mb={5}>{this.getAllUsers()}</Box>
            {this.getRenameDomainBox()}
            {this.getDomainTransferBox()}
            {this.getDisableApiOnDomainsBox()}
          </Grid>
          <Grid item xs={12} md={4}>
            <Box mb={5}>
              <Typography variant='h6' gutterBottom>
                Search for user
              </Typography>
              {this.getSearchInput()}
            </Box>

            <Box mb={5}>
              <Typography variant='h6' gutterBottom>
                Users with plans
              </Typography>
              {this.getUsersWithPlan()}
            </Box>
            <Box mb={5}>
              <Typography variant='h6' gutterBottom>
                Recently active users
              </Typography>
              {this.getRecentlyActiveUsers(this.state.activeUsersLimit)}
            </Box>
            <Box mb={5}>{this.getDeactivatedUsers()}</Box>
            <Box mb={5}>
              <Typography variant='h6' gutterBottom>
                Other options
              </Typography>
              {this.getExtraOptions()}
            </Box>
          </Grid>
        </Grid>
        <SaveSnackbar open={this.state.isSaving} />
      </Box>
    );
  }
}

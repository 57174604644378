import { Theme } from '@material-ui/core/styles';

const styles: any = (theme: Theme) => ({
  marginRight: {
    marginRight: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(2, 0)
  },
  container: {
    margin: 0,
    width: '100%'
  },
  loader: {
    marginRight: theme.spacing(2)
  },
  snackbar_loader: {
    color: theme.palette.common.white,
    marginRight: theme.spacing(2)
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(3, 5)
  },
  title: {
    marginRight: theme.spacing(3)
  },
  frame: {
    width: '100%',
    height: '75vh',
    border: 'none',
    overflow: 'hidden'
  },
  preview: {
    transformOrigin: '0 0',
    border: `1px solid ${theme.palette.grey[300]}`,
    transform: 'scale(0.5)',
    width: '200%',
    height: '200%'
  },
  intro: {
    [theme.breakpoints.down('md')]: {
      flexGrow: 1
    }
  },
  content: {
    minHeight: '60vh'
  },
  contentBlock: {
    position: 'sticky',
    top: 0,
    maxHeight: '100vh'
  },
  contentScroll: {
    overflowY: 'scroll',
    maxHeight: '70vh'
  },
  contentTableElement: {
    'cursor': 'pointer',
    '& .duplicate': {
      opacity: 0,
      transition: 'all .25s ease-out'
    },
    '&:hover': {
      'backgroundColor': theme.palette.grey[100],
      '& .duplicate': {
        opacity: 1
      }
    }
  },
  copyIcon: {
    'backgroundColor': theme.palette.primary.main,
    'color': theme.palette.grey[200],
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.grey[100]
    }
  },
  selected: {
    backgroundColor: theme.palette.grey[200]
  },
  online: {
    backgroundColor: theme.palette.success.main
  },
  offline: {
    backgroundColor: theme.palette.error.main
  },
  tabs: {
    background: `linear-gradient(to bottom, #fff 0, ${theme.palette.grey[100]} 100%)`
  }
});
export default styles;

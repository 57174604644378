import React, { ChangeEvent } from 'react';
import { Typography, Grid, Box, Fab, Tooltip, Tabs, Tab, IconButton, Switch } from '@material-ui/core';
import ContentTable from './DynamicTable';
import AppContext from '../../data/AppContext';
import { withStyles } from '@material-ui/styles';
import styles from './Dynamic.style';
import EditForm from './DynamicEditForm';
import { DynamicAnalytics } from './DynamicAnalytics';
import DynamicRules from './DynamicRules';

import AddIcon from '@material-ui/icons/Add';
import { VisitorTypes, PageEvent, IComponentProps } from '../../models/app.model';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import SaveSnackbar from '../SaveSnackbar';
import LoadingBox from '../LoadingBox';
import { withTranslation } from 'react-i18next';
import { isValidUrl } from '../../helpers';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import TuneIcon from '@material-ui/icons/Tune';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import { ISpyLink } from '../spylink/Spylink';
import { AlertDialog } from '../AlertDialog';
import NoDomain from '../NoDomain';

export interface IDynamicState {
  selected: any;
  isSaving: boolean;
  filter: string;
  content?: any;
  types?: any;
  domain?: any;
  activeTab: number;
  analyticsData: any;
  spylinks: ISpyLink[];
  removeDialog: boolean;
  limitDialog: boolean;
  rendered: boolean;
}

export enum DynamicType {
  Message = 'dynamic-message',
  Text = 'dynamic-text',
}

export enum ContentRule {
  CompanyName = 'company-name',
  CompanyType = 'company-type',
  CompanyNoteStatus = 'company-note-status',
}

export enum CompanyStatusType {
  isCustomer = 'isCustomer',
  isFavorite = 'isFavorite',
  notInteresting = 'notInteresting',
  competitor = 'competitor',
}

export interface DynamicContentSelector {
  classList: string[];
  tagName: string;
  text: string;
  htmlString: string;
  replaceText: string;
  replaceImage?: string;
}

export interface DynamicContentInterface {
  _id: string;
  name: string;
  content: string;
  ruleType: ContentRule;
  rule: string;
  rules: {
    branch: [{ active: boolean; value: string }];
    companyName: [{ active: boolean; value: string }];
    spylink: [{ active: boolean; value: string }];
    location: [{ active: boolean; value: { city: string; country: string } }];
    status: [{ active: boolean; value: string }];
  };
  domainKey: string;
  createdByUser: string;
  createdAt: Date;
  dynamicType: DynamicType;
  path: string[];
  selectors: DynamicContentSelector[];
  website: string;
  disabled: boolean;
}

export interface PostBody {
  domain: string;
  type: VisitorTypes;
  start: string;
  end: string;
  pagination?: PageEvent;
  searchField?: string;
  sortKey?: string;
  sortOrder?: 1 | -1;
}

export interface DynamicContentProps extends RouteComponentProps<any>, IComponentProps {}

class DynamicContent extends React.Component<DynamicContentProps, IDynamicState> {
  static contextType = AppContext;
  private mounted: boolean;
  defaultUrl: string;
  constructor(props: any) {
    super(props);
    this.state = {
      selected: null,
      isSaving: false,
      filter: '',
      content: [],
      activeTab: 0,
      analyticsData: null,
      spylinks: [] as ISpyLink[],
      removeDialog: false,
      limitDialog: false,
      rendered: false,
    };
    this.defaultUrl = '';
    this.mounted = false;
  }
  async componentDidMount() {
    this.mounted = true;
    const types = await this.context.getCompanyTypes();
    await this.setState({ ...this.state, types });
    this.loadDynamicContent();
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  async componentDidUpdate(prevProps: DynamicContentProps, prevState: IDynamicState) {
    if (
      prevState.domain &&
      this.state.domain.key !== this.context.getData.domain.key &&
      !this.context.getData.fetching.dynamicContent
    ) {
      await this.setState({ ...this.state, domain: this.context.getData.domain, selected: null });
      console.log('SHOULD BE LOADED');
      this.loadDynamicContent();
    }
  }

  loadDynamicContent = async () => {
    await this.setState({
      ...this.state,
      domain: this.context.getData.domain,
      rendered: false,
    });
    await this.context.getDynamicContent();
    await this.context.getSpylinks();
    this.defaultUrl = await this.context.getData.detectedUrl;
    const { spylinks, dynamicContent: content } = this.context.getData;
    if (content.length < 1) {
      if (this.mounted) {
        await this.setState({
          ...this.state,
          selected: null,
          content,
          spylinks,
          rendered: true,
        });
      }

      return (this.context.setData = {
        fetching: {
          ...this.context.getData.fetching,
          domains: false,
          dynamicContent: false,
          spylinks: false,
        },
      });
    }

    let selected = null;
    if (this.props.location.state) {
      const id = this.props.location ? (this.props.location as any).state.id : '';
      const el = content.find((el: any) => el._id === id);
      selected = el;
    } else {
      selected = this.context.getData.dynamicContent[this.context.getData.dynamicContent.length - 1];
    }

    if (this.mounted) {
      if (!selected) {
        selected = content[content.length - 1];
      }
      if (!selected.website) {
        selected.website = this.defaultUrl;
      }
      if (!selected.name) {
        selected.name = '';
      }
      if (!selected.rules) {
        selected.rules = {};
      }
      if (selected.rules.spylink && spylinks) {
        selected.rules.spylink = selected.rules.spylink.filter((link: any) =>
          spylinks.some((sl: ISpyLink) => sl.linkId === link.value)
        );
      }

      await this.setState({
        ...this.state,
        content,
        selected,
        domain: this.context.getData.domain,
        rendered: true,
      });
    }
  };

  selectContent = async (element: any) => {
    if (this.mounted) {
      if (!element.website) {
        element.website = this.defaultUrl;
      }
      if (this.mounted) {
        this.setState({
          ...this.state,
          selected: element,
        });
      }
    }
  };

  handleFilterChange = (e: any) => {
    if (this.mounted) {
      this.setState({ ...this.state, filter: e.target.value });
    }
  };

  updateContent = async (e: ChangeEvent<any>) => {
    try {
      const content = [...this.state.content];
      const element = content.find((el: any) => el._id === this.state.selected._id);
      if (e.target.name === 'ruleType') {
        element['rule'] = '';
      }
      element[e.target.name] = e.target.value;
      await this.setState({
        ...this.state,
        content,
        selected: element,
      });
      await (this.context.setData = {
        dynamicContent: content,
      });
    } catch (error) {}
  };

  toggleDisabled = async (e: ChangeEvent<any>) => {
    const { content: contentLimit } = this.context.getData.limits;
    console.log('LIMIT', contentLimit);
    if (e.target.checked && contentLimit !== -1) {
      // ONLY THEN WE BOTHER TO CHECK
      const activeContent = this.state.content.filter((c: DynamicContentInterface) => !c.disabled)
        .length;
      if (activeContent >= contentLimit) {
        return this.setState({ ...this.state, limitDialog: true });
      }
    }

    if (!this.state.selected) {
      return;
    }
    const content = this.state.content.find((el: any) => el._id === this.state.selected._id);
    content[e.target.name] = !e.target.checked;
    await (this.context.setData = {
      dynamicContent: [...this.state.content],
    });
  };
  saveContent = async (e: React.FormEvent) => {
    e.preventDefault();
    await this.setState({ ...this.state, isSaving: true });
    await this.context.setDynamicContent();
    await this.setState({ ...this.state, isSaving: false });
  };

  duplicateContent = async (i: number) => {
    const dynamicContent = this.context.getData.dynamicContent;
    const copy = Object.assign({}, dynamicContent[i]);
    copy.name = this.props.t('common:copyOf') + copy.name;
    delete copy['_id'];
    await (this.context.setData = {
      dynamicContent: [...dynamicContent, copy],
    });
    await this.setState({ ...this.state, isSaving: true });
    await this.context.setDynamicContent();
    await this.context.getDynamicContent();
    const content = this.context.getData.dynamicContent;
    await this.setState({
      ...this.state,
      isSaving: false,
      content,
      selected: content[content.length - 1],
    });
  };

  removeContent = async () => {
    await this.context.removeDynamicContent(this.state.selected);
    await this.context.getDynamicContent();
    const content = this.context.getData.dynamicContent;
    if (this.mounted) {
      await this.setState({
        content,
        selected: content[content.length - 1],
      });
    }
  };
  addRuleToSelected = (key: string) => {
    const selected = this.state.selected;
    if (key === 'newVisitor') {
      if (!selected.rules[key]) {
        selected.rules[key] = { active: true, value: true };
      }
    } else {
      if (!selected.rules[key]) {
        selected.rules[key] = [];
      }
      if (key === 'location') {
        selected.rules[key].push({ active: true, value: { city: '', country: '' } });
      } else {
        selected.rules[key].push({ active: true, value: '' });
      }
    }

    this.setState({ ...this.state, selected });
  };
  removeRuleFromSelected = (key: string, i: number) => {
    const selected = this.state.selected;
    if (key === 'newVisitor') {
      delete selected.rules[key];
    } else {
      selected.rules[key].splice(i, 1);
    }
    this.setState({ ...this.state, selected });
  };
  updateRuleFromSelected = (key: string, value?: any, i?: number) => {
    if (i === undefined) {
      i = -1;
    }
    const selected = this.state.selected;
    if (i >= 0 && value !== undefined) {
      selected.rules[key][i].value = value;
    } else if (key === 'newVisitor') {
      selected.rules[key].value = !selected.rules[key].value;
    }
    this.setState({ ...this.state, selected });
  };
  changeTab = (i: number) => this.setState({ ...this.state, activeTab: i });
  getPreviewUrl = () => {
    try {
      const url = new URL(this.state.selected.website);
      url.searchParams.append('dc_id', this.state.selected._id);
      return url.href;
    } catch (error) {
      return '';
    }
  };
  render() {
    const { classes, t } = this.props;
    const tabOptions = [
      { title: 'previewTab', icon: <VisibilityIcon />, tab: 0 },
      { title: 'settingsTab', icon: <TuneIcon />, tab: 1 },
      { title: 'analyticsTab', icon: <EqualizerIcon />, tab: 2 },
    ];
    return (
      <>
        {this.state.domain && (
          <form onSubmit={this.saveContent}>
            <Box p={5} className={classes.container}>
              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                flexWrap='wrap'
                pb={3}
              >
                <Box>
                  <Typography variant='h4'>{t('title')}</Typography>
                  <Typography variant='subtitle1' gutterBottom>
                    {t('description')}
                  </Typography>
                </Box>

                <Box display='flex' alignItems='center'>
                  {this.context.getData.readOnly ? (
                    <Tooltip title={t('addRule') as string}>
                      <Fab
                        color='primary'
                        component={Link}
                        to='/dynamic-content/add'
                        className={classes.marginRight}
                        disabled={this.context.getData.readOnly}
                      >
                        <AddIcon />
                      </Fab>
                    </Tooltip>
                  ) : (
                    <Fab
                      color='secondary'
                      component={Link}
                      to='/dynamic-content/add'
                      className={classes.marginRight}
                      disabled={this.context.getData.readOnly}
                    >
                      <AddIcon />
                    </Fab>
                  )}
                </Box>
              </Box>

              <Grid container spacing={5} component={Box} position='relative' minHeight='100vh'>
                <Grid item xs={12} md={4}>
                  <LoadingBox
                    condition={
                      this.context.getData.fetching.dynamicContent ||
                      this.context.getData.fetching.spylinks ||
                      !this.state.rendered
                    }
                    empty={this.state.content.length === 0 && this.state.rendered}
                  >
                    <ContentTable
                      onSelect={this.selectContent}
                      selected={this.state.selected}
                      filter={this.state.filter}
                      filterChange={this.handleFilterChange}
                      data={this.context.getData.dynamicContent || []}
                      duplicateRule={this.duplicateContent}
                    />
                  </LoadingBox>
                </Grid>
                <Grid item xs={12} md={8} className={classes.contentBlock}>
                  <LoadingBox
                    condition={
                      this.context.getData.fetching.dynamicContent ||
                      this.context.getData.fetching.spylinks ||
                      !this.state.rendered
                    }
                    empty={!this.state.selected && this.state.rendered}
                    position='relative'
                  >
                    {(this.state.selected && (
                      <Box>
                        <Tabs
                          value={this.state.activeTab}
                          classes={{ root: classes.tabs }}
                          onChange={(e: any, value: number) => {
                            this.changeTab(value);
                          }}
                        >
                          {tabOptions.map((option: any, i: number) => (
                            <Tab
                              key={i}
                              label={
                                <Box display='flex' alignItems='center'>
                                  {option.icon}
                                  <Typography variant='body2' style={{ marginLeft: 5 }}>
                                    {t('common:' + option.title)}
                                  </Typography>
                                </Box>
                              }
                            />
                          ))}
                        </Tabs>
                        <Box className={classes.contentScroll}>
                          {this.state.activeTab === 0 && (
                            <EditForm
                              selected={this.state.selected}
                              onChange={this.updateContent}
                              onDisable={this.toggleDisabled}
                              onEditRules={this.changeTab}
                            />
                          )}
                          {this.state.activeTab === 1 && this.state.selected.rules && (
                            <DynamicRules
                              addRule={this.addRuleToSelected}
                              updateRule={this.updateRuleFromSelected}
                              removeRule={this.removeRuleFromSelected}
                              selected={this.state.selected}
                            />
                          )}
                          {this.state.activeTab === 2 && <DynamicAnalytics data={this.state.selected} />}
                        </Box>
                      </Box>
                    )) ||
                      null}

                    {this.state.selected && (
                      <Box
                        position='absolute'
                        top='100%'
                        mt={1}
                        display='flex'
                        alignItems='center'
                        justifyContent='space-between'
                        flexWrap='wrap'
                        width='100%'
                      >
                        <Box>
                          <Fab
                            variant='extended'
                            color='primary'
                            size='small'
                            type='submit'
                            style={{ margin: '0 10px 10px 0' }}
                            disabled={this.context.getData.readOnly}
                          >
                            {t('common:saveChanges') as string}
                          </Fab>
                          {this.state.selected &&
                            this.state.selected.website &&
                            isValidUrl(this.state.selected.website) && (
                              <Fab
                                variant='extended'
                                href={this.getPreviewUrl()}
                                target='_blank'
                                style={{ margin: '0 10px 10px 0' }}
                                size='small'
                              >
                                {t('common:preview') as string}
                              </Fab>
                            )}
                        </Box>

                        <Box>
                          {this.context.getData.readOnly ? (
                            <Switch
                              checked={!JSON.parse(this.state.selected.disabled || 'false')}
                              name='disabled'
                              onChange={this.toggleDisabled}
                              disabled={this.context.getData.readOnly}
                            />
                          ) : (
                            <Tooltip
                              placement='left-start'
                              title={
                                JSON.parse(this.state.selected.disabled || 'false')
                                  ? t('enableRule') as string
                                  : t('disableRule') as string
                              }
                            >
                              <Switch
                                checked={!JSON.parse(this.state.selected.disabled || 'false')}
                                name='disabled'
                                onChange={this.toggleDisabled}
                                disabled={this.context.getData.readOnly}
                              />
                            </Tooltip>
                          )}
                          {this.context.getData.readOnly ? (
                            <IconButton
                              onClick={() => this.setState({ ...this.state, removeDialog: true })}
                              style={{ marginLeft: 10 }}
                              disabled={this.context.getData.readOnly}
                            >
                              <DeleteIcon />
                            </IconButton>
                          ) : (
                            <Tooltip placement='left-start' title={t('deleteRule') as string}>
                              <IconButton
                                onClick={() => this.setState({ ...this.state, removeDialog: true })}
                                style={{ marginLeft: 10 }}
                                disabled={this.context.getData.readOnly}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Box>
                      </Box>
                    )}
                  </LoadingBox>
                </Grid>
              </Grid>
              <SaveSnackbar open={this.state.isSaving} />
              <AlertDialog
                open={this.state.removeDialog}
                title={t('deleteRule')}
                message={t('confirmRemove')}
                handleClose={() => {
                  if (this.mounted) {
                    this.setState({ ...this.state, removeDialog: false });
                  }
                }}
                action={() => this.removeContent()}
              />
              <AlertDialog
                justConfirm={true}
                open={this.state.limitDialog}
                title={t('limitTitle')}
                message={t('limitExceeded', { limit: this.context.getData.limits.content })}
                handleClose={() => {
                  if (this.mounted) {
                    this.setState({ ...this.state, limitDialog: false });
                  }
                }}
              />
            </Box>
          </form>
        )}
        {!this.context.getData.fetching.domains &&
          !this.context.getData.fetching.dynamicContent &&
          !this.context.getData.fetching.spylinks &&
          !this.state.domain && <NoDomain />}
      </>
    );
  }
}

export default withStyles(styles)(
  withTranslation(['dynamicContent', 'common'])(withRouter(DynamicContent))
);

import React from 'react';
import { TextField } from '@material-ui/core';
//STYLES
//CONTEXT
import AppContext from '../data/AppContext';
//MODELS
import { IDomain } from '../models/app.model';
import { useTranslation, WithTranslation } from 'react-i18next';

interface IDomainSelectProps extends WithTranslation {
  domains: IDomain[];
}

const DomainSelect = () => {
  const context = React.useContext(AppContext);
  const { domains, domain, userData } = context.getData;
  const { t } = useTranslation();
  return domain ? (
    <TextField
      select
      variant='outlined'
      fullWidth
      label={t('header.availableDomains')}
      value={(domain as IDomain).key}
      onChange={(e: React.ChangeEvent<any>) => context.switchDomain(e.target.value)}
      SelectProps={{
        native: true
      }}
    >
      {domains.map((dom: IDomain, i: number) => {
        const isOwner = userData.domains.some((d) => d.key === dom.key);
        return (
          <option key={i} value={dom.key}>
            {`${dom.name}${!isOwner ? ` (${t('shared')})` : ''}`}
          </option>
        );
      })}
    </TextField>
  ) : null;
};

export default DomainSelect;

import React from 'react';
import { Box, Fade, Paper, Theme, BoxProps, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

const styles = makeStyles((theme: Theme) => ({
  box: {
    borderTop: `2px solid ${theme.palette.primary.light}`,
    marginBottom: theme.spacing(2)
  },
  cbox: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  }
}));

interface ILoadingBox extends BoxProps {
  condition: boolean;
  empty: boolean;
  text?: string;
  standOut?: boolean;
}

const LoadingBox: React.FC<ILoadingBox> = ({ condition, empty, children, text, standOut, ...rest }) => {
  const classes = styles();
  const { t } = useTranslation();
  console.log(text, t("loading"))
  return (
    <>
      <Fade in={true} timeout={500}>
        <Paper className={clsx(standOut ? classes.cbox : classes.box)}>
          {condition ? (
            <Skeleton variant='rect' height={200} width="100%">
              <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                style={{ height: 200 }}
                px={10}
              >
                hello
                <Typography variant='h6'>{text ? t(text) as string : t('loading') as string}</Typography>
              </Box>
            </Skeleton>
          ) : empty ? (
            <Fade in={!condition} timeout={500}>
              <Box p={5}>
                <Typography variant='body2'>{t('common:noData')}</Typography>
              </Box>
            </Fade>
          ) : (
            <Fade in={!condition} timeout={500}>
              <Box {...rest}>{children}</Box>
            </Fade>
          )}
        </Paper>
      </Fade>
    </>
  );
};

export default LoadingBox;

import React, { Component, FormEvent } from 'react';
import {
  Box,
  Grid,
  TextField,
  Button,
  WithStyles,
  createStyles,
  Theme,
  withStyles,
  IconButton
} from '@material-ui/core';
import clsx from 'clsx';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SaveSnackbar from '../SaveSnackbar';

const styles = createStyles((theme: Theme) => ({
  even: {
    backgroundColor: theme.palette.common.white
  },
  Button: {
    marginLeft: theme.spacing(2)
  }
}));

interface IspListProps extends WithStyles {}
interface IspListState {
  selected: string[];
  keyword: string;
  list: string[];
  newISP: string[];
  saving: boolean;
}
class IspList extends Component<IspListProps, IspListState> {
  private form: React.RefObject<HTMLFormElement>;
  constructor(props: IspListProps) {
    super(props);
    this.form = React.createRef<HTMLFormElement>();
    this.state = {
      selected: [],
      list: [],
      newISP: [],
      keyword: '',
      saving: false
    };
  }

  fetchIspList = async () => {
    try {
      const response = await fetch('/app-api/v2/admin/isp');
      return await response.json();
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  saveIspList = async (e: FormEvent) => {
    e.preventDefault();
    const confirm = window.confirm('Confirm');
    if (!confirm) {
      return;
    }
    // EXTRACT JUST NAME AND SEND OBJECT TO SERVER
    await this.setState({ ...this.state, saving: true });
    try {
      const list: string[] = Array.from((this.form.current as HTMLFormElement).querySelectorAll('input'))
        .filter((el) => el.value)
        .map((el: HTMLInputElement) => el.value);
      await fetch('/app-api/v2/admin/isp', {
        method: 'POST',
        body: JSON.stringify(list),
        headers: {
          'content-type': 'application/json'
        }
      });
      this.setState({
        ...this.state,
        saving: false,
        list,
        newISP: []
      });
    } catch (error) {
      console.error(error);
      this.setState({ ...this.state, saving: false });
    }
  };
  componentDidMount = async () => {
    const list = await this.fetchIspList();
    this.setState({ ...this.state, list });
  };
  getFilter = () => {
    return (
      <Box position='sticky' top='0'>
        <Box mb={2} display='flex' justifyContent='space-between' alignItems='center'>
          <IconButton
            onClick={() => {
              this.setState({
                ...this.state,
                newISP: ['', ...this.state.newISP]
              });
            }}
          >
            <AddCircleOutlineIcon />
          </IconButton>
          <Button variant='contained' color='primary' type='submit'>
            Save
          </Button>
        </Box>

        <TextField
          variant='outlined'
          fullWidth
          value={this.state.keyword}
          onChange={(e) => this.setState({ ...this.state, keyword: e.target.value })}
          label={'Filter by keyword'}
        />
      </Box>
    );
  };
  removeIsp = (i: number, arrayName: string) => {
    const confirm = window.confirm('Confirm');
    if (!confirm) {
      return;
    }
    const list: string[] = (this.state as any)[arrayName];
    list.splice(i, 1);
    this.setState({ ...this.state, [arrayName]: list });
  };
  getISPForm = (isp: string, i: number, arrayName: string) => {
    return (
      <Box
        key={isp + i}
        p={2}
        className={clsx(i % 2 === 0 && this.props.classes.even)}
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <TextField
          key={isp + i}
          defaultValue={isp}
          variant='outlined'
          required
          fullWidth
          label='ISP name'
        />
        <Box display='flex' alignItems='center'>
          <IconButton
            classes={{ root: this.props.classes.Button }}
            onClick={() => this.removeIsp(i, arrayName)}
          >
            <DeleteForeverIcon />
          </IconButton>
        </Box>
      </Box>
    );
  };
  render() {
    return (
      <form onSubmit={this.saveIspList} ref={this.form}>
        <Box p={5}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={9}>
              {this.state.newISP.map((isp: string, i: number) => this.getISPForm(isp, i, 'newISP'))}
              {this.state.list.map((isp: string, i: number) => this.getISPForm(isp, i, 'list'))}
            </Grid>
            <Grid item xs={12} md={3}>
              {this.getFilter()}
            </Grid>
          </Grid>
          <SaveSnackbar open={this.state.saving} />
        </Box>
      </form>
    );
  }
}

export default withStyles(styles)(IspList);

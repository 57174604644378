import React, { FormEvent } from 'react';
import AppContext from '../../data/AppContext';
import {
  createStyles,
  Theme,
  withStyles,
  Box,
  Typography,
  Grid,
  TextField,
  Switch,
  Collapse
} from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { IComponentProps } from '../../models/app.model';
import RemindersList from './RemindersList';
import RemindersForm from './RemindersForm';
import RemindersFilter from './RemindersFilter';
import { DevMode } from '../../helpers';
import SaveSnackbar from '../SaveSnackbar';
import { AlertDialog } from '../AlertDialog';

const styles = (theme: Theme) => createStyles({});

export interface RemindersProps extends IComponentProps {}
export interface RemindersState {
  reminders: Reminder[];
  selected: Reminder | undefined;
  isFilterVisible: boolean;
  filter: ReminderFilter;
  isSaving: boolean;
  changed: Reminder[];
  unsavedDialog: boolean;
  removeDialog: boolean;
  reminderToRemove: Reminder | undefined;
}
export interface ReminderFilter {
  keyword: string;
  sortBy: 'companyName' | 'validTill' | 'title' | 'status';
  order: number;
  hideCompleted: boolean;
  showOnlyImportant: boolean;
  createdBy: '' | 'shared' | 'own';
  showHidden: boolean;
}
export interface Reminder {
  _id: string;
  addedAt: Date;
  modifiedAt: Date;
  validTill: Date;
  expired: boolean;
  owner: boolean;
  author: {
    authorId: string;
    authorEmail: string;
  };
  status: 'done' | 'open';
  company: {
    companyKey: string;
    companyName: string;
    companyDomain: string;
  };
  sharedWith: string[];
  title: string;
  content: string;
  activities: string[];
  important: boolean;
  notifications: {
    email: boolean;
    push: boolean;
  };
}

class Reminders extends React.Component<RemindersProps, RemindersState> {
  static contextType = AppContext;
  private mounted: boolean = false;
  constructor(props: RemindersProps) {
    super(props);
    this.state = {
      reminders: [] as Reminder[],
      filter: {
        keyword: '',
        createdBy: '',
        hideCompleted: true,
        sortBy: 'validTill',
        showOnlyImportant: false,
        order: 1,
        showHidden: false
      },
      isFilterVisible: true,
      isSaving: false,
      changed: [] as Reminder[],
      unsavedDialog: false,
      removeDialog: false
    } as RemindersState;
  }
  async componentDidMount() {
    this.mounted = true;
    const reminders = await this.context.getReminders(this.state.filter);
    const selected = reminders.length > 0 ? reminders[0] : null;
    if (this.mounted) {
      this.setState({ ...this.state, reminders, selected });
    }
  }
  componentWillUnmoun() {
    this.mounted = false;
  }

  selectReminder = (reminder: Reminder) => {
    if (this.mounted) {
      this.setState({ ...this.state, selected: reminder });
    }
  };

  getRemindersList = () => {
    return (
      <RemindersList
        reminders={this.state.reminders}
        onSelect={this.selectReminder}
        selected={this.state.selected}
        onUpdate={this.updateReminder}
        onRemove={this.chooseRemoveReminder}
      />
    );
  };

  getSelectedReminder = (selected: Reminder) => {
    return (
      selected && (
        <RemindersForm reminder={selected} onUpdate={this.updateReminder} onSave={this.saveReminder} />
      )
    );
  };

  getRemindersFilter = () => {
    return (
      <Box mb={2}>
        <RemindersFilter
          filter={this.state.filter}
          onUpdate={this.updateFilter}
          onSubmit={this.applyFilter}
        />
      </Box>
    );
  };

  updateFilter = (name: string, value: any) => {
    this.setState({
      ...this.state,
      filter: {
        ...this.state.filter,
        [name]: value
      }
    });
  };

  updateReminder = async (reminder: Reminder, name: string, value: any) => {
    const changed = [...this.state.changed, reminder].filter(
      (rem: Reminder, i: number, arr: Reminder[]) => arr.indexOf(rem) === i
    );
    const reminders = [...this.state.reminders];
    (reminders.find((rem: Reminder) => rem === reminder) as any)[name] = value;
    await this.setState({
      ...this.state,
      changed,
      reminders
    });
  };

  chooseRemoveReminder = async (reminder: Reminder | undefined) => {
    console.log(reminder);
    if (!(reminder as Reminder).owner || !reminder) {
      return;
    }
    await this.setState({ ...this.state, reminderToRemove: reminder, removeDialog: true });
  };

  removeReminder = async (reminder: Reminder | undefined) => {

    if (!reminder) {
      return;
    }
    const reminders = [...this.state.reminders].filter((rem: Reminder) => rem !== reminder);
    const selected =
      this.state.selected === reminder
        ? reminders.length > 0
          ? reminders[0]
          : undefined
        : reminders.length > 0
        ? this.state.selected
        : undefined;
    await this.setState({ ...this.state, isSaving: true, reminders });
    await this.context.removeReminder(reminder);
    this.setState({ ...this.state, isSaving: false, selected });
  };

  saveReminder = async () => {
    await this.setState({ ...this.state, isSaving: true });
    await this.context.updateReminder(this.state.changed);
    await this.setState({ ...this.state, isSaving: false });
    this.fetchNewReminders();
  };

  applyFilter = async (e: FormEvent) => {
    e.preventDefault();
    if (this.state.changed.length > 0) {
      this.setState({ ...this.state, unsavedDialog: true });
    } else {
      this.fetchNewReminders();
    }
  };

  fetchNewReminders = async () => {
    const reminders = await this.context.getReminders(this.state.filter);
    this.setState({
      ...this.state,
      reminders,
      selected: reminders.length > 0 ? reminders[0] : undefined,
      changed: [],
      unsavedDialog: false,
      isSaving: false
    });
  };

  render() {
    return (
      <DevMode>
        <Box p={5}>
          <Box pb={3} display='flex' justifyContent='space-between' alignItems='center'>
            <Box>
              <Typography variant='h4'>Action Reminders</Typography>
              <Typography variant='subtitle1'>
                Create, share and get notified about actions related to a company.
              </Typography>
            </Box>
            <Switch
              checked={this.state.isFilterVisible}
              onChange={() =>
                this.setState({ ...this.state, isFilterVisible: !this.state.isFilterVisible })
              }
            />
          </Box>
          <Collapse in={this.state.isFilterVisible}>{this.getRemindersFilter()}</Collapse>
          <Grid container>
            <Grid item xs={12} sm={5} md={4}>
              <Box>{this.getRemindersList()}</Box>
            </Grid>
            <Grid item xs={12} sm={7} md={8}>
              <Box ml={3} style={{ position: 'sticky', top: 0 }}>
                {this.state.selected && this.getSelectedReminder(this.state.selected)}
              </Box>
            </Grid>
          </Grid>
          <SaveSnackbar open={this.state.isSaving} />
          <AlertDialog
            open={this.state.unsavedDialog}
            title={'Unsaved changes'}
            message={'Please confirm'}
            handleClose={() => this.setState({ ...this.state, unsavedDialog: false })}
            action={async () => {
              await this.fetchNewReminders();
            }}
          />
          <AlertDialog
            open={this.state.removeDialog}
            title={'Remove dialog'}
            message={'Please confirm'}
            handleClose={() => this.setState({ ...this.state, removeDialog: false })}
            action={async () => {
              await this.removeReminder(this.state.reminderToRemove);
            }}
          />
        </Box>
      </DevMode>
    );
  }
}

export default withStyles(styles)(withTranslation()(Reminders));

import React, { FormEvent, ChangeEvent } from 'react';
import {
  TextField,
  Box,
  Typography,
  Grid,
  IconButton,
  FormControl,
  Fab,
  FormControlLabel,
  Divider,
  Tooltip,
  withStyles,
  Chip,
  Switch,
  RadioGroup,
  Radio,
  Button,
  Collapse,
  Link
} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AppContext from '../../data/AppContext';
import styles from './Settings.style';
import SaveSnackbar from '../SaveSnackbar';
import { withTranslation } from 'react-i18next';
import { AlertDialog } from '../AlertDialog';
import { IComponentProps, IDomain, AuthUser } from '../../models/app.model';
import { Alert } from '@material-ui/lab';

interface SharedUser {
  id?: string;
  email: string;
  domains: Array<{
    domainKey: string;
    domainName: string;
    role: string;
  }>;
  editMode: boolean;
  verified: boolean;
  invitesSent?: number;
}
export interface UsersProps extends IComponentProps {}
export interface UsersState {
  domainOwner: AuthUser;
  isSaving: boolean;
  removeUserDialog: boolean;
  userToRemove: SharedUser;
  users: SharedUser[];
  sent: boolean;
}

class UsersSettings extends React.Component<UsersProps, UsersState> {
  static contextType = AppContext;
  constructor(props: UsersProps) {
    super(props);
    this.state = {
      domainOwner: {} as AuthUser,
      isSaving: false,
      removeUserDialog: false,
      userToRemove: {} as SharedUser,
      users: [] as any,
      sent: false
    };
  }

  componentDidMount = async () => {
    this.init();
  };
  init = async () => {
    const users = this.getAllUsers();
    const verifiedUsers = await Promise.all(
      users.map(async (user: SharedUser) => {
        return { ...user, verified: await this.context.verifyEmail(user.email) };
      })
    );
    await this.setState({
      ...this.state,
      domainOwner: this.context.getData.userData,
      users: verifiedUsers as any
    });
    this.initUsers();
  };
  getAllUsers = () => {
    const users = this.context.getData.userData.domains
      .filter((domain: IDomain) => !domain.disabled && domain.allowedUsers)
      .map((domain: any, i: number) => {
        return domain.allowedUsers;
      })
      .flat()
      .map((user: any) => {
        const u = {
          id: user._id,
          email: user.email,
          domains: [],
          editMode: false,
          verified: false
        } as any;
        if (user.invitesSent) {
          u.invitesSent = user.invitesSent;
        }
        return u;
      })
      .reduce((acc: SharedUser[], current: SharedUser) => {
        if (acc.some((user) => user.email === current.email)) {
          return acc;
        } else return [...acc, current];
      }, []);
    return users;
  };
  initUsers = () => {
    const users = this.state.users.map((u: SharedUser) => {
      this.state.domainOwner.domains.filter((domain: IDomain) => {
        return (domain as any).allowedUsers.some((checkedUser: any) => {
          if (u.email === checkedUser.email) {
            u.domains.push({
              domainKey: domain.key,
              domainName: domain.name,
              role: checkedUser.role
            });
            return true;
          }
          return false;
        });
      });
      return u;
    });
    this.setState({ ...this.state, users });
  };
  newUser = () => {
    const newUser = {
      email: '',
      domains: [],
      editMode: false,
      verified: false
    };
    this.setState({
      ...this.state,
      users: [...this.state.users, newUser]
    });
  };
  removeUser = (user: SharedUser) => {
    this.setState({
      ...this.state,
      users: this.state.users.filter((u: SharedUser) => u !== user)
    });
  };
  updateUser = (e: ChangeEvent<HTMLInputElement>, i: number) => {
    const users = [...this.state.users];
    users[i].email = e.target.value;
    this.setState({ ...this.state, users });
  };
  toggleDomain = (user: SharedUser, domain: IDomain) => {
    const users = [...this.state.users];
    if (user.domains.some((d: any) => d.domainKey === domain.key)) {
      (users.find((u: SharedUser) => u === user) as any).domains = user.domains.filter(
        (d: any) => d.domainKey !== domain.key
      );
    } else {
      (users.find((u: SharedUser) => u === user) as any).domains.push({
        domainKey: domain.key,
        domainName: domain.name,
        role: 'default'
      });
    }
    this.setState({ ...this.state, users });
  };
  toggleEdit = (user: SharedUser) => {
    const users = [...this.state.users];
    (users.find((u: SharedUser) => u === user) as any).editMode = !user.editMode;
    this.setState({ ...this.state, users });
  };
  getSharedDomains = (user: SharedUser) => {
    return user.editMode
      ? this.getAllDomains(user)
      : user.domains.map((domain: any, i: number) => (
          <Chip
            key={i}
            deleteIcon={
              <Tooltip
                title={
                  domain.role === 'default'
                    ? this.props.t('users.readAccess') as string
                    : this.props.t('users.writeAccess') as string
                }
              >
                {domain.role === 'default' ? (
                  <VisibilityIcon fontSize='small' />
                ) : (
                  <EditIcon fontSize='small' />
                )}
              </Tooltip>
            }
            color='primary'
            variant='outlined'
            label={domain.domainName}
            style={{ margin: '0 .5rem .5rem 0' }}
            onDelete={() => {}}
          />
        ));
  };

  isUserAllowed = (user: SharedUser, domain: IDomain): boolean => {
    return user.domains.some((d: any) => d.domainKey === domain.key);
  };

  getRole = (user: SharedUser, domain: IDomain): string => {
    return (user.domains.find((d: any) => d.domainKey === domain.key) as any).role;
  };

  switchRole = (user: SharedUser, domain: IDomain, role: string) => {
    const u = [...this.state.users];
    ((u.find((u: SharedUser) => u === user) as SharedUser).domains.find(
      (d: any) => d.domainKey === domain.key
    ) as any).role = role;
    this.setState({ ...this.state, users: u });
  };

  getAllDomains = (user: SharedUser) => {
    return (
      <Box mb={5}>
        <Grid container spacing={2}>
          {this.state.domainOwner.domains.map((domain: IDomain, i: number) => (
            <Grid key={i} item xs={12} sm={6}>
              <Box>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Switch
                        size='small'
                        checked={this.isUserAllowed(user, domain)}
                        onChange={() => this.toggleDomain(user, domain)}
                      />
                    }
                    label={domain.name}
                  />
                  {this.isUserAllowed(user, domain) && (
                    <RadioGroup style={{ flexDirection: 'row' }}>
                      <FormControlLabel
                        value='default'
                        control={
                          <Radio
                            size='small'
                            checked={this.getRole(user, domain) === 'default'}
                            onChange={() => this.switchRole(user, domain, 'default')}
                          />
                        }
                        label={this.props.t('users.readAccess')}
                        classes={{ label: this.props.classes.radioLabel }}
                      />
                      <FormControlLabel
                        value='write'
                        control={
                          <Radio
                            size='small'
                            checked={this.getRole(user, domain) === 'write'}
                            onChange={() => this.switchRole(user, domain, 'write')}
                          />
                        }
                        label={this.props.t('users.writeAccess')}
                        classes={{ label: this.props.classes.radioLabel }}
                      />
                    </RadioGroup>
                  )}
                </FormControl>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  updateDomainOwner = async () => {
    await this.setState({ ...this.state, isSaving: true });
    const newUsers = {} as any;
    const domains = await Promise.all(
      [...this.state.domainOwner.domains].map(async (domain: IDomain) => {
        this.state.users.forEach((user: SharedUser) => {
          if (
            !(domain.allowedUsers || []).some((au) => au.email === user.email) &&
            user.domains.some((d) => d.domainKey === domain.key)
          ) {
            newUsers[user.email] = newUsers[user.email]
              ? [...newUsers[user.email], domain.name]
              : [domain.name];
          }
        });

        domain.allowedUsers = this.state.users
          .filter((user: SharedUser) => user.domains.some((d: any) => d.domainKey === domain.key))
          .map((user: SharedUser) => {
            const d = user.domains.find((d: any) => d.domainKey === domain.key) as any;
            return {
              email: user.email,
              role: d.role,
              created: new Date()
            };
          });
        return domain;
      })
    );
    await Promise.all(
      Object.entries(newUsers).map(async (nu: any) => {
        await this.context.notifyUserAboutSharedDomain(nu[0], nu[1]);
      })
    );

    await this.setState({
      ...this.state,
      domainOwner: { ...this.state.domainOwner, domains }
    });
    await (this.context.setData = { userData: this.state.domainOwner });
    await this.context.setUser();
    await this.context.getUser();
    this.init();
    this.setState({ ...this.state, isSaving: false });
  };
  invitationSent = (user: SharedUser) => {
    const users = [...this.state.users];
    const u = users.find((u: SharedUser) => u === user) as SharedUser;
    u.invitesSent = u.invitesSent ? ++u.invitesSent : 1;
    this.setState({ ...this.state, users, sent: true });
  };
  handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    this.updateDomainOwner();
  };

  render() {
    const { t, classes } = this.props;
    return (
      <form onSubmit={this.handleSubmit} autoComplete='off'>
        <Box p={5} display='flex' justifyContent='space-between' alignItems='center'>
          <Box mr={5}>
            <Typography variant='h4'>{t('users.manageUsers')}</Typography>
            <Typography variant='subtitle1' gutterBottom>
              {t('users.description')}
            </Typography>
          </Box>
          <Box flexShrink='0'>
            <Tooltip title={t('users.addUser') as string}>
              <Fab color='secondary' style={{ margin: '0 .5rem' }} onClick={this.newUser}>
                <AddIcon />
              </Fab>
            </Tooltip>
            <Tooltip title={t('users.saveUsers') as string}>
              <Fab color='primary' type='submit'>
                <SaveIcon />
              </Fab>
            </Tooltip>
          </Box>
        </Box>

        {this.state.users
          .map((user: SharedUser, i: number) => (
            <Box key={i}>
              <Divider />
              <Box px={5} py={2} className={classes.user}>
                <Box my={2} display='flex' alignItems='center'>
                  <TextField
                    value={user.email}
                    variant='outlined'
                    fullWidth
                    placeholder={t('users.typeEmail')}
                    onChange={(e: any) => this.updateUser(e, i)}
                    label={t('notifications.email')}
                    required
                    inputProps={{
                      autoComplete: 'new-email'
                    }}
                    type='email'
                    autoFocus={true}
                  />
                  <Tooltip title={t('users.editRights') as string}>
                    <IconButton onClick={() => this.toggleEdit(user)} style={{ margin: '0 .5rem' }}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('users.removeUser') as string}>
                    <IconButton
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          removeUserDialog: true,
                          userToRemove: user
                        })
                      }
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                {!user.verified && user.id && (
                  <Alert
                    severity='info'
                    action={
                      (user.invitesSent || 0) < 3 ? (
                        <Button
                          color='inherit'
                          variant='outlined'
                          size='small'
                          onClick={async () => {
                            await this.context.inviteUser(user.email);
                            await this.invitationSent(user);
                          }}
                        >
                          {t('users.invite')}
                        </Button>
                      ) : null
                    }
                  >
                    <Typography variant='body2'>{t('users.userNotFound')}</Typography>
                    {this.state.sent && (
                      <Collapse in={this.state.sent} timeout={500}>
                        <Typography variant='caption'>
                          {t('users.invitationSent', { date: new Date().toLocaleString() })}
                        </Typography>
                      </Collapse>
                    )}
                    {user.invitesSent && user.invitesSent >= 3 && (
                      <Typography
                        variant='caption'
                        className={classes.link}
                        dangerouslySetInnerHTML={{
                          __html: t('users.inviteLimit', {
                            support: '<a target="_blank"" href="#">support</a>'
                          })
                        }}
                      />
                    )}
                  </Alert>
                )}
                <Box my={2}>{this.getSharedDomains(user)}</Box>
              </Box>
            </Box>
          ))
          .reverse()}
        {this.state.users.length === 0 && (
          <Box p={5}>
            <Typography variant='body2'>{t('common:noData')}</Typography>
          </Box>
        )}
        <AlertDialog
          open={this.state.removeUserDialog}
          title={t('common:dialogs.domainRemove')}
          message={t('domains.confirm')}
          handleClose={() => this.setState({ ...this.state, removeUserDialog: false })}
          action={async () => {
            await this.removeUser(this.state.userToRemove);
            this.updateDomainOwner();
          }}
        />
        <SaveSnackbar open={this.state.isSaving} />
      </form>
    );
  }
}
export default withStyles(styles)(withTranslation(['settings', 'common'])(UsersSettings));

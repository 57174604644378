import React, { Component } from 'react';
import { Box, Typography, TextField, withStyles, createStyles, Theme, Button } from '@material-ui/core';
import { IComponentProps } from '../../models/app.model';
import AppContext from '../../data/AppContext';
import { withTranslation } from 'react-i18next';
import { DevMode } from '../../helpers';
import Highlight from 'react-highlight';
import '../../../node_modules/highlight.js/styles/agate.css';

const styles = createStyles((theme: Theme) => ({}));

interface CustomDomainInfoState {
  endpoint: string;
  detectedUrl: string;
  fileName: string;
  dirName: string;
}
interface CustomDomainInfoProps extends IComponentProps {}
class CustomDomainInfo extends Component<CustomDomainInfoProps, CustomDomainInfoState> {
  static contextType = AppContext;
  private mounted: boolean = false;
  state = {
    endpoint: '',
    detectedUrl: '',
    fileName: 'pdf.html',
    dirName: '/public',
  };
  async componentDidMount() {
    this.mounted = true;
    const { pdfEndpoint: endpoint } = this.context.getData.domain;
    const { detectedUrl } = await this.context.getData;
    const url = detectedUrl ? new URL(detectedUrl).href : '';
    this.setState({ ...this.state, endpoint, detectedUrl: url });
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  updateEndpoint = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ ...this.state, endpoint: e.target.value });
  };
  saveEndpoint = (e: React.FormEvent) => {
    e.preventDefault();
    if (this.mounted) {
      this.context.updatePdfEndpoint(this.state.endpoint, this.context.getData.domain.key);
    }
  };
  getStep = (step: string, i: number) => {
    const { t } = this.props;

    const indexFile = `
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="referrer" content="no-referrer-when-downgrade" />
        <script src="https://app.primeleads.de/api/v1/${this.context.getData.domain.key}" async></script>
      </head>
      <body></body>
    </html>
    `;
    const expressScript = `
    app.get("/${this.state.endpoint}/*",(req,res,next)=>{
      const pdfTemplate = path.join(__dirname,"..${this.state.dirName}/${this.state.fileName}"); // THIS MAY BE DIFFERENT
      res.status(200).sendFile(pdfTemplate);
    })
    `;
    switch (i) {
      case 0:
        return (
          <Box>
            <Box display='flex' alignItems='center' my={2}>
              <Box mr={1}>
                <Typography variant='body2'>{this.state.detectedUrl}</Typography>
              </Box>
              <Box mr={1}>
                <TextField
                  value={this.state.endpoint || ''}
                  onChange={this.updateEndpoint}
                  variant='outlined'
                  fullWidth
                  label={t('customDomainLabel')}
                />
              </Box>
              <Button type='submit' variant='contained' color='primary'>
                Save endpoint
              </Button>
            </Box>
          </Box>
        );
      case 1:
        return (
          <Box>
            <Highlight className='html'>{indexFile}</Highlight>
          </Box>
        );
      case 2:
        return <Box></Box>;
      case 3:
        return (
          <Box>
            <Highlight className='javascript'>{expressScript}</Highlight>
          </Box>
        );
    }
  };
  render() {
    const { t } = this.props;
    const steps = ['setupEndpoint', 'createFile', 'uploadFile', 'handleRedirect'];
    const { fileName, dirName } = this.state;
    return (
      <>
        <Box p={5}>Coming soon</Box>
        <DevMode onlyAdmin={true}>
          <form onSubmit={this.saveEndpoint}>
            <Box p={5}>
              <Box mb={3}>
                <Typography variant='h4'>Custom domain for pdf tracking</Typography>
                <Typography variant='subtitle1'>Custom domain for pdf tracking</Typography>
              </Box>

              {steps.map((step, i) => {
                return (
                  <Box mb={3}>
                    <Typography variant='h5' gutterBottom>
                      {`${i + 1}. `}
                      {t(`steps.${step}`)}
                    </Typography>
                    <Typography
                      variant='body2'
                      gutterBottom
                      dangerouslySetInnerHTML={{
                        __html: t(`steps.${step}Description`, {
                          fileName,
                          dirName,
                          pathName: `/${this.state.endpoint}/*`,
                        }),
                      }}
                    />
                    {this.getStep(step, i)}
                  </Box>
                );
              })}
              <Typography variant='h5'>That should be it!</Typography>
            </Box>
          </form>
        </DevMode>
      </>
    );
  }
}

export default withStyles(styles)(withTranslation(['uploadPdf', 'common'])(CustomDomainInfo));

import React from 'react';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import Checkout from './Checkout';
import Stripe from '@stripe/stripe-js';

export const InjectedCheckoutForm = (props: any) => {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => (
        <Checkout
          elements={elements}
          stripe={stripe as Stripe.Stripe}
          plan={props.plan}
          onCancel={props.onCancel}
        />
      )}
    </ElementsConsumer>
  );
};

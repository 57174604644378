import React, { Fragment, ChangeEvent, FormEvent } from 'react';
import {
  TextField,
  Box,
  Typography,
  IconButton,
  Tooltip,
  Fab,
  Grid,
  Divider,
  makeStyles,
  Snackbar
} from '@material-ui/core';
import AppContext from '../../data/AppContext';
import styles from './Settings.style';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import SaveSnackbar from '../SaveSnackbar';
import { useTranslation } from 'react-i18next';
import { copyToClipboard } from '../../helpers';
import { IDomain } from '../../models/app.model';
import MuiAlert from '@material-ui/lab/Alert';
import { AlertDialog } from '../AlertDialog';
import { Link } from 'react-router-dom';

const Domains = () => {
  const context = React.useContext(AppContext);
  const [copied, setCopied] = React.useState(false);
  const [isSaving, setSaving] = React.useState(false);
  const [removeDomainDialog, setRemoveDomainDialog] = React.useState(false);
  const [domainToRemove, setDomainToRemove] = React.useState<IDomain>();
  const [upgradeAccountDialog, setUpgradeAccountDialog] = React.useState(false);
  const classes = makeStyles(styles)();
  const { t } = useTranslation(['settings', 'common']);

  const [domains, setDomains] = React.useState(
    context.getData.userData.domains.filter((domain: IDomain) => !domain.disabled)
  );

  //SWITCHING OFF DOMAIN
  const removeDomain = async (domain: IDomain) => {
    await setDomainToRemove(domain);
    setRemoveDomainDialog(true);
  };
  //NEW DOMAIN
  const newDomain = async () => {
    const { domains: domainLimit } = context.getData.userData.stripe;
    const canAdd = domains.length + 1 <= domainLimit;
    if (!canAdd) {
      setUpgradeAccountDialog(true);
      return;
    }
    const domain = await context.createDomain();
    setDomains([{ ...domain, name: '' } as any, ...domains]);
  };
  //SAVE DOMAINS
  const saveDomains = async () => {
    await setSaving(true);
    await (context.setData = {
      userData: { ...context.getData.userData, domains }
    });
    await context.setUser();
    const all = await context.getDomains();
    await (context.setData = {
      domains: all
    });
    if (all.length === 1 && !context.getData.domain) {
      window.location.reload();
    }
    await setDomains(context.getData.userData.domains.filter((domain) => !domain.disabled));
    setSaving(false);
  };
  //UPDATE NAME
  const updateName = async (domain: IDomain, newName: string) => {
    const found = domains.find((dom: IDomain) => dom === domain);
    (found as any).name = newName;
    setDomains([...domains]);
  };

  React.useEffect(() => {
    if (domains.length === 0 && isSaving) {
      window.location.reload();
      return;
    }
  }, [domains, isSaving]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    await saveDomains();
    window.location.reload();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box p={5} display='flex' justifyContent='space-between'>
        <Box mr={5}>
          <Typography variant='h4'>{t('domains.domains') as string}</Typography>
          <Typography variant='subtitle1'>{t('domains.reminder') as string}</Typography>
        </Box>
        <Box flexShrink='0'>
          <Tooltip title={t('domains.addDomain') as string}>
            <Fab color='secondary' onClick={newDomain} className={classes.rightSpace}>
              <AddIcon />
            </Fab>
          </Tooltip>
          <Tooltip title={t('common:saveChanges') as string}>
            <Fab color='primary' type='submit'>
              <SaveIcon />
            </Fab>
          </Tooltip>
        </Box>
      </Box>
      {domains.map((domain: any, i: number) => (
        <Fragment key={i}>
          <Divider />
          <Grid container spacing={2} component={Box} px={5} py={3}>
            <Grid item xs={12} md={4}>
              <TextField
                value={domain.name}
                fullWidth
                name='name'
                onChange={(e: ChangeEvent<HTMLInputElement>) => updateName(domain, e.target.value)}
                placeholder={t('domains.urlAlias') as string}
                variant='outlined'
                label={t('domains.nameLabel') as string}
                required
              />
            </Grid>

            <Grid item xs={12} md={8}>
              <Box display='flex' alignItems='center'>
                <TextField
                  value={`<script src="https://app.primeleads.de/api/v1/${domain.key}" async></script>`}
                  fullWidth
                  variant='outlined'
                  label={t('domains.scriptLabel')}
                  InputProps={{
                    endAdornment: (
                      <Tooltip title={t('common:copyToClipboard') as string}>
                        <IconButton
                          onClick={() => {
                            setCopied(true);
                            copyToClipboard(
                              `<script src="https://app.primeleads.de/api/v1/${domain.key}" async></script>`
                            );
                          }}
                        >
                          <FileCopyIcon />
                        </IconButton>
                      </Tooltip>
                    )
                  }}
                />
                <Tooltip title={t('domains.removeDomain') as string}>
                  <IconButton onClick={() => removeDomain(domain)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </Fragment>
      ))}
      {domains.length === 0 && (
        <Box p={5} py={2}>
          <Typography variant='body2' gutterBottom>
            {t('common:noDomain.subtitle') as string}
          </Typography>
          {!context.getData.domain && (
            <Box my={3}>
              <Fab variant='extended' color='primary' component={Link} to='/'>
                {t('common:noDomain.button') as string}
              </Fab>
            </Box>
          )}
        </Box>
      )}
      <Snackbar
        open={copied}
        autoHideDuration={3000}
        onClose={() => setCopied(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert variant='filled' severity='info'>
          {t('common:scriptCopied')}
        </MuiAlert>
      </Snackbar>
      <AlertDialog
        open={removeDomainDialog}
        title={t('common:dialogs.domainRemove')}
        message={t('domains.confirm')}
        handleClose={() => setRemoveDomainDialog(false)}
        action={async () => {
          await setSaving(true);
          if (domainToRemove) {
            domainToRemove.disabled = true;
          }

          saveDomains();
        }}
      />
      <AlertDialog
        open={upgradeAccountDialog}
        title={t('common:dialogs.domainLimit')}
        justConfirm={true}
        message={t('domains.pleaseUpgrade')}
        handleClose={() => setUpgradeAccountDialog(false)}
      />
      <SaveSnackbar open={isSaving} />
    </form>
  );
};
export default Domains;

import { DynamicContent } from '../langTemplate';

const dynamicContent: DynamicContent = {
  title: 'Dynamic content',
  description: 'Passen Sie den Inhalt Ihrer Website an unterschiedliche Besucher an.',
  titleAdding: 'Dynamic Content erstellen',
  confirmChange: 'URL-Adresse ändern, werden alle Selektoren entfernen. Möchten Sie fortfahren?',
  addRule: 'Neue Regel hinzufügen',
  filterRules: 'Bestehende Regeln filtern',
  modifiedElements: 'Geänderte Elemente',
  deleteElement: 'Element löschen',
  deleteRule: 'Regel löschen',
  editElement: 'Element bearbeiten',
  editRules: 'Regeln bearbeiten',
  replacedWith: 'Ersetzt mit',
  addContent: 'Neuen Inhalt hinzufügen',
  typeUrl: 'Geben Sie die URL Ihrer Website ein, um die Vorschau anzuzeigen',
  enableRule: 'Regel aktivieren',
  disableRule: 'Regel deaktivieren',
  confirmRemove: 'Bitte bestätigen Sie das Entfernen dieser Regel',
  noEmpty: 'Bitte füllen Sie das Feld Regelname aus',
  duplicate: 'Ausgewählte Regel duplizieren',
  visibleOnly: 'Nur sichtbar für Unternehmen, die diese Kriterien erfüllen',
  multipleRules: 'Kriterien anzeigen',
  multipleDescription: 'Hier können Sie angeben, welches Unternehmen Ihre Dynamic Content sehen soll',
  uploadImage: 'Bild hochladen',
  contentDetails: 'Dynamic Content Zusammenfassung',
  limitTitle: 'Regelbegrenzung überschritten',
  limitExceeded:
    'Bitte aktualisieren Sie Ihren Plan, um weitere Regeln zu aktivieren. Ihr aktuelles Limit liegt bei {{limit}}.',
  explanation:
    'Jede Bedingung kann mehrere Regeln haben. Das Unternehmen sieht den Inhalt, wenn mindestens eine Regel für jede bereitgestellte Bedingung übereinstimmt. Klicken Sie rechts auf die Button, um Beispieldaten zu laden und zu sehen, wie es funktioniert.',
  labels: {
    'branch': 'Unternehmensbereich',
    'location': 'Standort',
    'city': 'Stadt',
    'status': 'Firmenstatus',
    'markierung': 'Anmerkung',
    'ruleName': 'Name der Regel',
    'content': 'Inhalt der Nachricht',
    'contentType': 'Content typ',
    'website': 'Website',
    'ruleType': 'Art der Regel',
    'companyName': 'Name der Firma',
    'branchName': 'Branche',
    'companyStatus': 'Firmenstatus',
    'dynamic-text': 'Dynamischer Text (angepasst HTML-Elemente)',
    'dynamic-message': 'Dynamische Nachricht (oben auf der Website)',
    'company-name': 'Name der Firma',
    'company-type': 'Branche',
    'company-note-status': 'Firmenstatus',
    'isCustomer': 'Kunde',
    'isFavorite': 'Favorite',
    'notInteresting': 'Nicht interessant',
    'competitor': 'Wettbewerber',
    'spylink': 'Spylink',
  },
  steps: {
    contentType: 'Content typ',
    contentTypeInfo: 'Wie möchten Sie den Inhalt den Besuchern anzeigen?',
    target: 'Zielgruppe',
    targetInfo: 'Definieren Sie die Bedingung, die der Besucher erfüllen muss',
    companyNameTitle: 'Filtern nach Firmennamen',
    companyNameInfo:
      'Anzeigen von Nachrichten nur an Unternehmen mit einem bestimmten Namen. (Groß- und Kleinschreibung)',
    branchTitle: 'Filter nach Branche',
    branchInfo: 'Anzeigeinhalt für alle Besucher aus bestimmten Geschäftszweig.',
    locationTitle: 'Filter nach Ort',
    locationInfo:
      'Anzeigen von Inhalten für Besucher aus einer bestimmten Stadt. (Groß- und Kleinschreibung)',
    spylinkTitle: 'Filtern nach Spylink-Einträgen',
    spylinkInfo: 'Zeigen Sie Inhalte nur Besuchern an, die über Spylink eingegeben haben',
    statusTitle: 'Filtern nach Firmenstatus',
    statusInfo:
      'Anzeigen von Inhalten für Unternehmen, die mit einem der verfügbaren Status gekennzeichnet sind (Favorit, Wettbewerber, nicht interessiert, Kunde)',
    urlChange:
      'Durch Ändern der URL werden alle derzeit ausgewählten Elemente gelöscht. Möchten Sie fortfahren?',
    message: 'Nachricht oben auf der Seite',
    messageInfo: 'Die Nachricht wird oben auf Ihrer Website angezeigt, sobald sie geladen wird.',
    customHtml: 'Angepasstes HTML-Element',
    customHtmlInfo:
      'Mit dieser Option können Sie mehrere HTML-Elemente auf Ihrer Site auswählen und für jedes einen Ersatzinhalt bereitstellen.',
    customise: 'Content anpassen',
    customiseInfo:
      'Hier können Sie den Inhalt der Nachricht eingeben oder HTML-Elemente zum Anpassen auswählen.',
    summary: 'Überblick',
    summaryInfo:
      'Dies ist Dynamic Content, den Sie gerade erstellt haben. Es wird für Unternehmen sichtbar sein, die diese Bedingungen erfüllen. Um es zu speichern, klicken Sie bitte auf die Fertig stellen.',
    companyPattern: 'Name der Firma',
    pleaseChooseOption: 'Klicken Sie, um die Optionen zu erweitern',
    pleaseChooseStatus: 'Bitte wählen Sie einen Firmenstatus',
    pleaseChooseType: 'Bitte wählen Sie eine Branche aus',
    pleaseChooseName: 'Bitte wählen Sie einen Firmennamen',
    pleaseTypeUrl: 'Bitte geben Sie die URL der Website ein',
    pleaseTypeMessage: 'Bitte geben Sie eine Nachricht für den Besucher ein',
    typeCompanyPlaceholder: 'Bitte geben Sie einen Firmennamen ein',
    urlPlaceholder: 'Geben Sie hier die URL-Adresse ein',
    dynamicContentFor: 'Dynamic Content für',
    chooseExisting: 'Bitte wählen Sie einen vorhandenen Spylink aus oder erstellen Sie einen neuen',
    spylinkCreated:
      'Der Spylink mit dem Namen "{{name}}" wurde gerade erstellt und in der Regel hinzugefügt',
    conditions: '{{count}} Bedingung',
    conditions_plural: '{{count}} Bedingungen',
  },
  form: {
    conditions: 'Voraussetzungen zu erfüllen',
    companyName: 'Name muss enthalten',
    status: 'Firmennotiz muss sein',
    branch: 'Branche muss sein',
    location: 'Der Name der Stadt muss enthalten',
    spylink: 'Muss über Spylink eingeben',
    sampleCompanies: 'Beispielfirmen',
    sampleConditions: 'Beispielbedingungen',
  },
  editor: {
    title: 'Ausgewählte Elemente',
    notFoundTitle: 'Elemente nicht mehr gefunden',
    notFound: 'Einige der Elemente wurden nicht mehr gefunden.',
    editElements: 'Bearbeiten',
    replacedWith: 'Ersetzt mit',
    deleteElement: 'Element löschen',
    confirmMessage: 'Bitte bestätigen Sie das Element entfernen',
  },
};
export default dynamicContent;

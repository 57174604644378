import { Theme, createStyles } from '@material-ui/core/styles';

const styles: any = (theme: Theme) =>
  createStyles({
    root: {
      'minHeight': '50vh',
      '& .MuiTab-wrapper': {
        [theme.breakpoints.up('md')]: {
          flexDirection: 'row',
          justifyContent: 'flex-start'
        }
      }
    },
    title: {
      padding: theme.spacing(5, 5, 1)
    },
    options: {
      borderRight: `1px solid ${theme.palette.grey[200]}`
    },
    option: {
      borderTop: `1px solid ${theme.palette.grey[200]}`
    },
    topSpace: {
      marginTop: theme.spacing(2)
    },
    select: {
      marginBottom: theme.spacing(2)
    },
    rightSpace: {
      marginRight: theme.spacing(1)
    },
    icon: {
      marginLeft: theme.spacing(1)
    },
    card: {
      backgroundColor: theme.palette.grey[50],
      textAlign: 'center'
    },
    media: {
      backgroundColor: theme.palette.grey[300],
      padding: theme.spacing(3)
    },
    bigIcon: {
      fontSize: '3rem'
    },
    link: {
      '& a': {
        color: theme.palette.secondary.main,
        textDecoration: 'none'
      }
    },
    listRoot: {
      '& .Mui-selected': {
        backgroundColor: theme.palette.grey[100]
      }
    },
    user: {
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2)
      }
    },
    upgradeButton: {
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(2, 0)
      }
    },
    radioLabel: {
      fontSize: '12px',
      color: theme.palette.text.secondary
    },
    invoice: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#fff',
      padding: theme.spacing(2),
      borderBottom: `3px solid ${theme.palette.grey[200]}`
    },
    bgBadge: {
      '&.paid': {
        backgroundColor: theme.palette.success.main
      },
      '&.open': {
        backgroundColor: theme.palette.info.main
      },
      '& .icon': {
        color: theme.palette.common.white
      },
      'borderRadius': '50%'
    },
    optionsTable: {
      overflowX: 'auto',
      [theme.breakpoints.down('md')]: {
        overflowX: 'scroll'
      }
    }
  });
export default styles;

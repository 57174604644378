import { Theme, createStyles } from "@material-ui/core/styles";

const styles = (theme: Theme) =>
  createStyles({
    tabs: {
      borderBottom: "1px solid #eee"
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: `1px solid ${theme.palette.grey[200]}`
    },
    sticky: {
      position: "sticky",
      top: 0,
      backgroundColor: theme.palette.common.white,
      zIndex: 1
    },
    note: {
      width: "20vw",
      height: "20vh",
      backgroundColor: theme.palette.common.white,
      boxShadow: "0 5px 10px -5px #aaa"
    },
    scroll: {
      overflowY: "hidden",
      flexWrap: "nowrap",
      flexDirection: "column"
    }
  });
export default styles;

import React, { Component } from 'react';
import { Reminder } from './Reminders';
import {
  Box,
  createStyles,
  Theme,
  withStyles,
  TextField,
  Grid,
  Typography,
  Switch,
  FormControlLabel,
  Fab,
  IconButton
} from '@material-ui/core';
import { IComponentProps } from '../../models/app.model';
import { withTranslation } from 'react-i18next';
import { getCompanyIcon } from '../../helpers';
import { Alert } from '@material-ui/lab';
import LoadingBox from '../LoadingBox';
import { Link } from 'react-router-dom';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      'backgroundColor': theme.palette.grey[100],
      '& a': {
        color: theme.palette.primary.main
      }
    }
  });

export interface RemindersFormProps extends IComponentProps {
  reminder: Reminder;
  onUpdate: (reminder: Reminder, name: string, value: any) => any;
  onSave: () => any;
}
export interface RemindersFormState {}

class RemindersForm extends Component<RemindersFormProps, RemindersFormState> {
  getStatus = () => {
    const { reminder } = this.props;
    if (reminder.expired && reminder.status === 'open') {
      return <Alert severity='error'>Expired</Alert>;
    } else if (!reminder.expired && reminder.status === 'open') {
      return <Alert severity='info'>Open</Alert>;
    } else {
      return <Alert severity='success'>Completed</Alert>;
    }
  };
  render() {
    const { reminder, onUpdate, onSave } = this.props;
    return (
      <LoadingBox condition={!!reminder} p={5} className={this.props.classes.root} empty={!reminder}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant='caption' color='textSecondary'>
              Added at
            </Typography>
            <Typography variant='h6'>{new Date(reminder.addedAt).toLocaleString()}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant='caption' color='textSecondary'>
              Added by
            </Typography>
            <Typography variant='h6'>{reminder.author.authorEmail}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant='caption' color='textSecondary'>
              Status
            </Typography>
            <Typography variant='h6'>{this.getStatus()}</Typography>
          </Grid>
          <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'flex-end' }}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DateTimePicker
                inputVariant='outlined'
                fullWidth
                label='Valid till'
                value={reminder.validTill}
                onChange={(value: any) => onUpdate(reminder, 'validTill', value.toDate())}
                margin='normal'
                required
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={12}>
            <Typography variant='caption' color='textSecondary'>
              Company
            </Typography>
            <Box display='flex' alignItems='center'>
              {getCompanyIcon(reminder.company.companyDomain)}

              <Typography variant='h5'>
                <Link target='_blank' to={'/companies/' + reminder.company.companyKey}>
                  {reminder.company.companyName}
                </Link>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Title'
              fullWidth
              variant='outlined'
              value={reminder.title}
              name='title'
              onChange={(e: any) => onUpdate(reminder, e.target.name, e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Content'
              fullWidth
              variant='outlined'
              multiline
              rows={6}
              value={reminder.content}
              name='content'
              onChange={(e: any) => onUpdate(reminder, e.target.name, e.target.value)}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={reminder.important}
                  onChange={(e: any) => onUpdate(reminder, e.target.name, e.target.checked)}
                  name='important'
                />
              }
              label={<Typography variant='caption'>Mark as important</Typography>}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='caption' color='textSecondary'>
              Shared with
            </Typography>

            <Box>
              <IconButton>
                <GroupAddIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='caption' color='textSecondary'>
              Notifications
            </Typography>
            <Box>
              <FormControlLabel
                control={
                  <Switch
                    checked={reminder.notifications.email}
                    onChange={(e: any) => onUpdate(reminder, e.target.name, e.target.checked)}
                    name='notifications.email'
                  />
                }
                label={<Typography variant='caption'>Email notifications</Typography>}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={reminder.notifications.push}
                    onChange={(e: any) => onUpdate(reminder, e.target.name, e.target.checked)}
                    name='notifications.push'
                  />
                }
                label={<Typography variant='caption'>Push notifications</Typography>}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Fab variant='extended' size='small' color='primary' onClick={onSave}>
              Save
            </Fab>
          </Grid>
        </Grid>
      </LoadingBox>
    );
  }
}

export default withStyles(styles)(withTranslation()(RemindersForm));

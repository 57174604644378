import React, { MouseEvent } from 'react';
import {
  MenuItem,
  MenuList,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Tooltip,
  ListItemIcon,
  Avatar
} from '@material-ui/core';
//CONTEXT
import { useTranslation } from 'react-i18next';
import en from '../assets/images/en.svg';
import de from '../assets/images/de.svg';

interface Language {
  name: string;
  code: string;
}

const languages: Language[] = [
  { name: 'English', code: 'en' },
  { name: 'Deutsch', code: 'de' }
];

const LanguageSelect: React.FC<{}> = () => {
  const [open, setOpen] = React.useState<boolean>(false);
  const anchorRef = React.useRef<any>(null);
  const { t, i18n } = useTranslation();

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: MouseEvent<Document | HTMLLIElement>, lang: string = i18n.language) => {
    i18n.changeLanguage(lang);
    if (anchorRef.current && anchorRef.current === event.target) {
      return;
    }
    setOpen(false);
  };

  const getCountryFlag = (code: string): string => {
    switch (code) {
      case 'en':
      case 'en-GB':
      case 'en-US':
        return en;
      default:
        return de;
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useLayoutEffect(() => {
    if (anchorRef.current && prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);
  return (
    <>
      <Tooltip title={t('header.changeLanguage') as string} placement='left-start'>
        <Avatar
          onClick={handleToggle}
          ref={anchorRef}
          style={{
            cursor: 'pointer',
            width: 32,
            height: 32,
            border: '2px solid #eee'
          }}
        >
          <img
            src={getCountryFlag(i18n.language)}
            style={{
              display: 'block',
              height: '100%',
              width: 'auto',
              objectFit: 'cover'
            }}
            alt='Country flag'
          />
        </Avatar>
      </Tooltip>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        role={undefined}
        style={{ zIndex: 10 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} disablePadding style={{ zIndex: 2 }}>
                  {languages.map((lang: Language, i: number) => (
                    <MenuItem
                      key={i}
                      onClick={(e: MouseEvent<HTMLLIElement>) => handleClose(e, lang.code)}
                    >
                      <ListItemIcon style={{ minWidth: 'auto', marginRight: 10 }}>
                        <img
                          style={{ height: 15, width: 25, display: 'block' }}
                          src={getCountryFlag(lang.code)}
                          alt='Country flag'
                        />
                      </ListItemIcon>
                      {lang.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default LanguageSelect;

import React, { RefObject } from 'react';
import { withStyles, createStyles } from '@material-ui/styles';
import { Theme, OutlinedTextFieldProps, InputProps } from '@material-ui/core';

const styles = createStyles((theme: Theme) => ({
  base: {
    color: theme.palette.text.primary,
    fontSize: `${theme.typography.fontSize}px`,
    fontFamily: theme.typography.fontFamily
  },
  invalid: {
    color: theme.palette.text.primary
  }
}));

interface StripeInputProps extends InputProps {
  component: any;
}

export const StripeInput: React.FC<StripeInputProps> = (props) => {
  const {
    'component': Component,
    inputRef,
    'aria-invalid': ariaInvalid,
    'aria-describedby': ariaDescribeBy,
    defaultValue,
    required,
    onKeyDown,
    onKeyUp,
    readOnly,
    autoComplete,
    autoFocus,
    type,
    name,
    rows,
    ...other
  } = props;
  const [mountNode, setMountNode] = React.useState(null);
  React.useImperativeHandle(
    inputRef,
    () => ({
      focus: () => (mountNode as any).focus()
    }),
    [mountNode]
  );
  return <Component onReady={setMountNode} style={styles} {...other} />;
};

export default withStyles(styles)(StripeInput);

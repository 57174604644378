import React, { ChangeEvent, Fragment } from 'react';
import {
  Box,
  Typography,
  Theme,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Grid,
  TextField,
  Tooltip,
  IconButton,
  Link,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import clsx from 'clsx';
import { withStyles, createStyles } from '@material-ui/styles';
import { withTranslation } from 'react-i18next';
import { DynamicType, ContentRule, CompanyStatusType } from './Dynamic';
import LoadingBox from '../LoadingBox';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import AppContext from '../../data/AppContext';
import SaveSnackbar from '../SaveSnackbar';
import ContentEditor from './editor/DynamicContentEditor';
import { DynamicContentSelector } from './Dynamic';
import { IDomain, IComponentProps } from '../../models/app.model';
import AddIcon from '@material-ui/icons/Add';
import { ISpyLink } from '../spylink/Spylink';
import DeleteIcon from '@material-ui/icons/Delete';
import { Alert } from '@material-ui/lab';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import { createRandomString } from '../../helpers';
const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
    option: {
      flexGrow: 1,
      cursor: 'pointer',
      padding: theme.spacing(3),
      border: `1px solid ${theme.palette.grey[200]}`,
      backgroundColor: theme.palette.common.white,
      transition: theme.transitions.create('background', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    notActive: {
      'backgroundColor': theme.palette.grey[50],

      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      },
    },
    active: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  });

export interface AddDynamicContentProps
  extends IComponentProps,
    RouteComponentProps<{
      companyName?: string;
    }> {}
export interface IRules {
  companyName?: [{ active: boolean; value: string }];
  status?: [{ active: boolean; value: string }];
  branch?: [{ active: boolean; value: string }];
  spylink?: [{ active: boolean; value: string }];
  location?: [{ active: boolean; value: { city: string; country: string } }];
}
export interface ICompanyData {
  companyName: string;
  status: string;
  branch: string;
  city: string;
  spylink: string | null;
}
export interface AddDynamicContentState {
  domain?: IDomain;
  isSaving: boolean;
  activeStep: number;
  errors: string[];
  websites: string[];
  showEditor: boolean;
  modified: boolean;
  spylinkCreated: boolean;
  example: {
    active: boolean;
    rules: IRules;
    companyData: ICompanyData[];
  };
  content: {
    content: string;
    dynamicType: string;
    name: string;
    rule: string;
    ruleType: string;
    rules: IRules;
    disabled: boolean;
    selectors: any[];
    website: string;
  };
}

class AddDynamicContent extends React.Component<AddDynamicContentProps, AddDynamicContentState> {
  static contextType = AppContext;
  constructor(props: AddDynamicContentProps) {
    super(props);
    this.state = {
      isSaving: false,
      activeStep: 0,
      errors: [],
      websites: [],
      showEditor: false,
      modified: false,
      spylinkCreated: false,
      example: {
        active: false,
        rules: {
          companyName: [
            { active: true, value: 'team' },
            { active: true, value: 'teclead' },
          ],
          location: [
            { active: true, value: { city: 'Berlin', country: '' } },
            { active: true, value: { city: 'Goch', country: '' } },
          ],
          branch: [{ active: true, value: 'Beratung & Consulting' }],
        } as any,
        companyData: [
          {
            companyName: 'Teclead',
            branch: 'Beratung & Consulting',
            city: 'Berlin',
            status: '',
            spylink: null,
          },
          {
            companyName: 'Team-IT Group GmbH',
            branch: 'Beratung & Consulting',
            city: 'Goch',
            status: '',
            spylink: null,
          },
          {
            companyName: 'Company XXXX',
            branch: 'Versicherung',
            city: 'Berlin',
            status: CompanyStatusType.notInteresting,
            spylink: null,
          },
        ] as ICompanyData[],
      },
      content: {
        dynamicType: '',
        name: '',
        rule: '',
        ruleType: '',
        disabled: false,
        selectors: [],
        website: '',
        content: '',
        rules: {} as any,
      },
    } as AddDynamicContentState;
  }
  steps = [
    {
      title: 'contentType',
      description: 'contentTypeInfo',
    },
    {
      title: 'customise',
      description: 'customiseInfo',
    },
    {
      title: 'target',
      description: 'targetInfo',
    },
    {
      title: 'summary',
      description: 'summaryInfo',
    },
  ];
  options = ['companyName', 'status', 'branch', 'location', 'spylink'];
  companyNoteTypes = [
    CompanyStatusType.isCustomer,
    CompanyStatusType.competitor,
    CompanyStatusType.isFavorite,
    CompanyStatusType.notInteresting,
  ];

  async componentDidMount() {
    this.context.getCompanyTypes();
    await this.context.getDynamicContent();
    this.context.getSpylinks();
    const websites = this.context.getData.dynamicContent
      .filter((el: any) => el.website)
      .map((el: any) => el.website)
      .filter((el: string, i: number, arr: string[]) => arr.indexOf(el) === i);
    await this.setState({ ...this.state, websites });
    let companyName = '';
    if (this.props.location.search) {
      companyName = new URLSearchParams(this.props.location.search).get('companyName') || '';
    } else if (this.props.location.state) {
      companyName = (this.props.location.state as any).companyName;
      if (!companyName) return;
    }
    if (companyName) {
      const rules = {
        companyName: [{ active: true, value: companyName }],
      } as any;
      this.setState({
        ...this.state,
        domain: this.context.getData.domain,
        content: {
          ...this.state.content,
          ruleType: companyName ? ContentRule.CompanyName : '',
          rule: companyName,
          rules,
          name: this.props.t('steps.dynamicContentFor') + ' ' + companyName,
        },
      });
    }
  }
  async componentDidUpdate(prevProps: AddDynamicContentProps) {
    if (!this.state.domain && this.context.getData.domain) {
      this.setState({ ...this.state, domain: this.context.getData.domain });
    }
    if (this.state.domain && this.state.domain !== this.context.getData.domain) {
      window.location.reload();
    }
  }

  handleBack = () => this.setState({ ...this.state, activeStep: this.state.activeStep - 1 });

  addContent = async () => {
    this.setState({ ...this.state, isSaving: true });
    await (this.context.setData = {
      dynamicContent: [...this.context.getData.dynamicContent, this.state.content],
    });
    await this.context.setDynamicContent();
    await this.setState({ ...this.state, isSaving: false });
  };

  selectOption = async (key: string, value: any) => {
    if (key === 'ruleType' && value !== this.state.content.ruleType)
      await this.setState({
        ...this.state,
        content: { ...this.state.content, rule: '', [key]: value },
      });
    else
      await this.setState({
        ...this.state,
        content: { ...this.state.content, [key]: value },
      });
  };
  addRule = (key: string) => {
    const { content } = { ...this.state };
    if (!(content.rules as any)[key]) {
      (content.rules as any)[key] = [];
    }
    (content.rules as any)[key as any].push(
      key === 'location'
        ? { active: true, value: { city: '', country: '' } }
        : { active: true, value: '' }
    );
    this.setState({ ...this.state, content });
  };
  handleSubmit = async (e: any) => {
    e.preventDefault();
    if (e.target.checkValidity()) this.handleNext();
  };
  handleNext = async () => {
    console.log(this.state.content);
    if (this.state.activeStep === 2) {
      await this.setState({ ...this.state, example: { ...this.state.example, active: false } });
    }
    if (this.state.activeStep === this.steps.length - 1) {
      await this.addContent();
    }
    this.setState({ ...this.state, activeStep: this.state.activeStep + 1 });
  };

  updateTextField = async (key: string, i: number, value: any) => {
    const rules = { ...this.state.content.rules };
    (rules as any)[key][i].value = value;
    console.log('Should be differrent', rules, this.state.content.rules);
    this.setState({ ...this.state, content: { ...this.state.content, rules } });
  };
  removeRule = (key: string, i: number) => {
    const rules = { ...this.state.content.rules };
    (rules as any)[key].splice(i, 1);
    if ((rules as any)[key].length === 0) {
      delete (rules as any)[key];
    }
    this.setState({ ...this.state, content: { ...this.state.content, rules } });
  };
  getDeleteIcon = (key: string, i: number) => {
    return (
      <Box mx={1}>
        <Tooltip title={this.props.t('deleteRule') as string}>
          <IconButton onClick={() => this.removeRule(key, i)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };
  addSpylink = async () => {
    const { spylinks } = this.context.getData;
    const { website, name } = this.state.content;
    const linkId = createRandomString();
    const spylink: ISpyLink = {
      name: `Spylink - ${name}`,
      linkId,
      linkUrl: website,
    } as ISpyLink;
    const url = new URL(website);
    url.searchParams.append('sl', linkId);
    //FETCH
    spylinks.push(spylink);
    await (this.context.setData = { spylinks });
    await this.context.setSpylink(spylinks);
    await this.context.getSpylinks();
    const { rules } = this.state.content;
    if (!rules.spylink) {
      rules.spylink = [] as any;
    }
    (rules.spylink as any).push({ active: true, value: spylink.linkId });
    this.setState({
      ...this.state,
      spylinkCreated: true,
      content: {
        ...this.state.content,
        rules,
      },
    });

    //UPDATE LOCAL STATE

    console.log(url.href, this.context.getData.spylinks);
  };
  getTextField = (key: string, rule: any, index: number) => {
    const { t } = this.props;
    switch (key) {
      case 'spylink':
        return (
          <TextField
            key={index}
            select
            variant='outlined'
            value={rule.value || ''}
            label={t('labels.' + key)}
            required
            InputLabelProps={{ shrink: true }}
            SelectProps={{
              native: true,
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              this.updateTextField(key, index, e.target.value)
            }
          >
            <option value=''>{t('common:pleaseChoose')}</option>
            {this.context.getData.spylinks.map((spylink: ISpyLink, i: number) => {
              return (
                <option key={i} value={spylink.linkId}>
                  {spylink.name} / {spylink.linkId}
                </option>
              );
            })}
          </TextField>
        );
      case 'branch':
        return (
          <TextField
            key={index}
            select
            variant='outlined'
            value={rule.value || ''}
            label={t('labels.' + key)}
            required
            InputLabelProps={{ shrink: true }}
            SelectProps={{
              native: true,
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              this.updateTextField(key, index, e.target.value)
            }
          >
            <option value=''>{t('common:pleaseChoose')}</option>
            {this.context.getData.companyTypes.map((branch: ISpyLink, i: number) => {
              return <option key={i}>{branch}</option>;
            })}
          </TextField>
        );
      case 'status':
        return (
          <TextField
            key={index}
            select
            variant='outlined'
            value={rule.value || ''}
            label={t('labels.' + key)}
            required
            InputLabelProps={{ shrink: true }}
            SelectProps={{
              native: true,
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              this.updateTextField(key, index, e.target.value)
            }
          >
            <option value=''>{t('common:pleaseChoose')}</option>
            {this.companyNoteTypes.map((type: any, i: number) => {
              return (
                <option key={i} value={type}>
                  {t('labels.' + type)}
                </option>
              );
            })}
          </TextField>
        );
      default:
        return (
          <TextField
            key={index}
            variant='outlined'
            name={key}
            label={t('labels.' + key)}
            value={rule.value.city || (key !== 'location' && rule.value) || ''}
            required
            style={{ minWidth: 300 }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              this.updateTextField(
                key,
                index,
                key === 'location' ? { ...rule.value, city: e.target.value } : e.target.value
              )
            }
          />
        );
    }
  };

  getSteps = () => {
    const { t } = this.props;
    switch (this.state.activeStep) {
      case 0:
        return (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} component={Box} display='flex'>
              <Box
                className={clsx(this.props.classes.option, {
                  [this.props.classes.active]: this.state.content['dynamicType'] === DynamicType.Message,
                })}
                onClick={() => this.selectOption('dynamicType', DynamicType.Message)}
              >
                <Typography variant='h6' gutterBottom>
                  {t('steps.message')}
                </Typography>
                <Typography variant='body2'>{t('steps.messageInfo')}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} component={Box} display='flex'>
              <Box
                className={clsx(this.props.classes.option, {
                  [this.props.classes.active]: this.state.content['dynamicType'] === DynamicType.Text,
                })}
                onClick={() => this.selectOption('dynamicType', DynamicType.Text)}
              >
                <Typography variant='h6' gutterBottom>
                  {t('steps.customHtml')}
                </Typography>
                <Typography variant='body2'>{t('steps.customHtmlInfo')}</Typography>
              </Box>
            </Grid>
            <TextField required value={this.state.content['dynamicType']} style={{ opacity: 0 }} />
          </Grid>
        );
      case 2:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} md={9}>
              {this.options.map((option: string, i: number) => {
                return (
                  <Box
                    display='flex'
                    key={i}
                    className={this.props.classes.option}
                    p={3}
                    mb={1}
                    alignItems='center'
                  >
                    <Box mr={2}>
                      <Tooltip title={t('addRule') as string}>
                        <IconButton onClick={() => this.addRule(option)}>
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>

                    <Box>
                      <Typography variant='h5' gutterBottom>
                        {t(`steps.${option}Title`)}
                      </Typography>
                      <Typography variant='body2'>{t(`steps.${option}Info`)}</Typography>
                      {option === 'spylink' && (
                        <>
                          <Typography variant='caption' color='textSecondary'>
                            {this.props.t('steps.chooseExisting')}{' '}
                            <Link
                              color='secondary'
                              style={{ cursor: 'pointer' }}
                              onClick={this.addSpylink}
                            >
                              {t('common:hier')}
                            </Link>
                          </Typography>

                          {this.state.spylinkCreated && (
                            <Box my={1}>
                              <Alert
                                severity='success'
                                action={
                                  <IconButton
                                    onClick={() =>
                                      this.setState({ ...this.state, spylinkCreated: false })
                                    }
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                }
                              >
                                {t('steps.spylinkCreated', {
                                  name: this.context.getData.spylinks.slice(-1)[0].name,
                                })}
                              </Alert>
                            </Box>
                          )}
                        </>
                      )}

                      {(this.state.content.rules as any)[option as any] && (
                        <Box mt={2} display='flex' flexWrap='wrap'>
                          {(this.state.content.rules as any)[option as any].map(
                            (rule: any, i: number) => {
                              return (
                                <Box display='flex' alignItems='center' mb={2} key={i}>
                                  {this.getTextField(option, rule, i)}
                                  {this.getDeleteIcon(option, i)}
                                </Box>
                              );
                            }
                          )}
                        </Box>
                      )}
                    </Box>
                  </Box>
                );
              })}

              <TextField
                required
                value={
                  Object.values(this.state.content.rules).every((arr: any) => arr && arr.length > 0) &&
                  Object.keys(this.state.content.rules).length > 0
                    ? 'ok'
                    : ''
                }
                style={{ visibility: 'hidden', position: 'absolute' }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Box position='sticky' top='0' py={3}>
                {this.getRulesSummary()}
              </Box>
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <>
            <Box mb={3}>
              <TextField
                variant='outlined'
                margin='normal'
                fullWidth
                value={this.state.content.name}
                label={this.props.t('labels.ruleName')}
                onChange={(e: any) => this.selectOption('name', e.target.value)}
                required
              />
              <Autocomplete
                options={this.state.websites || []}
                getOptionLabel={(option) => option}
                value={this.state.content.website}
                selectOnFocus={true}
                onChange={(e: React.ChangeEvent<{}>, value: string | null) => {
                  this.setState({
                    ...this.state,
                    content: {
                      ...this.state.content,
                      website: value || '',
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant='outlined'
                    margin='normal'
                    label={this.props.t('steps.pleaseTypeUrl')}
                    required
                    type='url'
                    name='website'
                    onBlur={(e: any) => {
                      this.setState({
                        ...this.state,
                        showEditor: true,
                      });
                    }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      this.setState({
                        ...this.state,
                        content: {
                          ...this.state.content,
                          selectors: [],
                          website: e.target.value,
                        },
                        showEditor: false,
                      });
                    }}
                  />
                )}
              />
            </Box>

            {/**
             * TODO @Karol
             * use real state here instead of my test data
             * think about how to handle if someone changes the url after he selects elements
             */}
            {this.state.content.dynamicType === DynamicType.Text && this.state.showEditor && (
              <>
                <Grid container spacing={3}>
                  <ContentEditor
                    website={this.state.content.website}
                    selectors={this.state.content.selectors}
                    editMode={true}
                    onSave={() => null}
                    onDelete={() => null}
                    onChange={(selectors: DynamicContentSelector[]) => {
                      this.setState({
                        ...this.state,
                        content: {
                          ...this.state.content,
                          selectors,
                        },
                      });
                    }}
                  />
                </Grid>
              </>
            )}

            {this.state.content.dynamicType === DynamicType.Text && (
              <TextField
                required
                value={this.state.content.selectors.length}
                type='number'
                inputProps={{
                  min: 1,
                }}
                style={{ opacity: 0 }}
              />
            )}

            {this.state.content.dynamicType === DynamicType.Message && (
              <>
                <TextField
                  required
                  value={this.state.content.content}
                  label={this.props.t('steps.pleaseTypeMessage')}
                  variant='outlined'
                  fullWidth
                  onChange={(e: any) => this.selectOption('content', e.target.value)}
                  multiline
                  rows={4}
                  margin='normal'
                />
              </>
            )}
          </>
        );
      case 3:
        return (
          // TODO overview
          <>
            <Box mb={3}>
              <Typography variant='h6'>{this.props.t('contentDetails')}</Typography>
              <Typography variant='caption' color='textSecondary'>
                {this.props.t('labels.contentType')}
              </Typography>
              <Typography variant='body2'>
                {this.props.t('labels.' + this.state.content.dynamicType)}
              </Typography>
              <Typography variant='caption' color='textSecondary'>
                {this.props.t('labels.ruleName')}
              </Typography>
              <Typography variant='body2'>{this.state.content.name}</Typography>
              <Typography variant='caption' color='textSecondary'>
                {this.props.t('labels.website')}
              </Typography>
              <Typography variant='body2'>{this.state.content.website}</Typography>
            </Box>
            {this.getRulesSummary()}
          </>
        );
    }
  };
  getDynamicType = () => {
    return this.state.content.dynamicType
      ? this.props.t('labels.' + this.state.content.dynamicType)
      : null;
  };

  getRuleTypes = () => {
    return this.props.t('steps.conditions', { count: Object.keys(this.state.content.rules).length });
  };
  getContentType = () => {
    switch (this.state.content.dynamicType) {
      case DynamicType.Message:
        return this.state.content.website
          ? this.state.content.website + ' / ' + this.state.content.content
          : '';
      default:
        return this.state.content.website
          ? this.state.content.website +
              ' / ' +
              this.state.content.selectors.length +
              ' ' +
              this.props.t('common:selectors')
          : '';
    }
  };
  getSpylinkUrl = (spylink: ISpyLink, asLink?: boolean) => {
    const url = new URL(spylink.linkUrl || 'https://primeleads.de');
    url.searchParams.append('sl', spylink.linkId);
    return asLink ? (
      <Link color='secondary' target='_blank' href={url.href}>
        {url.href}
      </Link>
    ) : (
      url.href
    );
  };
  displayConditions = (rules: IRules) => {
    return Object.entries(rules)
      .filter(
        (rule: any) => rule[1].length > 0 && rule[1].some((rule: any) => rule.value.city || rule.value)
      )
      .map((condition: any, i: number) => {
        return (
          <Box key={i} mb={2}>
            <Box>
              <Typography variant='caption' color='textSecondary'>
                {this.props.t('form.' + condition[0])}
              </Typography>
            </Box>

            {condition[1]
              .filter((rule: any) => rule.value.city || rule.value)
              .map((rule: { active: boolean; value: any }, j: number, arr: any) => (
                <Fragment key={j}>
                  <Typography variant='body2' component='span'>
                    {condition[0] === 'location'
                      ? rule.value.city
                      : condition[0] === 'status' && rule.value
                      ? this.props.t('labels.' + rule.value)
                      : condition[0] === 'spylink'
                      ? this.getSpylinkUrl(
                          this.context.getData.spylinks.find((sl: ISpyLink) => sl.linkId === rule.value),
                          true
                        )
                      : rule.value}
                  </Typography>
                  {j !== arr.length - 1 && (
                    <Typography
                      variant='caption'
                      color='textSecondary'
                      component='span'
                      style={{ margin: '0 .5rem' }}
                      gutterBottom
                    >
                      {this.props.t('common:form.or')}
                    </Typography>
                  )}
                </Fragment>
              ))}
          </Box>
        );
      });
  };
  toggleExample = () => {
    if (this.state.example.active) {
      this.setState({
        ...this.state,
        content: {
          ...this.state.content,
          rules: {},
        },
        example: {
          ...this.state.example,
          active: false,
        },
      });
    } else {
      this.setState({
        ...this.state,
        content: {
          ...this.state.content,
          rules: {
            companyName: [...(this.state.example.rules.companyName || [])],
            location: [...(this.state.example.rules.location || [])],
            branch: [...(this.state.example.rules.branch || [])],
          } as any,
        },
        example: {
          ...this.state.example,
          active: true,
        },
      });
    }
  };
  displayCompanies = () => {
    const rules = this.state.content.rules;
    return this.state.example.companyData.map((company, i) => {
      let isCorrect = Object.keys(rules).length > 0;
      if (
        (rules as any)['location'] &&
        !(rules as any)['location' as any]
          .filter((location: any) => location.value.city)
          .some((location: any) => new RegExp(location.value.city, 'i').test(company.city))
      ) {
        isCorrect = false;
      }
      if (
        (rules as any)['companyName'] &&
        !(rules as any)['companyName' as any]
          .filter((name: any) => name.value)
          .some((name: any) => new RegExp(name.value, 'i').test(company.companyName))
      ) {
        isCorrect = false;
      }
      if (
        (rules as any)['branch'] &&
        !(rules as any)['branch' as any]
          .filter((branch: any) => branch.value)
          .some((branch: any) => branch.value && branch.value === company.branch)
      ) {
        isCorrect = false;
      }
      if (
        (rules as any)['status'] &&
        (rules as any)['status'].filter((status: any) => status.value).length > 0 &&
        !(rules as any)['status' as any]
          .filter((status: any) => status.value)
          .some((status: any) => status.value && status.value === company.status)
      ) {
        isCorrect = false;
      }
      if (
        (rules as any)['spylink'] &&
        (rules as any)['spylink'].filter((spylink: any) => spylink.value).length > 0 &&
        !(rules as any)['spylink' as any]
          .filter((spylink: any) => spylink.value)
          .some((spylink: any) => spylink.value && spylink.value === company.spylink)
      ) {
        isCorrect = false;
      }
      return (
        <Alert severity={isCorrect ? 'success' : 'error'} style={{ marginBottom: 10 }} key={i}>
          <Typography variant='body2'>{company.companyName}</Typography>
          <Typography variant='caption'>{company.city}</Typography>
          <Typography variant='caption'>{company.branch}</Typography>
          {company.status && (
            <Typography variant='caption'>{this.props.t('labels.' + company.status)}</Typography>
          )}
        </Alert>
      );
    });
  };
  getRulesSummary = () => {
    const rules = this.state.content.rules;
    const empty =
      Object.entries(rules).filter((rule: any) => {
        if (rule[0] === 'city' && rule[1].some((location: any) => location.value.city)) {
          return true;
        }
        if (rule[0] !== 'city' && rule[1].some((el: any) => el.value)) {
          return true;
        }
        return false;
      }).length === 0;
    return (
      <>
        {this.state.example.active ? (
          <>
            {this.state.example.active && (
              <>
                <Typography variant='h6' gutterBottom>
                  {this.props.t('form.sampleConditions')}
                </Typography>
                {this.displayConditions(this.state.content.rules)}
                <Typography variant='h6' gutterBottom>
                  {this.props.t('form.sampleCompanies')}
                </Typography>
                {this.displayCompanies()}
              </>
            )}
          </>
        ) : (
          !empty && (
            <>
              <Typography variant='h6' gutterBottom>
                {this.props.t('form.conditions')}
              </Typography>
              {this.displayConditions(rules)}
            </>
          )
        )}
      </>
    );
  };
  render() {
    const { classes } = this.props;
    return (
      <Box m={5}>
        <Box pb={3}>
          <Typography variant='h4'>{this.props.t('titleAdding')}</Typography>
        </Box>

        <LoadingBox condition={!this.steps} p={5} empty={this.steps.length === 0}>
          <form name='steps' onSubmit={this.handleSubmit}>
            <Stepper activeStep={this.state.activeStep} orientation='vertical'>
              {this.steps.map((step: any, i: number) => (
                <Step key={i}>
                  <StepLabel>
                    <Box display='flex' alignItems='center'>
                      <Typography variant='h6'>
                        {this.props.t('steps.' + step.title)}
                        <Typography
                          variant='inherit'
                          color='secondary'
                          component='span'
                          style={{ marginLeft: 20 }}
                        >
                          {i === 0 && this.getDynamicType()}
                          {i === 2 &&
                            Object.keys(this.state.content.rules).length > 0 &&
                            this.getRuleTypes()}
                          {i === 1 && this.getContentType()}
                        </Typography>
                      </Typography>
                    </Box>
                  </StepLabel>
                  <StepContent>
                    <Box p={5}>
                      <Typography variant='h3' gutterBottom>
                        {this.props.t('steps.' + step.title)}
                      </Typography>
                      <Typography variant='subtitle1' gutterBottom>
                        {this.props.t('steps.' + step.description)}
                      </Typography>
                      {this.state.activeStep === 2 && (
                        <Alert
                          variant='standard'
                          severity='info'
                          action={
                            <Button variant='contained' color='primary' onClick={this.toggleExample}>
                              {this.state.example.active
                                ? this.props.t('common:clear')
                                : this.props.t('common:example')}
                            </Button>
                          }
                        >
                          {this.props.t('explanation')}
                        </Alert>
                      )}
                      <Box mt={3}>{this.getSteps()}</Box>
                      <Box my={3}>
                        <Button
                          disabled={this.state.activeStep === 0}
                          onClick={this.handleBack}
                          className={classes.button}
                          variant='contained'
                          type='button'
                        >
                          {this.props.t('common:back')}
                        </Button>
                        <Button
                          variant='contained'
                          color='primary'
                          type='submit'
                          className={classes.button}
                        >
                          {this.state.activeStep === this.steps.length - 1
                            ? this.props.t('common:finish')
                            : this.props.t('common:next')}
                        </Button>
                      </Box>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </form>
          {this.state.activeStep === this.steps.length && <Redirect to='/dynamic-content' />}
        </LoadingBox>
        <SaveSnackbar open={this.state.isSaving} />
      </Box>
    );
  }
}
export default withStyles(styles)(
  withTranslation(['dynamicContent', 'common'])(withRouter(AddDynamicContent))
);

import { SignUps } from '../langTemplate';

const signup: SignUps = {
  login: {
    forgotPassword: 'Haben Sie Ihr Passwort vergessen?',
    noAccount: 'Noch keinen Account? Erstellen Sie eine kostenlos'
  },
  register: {
    alreadyGot: 'Ich habe bereits einen Account',
    companyName: 'Name der Firma',
    fullName: 'Name oder Spitzname',
    firstName: 'Vorname',
    lastName: 'Nachname',
    minimum: 'Mindest. {{chars}} Charaktere',
    rules: 'Mit der Anmeldung stimmen Sie unseren Nutzungsbedingungen und Datenschutzbestimmungen zu.',
    newsletter: 'Melden Sie sich für den Newsletter an und erhalten Sie die neuesten Updates und exklusiven Angebote.'
  },
  emailSent: 'E-Mail mit Reset-Link wurde gesendet. Bitte überprüfen Sie Ihre Mailbox.',
  errors: {
    credentials: 'Falsche Anmeldeinformationen.',
    emailCredentials: 'Emailadresse wurde nicht gefunden.',
    something: 'Etwas ist schief gelaufen.',
    exists: 'Benutzer existiert bereits.',
    failed: 'Login fehlgeschlagen.',
    incorrect: 'Falsche Anmeldeinformationen'
  },
  protectedBy: 'Geschützt durch Auth0',
  signIn: 'Einloggen',
  signUp: 'Anmelden',
  confirmEmail: 'Bitte bestätigen Sie Ihre E-Mail-Adresse',
  getLink: 'Kennwort zurücksetzen',
  pleaseConfirm:
    'Bitte bestätigen Sie Ihre E-Mail-Adresse und erhalten Sie einen Link zum Zurücksetzen Ihres Passworts.',
  email: 'Email Adresse',
  password: 'Kennwort'
};
export default signup;

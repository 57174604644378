import { Settings } from '../langTemplate';

const settings: Settings = {
  title: 'Einstellungen',
  accountItem: 'Konto',
  domainsItem: 'Domains',
  usersItem: 'Benutzerverwaltung',
  upgradeItem: 'Abonnieren',
  notificationsItem: 'Benachrichtigungen',
  integrationsItem: 'Integrationen',
  excludedUrls: 'Video-Tracking',
  cancel: 'Stornieren',
  confirmSubscription: 'Abonnement bestätigen',
  paymentInProgress: 'Zahlung läuft',
  paymentSuccessful: 'Zahlung erfolgreich',
  goBack: 'Zurück zur Startseite',
  personalDetails: 'Persönliche Daten',
  sepaDebit: 'SEPA Lastschrift',
  creditCard: 'Kreditkarte',
  sepaRules: `Durch die Bereitstellung Ihrer IBAN und die Bestätigung dieser Zahlung ermächtigen Sie die Spyla GmbH und Stripe, unseren Zahlungsdienstleister, Anweisungen an Ihre Bank zu senden, um Ihr Konto zu belasten, und Ihre Bank, Ihr Konto gemäß diesen Anweisungen zu belasten. Sie haben Anspruch auf eine Rückerstattung von Ihrer Bank gemäß den Bedingungen Ihrer Vereinbarung mit Ihrer Bank. Eine Rückerstattung muss innerhalb von 8 Wochen ab dem Datum der Belastung Ihres Kontos beantragt werden.`,
  cardRules: `Durch die Bestätigung dieser Zahlung gestatten Sie Spyla UG, Ihre Karte für diese Zahlung und zukünftige Zahlungen gemäß deren Bedingungen zu belasten.`,
  bankDetails: 'Bankdaten',
  total: 'Gesamtsumme',
  tax: 'Umsatzsteuer',
  summary: 'Zusammenfassung',
  bankAccount: 'Kontonummer',
  cardNumber: 'Kreditkartennummer',
  cvcCode: 'CVC-Code',
  expires: 'Läuft ab',
  firstName: 'Vorname',
  lastName: 'Nachname',
  downgradeTitle: 'Freier plan',
  downgrade:
    'Der kostenlose Plan ist standardmäßig festgelegt. Wenn Sie ein Downgrade durchführen möchten, wenden Sie sich bitte an unseren Support.',
  planNames: {
    'plan-l': 'Premium plan',
    'plan-m': 'Basic plan',
    'plan-xl': 'Enterprise plan',
    'pl-xl-20': 'Test plan',
    'FREE_PLAN': 'Frei plan',
  },
  details: {
    accountDetails: 'Kontodetails',
    invoiceAddress: 'Rechnungsanschrift',
    company: 'Name der Firma',
    address: 'Addresse',
    code: 'Postleizahl',
    city: 'Stadt',
    domainUsage: 'Domain-Nutzung',
    download: 'PDF Herunterladen',
    extraData: 'Zusätzliche Information',
    invoices: 'Rechnungen',
    paidOn: 'Bezahlt am',
    phone: 'Telefonnummer',
    newsletter: 'Newsletter',
    newsletterAlready: 'Sie haben sich bereits angemeldet.',
    newsletterDescription:
      'Erhalten Sie die neuesten Updates und exklusiven Angebote nur für Newsletter-Teilnehmer.',
    newsletterJoin: 'Sie haben noch nicht beigetreten.',
    newsletterToggle:
      'Verwenden Sie diese button, um unserem Newsletter beizutreten oder ihn zu verlassen.',
    chooseCountry: 'Bitte wählen Sie ein Land',
    country: 'Land',
  },
  integrations: {
    title: 'Integration mit externen Apps',
    description: 'Hier können Sie Primeleads mit anderen Apps verbinden.',
    chooseDomains: 'Domains auswählen',
    updateDomains: 'Aktualisieren',
    selectAll: 'Toggle Alle wählen',
    connect: 'Verbinden',
    disconnect: 'Unterbrechen',
    hubspot: {
      description:
        'Immer wenn wir ein Unternehmen identifizieren, das Ihre Seite besucht, senden wir die Daten an den mit Ihrem Konto verbundenen Hub.',
    },
  },
  notifications: {
    title: 'Benachrichtungen',
    name: 'Name',
    email: 'E-mail addresse',
    description: 'Verwalten Sie E-Mail-Benachrichtigungen',
    everyDay: 'Täglich',
    everyWeek: 'Wöchentlich',
    every2Weeks: 'Einmal pro 2 Wochen',
    everyMonth: 'Monatlich',
    addNew: 'Neue E-Mail hinzufügen',
    delete: 'E-Mail löschen',
    confirmMessage: 'Bitte bestätigen Sie das Entfernen dieser E-Mail',
    frequency: 'Häufigkeit',
    disabled: 'Deaktiviert',
  },
  domains: {
    domains: 'Domains',
    confirm: 'Bitte bestätigen Sie das Entfernen der Domain.',
    reminder:
      'Vergessen Sie nicht: Um mit dem Tracking zu beginnen, müssen Sie das Skript in Ihre Website einfügen.',
    addDomain: 'Neue Domain hinzufügen',
    removeDomain: 'Domain entfernen',
    urlAlias: 'Domain Name (url oder alias)',
    nameLabel: 'Domain name',
    scriptLabel: 'Script (Kopieren und fügen Sie ihn auf Ihrer Webseite)',
    pleaseUpgrade: 'Für weitere Domains aktualisieren Sie bitte Ihr Konto',
    allNamesFill: 'Bitte stellen Sie sicher, dass alle Domains einen Namen haben',
  },
  users: {
    manageUsers: 'Benutzer verwalten',
    description: 'Gewähren Sie Zugriff auf Ihre Domains',
    addUser: 'Benutzer hinzufügen',
    saveUsers: 'Speichern Sie Benutzer',
    removeUser: 'Benutzer entfernen',
    typeEmail: 'Geben Sie hier Ihre E-Mail-Adresse ein',
    confirm: 'Sind Sie sicher, dass Sie diesen Benutzer entfernen?',
    readAccess: 'Schreibgeschützt',
    writeAccess: 'Bearbeitungsmodus',
    editRights: 'Benutzerzugriffsrechte bearbeiten',
    userNotFound:
      'Benutzer nicht gefunden. Klicken Sie rechts auf die button, um eine Einladung zu senden.',
    invitationSent: 'Die Einladung wurde am {{date}} verschickt',
    inviteLimit:
      'Die Einladung wurde bereits dreimal gesendet. Bitte kontaktieren Sie unseren {{support}} für den Fall, dass keine E-Mail eingetroffen ist',
    invite: 'Einladen',
  },
  upgrade: {
    upgrade: 'Abonnieren',
    choosePlan: 'Wählen Sie Ihren Plan',
    redeemVoucher: 'Gutschein einlösen',
    voucher: 'Gutschein',
    typeVoucher: 'Geben Sie hier Ihren Gutschein ein',
    perMonth: 'monatlich',
    allPrices: 'Preise zzgl. 19% Umsatzsteuer',
    otherMethods: 'Bei Fragen zu anderen Zahlungsmethoden wenden Sie sich bitte an unseren Support',
    notAvailable: '-',
    onlyPdf: 'Nur auf PDF-Dokumenten',
    included: 'Inbegriffen',
    features: {
      companyLimit: 'Monatlich Erkennungen',
      content: 'Dynamic Content regeln',
      domains: 'Domänenlimit',
      history: 'Daten aufbewahrt für',
      price: 'Preis',
      spylink: 'Dynamic Link regeln',
      pdf: 'PDF-Dateien begrenzen',
      hubspot: 'Hubspot-Integration',
    },
    unlimited: 'Unbegrenzt',
    days: 'tage',
    plans: {
      free: 'Frei',
      basic: 'Basic',
      premium: 'Premium',
      enterprise: 'Enterprise',
      pdf: 'PDF',
    },
  },
  excluded: {
    excludedTitle: 'Deaktivieren Sie Video-Tracking',
    excludedDescription:
      'Hier können Sie die Aufnahme Video von Besucher Aktivitäten auf bestimmte Websites verhindern.',
    excludedSubPaths: 'Alle Unterseiten ausgeschlossen',
    excludeSubPaths: 'Schließen Sie diese und alle darunter liegenden Seiten aus',
    add: 'Ausgeschlossene Site hinzufügen',
    domain: 'Domain, zu der diese Seite gehört',
    url: 'URL-Adresse der Site',
    confirmRemove: 'URL entfernen',
    pleaseConfirm: 'Bitte bestätigen Sie das Entfernen der ausgewählten URL.',
  },
};
export default settings;

import React, { Fragment, ChangeEvent, FormEvent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import {
  Typography,
  TextField,
  IconButton,
  Box,
  Grid,
  Fab,
  Divider,
  Tooltip,
  WithStyles,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AppContext from '../../data/AppContext';
import { INotificationMail } from '../../models/app.model';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import SaveSnackbar from '../SaveSnackbar';
import { Theme } from '@material-ui/core/styles';
import { AlertDialog } from '../AlertDialog';

export const drawerWidth = 300;
const styles = (theme: Theme) => ({
  marginRight: {
    marginRight: theme.spacing(2),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(3, 5),
  },
  buttons: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2, 0),
    },
  },
});
export interface INotificationsState {
  emails: INotificationMail[];
  isSaving: boolean;
  removeDialog: boolean;
  emailToDelete: INotificationMail;
}
export interface NotificationProps extends WithStyles, WithTranslation {}

class Notifications extends React.Component<NotificationProps, INotificationsState> {
  static contextType = AppContext;
  constructor(props: NotificationProps) {
    super(props);
    this.state = {
      emails: [] as INotificationMail[],
      isSaving: false,
      removeDialog: false,
      emailToDelete: {} as INotificationMail,
    } as INotificationsState;
  }
  async componentDidMount() {
    const emails = await this.context.getData.userData.notificationMails;

    await this.setState({
      ...this.state,
      emails,
    });
  }

  handleChange = (e: React.ChangeEvent<any>, i: number) => {
    const emails = this.state.emails;
    if (!e.target.name) return;
    (emails[i] as any)[e.target.name as any] = e.target.value;
    this.setState({
      ...this.state,
      emails,
    });
  };
  newEmail = () => {
    this.setState({
      ...this.state,
      emails: [
        ...this.state.emails,
        {
          id: '',
          name: '',
          mail: '',
          notification_new: '1',
          notification_last_send: null,
        } as INotificationMail,
      ],
    });
  };
  deleteEmail = async (email: INotificationMail) => {
    await this.setState({
      ...this.state,
      emails: [...this.state.emails.filter((e: INotificationMail) => e !== email)],
    });
    await this.saveEmails();
  };
  saveEmails = async () => {
    await this.setState({ ...this.state, isSaving: true });
    await (this.context.setData = {
      userData: {
        ...this.context.getData.userData,
        notificationMails: this.state.emails.map((not: INotificationMail) => ({
          ...not,
          notification_new: +not.notification_new,
        })),
      },
    });
    await this.context.setUser();
    this.setState({ ...this.state, isSaving: false });
  };
  handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    this.saveEmails();
  };
  options = [
    { label: 'disabled', value: 0 },
    { label: 'everyDay', value: 1 },
    { label: 'everyWeek', value: 7 },
  ] as any[];
  render() {
    const { classes, t } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <Box p={5} display='flex' justifyContent='space-between' alignItems='center'>
          <Box mr={5}>
            <Typography variant='h4'>{t('notifications.title')}</Typography>
            <Typography variant='subtitle1' gutterBottom>
              {t('notifications.description')}
            </Typography>
          </Box>

          <Box className={classes.buttons}>
            <Tooltip title={t('notifications.addNew') as string}>
              <Fab color='secondary' onClick={this.newEmail} className={classes.marginRight}>
                <AddIcon />
              </Fab>
            </Tooltip>
            <Tooltip title={t('common:saveChanges') as string}>
              <Fab color='primary' type='submit'>
                <SaveIcon />
              </Fab>
            </Tooltip>
          </Box>
        </Box>
        {this.state.emails
          .map((mail: INotificationMail, i: number) => (
            <Fragment key={i}>
              <Divider />
              <Grid container key={i} spacing={2} component={Box} px={5} py={3}>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    name='name'
                    fullWidth
                    value={mail.name}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => this.handleChange(e, i)}
                    label={t('notifications.name')}
                    variant='outlined'
                    className={classes.marginRight}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    name='notification_new'
                    fullWidth
                    value={mail.notification_new || ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => this.handleChange(e, i)}
                    label={t('notifications.frequency')}
                    variant='outlined'
                    className={classes.marginRight}
                    required
                    select
                    SelectProps={{
                      native: true,
                    }}
                  >
                    {this.options.map((option: { label: string; value: number }, j: number) => {
                      return (
                        <option key={j} value={option.value}>
                          {t('notifications.' + option.label)}
                        </option>
                      );
                    })}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={12} md={5} component={Box} display='flex'>
                  <TextField
                    name='mail'
                    label={t('notifications.email')}
                    type='email'
                    required
                    fullWidth
                    value={mail.mail}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => this.handleChange(e, i)}
                    variant='outlined'
                    className={classes.marginRight}
                  />
                  <Box>
                    <Tooltip title={t('notifications.delete') as string}>
                      <IconButton
                        onClick={() =>
                          this.setState({
                            ...this.state,
                            emailToDelete: mail,
                            removeDialog: true,
                          })
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>
            </Fragment>
          ))
          .reverse()}
        {this.state.emails.length === 0 && <Box p={5}>{t('common:noData')}</Box>}

        <AlertDialog
          open={this.state.removeDialog}
          title={t('notifications.delete')}
          message={t('notifications.confirmMessage')}
          handleClose={() => this.setState({ ...this.state, removeDialog: false })}
          action={() => this.deleteEmail(this.state.emailToDelete)}
        />
        <SaveSnackbar open={this.state.isSaving} />
      </form>
    );
  }
}
export default withStyles(styles)(withTranslation(['settings', 'common'])(Notifications as any));

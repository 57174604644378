import { Dashboard } from '../langTemplate';

const dashboard: Dashboard = {
  welcome: 'Welcome to dashboard',
  explanation: 'To install primeleads on your site, pleas paste our script before closing body tag.',
  howToPaste: 'Where should my script go?',
  last5visits: 'Last 5 recognized companies in 10 days',
  lastWeek: 'Last 10 days statistics',
  top10Visitors: 'Top 10 visitors',
  visitsByDate: 'Visits by date',
  topVisitorsByType: 'Top visitors by branch',
  viewAll: 'View all',
  mostVisitedPages: 'Most visited pages',
  errorSubscribed: 'Something went wrong. Pleas contact our support.',
  newsletterButton: 'Sign up',
  newsletter: 'Newsletter',
  newsletterDescription: 'Sign up to our newsletter and get latest updates and exclusive offers.',
  successSubscribed: 'Thank you. You have successfully joined to our newsletter.',
  uniqueVisitor: 'You had <b>{{count}}</b> unique visitor.',
  uniqueVisitor_plural: 'You had <b>{{count}}</b> unique visitors.',
  seeAll: 'Please go to visitors and change filter to <b>all</b>, to see all of them.',
  onBoarding: {
    welcome: 'Welcome to Primeleads, thank you for joining us',
    subheader: 'Set up your first domain within a minute, and start using the app',
    setUpTitle: 'Domain setup',
    setUpDescription: 'Please provide a name that will be displayed in the list of all domains',
    installation: 'Script installation',
    installationDescription: 'Please copy the script and paste it into your page.',
    finish: "That's it!",
    finishDescription: 'Your first domain was added! You are ready to start using the app.',
    forExample: 'For example url address or company name',
    verification: 'Verification',
    verificationDescription:
      'If you would like to verify if the script is correctly installed, please follow these 2 simple steps:',
    step1: 'Open your website in browser.',
    step2: 'Then come back here, and press',
    verify: 'Verify script',
    success: 'Congratulation, script was successfully installed!',
    fail: 'Something went wrong. Script was not installed correctly or there were no visits yet.',
  },
  unternehmen: 'Companies',
  standorte: 'Locations',
  seitenaufrufe: 'Displays',
  wiederkehrer: 'Returns',
  kunden: 'Clients',
  favoriten: 'Favourites',
  page: 'Page',
  companies: 'Companies',
  visits: 'Visits',
  users: 'Users',
};
export default dashboard;

import React from 'react';
import styles from '../Visitors.style';
import AppContext from '../../../data/AppContext';
//COMPONENTS
import { withStyles, Box, Fade, Typography, Fab } from '@material-ui/core';
import TableBody from './VisitorsTableBody';
import { IVisitor, IComponentProps } from '../../../models/app.model';
import Skeleton from '@material-ui/lab/Skeleton';
import { withRouter, RouteComponentProps } from 'react-router';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Company from '../../company/Company';
export interface IDataTableProps extends IComponentProps, RouteComponentProps<any> {
  companies: IVisitor[];
  full?: boolean;
}
class DataTable extends React.Component<IDataTableProps> {
  static contextType = AppContext;
  render() {
    const { companies, full, classes } = this.props;
    const { fetching, selected } = this.context.getData;
    return (
      <>
        <Box display='flex' position='relative'>
          <Box
            overflow='hidden'
            style={{
              width: '100%'
            }}
          >
            <TableBody companies={companies} full={full} />
          </Box>
          {selected && companies.length > 0 && (
            <Fade in={full && !!selected} timeout={250}>
              <Box className={classes.details}>
                <Box className={classes.sticky}>
                  <Company
                    company={selected}
                    mini={true}
                    onClose={() => (this.context.setData = { selected: null })}
                  />
                </Box>
              </Box>
            </Fade>
          )}
        </Box>
        {fetching.visitors && (
          <Skeleton variant='rect' height={companies.length === 0 ? '90vh' : '20vh'} width="100%">
            <Box display='flex' justifyContent='center' pt={5}>
              <Typography variant='h6'>{this.props.t('fetchingMore')}</Typography>
            </Box>
          </Skeleton>
        )}
        {!fetching.visitors && companies.length === 0 && full && (
          <Box display='flex' flexDirection='column' p={5}>
            <Typography variant='body2'>{this.props.t('noData')}</Typography>
            <Box my={3}>
              <Fab variant='extended' color='primary' component={Link} to='/dynamic-content'>
                {this.props.t('createContent') as string}
              </Fab>
            </Box>
          </Box>
        )}
      </>
    );
  }
}

export default withStyles(styles)(withTranslation(['common', 'companies'])(withRouter(DataTable)));

import React, { SyntheticEvent } from 'react';
//CONTEXT
import AppContext from '../../data/AppContext';
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  TextField,
  IconButton,
  Theme,
  withStyles,
  Tooltip,
  Snackbar,
  CircularProgress,
} from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import Highlight from 'react-highlight';
import { withTranslation } from 'react-i18next';
import Alert from '@material-ui/lab/Alert';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { IComponentProps } from '../../models/app.model';
import { copyToClipboard, DevMode } from '../../helpers';
import '../../../node_modules/highlight.js/styles/agate.css';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(5),
      },
      '& pre code': {
        padding: theme.spacing(2),
        fontSize: theme.typography.body2.fontSize,
        whiteSpace: 'pre-wrap',
      },
    },
    content: {
      margin: theme.spacing(1, 0, 3),
    },
    title: {
      '& span': {
        color: theme.palette.secondary.main,
      },
    },
    stepper: {
      margin: theme.spacing(5, 0),
      padding: 0,
    },
    copy: {
      'marginLeft': 10,
      'backgroundColor': theme.palette.primary.main,
      'color': theme.palette.grey[200],
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.grey[100],
      },
    },
    script: {
      position: 'relative',
      margin: theme.spacing(2, 0),
      cursor: 'pointer',
    },
    input: {
      margin: theme.spacing(2, 0),
    },
  });

export interface OnboardingProps extends IComponentProps {}
export interface OnboardingState {
  activeStep: number;
  domain: string;
  key: string;
  script: string;
  showSnackbar: boolean;
  isInstalled?: boolean | undefined;
  verifying: boolean;
}

class Onboarding extends React.Component<OnboardingProps, OnboardingState> {
  static contextType = AppContext;
  private steps: any[];
  private mounted: boolean;
  constructor(props: OnboardingProps) {
    super(props);
    this.state = {
      activeStep: 0,
      domain: '',
      key: '',
      script: '',
      showSnackbar: false,
      verifying: false,
    };
    this.steps = [
      {
        text: props.t('onBoarding.setUpTitle'),
        instruction: () => (
          <>
            {props.t('onBoarding.setUpDescription')}
            <TextField
              variant='outlined'
              className={props.classes.input}
              placeholder={props.t('onBoarding.forExample')}
              fullWidth
              value={this.state.domain}
              onChange={(e) => this.setState({ ...this.state, domain: e.target.value })}
            />
          </>
        ),
      },
      {
        text: props.t('onBoarding.installation'),
        instruction: () => (
          <>
            <Typography variant='body2'>{props.t('onBoarding.installationDescription')}</Typography>
            <Tooltip title={props.t('common:copyToClipboard') as string}>
              <Box
                className={props.classes.script}
                onClick={() => {
                  copyToClipboard(this.state.script);
                  this.setState({ ...this.state, showSnackbar: true });
                }}
                display='flex'
                alignItems='center'
              >
                <Box flexGrow='1'>
                  <Highlight className='html'>{this.state.script}</Highlight>
                </Box>

                <IconButton
                  className={props.classes.copy}
                  onClick={(e: SyntheticEvent) => {
                    e.stopPropagation();
                    copyToClipboard(this.state.script);
                    this.setState({ ...this.state, showSnackbar: true });
                  }}
                >
                  <FileCopyIcon />
                </IconButton>
              </Box>
            </Tooltip>
            <Box>
              <Typography variant='body2'>{props.t('howToPaste')}</Typography>
              <Highlight className='html'>{this.getExample(this.state.script)}</Highlight>
            </Box>
            <DevMode onlyAdmin={true}>
              <Box mt={5}>
                <Typography variant='h6'>
                  {this.props.t('onBoarding.verification')} ({this.props.t('common:optional')})
                </Typography>
                <Typography variant='body2'>
                  {this.props.t('onBoarding.verificationDescription')}
                </Typography>
                <ol style={{ margin: '10px 0', padding: 0, listStylePosition: 'inside' }}>
                  <li>{this.props.t('onBoarding.step1')}</li>
                  <li>
                    {this.props.t('onBoarding.step2')}
                    <Button
                      size='small'
                      variant='outlined'
                      style={{ margin: '0 10px' }}
                      onClick={this.verifyScript}
                    >
                      {this.props.t('onBoarding.verify')}
                    </Button>
                    {this.state.verifying && (
                      <CircularProgress style={{ verticalAlign: 'middle' }} size={20} thickness={5} />
                    )}
                  </li>
                </ol>
              </Box>
              <Box display='flex' mt={2} alignItems='center'>
                {!this.state.verifying && (
                  <>
                    {this.state.isInstalled === true && (
                      <Alert severity='success'>{this.props.t('onBoarding.success')}</Alert>
                    )}
                    {this.state.isInstalled === false && (
                      <Alert severity='error'>{this.props.t('onBoarding.fail')}</Alert>
                    )}
                  </>
                )}
              </Box>
            </DevMode>
          </>
        ),
      },
      {
        text: props.t('onBoarding.finish'),
        instruction: () => (
          <>
            <Typography>{props.t('onBoarding.finishDescription')}</Typography>
          </>
        ),
      },
    ];
    this.mounted = false;
  }
  getExample = (script: string) => {
    const { t } = this.props;
    return `<html>
    <head>
      <!-- HEAD SECTION -->
    </head>
    <body>
      <!-- BODY SECTION -->
      <!-- ${t('explanation')} -->
      ${script}
    </body>
  </html>`;
  };
  verifyScript = async () => {
    await this.setState({ ...this.state, verifying: true });
    const result = await this.context.verifyScript(this.state.key);
    console.log(result, this.state.key);
    this.setState({ ...this.state, isInstalled: result, verifying: false });
  };

  async componentDidMount() {
    this.mounted = true;
    const newDomain = await this.context.createDomain();
    this.setState({
      ...this.state,
      key: newDomain.key,
      script: newDomain.script,
    });
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  saveDomain = async () => {
    await this.setState({ ...this.state, verifying: true });
    await (this.context.setData = {
      domains: {
        key: this.state.key,
        name: this.state.domain,
      },
    });
    await this.context.setDomains();
    await this.setState({ ...this.state, verifying: false });
  };
  handleNext = async (steps: any[]) => {
    if (this.state.activeStep === 1) {
      await this.saveDomain();
    }
    if (this.state.activeStep === steps.length - 1) {
      this.reloadPage();
    }
    if (this.mounted) {
      this.setState({
        ...this.state,
        activeStep: this.state.activeStep + 1,
      });
    }
  };
  handleBack = () => {
    if (this.state.activeStep > 0) {
      if (this.mounted) {
        this.setState({
          ...this.state,
          activeStep: this.state.activeStep - 1,
        });
      }
    }
  };
  isValidName = () => {
    return this.state.domain.length > 3;
  };
  reloadPage = async () => {
    window.location.reload();
  };
  render() {
    const { t, classes } = this.props;
    return (
      <>
        <Box p={10} className={classes.root}>
          <Typography variant='h4' gutterBottom className={classes.title}>
            {t('onBoarding.welcome')}
          </Typography>
          <Typography variant='subtitle1'>{t('onBoarding.subheader')}</Typography>
          <Stepper activeStep={this.state.activeStep} orientation='vertical' className={classes.stepper}>
            {this.steps.map((step, i) => (
              <Step key={i}>
                <StepLabel>
                  <Typography variant='h6' style={{ verticalAlign: 'middle' }}>
                    {step.text}
                    {i === 0 && this.state.activeStep === 0 && this.state.verifying && (
                      <CircularProgress style={{ marginLeft: 10 }} size={20} thickness={5} />
                    )}
                  </Typography>
                </StepLabel>
                <StepContent>
                  <Box className={classes.content}>{step.instruction()}</Box>
                  <Box mt={5}>
                    {this.state.activeStep > 0 && (
                      <Button
                        style={{ marginRight: 10 }}
                        variant='contained'
                        color='default'
                        onClick={() => this.handleBack()}
                      >
                        {t('common:back')}
                      </Button>
                    )}
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => this.handleNext(this.steps)}
                      disabled={!this.isValidName()}
                    >
                      {this.state.activeStep === this.steps.length - 1
                        ? t('common:finish')
                        : t('common:next')}
                    </Button>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
          <Snackbar
            open={this.state.showSnackbar}
            autoHideDuration={3000}
            onClose={() => {
              if (this.mounted) {
                this.setState({ ...this.state, showSnackbar: false });
              }
            }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert variant='filled' severity='info'>
              {t('common:scriptCopied')}
            </Alert>
          </Snackbar>
        </Box>
      </>
    );
  }
}

export default withStyles(styles)(withTranslation(['dashboard', 'common'])(Onboarding));

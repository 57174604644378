import React from 'react';
import AppContext from '../../../data/AppContext';
import { IbanElement, CardNumberElement } from '@stripe/react-stripe-js';
import Stripe from '@stripe/stripe-js';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Grid,
  Button,
  Typography,
  CircularProgress,
  Box,
  withStyles,
  createStyles,
  Theme,
  makeStyles,
} from '@material-ui/core';
import {
  StripeTextFieldNumber,
  StripeTextFieldExpiry,
  StripeTextFieldCvc,
  StripeTextFieldBankNumber,
} from './CommonTextFields';
import { Alert } from '@material-ui/lab';
import { IComponentProps } from '../../../models/app.model';
import { withTranslation, useTranslation } from 'react-i18next';

const styles = createStyles((theme: Theme) => ({
  row: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(1, 0),
  },
  button: {
    marginRight: 10,
  },
}));

interface CheckoutProps extends IComponentProps {
  stripe: Stripe.Stripe;
  elements: Stripe.StripeElements | null;
  plan: string;
  onCancel: () => any;
}
interface PlanDetails {
  name: string;
  price: number;
  tax: number;
  taxName: string;
  total: number;
  currency: string;
}
interface CheckoutState {
  details: {
    firstName: string;
    lastName: string;
    email: string;
    planDetails: PlanDetails;
  };
  paymentShort: 'card' | 'iban';
  paymentIntent: Stripe.PaymentIntent;
  paymentMethod: Stripe.PaymentMethod;
  paymentRequest: Stripe.PaymentRequest;
  infoMessage: string;
  errorMessage: string;
  success: boolean;
  loader: boolean;
}

class Checkout extends React.PureComponent<CheckoutProps, CheckoutState> {
  static contextType = AppContext;
  state = {
    paymentShort: 'iban',
    details: {
      firstName: this.context.getData.userData.firstName || '',
      lastName: this.context.getData.userData.lastName || '',
      email: this.context.getData.userData.emails[0].value || '',
      planDetails: {
        name: '',
        price: 0,
        taxName: '',
        tax: 0,
        total: 0,
        currency: '',
      },
    },
    errorMessage: '',
    success: false,
    loader: false,
  } as CheckoutState;

  componentDidMount = async () => {
    // GET PLAN INFO WITH TAXES
    this.getPlanDetails(this.props.plan);
  };
  getPaymentIntent = async (id: string) => {
    return await this.context.getPaymentIntent(id);
  };
  getPlanDetails = async (planId: string) => {
    console.log(planId);
    const planDetails = await this.context.getPlanDetails(planId);
    this.setState({
      ...this.state,
      details: { ...this.state.details, planDetails },
    });
    /* const pr = this.props.stripe.paymentRequest({
      country: 'DE',
      currency: 'eur',
      total: {
        label: 'Demo total',
        amount: 1099,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });
    console.log('PR', pr);
    pr.canMakePayment().then((result) => {
      if (result) {
        console.log('RESULT', result);
        this.setState({
          ...this.state,
          paymentRequest: pr,
          details: { ...this.state.details, planDetails },
        });
      }
    }); */
  };
  getOrderSummary = () => {
    const { classes, t } = this.props;
    const { details } = this.state;
    const { planDetails } = details;
    const {price, total, name } = planDetails;
    const tax = planDetails.price * (planDetails.tax / 100);
    const currency = planDetails.currency.toLocaleUpperCase();
    return (
      <Box>
        <Box pb={2}>
          <Typography variant='h4'> {this.props.t('summary')}</Typography>
        </Box>

        <Box display='flex' justifyContent='space-between' alignItems='center' className={classes.row}>
          <Typography variant='h6'>{name.toLocaleLowerCase().includes("custom") ? name : t('planNames.' + name)}</Typography>
          <Typography variant='h6'>
            {`${price.toFixed(2)} ${currency}`}
          </Typography>
        </Box>
        <Box display='flex' justifyContent='space-between' alignItems='center' className={classes.row}>
          <Typography variant='h6'>{`${t('tax')} (${planDetails.tax}%)`}</Typography>
          <Typography variant='h6'>{`${tax.toFixed(2)} ${currency}`}</Typography>
        </Box>
        <Box display='flex' justifyContent='space-between' alignItems='center' className={classes.row}>
          <Typography variant='h6'> {this.props.t('total')}</Typography>
          <Typography variant='h6'>
            {`${total.toFixed(2)} ${currency}`}
          </Typography>
        </Box>
      </Box>
    );
  };
  handleSubmit = (ev: React.FormEvent) => {
    const { stripe, elements } = this.props;
    ev.preventDefault();
    this.setState({ ...this.state, loader: true });
    if (!stripe || !elements) {
      return;
    }
    switch (this.state.paymentShort) {
      case 'card':
        return this.payWithCard(elements);
      case 'iban':
        return this.payWithSepa(elements);
    }
  };
  payWithCard = async (elements: Stripe.StripeElements) => {
    try {
      // GET CARD ELEMENT
      const cardElement = elements.getElement(CardNumberElement);

      // METHOD
      const method: {
        paymentMethod?: Stripe.PaymentMethod | undefined;
        error?: Stripe.StripeError | undefined;
      } = await this.props.stripe.createPaymentMethod({
        card: cardElement,
        type: 'card',
        billing_details: {
          name: this.state.details.firstName,
          email: this.state.details.email,
        },
      } as Stripe.CreatePaymentMethodData);
      if (method.error) {
        return this.handleError(method.error);
      }
      // GET SECRET
      const intent: Stripe.PaymentIntent = await this.context.addPaymentMethod(
        this.props.plan,
        method.paymentMethod?.id
      );
      this.handleResult(intent);
    } catch (error:any) {
      this.handleError(error);
    }
  };
  payWithSepa = async (elements: Stripe.StripeElements) => {
    try {
      // GET IBAN ELEMENT
      const MyIbanElement = elements.getElement(IbanElement);
      const method: {
        paymentMethod?: Stripe.PaymentMethod | undefined;
        error?: Stripe.StripeError | undefined;
      } = await this.props.stripe.createPaymentMethod({
        type: 'sepa_debit',
        sepa_debit: MyIbanElement,
        billing_details: {
          name: this.state.details.firstName,
          email: this.state.details.email,
        },
      } as Stripe.CreatePaymentMethodData);
      if (method.error) {
        return this.handleError(method.error);
      }
      // GET CLIENT SECRET
      const intent: Stripe.PaymentIntent = await this.context.addPaymentMethod(
        this.props.plan,
        method.paymentMethod?.id
      );
      this.handleResult(intent);
    } catch (error:any) {
      this.handleError(error);
    }
  };
  handleError = (error: Stripe.StripeError | Error) => {
    console.log('ERROR WAS CATCHED', error, error.message);
    this.setState({ ...this.state, loader: false, errorMessage: error.message || 'error' });
  };
  handleSuccess = (paymentIntent: Stripe.PaymentIntent) => {
    this.setState({
      ...this.state,
      success: true,
      paymentIntent,
      errorMessage: '',
    });
  };
  handleRequiresAction = (paymentIntent: Stripe.PaymentIntent) => {
    this.setState({ ...this.state, loader: false, infoMessage: paymentIntent.status });
  };
  handleResult = async (paymentIntent: Stripe.PaymentIntent) => {
    console.log(paymentIntent);
    if ((paymentIntent as Stripe.PaymentIntent).status === 'succeeded') {
      return this.handleSuccess(paymentIntent as Stripe.PaymentIntent);
    }
    if ((paymentIntent as Stripe.PaymentIntent).status === 'canceled') {
      return this.handleError(new Error(paymentIntent.cancellation_reason as string));
    }
    if ((paymentIntent as Stripe.PaymentIntent).status === 'requires_payment_method') {
      return this.handleError(new Error('Requires Payment method'));
    }
    if ((paymentIntent as Stripe.PaymentIntent).status === 'requires_action') {
      return this.handleRequiresAction(paymentIntent as Stripe.PaymentIntent);
    }
    if ((paymentIntent as Stripe.PaymentIntent).status === 'requires_confirmation') {
      return this.handleRequiresAction(paymentIntent as Stripe.PaymentIntent);
    }
    if ((paymentIntent as Stripe.PaymentIntent).status === 'processing') {
      return this.checkForSuccess((paymentIntent as Stripe.PaymentIntent).id, 5000);
    }
  };
  checkForSuccess = (id: string, timeout: number) => {
    setTimeout(async () => {
      const intent = await this.getPaymentIntent(id);
      this.handleResult(intent);
    }, timeout);
  };
  handleMethodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      errorMessage: '',
      paymentShort: e.target.value,
    } as CheckoutState);
  };
  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      details: {
        ...this.state.details,
        [e.target.name]: e.target.value,
      },
    } as CheckoutState);
  };
  getPaymentForm = () => {
    switch (this.state.paymentShort) {
      case 'card':
        return (
          <>
            <Grid item xs={12}>
              <Typography variant='h6'>{this.props.t('creditCard')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <StripeTextFieldNumber required variant='outlined' />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StripeTextFieldExpiry required variant='outlined' />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StripeTextFieldCvc required variant='outlined' />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='caption' color='textSecondary'>
                {this.props.t('cardRules')}
              </Typography>
            </Grid>
          </>
        );
      case 'iban':
        return (
          <>
            <Grid item xs={12}>
              <Typography variant='h6'> {this.props.t('bankDetails')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <StripeTextFieldBankNumber required variant='outlined' />
            </Grid>
            <Grid item xs={12}>
              <div id='mandate-acceptance'>{this.props.t('sepaRules')}</div>
              {/* <Typography variant='caption' color='textSecondary'>
                
              </Typography> */}
            </Grid>
          </>
        );
    }
  };
  getCheckoutForm = () => {
    return (
      <>
        <Grid item xs={12}>
          <Box mb={2}>
            <RadioGroup value={this.state.paymentShort} onChange={this.handleMethodChange} row>
              <FormControlLabel
                value='card'
                control={<Radio />}
                label={
                  <Box display='flex' alignItems='center'>
                    <CreditCardIcon color='primary' style={{ marginRight: 10 }} />
                    <Typography variant='h6'> {this.props.t('creditCard')}</Typography>
                  </Box>
                }
              />
              <FormControlLabel
                value='iban'
                control={<Radio />}
                label={
                  <Box display='flex' alignItems='center'>
                    <AccountBalanceIcon color='primary' style={{ marginRight: 10 }} />
                    <Typography variant='h6'>{this.props.t('sepaDebit')}</Typography>
                  </Box>
                }
              />
            </RadioGroup>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6'>{this.props.t('personalDetails')}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label='First name'
            value={this.state.details.firstName}
            variant='outlined'
            fullWidth
            name='firstName'
            onChange={this.handleInputChange}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label='Last name'
            value={this.state.details.lastName}
            variant='outlined'
            name='lastName'
            fullWidth
            onChange={this.handleInputChange}
            required
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label='Email'
            value={this.state.details.email}
            variant='outlined'
            name='email'
            fullWidth
            onChange={this.handleInputChange}
          />
        </Grid>

        {this.getPaymentForm()}
        {this.state.infoMessage && (
          <Grid item xs={12}>
            <Alert severity='info'>
              <Typography variant='body2'>{this.state.infoMessage}</Typography>
            </Alert>
          </Grid>
        )}
        {this.state.errorMessage && (
          <Grid item xs={12}>
            <Alert severity='error'>
              <Typography variant='body2'>{this.state.errorMessage}</Typography>
            </Alert>
          </Grid>
        )}
      </>
    );
  };
  redirectHome = () => {
    window.location.href = '/';
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {this.getOrderSummary()}
          </Grid>

          {!this.state.success && this.getCheckoutForm()}
          <Grid item xs={12}>
            {/* {this.state.paymentRequest && (
              <PaymentRequestButtonElement options={{ paymentRequest: this.state.paymentRequest }} />
            )} */}
            {!this.state.success ? (
              <Buttons onCancel={this.props.onCancel} showOn={this.state.loader} />
            ) : (
              <Button
                classes={{ root: this.props.classes.button }}
                variant='contained'
                color='primary'
                onClick={this.redirectHome}
              >
                {this.props.t('goBack')}
              </Button>
            )}
          </Grid>
          {this.state.success && (
            <Grid item xs={12}>
              <Alert severity='success'>
                <Typography variant='body2'>
                  {this.props.t('paymentSuccessful')} / {this.state.paymentIntent.id}
                </Typography>
              </Alert>
            </Grid>
          )}
        </Grid>
      </form>
    );
  }
}

export default withStyles(styles)(withTranslation(['settings'])(Checkout));

const Buttons = (props: any) => {
  const { onCancel, showOn } = props;
  const [loader, setLoader] = React.useState(false);
  const { t, i18n } = useTranslation(['settings']);
  const classes = makeStyles(styles)() as any;
  React.useEffect(() => {
    if (showOn) {
      setLoader(true);
    }
    if (!showOn) {
      setLoader(false);
    }
  }, [showOn]);
  return (
    <>
      <Button classes={{ root: classes.button }} variant='contained' onClick={onCancel}>
        {t('cancel')}
      </Button>
      <Button classes={{ root: classes.button }} variant='contained' color='primary' type='submit'>
        {t('confirmSubscription')}
      </Button>
      {loader && (
        <Box display='flex' alignItems='center' my={2}>
          <CircularProgress size={20} thickness={5} style={{ marginRight: 10 }} />
          <Typography variant='body2'>{t('paymentInProgress')}</Typography>
        </Box>
      )}
    </>
  );
};

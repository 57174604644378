const convertedVapidKey = urlBase64ToUint8Array(process.env.REACT_APP_PUBLIC_VAPID_KEY || '');

function urlBase64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  // eslint-disable-next-line
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function sendSubscription(subscription: any) {
  const isLocalhost =
    window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
  fetch(
    `${
      isLocalhost ? 'http://localhost:9090' : process.env.REACT_APP_API_URL
    }/app-api/v2/user/subscription`,
    {
      method: 'POST',
      body: JSON.stringify(subscription),
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
}

export function subscribeUser() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(function(registration) {
        if (!registration.pushManager) {
          return;
        }

        registration.pushManager.getSubscription().then(function(existedSubscription) {
          if (existedSubscription === null) {
            registration.pushManager
              .subscribe({
                applicationServerKey: convertedVapidKey,
                userVisibleOnly: true
              })
              .then(function(newSubscription) {
                sendSubscription(newSubscription);
              })
              .catch(function(e) {
                if (Notification.permission !== 'granted') {
                  console.log('Permission was not granted.');
                } else {
                  console.error('An error ocurred during the subscription process.', e);
                }
              });
          } else {
            sendSubscription(existedSubscription);
          }
        });
      })
      .catch(function(e) {
        console.error('An error ocurred during Service Worker registration.', e);
      });
  }
}

import React, { SyntheticEvent, FormEvent, ChangeEvent, RefObject } from 'react';
import { withStyles, createStyles } from '@material-ui/styles';
import {
  Box,
  TextField,
  IconButton,
  Tooltip,
  Typography,
  Fab,
  ButtonGroup,
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Theme
} from '@material-ui/core';
import AppContext from '../../data/AppContext';
import { DynamicType, CompanyStatusType, DynamicContentInterface } from './Dynamic';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AddIcon from '@material-ui/icons/Add';
import { IComponentProps } from '../../models/app.model';
import { withTranslation } from 'react-i18next';
import { ISpyLink } from '../spylink/Spylink';
import { Alert } from '@material-ui/lab';

const styles = (theme: Theme) => createStyles({});

export interface DynamicRulesProps extends IComponentProps {
  addRule: (key: string) => any;
  updateRule: (key: string, value?: any, i?: number) => any;
  removeRule: (key: string, i: number) => any;
  selected: DynamicContentInterface;
}
export interface DynamicRulesState {
  optionIndex: number;
  optionsOpen: boolean;
}

class DynamicRules extends React.Component<DynamicRulesProps, DynamicRulesState> {
  static contextType = AppContext;
  constructor(props: DynamicRulesProps) {
    super(props);
    this.state = {
      optionIndex: 0,
      optionsOpen: false
    };
  }

  private anchorRef: RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
  private options = ['companyName', 'location', 'branch', 'status', 'spylink'];
  dynamicTypes = [
    { text: 'dynamic-message', value: DynamicType.Message },
    { text: 'dynamic-text', value: DynamicType.Text }
  ];
  CompanyNoteTypes = [
    { label: 'isCustomer', value: CompanyStatusType.isCustomer },
    { label: 'competitor', value: CompanyStatusType.competitor },
    { label: 'isFavorite', value: CompanyStatusType.isFavorite },
    { label: 'notInteresting', value: CompanyStatusType.notInteresting }
  ];
  handleButtonToggle = async () => {
    await this.setState({ ...this.state, optionsOpen: !this.state.optionsOpen });
  };
  handleButtonClick = async (e: SyntheticEvent, i: number) => {
    await this.setState({ ...this.state, optionIndex: i });
    this.handleButtonToggle();
    this.props.addRule(this.options[this.state.optionIndex]);
  };
  handleButtonClose = () => {
    this.setState({ ...this.state, optionsOpen: false });
  };

  getSplitButton = () => {
    const { t } = this.props;
    return (
      <>
        <ButtonGroup variant='contained' color='primary' ref={this.anchorRef}>
          <Button onClick={() => this.handleButtonToggle()}>
            {t('labels.' + this.options[this.state.optionIndex])}
            <ArrowDropDownIcon />
          </Button>
          <Button
            color='primary'
            size='small'
            onClick={() => this.props.addRule(this.options[this.state.optionIndex])}
          >
            <AddIcon />
          </Button>
        </ButtonGroup>
        <Popper
          open={this.state.optionsOpen}
          anchorEl={this.anchorRef.current}
          role={undefined}
          transition
          style={{ zIndex: 10 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleButtonClose}>
                  <MenuList id='split-button-menu'>
                    {this.options.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={index === this.state.optionIndex}
                        onClick={(event) => this.handleButtonClick(event, index)}
                      >
                        {t('labels.' + option)}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  };
  getRules = (selected: DynamicContentInterface) => {
    const { t } = this.props;
    return (
      <>
        <Box mb={5}>
          <Box mb={2}>
            <Typography variant='h4'>{t('multipleRules')}</Typography>
            <Typography variant='body2' gutterBottom>
              {t('multipleDescription')}
            </Typography>
          </Box>

          {this.getSplitButton()}
        </Box>
        {selected.rules && selected.rules.companyName && selected.rules.companyName.length > 0 && (
          <>
            <Typography variant='h6'>{t('labels.companyName')}</Typography>
            {selected.rules.companyName.map((rule: { active: boolean; value: string }, i: number) => (
              <Box display='flex' alignItems='center' my={2} key={i}>
                <TextField
                  label={t('labels.companyName')}
                  variant='outlined'
                  value={rule.value}
                  fullWidth
                  name='companyName'
                  style={{ marginRight: 10 }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    this.props.updateRule('companyName', e.target.value, i)
                  }
                  required
                />
                <Tooltip title={t('deleteRule') as string}>
                  <IconButton onClick={() => this.props.removeRule('companyName', i)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            ))}
          </>
        )}
        {selected.rules && selected.rules.branch && selected.rules.branch.length > 0 && (
          <>
            <Typography variant='h6'>{t('labels.branch')}</Typography>
            {selected.rules.branch.map((rule: { active: boolean; value: string }, i: number) => (
              <Box display='flex' alignItems='center' my={2} key={i}>
                <TextField
                  select
                  label={t('labels.branch')}
                  variant='outlined'
                  value={rule.value}
                  fullWidth
                  name='branch'
                  style={{ marginRight: 10 }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    this.props.updateRule('branch', e.target.value, i)
                  }
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{
                    native: true
                  }}
                  required
                >
                  <option value=''>{this.props.t('common:pleaseChoose')}</option>
                  {this.context.getData.companyTypes.map((type: string, i: number) => (
                    <option key={i} value={type}>
                      {type}
                    </option>
                  ))}
                </TextField>
                <IconButton onClick={() => this.props.removeRule('branch', i)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
          </>
        )}
        {selected.rules && selected.rules.spylink && selected.rules.spylink.length > 0 && (
          <>
            <Typography variant='h6'>Spylink</Typography>
            {selected.rules.spylink.map((rule: { active: boolean; value: string }, i: number) => (
              <Box display='flex' alignItems='center' my={2} key={i}>
                <TextField
                  select
                  label={this.props.t('labels.spylink')}
                  variant='outlined'
                  value={rule.value}
                  fullWidth
                  name='spylink'
                  style={{ marginRight: 10 }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    this.props.updateRule('spylink', e.target.value, i)
                  }
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{
                    native: true
                  }}
                  required
                >
                  <option value=''>{this.props.t('common:pleaseChoose')}</option>
                  {this.context.getData.spylinks.map((spylink: ISpyLink, i: number) => {
                    return (
                      <option key={i} value={spylink.linkId}>
                        {spylink.name} / {spylink.linkId}
                      </option>
                    );
                  })}
                </TextField>
                <IconButton onClick={() => this.props.removeRule('spylink', i)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
          </>
        )}
        {selected.rules && selected.rules.location && selected.rules.location.length > 0 && (
          <>
            <Typography variant='h6'>{t('labels.location')}</Typography>
            {selected.rules.location.map(
              (rule: { active: boolean; value: { city: string; country: string } }, i: number) => (
                <Box display='flex' alignItems='center' my={2} key={i}>
                  <TextField
                    label={this.props.t('labels.city')}
                    variant='outlined'
                    value={rule.value.city}
                    fullWidth
                    name='location'
                    style={{ marginRight: 10 }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      this.props.updateRule('location', { ...rule.value, city: e.target.value }, i)
                    }
                    required
                  />
                  {/* <TextField
                    label={this.props.t('location.country')}
                    variant='outlined'
                    value={rule.value.country}
                    fullWidth
                    name='location'
                    style={{ marginRight: 10 }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      this.props.updateRule('location', { ...rule.value, country: e.target.value }, i)
                    }
                  /> */}
                  <IconButton onClick={() => this.props.removeRule('location', i)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )
            )}
          </>
        )}
        {selected.rules && selected.rules.status && selected.rules.status.length > 0 && (
          <>
            <Typography variant='h6'>{t('labels.status')}</Typography>
            {selected.rules.status.map((rule: { active: boolean; value: string }, i: number) => (
              <Box display='flex' alignItems='center' my={2} key={i}>
                <TextField
                  select
                  label={t('labels.status')}
                  variant='outlined'
                  value={rule.value}
                  fullWidth
                  name='status'
                  style={{ marginRight: 10 }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    this.props.updateRule('status', e.target.value, i)
                  }
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{
                    native: true
                  }}
                  required
                >
                  <option value=''>{this.props.t('common:pleaseChoose')}</option>
                  {this.CompanyNoteTypes.map((type: any, i: number) => (
                    <option key={i} value={type.value}>
                      {this.props.t('labels.' + type.label)}
                    </option>
                  ))}
                </TextField>
                <IconButton onClick={() => this.props.removeRule('status', i)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
          </>
        )}
      </>
    );
  };
  render() {
    return <Box p={5}>{this.getRules(this.props.selected)}</Box>;
  }
}

export default withStyles(styles)(withTranslation(['dynamicContent', 'common'])(DynamicRules));

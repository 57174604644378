import React from 'react';
import {
  Typography,
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  Fab,
  Theme,
  createStyles,
  withStyles,
  Link as HLink,
  CircularProgress,
} from '@material-ui/core';
import logo from '../../assets/images/logo2.png';
import auth0logo from '../../assets/images/auth0.svg';
import { IComponentProps } from '../../models/app.model';
import { withTranslation } from 'react-i18next';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import LanguageSelect from '../LanguageSelect';
const styles = createStyles((theme: Theme) => ({
  wrapper: {
    'display': 'flex',
    'margin': '0 auto',
    'padding': theme.spacing(0, 2),
    'minHeight': '100vh',
    'maxWidth': '400px',
    'flexWrap': 'wrap',
    'justifyContent': 'center',
    'alignItems': 'flex-start',
    'alignContent': 'flex-start',
    '& form, & > div': {
      width: '100%',
    },
  },
  box: {
    padding: theme.spacing(2, 10),
  },
  bottomBox: {
    'position': 'relative',
    'borderTop': `1px solid ${theme.palette.grey[200]}`,
    '&::before': {
      content: '""',
      width: theme.spacing(5),
      height: 1,
      backgroundColor: theme.palette.primary.main,
      position: 'absolute',
      top: -1,
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  cursor: {
    cursor: 'pointer',
  },
  logo: {
    'flexBasis': '100%',
    'margin': theme.spacing(3, 0),
    '& img': { width: '10rem', margin: '0 auto', display: 'block' },
  },
  auth0logo: {
    width: '1.5rem',
    marginRight: theme.spacing(1),
  },
}));

interface SignupProps extends IComponentProps, RouteComponentProps {}
interface SignupState {
  data: {
    email: string;
    password: string;
    givenName: string;
    familyName: string;
    fullName: string;
    company: string;
    newsletter: boolean;
  };
  submited: boolean;
  errors: string[];
}
class SignUpForm extends React.Component<SignupProps, SignupState> {
  state = {
    data: {
      email: '',
      password: '',
      givenName: '',
      familyName: '',
      fullName: '',
      company: '',
      newsletter: false,
    },
    submited: false,
    errors: [],
  } as SignupState;
  componentDidMount() {
    const af = localStorage.getItem('af');
    const query = new URLSearchParams(this.props.location.search).get('af');
    if (!af && query) {
      localStorage.setItem('af', query);
    }
  }
  handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const { fullName } = this.state.data;
    await this.setState({
      ...this.state,
      submited: true,
      errors: [],
      data: {
        ...this.state.data,
        givenName: fullName.split(' ')[0],
        familyName: fullName.split(' ')[1] || '',
      },
    });
    try {
      const af = localStorage.getItem('af');
      const response = await fetch('/app-api/v2/signup', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({ ...this.state.data, af }),
      });
      console.log(response);
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error);
      }
      window.location.reload();
    } catch (error:any) {
      this.setState({
        ...this.state,
        errors: [...this.state.errors, error.message],
        submited: false,
      });
    }
  };
  handleChange = (e: React.ChangeEvent<HTMLInputElement>, checked?: boolean) => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        [e.target.name]: checked ? e.target.checked : e.target.value,
      },
      errors: [],
    });
  };
  render() {
    const { classes, t } = this.props;
    console.log(this.state);
    return (
      <Box className={classes.wrapper}>
        <Box className={classes.logo}>
          <img src={logo} alt='logo' />
        </Box>
        <form onSubmit={this.handleSubmit}>
          <Box display='flex' justifyContent='space-between' alignItems='center' mb={3}>
            <Typography variant='h4'>{t('signUp')}</Typography>
            <LanguageSelect />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              variant='outlined'
              label={t('register.fullName')}
              name='fullName'
              required
              value={this.state.data.fullName}
              onChange={this.handleChange}
              inputProps={{
                pattern: "[A-Za-z-'\\s]{1,30}",
              }}
            />
          </Box>

          <Box mb={2}>
            <TextField
              fullWidth
              variant='outlined'
              label={t('register.companyName')}
              name='company'
              value={this.state.data.company}
              onChange={this.handleChange}
            />
          </Box>

          <Box mb={2}>
            <TextField
              fullWidth
              variant='outlined'
              label={t('email')}
              name='email'
              type='email'
              required
              value={this.state.data.email}
              onChange={this.handleChange}
            />
          </Box>

          <Box>
            <TextField
              fullWidth
              variant='outlined'
              label={t('password')}
              name='password'
              type='password'
              required
              value={this.state.data.password}
              onChange={this.handleChange}
              helperText={
                <Typography variant='caption'>{t('register.minimum', { chars: 8 })}</Typography>
              }
            />
          </Box>
          <Box my={2}>
            <FormControlLabel
              control={
                <Checkbox
                  name='newsletter'
                  checked={this.state.data.newsletter}
                  onChange={(e) => this.handleChange(e, true)}
                />
              }
              label={<Typography variant='body2'>{t('register.newsletter')}</Typography>}
            />
          </Box>
          <Box my={3}>
            <Typography
              variant='caption'
              color='textSecondary'
              dangerouslySetInnerHTML={{
                __html: t('register.rules', {
                  datenschutz: 'https://www.primeleads.de/datenschutz',
                  nutzungsbedingungen: 'https://www.primeleads.de/nutzungsbedingungen',
                }),
              }}
            />
          </Box>

          {this.state.errors.length > 0 && (
            <Box my={2}>
              <Alert severity='error'>
                {this.state.errors.map((err, i) => (
                  <Typography variant='body2' key={i}>
                    {t('errors.' + err)}
                  </Typography>
                ))}
              </Alert>
            </Box>
          )}

          <Box display='flex' alignItems='center' justifyContent='center'>
            <Fab type='submit' variant='extended' color='primary' size='small'>
              {t('signUp') as string}
            </Fab>
            {this.state.submited && (
              <CircularProgress size={20} thickness={5} style={{ marginLeft: 10 }} />
            )}
          </Box>
        </form>
        <Box my={3} py={3} borderTop={1} className={classes.bottomBox} textAlign='center'>
          <Typography variant='h5' gutterBottom>
            {t('register.alreadyGot')}
          </Typography>
          <HLink component={Link} to='/login'>
            <Typography variant='body2'>{t('signIn')}</Typography>
          </HLink>
        </Box>
        <Box display='flex' alignItems='center' justifyContent='center' mb={4}>
          <img src={auth0logo} className={classes.auth0logo} alt="Auth0 logo" />
          <Typography variant='caption' color='textSecondary'>
            {t('protectedBy')}
          </Typography>
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(withTranslation(['signup'])(withRouter(SignUpForm)));

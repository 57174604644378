import { Spylink } from '../langTemplate';

const spylink: Spylink = {
  title: 'Dynamic Link',
  copied: 'Der Link wurde in die Zwischenablage kopiert',
  description: 'Lassen Sie sich benachrichtigen, wenn Besucher auf Ihren Link klicken',
  addSpylink: 'Neuen Dynamic Link hinzufügen',
  removeSpylink: 'Dynamic Link entfernen',
  confirmRemove: 'Bitte bestätigen Sie das Entfernen dieses Links',
  details: 'Details',
  linkDescription: 'Bitte geben Sie einen Link an, den Sie verfolgen möchten.',
  filterLinks: 'Filtern Sie Dynamic Links nach Namen',
  name: 'Name',
  notification: 'Benachrichtigungsemail',
  label: 'Link zum Tracking',
  linkToCopy: 'Link zum Kopieren',
  linkToCopyDescription:
    'Sie können diesen Link teilen. Einmal pro Stunde werden Sie benachrichtigt, wenn der Benutzer darauf klickt.',
  notificationsEnabled: 'Benachrichtigungen sind aktiviert',
  notificationsDisabled: 'Benachrichtigungen sind deaktiviert',
  linkEnabled: 'Tracking ist aktiviert',
  linkDisabled: 'Tracking ist deaktiviert',
  titleAdding: 'Neuen Dynamic Link hinzufügen',
  limitTitle: 'Regelbegrenzung überschritten',
  limitExceeded:
    'Bitte aktualisieren Sie Ihren Plan, um weitere Regeln zu aktivieren. Ihr aktuelles Limit liegt bei {{limit}}.',
  steps: {
    link: 'Link zum Track',
    linkDescription: 'Bitte geben Sie einen Link von Ihrer Domain an, den Sie verfolgen möchten.',
    linkPlaceholder: 'Bitte schreiben Sie die URL-Adresse',
    notifications: 'E-Mail-Benachrichtigungen (optional)',
    notificationsDescription:
      'Mit dieser Option können Sie benachrichtigt werden, wenn der Benutzer auf den Link klickt. Berichte werden einmal pro Stunde gesendet. Sie können diese Option später deaktivieren.',
    notificationsPlaceholder: 'Bitte geben Sie die E-Mail-Adresse ein, die benachrichtigt wird',
    summary: 'Überblick',
    summaryDescription:
      'Bitte geben Sie diesem Link einen Namen. So wird es in der Liste angezeigt. Nach diesem Prozess Beendigung finden Sie den Link bereit zu kopieren und mit anderen teilen können.',
    summaryPlaceholder: 'Bitte schreiben Sie einen Namen / Alias'
  }
};
export default spylink;

import React from 'react';
//DATE RANGE THEME
/* import ThemedStyleSheet from "react-with-styles/lib/ThemedStyleSheet";
import DefaultTheme from "react-dates/lib/theme/DefaultTheme"; */
import moment, { Moment } from 'moment';
import { DateRangePicker } from 'react-dates';
import AppContext from '../data/AppContext';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { IFilterState } from '../models/app.model';
import { resetClock } from '../helpers';

interface CustomDateRangeProps {
  block?: boolean;
  modifyFilter: () => any;
  updateFilter: (propsToUpdate: any) => any;
  filter: IFilterState;
}
export const FilterDateRangePicker = (props: CustomDateRangeProps) => {
  const { block, filter, modifyFilter, updateFilter } = props;
  const { t } = useTranslation('visitors');
  const context = React.useContext(AppContext);
  const { start, end, focusedInput } = props.filter;
  const [dates, setDates] = React.useState<any>({
    startDate: moment(start),
    endDate: moment(end),
  });
  const verifyDate = (e: Moment): boolean => {
    const { history } = context.getData.limits;
    const historyLimit = new Date();
    if (history != -1) {
      historyLimit.setDate(historyLimit.getDate() - history);
    }
    const limitedDate = history != -1 ? historyLimit : new Date(context.getData.domain.created);
    const minDate = resetClock(
      history != -1 ? limitedDate : new Date(context.getData.domain.created),
      true
    );
    const maxDate = resetClock(new Date());
    const isValid = !(
      e.toDate().toISOString() >= resetClock(minDate, true).toISOString() &&
      e.toDate().toISOString() < maxDate.toISOString()
    );
    return isValid;
  };
  const handleDateChange = async (dates: {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  }) => {
    await modifyFilter();
    const { startDate, endDate } = dates;
    let std, end;
    if (startDate) {
      std = new Date(startDate.toDate());
      std.setHours(0, 0, 0, 0);
    } else {
      std = filter.start;
    }
    if (endDate) {
      end = new Date(endDate.toDate());
      end.setHours(23, 59, 59, 0);
    } else {
      end = filter.end;
    }
    await updateFilter({
      start: std,
      end: end,
    });
  };

  React.useEffect(() => {
    setDates({
      startDate: moment(filter.start),
      endDate: moment(filter.end),
    });
  }, [filter, filter.end, filter.start]);
  return (
    <Box position='relative' zIndex='3'>
      <DateRangePicker
        startDateId='startDate'
        startDate={dates.startDate}
        endDateId='endDate'
        endDate={dates.endDate}
        onDatesChange={handleDateChange}
        focusedInput={focusedInput}
        onFocusChange={(e) => updateFilter({ focusedInput: e || null })}
        isOutsideRange={verifyDate}
        displayFormat='D MMM Y'
        block={block}
        startDatePlaceholderText={t ? t('filters.startDate') : 'Start date'}
        endDatePlaceholderText={t ? t('filters.endDate') : 'End date'}
      />
    </Box>
  );
};

import React, { FormEvent } from 'react';
import {
  TextField,
  Box,
  Typography,
  Fab,
  Tooltip,
  Grid,
  Link,
  IconButton,
  Badge,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import AppContext from '../../data/AppContext';
import { withStyles } from '@material-ui/styles';
import styles from './Settings.style';
import SaveSnackbar from '../SaveSnackbar';
import { withTranslation } from 'react-i18next';
import Stripe from 'stripe';
import GetAppIcon from '@material-ui/icons/GetApp';
import ReceiptIcon from '@material-ui/icons/Receipt';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';
import { IComponentProps, AuthUser, IDomain } from '../../models/app.model';
import countries from '../../assets/countries.json';

interface DetailsProps extends IComponentProps {
  upgradeAction: () => any;
}
interface DetailsState {
  user: AuthUser;
  invoices: Stripe.Invoice[];
  isSaving: boolean;
}

class Details extends React.Component<DetailsProps, DetailsState> {
  static contextType = AppContext;
  constructor(props: DetailsProps) {
    super(props);
    this.state = {
      invoices: [] as Stripe.Invoice[],
      isSaving: false,
    } as DetailsState;
  }

  componentDidMount = async () => {
    const invoices = await this.context.getAllInvoices();
    const user = this.context.getData.userData;
    this.setState({ ...this.state, invoices, user: { ...user } });
  };

  updateDetails = async (e: any) => {
    this.setState({ ...this.state, user: { ...this.state.user, [e.target.name]: e.target.value } });
  };
  saveData = async () => {
    await this.setState({ ...this.state, isSaving: true });
    await (this.context.setData = { userData: this.state.user });
    await this.context.setUser();
    await this.setState({ ...this.state, isSaving: false });
  };
  handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    console.log(this.state);
    this.saveData();
  };

  getBadgeIcon = (status: Stripe.Invoice.Status | null) => {
    const { classes } = this.props;
    switch (status) {
      case 'paid':
        return (
          <Box className={clsx(classes.bgBadge, 'paid')}>
            <CheckIcon fontSize='small' className='icon' />
          </Box>
        );
      case 'open':
        return (
          <Box className={clsx(classes.bgBadge, 'open')}>
            <HourglassEmptyIcon fontSize='small' className='icon' />
          </Box>
        );
      default:
        return null;
    }
  };
  toggleNewsletter = async () => {
    await this.setState({ ...this.state, isSaving: true });
    // LOGIC GOES HERE
    if (this.context.getData.userData.newsletter) {
      await this.context.signOutFromNewsletter();
    } else {
      await this.context.signUpForNewsletter();
    }
    await this.setState({ ...this.state, user: this.context.getData.userData, isSaving: false });
  };
  render() {
    const {
      company = '',
      street = '',
      plz = '',
      city = '',
      phone = '',
      country = '',
      stripe,
      newsletter = false,
    } = this.state.user || {};
    const { domains = 1 } = stripe || {};
    const { classes, t, upgradeAction } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <Box p={5}>
          <Box display='flex' justifyContent='space-between' mb={2}>
            <Typography variant='h4' gutterBottom>
              {t('details.accountDetails')}
            </Typography>
            <Box display='flex' alignItems='center'>
              <Fab
                variant='extended'
                color='secondary'
                onClick={upgradeAction}
                className={classes.rightSpace}
              >
                {t('upgradeItem') as string}
              </Fab>
              <Tooltip title={t('common:saveChanges') as string}>
                <Fab color='primary' type='submit'>
                  <SaveIcon />
                </Fab>
              </Tooltip>
            </Box>
          </Box>
          <Box mb={5}>
            <Typography variant='caption' color='textSecondary'>
              {t('details.domainUsage') as string}
            </Typography>
            <Typography variant='h6' gutterBottom>{`${
              this.context.getData.userData.domains.filter((domain: IDomain) => !domain.disabled).length
            } / ${domains}`}</Typography>
          </Box>
          <Box mb={2}>
            <Typography variant='h6' gutterBottom>
              {t('details.invoiceAddress') as string}
            </Typography>
            <TextField
              fullWidth
              label={t('details.company') as string}
              name='company'
              value={company}
              margin='normal'
              variant='outlined'
              onChange={this.updateDetails}
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              fullWidth
              label={t('details.address') as string}
              value={street}
              margin='normal'
              name='street'
              variant='outlined'
              onChange={this.updateDetails}
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              fullWidth
              label={t('details.code') as string}
              value={plz}
              name='plz'
              margin='normal'
              variant='outlined'
              onChange={this.updateDetails}
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              fullWidth
              label={t('details.city') as string}
              name='city'
              value={city}
              margin='normal'
              variant='outlined'
              onChange={this.updateDetails}
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              fullWidth
              label={t('details.country') as string}
              name='country'
              value={country}
              margin='normal'
              variant='outlined'
              onChange={this.updateDetails}
              required
              select
              SelectProps={{
                native: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            >
              <option value=''>{t('details.chooseCountry') as string}</option>
              {countries.map((c, j) => (
                <option key={j} value={c.code}>
                  {c.name}
                </option>
              ))}
            </TextField>
          </Box>
          <Box mb={2}>
            <Typography variant='h6' gutterBottom>
              {t('details.extraData') as string}
            </Typography>
            <TextField
              fullWidth
              label={t('details.phone') as string}
              name='phone'
              value={phone}
              margin='normal'
              variant='outlined'
              onChange={this.updateDetails}
              type='tel'
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          <Box mb={2}>
            <Typography variant='h6' gutterBottom>
              {t('details.newsletter') as string}
            </Typography>
            <Typography variant='body2' gutterBottom>
              {t('details.newsletterDescription') as string}
            </Typography>
            <FormControlLabel
              control={
                <Tooltip title={t('details.newsletterToggle') as string}>
                  <Switch checked={!!newsletter} onChange={this.toggleNewsletter} />
                </Tooltip>
              }
              label={
                newsletter ? (
                  <Typography gutterBottom variant='caption'>
                    {t('details.newsletterAlready') as string}
                  </Typography>
                ) : (
                  <Typography variant='caption' gutterBottom>
                    {t('details.newsletterJoin') as string}
                  </Typography>
                )
              }
            />
          </Box>
          <Box mb={2}>
            <Typography variant='h6' gutterBottom>
              {t('details.invoices') as string}
            </Typography>
            {this.state.invoices.length === 0 && <Box py={5}>{t('common:noData') as string}</Box>}
            <Grid container spacing={2}>
              {this.state.invoices
                .filter((i) => !!i.invoice_pdf)
                .map((invoice: Stripe.Invoice, i: number) => (
                  <Grid item xs={12} sm={6} lg={4} key={i}>
                    <Box className={classes.invoice}>
                      <Box mr={2}>
                        <Badge
                          overlap='circle'
                          badgeContent={this.getBadgeIcon(invoice.status)}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                        >
                          <ReceiptIcon color='primary' fontSize='large' />
                        </Badge>
                      </Box>
                      <Box mr={2} flexGrow='1'>
                        <Typography variant='caption' color='textSecondary'>
                          {invoice.status === 'paid' && `${t('details.paidOn') as string} `}
                          {new Date(invoice.period_end * 1000).toLocaleDateString()}
                        </Typography>
                        <Typography variant='h6'>
                          {`${Number(invoice.amount_paid / 100).toFixed(
                            2
                          )} ${invoice.currency.toLocaleUpperCase()}`}
                        </Typography>
                      </Box>

                      <Link href={invoice.invoice_pdf || ''} target='_blank'>
                        <Tooltip title={t('details.download') as string}>
                          <IconButton>
                            <GetAppIcon />
                          </IconButton>
                        </Tooltip>
                      </Link>
                    </Box>
                  </Grid>
                ))}
            </Grid>
          </Box>
          <SaveSnackbar open={this.state.isSaving} />
        </Box>
      </form>
    );
  }
}
export default withStyles(styles)(withTranslation(['settings', 'common'])(Details));

import React from 'react';
import { FeatureProp } from './NewFeatures';
import EqualizerIcon from '@material-ui/icons/Equalizer';
//import LinkIcon from "@material-ui/icons/Link";
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import BusinessIcon from '@material-ui/icons/Business';
import MailIcon from '@material-ui/icons/Mail';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import GetAppIcon from '@material-ui/icons/GetApp';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';

export const features: FeatureProp[] = [
  {
    addedOn: new Date(2020, 2, 5),
    icon: <BusinessIcon />,
    title: 'companies',
    link: '/companies',
  },
  {
    addedOn: new Date(2020, 2, 12),
    icon: <DynamicFeedIcon />,
    title: 'multipleDynamic',
    link: '/dynamic-content',
  },
  {
    addedOn: new Date(2020, 2, 12),
    icon: <DynamicFeedIcon />,
    title: 'pushNotifications',
    link: '/',
  },
  {
    addedOn: new Date(2020, 2, 23),
    icon: <EqualizerIcon />,
    title: 'analytik',
    link: '/dynamic-content',
  },
  {
    addedOn: new Date(2020, 3, 2),
    icon: <EqualizerIcon />,
    title: 'spylinkAnalytik',
    link: '/spylink',
  },
  {
    addedOn: new Date(2020, 3, 2),
    icon: <MailIcon />,
    title: 'notificationsFrequency',
    link: { pathname: '/settings', state: { activeTab: 3 } },
  },
  {
    addedOn: new Date(2020, 4, 6),
    icon: <VideocamOffIcon />,
    title: 'videoTracking',
    link: { pathname: '/settings', state: { activeTab: 4 } },
  },
  {
    addedOn: new Date(2020, 4, 6),
    icon: <GetAppIcon />,
    title: 'excel',
    link: '/visitors',
  },
  {
    addedOn: new Date(2020, 4, 6),
    icon: <MoneyOffIcon />,
    title: 'freemium',
    link: { pathname: '/settings', state: { activeTab: 5 } },
  },
  {
    addedOn: new Date(2020, 6, 23),
    icon: <VideocamOffIcon />,
    title: 'pdfTracking',
    link: '/documents',
  },
];

import App from '../app/App';
import { subtractDays } from '../helpers/dateUtils';
import {
  IVisitor,
  PageEvent,
  VisitorTypes,
  IState,
  IDomain,
  AuthUser,
  IFilterState,
  ReminderPostBody,
  ExcludedUrl,
  DocumentFile,
  Plans,
} from '../models/app.model';
import { ISpyLink } from '../components/spylink/Spylink';
import { createRandomString, readOnlyMode, isAdmin } from '../helpers';
import { IDashboardState } from '../components/dashboard/Dashboard';
import { Reminder } from '../components/reminders/Reminders';
import { AdminCompany, CompaniesFilter } from '../components/admin/Companies';
import { DynamicContentInterface } from '../components/dynamicContent/Dynamic';

export interface PostBody {
  domain: string;
  type: VisitorTypes;
  start: Date;
  end: Date;
  pagination?: PageEvent;
  searchField?: string;
  sortKey?: string;
  sortOrder?: 1 | -1;
}

export const defaultFilter = {
  start: subtractDays(new Date(), 7), //isLocalhost() ? new Date(2019, 5, 1) :
  end: new Date(), //isLocalhost() ? new Date(2019, 5, 30) :
  keyword: '',
  pagination: {
    pageIndex: 0,
    pageSize: 20,
  },
  sortBy: 'day',
  sortAsc: false,
  type: VisitorTypes.Companys,
} as IFilterState;

export const defaultState: IState = {
  loginFailed: false,
  isNavOpen: JSON.parse(localStorage.getItem('isNavOpen') || 'false'),
  domainOwnerPlan: '',
  domains: [] as IDomain[],
  readOnly: true,
  fetching: {
    dynamicContent: true,
    domains: true,
    visitors: true,
    dashboard: true,
    spylinks: true,
    companies: true,
  },
  detectedUrl: '',
  plans: {},
  notes: [],
  dynamicContent: [],
  companyTypes: [],
  spylinks: [] as ISpyLink[],
  selected: null,
  dashboard: {} as IDashboardState,
  allUsers: [] as AuthUser[],
} as IState;

export const defaultReminderPostBody: ReminderPostBody = {
  keyword: '',
  sortBy: 'validTill',
  order: 1,
  hideCompleted: true,
  showOnlyImportant: false,
  createdBy: '',
  showHidden: false,
};

class DataProvider {
  private API_URL: string;
  private APIV2_URL: string;
  private app: App;
  constructor(app: App) {
    this.app = app;
    this.app.state = defaultState;
    this.API_URL = '/app-api/v1';
    this.APIV2_URL = '/app-api/v2';
    this.setUp();
  }

  private async setUp() {
    try {
      const user: AuthUser = await this.getUser();
      if (!user) {
        // IN CASE OF FAILED USER FETCHING
        await this.app.setState({ ...this.app.state, loginFailed: true });
        if (window.location.pathname !== '/login' && window.location.pathname !== '/signup') {
          return (window.location.pathname = '/login');
        } else return;
      }
      let allUsers = [];
      if (isAdmin(user)) {
        allUsers = await this.getAllUsers();
      }
      const plans = await this.fetchPlansFromStripe();
      const domains: IDomain[] = await this.getDomains();

      let domainIndex = domains.findIndex((domain: IDomain) => !domain.disabled) || 0;
      let savedDomain: string | null = localStorage.getItem('domain');

      if (savedDomain) {
        savedDomain = await JSON.parse(savedDomain);
        const domain = domains.find((domain: any) => domain.key === savedDomain);
        if (domain) {
          domainIndex = domains.indexOf(domain);
        } else {
          localStorage.removeItem('domain');
        }
      }
      const domain = domains[domainIndex];
      let readOnly = !domain;
      let domainOwnerPlan = '';
      await this.updateLimitsIfNotOwner(user, domain);
      let detectedUrl = '';
      if (domain) {
        domainOwnerPlan = (await this.getDomainOwnerPlan(domain.key || '')) || '';
        readOnly = readOnlyMode(user, domains[domainIndex]);
        detectedUrl = await this.getUrlFallback(domain.key);
      }
      const notes = await this.getAllNotes();
      await this.app.setState({
        ...defaultState,
        userData: user,
        readOnly,
        domain,
        domainOwnerPlan,
        domains,
        detectedUrl,
        isNavOpen: JSON.parse(localStorage.getItem('isNavOpen') || 'false'),
        notes,
        plans,
        allUsers,
        fetching: {
          ...this.app.state.fetching,
          domains: false,
        },
      });
    } catch (err) {
      console.error(err);
    }
  }
  //GETTER
  get getData() {
    return this.app.state;
  }
  //SETTER
  set setData(props: any) {
    this.app.setState({
      ...this.app.state,
      ...props,
    });
  }
  //PLANS
  fetchPlansFromStripe = async () => {
    const response = await fetch(`${this.APIV2_URL}/payment/plans`,{credentials:"include"});
    return await response.json();
  };
  getProductFromPlan = (plan: string) => {
    if (!plan) return '';
    const allProducts = Object.entries(this.app.state.plans);
    const match = (allProducts as any[]).find((prod: any) => prod[1].some((p: string) => p === plan));
    return match ? match[0] : '';
  };
  //USER
  private getUser = async () => {
    try {
      const data = await fetch(`${this.API_URL}/user`,{credentials:"include"});
      const user = await data.json();
      if (!user) throw new Error('Not authorized');
      if (!user.pushFrequency) {
        user.pushFrequency = 120;
      }
      return user;
    } catch (err) {
      return null;
    }
  };
  public getUserInfo = () => {
    const plan = Object.entries(this.app.state.plans).find((entry: any[]) =>
      entry[1].includes(this.app.state.userData.stripe.plan_id)
    ) as any;
    return { ...this.app.state.userData.stripe, plan: plan ? plan[0] : 'FREE' };
  };
  public updateUsers = async (users: AuthUser[]) => {
    try {
      await fetch(`${this.API_URL}/user`, {
        method: 'PATCH',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(users),
        credentials:"include"
      });
    } catch (err) {
      console.error(err);
    }
  };
  verifyEmail = async (email: string) => {
    const response = await fetch(`${this.APIV2_URL}/user/exists`, {
      method: 'POST',
      body: JSON.stringify({ email }),
      headers: {
        'content-type': 'application/json',
      },
      credentials:"include"
    });
    const result = await response.text();
    return JSON.parse(result);
  };
  inviteUser = async (email: string) => {
    await fetch(`${this.APIV2_URL}/mail/invite`, {
      method: 'POST',
      body: JSON.stringify({ email }),
      headers: {
        'content-type': 'application/json',
      },
    });
  };
  notifyUserAboutSharedDomain = async (email: string, domains: string[]) => {
    await fetch(`${this.APIV2_URL}/mail/domain-shared`, {
      method: 'POST',
      body: JSON.stringify({ email, user: this.app.state.userData.emails[0].value, domains }),
      headers: {
        'content-type': 'application/json',
      },
    });
  };
  changeUser = (userId: string) => {
    document.cookie = `adminAuth=${userId};path=/`;
    window.location.reload();
  };
  clearUser = () => {
    document.cookie = `adminAuth=;expires=${new Date(0).toUTCString()};path=/`;
    window.location.reload();
  };
  getAllUsers = async () => {
    try {
      const data = await fetch(`${this.API_URL}/users`);
      const users = await data.json();
      return users.filter((u: AuthUser) => u.id && u.displayName);
    } catch (err) {
      console.error(err);
      return [];
    }
  };
  setUser = async () => {
    try {
      await fetch(`${this.API_URL}/user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.app.state.userData),
      });
      this.setData = { userData: await this.getUser() };
    } catch (error) {
      console.error(error);
    }
  };
  removeUser = async (userId: string) => {
    try {
      const response = await fetch(`${this.APIV2_URL}/admin/removeUser/${userId}`, {
        method: 'DELETE',
      });
      const responseContent = await response.text();
      if (response.ok) {
        return { response: responseContent };
      } else {
        throw new Error(responseContent);
      }
    } catch (error) {
      console.error(error);
      return { error };
    }
  };
  //DOMAINS
  createDomain = async () => {
    //CHECK IF IT ALREADY EXIST
    const key = createRandomString();
    return {
      key,
      script: `<script src="https://app.primeleads.de/api/v1/${key}" async></script>`,
    };
  };
  updateAdminDomains = async (domains: IDomain[]) => {
    try {
      const response = await fetch(`${this.APIV2_URL}/admin/domains`, {
        method: 'POST',
        body: JSON.stringify(domains),
        headers: {
          'content-type': 'application/json',
        },
      });
      if (response.ok) {
        return true;
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  };
  getDomainOwnerPlan = async (domainKey: string) => {
    try {
      const response = await fetch(`${this.API_URL}/domains/ownerplan/${domainKey}`);
      const plan = await response.text();
      return plan;
    } catch (err) {
      console.error(err);
    }
  };
  getDomains = async () => {
    try {
      await (this.setData = {
        fetching: { ...this.app.state.fetching, domains: true },
      });
      const response = await fetch(`${this.API_URL}/domains`);
      await (this.setData = {
        fetching: { ...this.app.state.fetching, domains: false },
      });
      return (await response.json()).filter((domain: any) => !domain.disabled);
    } catch (err) {
      console.error(err);
    }
  };
  setDomains = async () => {
    try {
      await fetch(`${this.API_URL}/domains/allowed`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.app.state.domains),
      });
    } catch (err) {
      console.error(err);
    }
  };
  getEnabledDomains = () => {
    return this.app.state.domains.filter((domain: any) => !domain.disabled);
  };
  updateLimitsIfNotOwner = async (user: AuthUser, domain: IDomain) => {
    const isOwner =
      !domain ||
      (user.domains && user.domains.length > 0 && user.domains.some((d) => d.key === domain.key));
    if (!user.stripe) {
      user.stripe = {
        spylink: 3,
        content: 1,
        domains: 1,
        history: 90,
        companyLimit: 100,
      } as any;
    }
    if (isOwner) {
      const { spylink = 3, content = 1, domains = 1, history = 90, companyLimit = 100 } = user.stripe;
      const limits = {
        spylink,
        content,
        domains,
        history,
        companyLimit,
      };
      await this.app.setState({
        ...this.app.state,
        limits,
      });
    } else {
      const response = await fetch(`${this.APIV2_URL}/domains/ownerLimits/${domain.key}`);
      if (response.ok) {
        const limits = await response.json();
        await this.app.setState({
          ...this.app.state,
          limits,
        });
      }
    }
  };
  switchDomain = async (key: string) => {
    if (!key) return;
    const domain: IDomain =
      this.app.state.domains.find((domain) => domain.key === key) || ({} as IDomain);
    localStorage.setItem('domain', JSON.stringify(domain.key));
    this.updateLimitsIfNotOwner(this.app.state.userData, domain);
    const detectedUrl = await this.getUrlFallback(domain.key);
    const readOnly = readOnlyMode(this.app.state.userData, domain);
    this.setData = {
      domain,
      readOnly,
      selected: null,
      detectedUrl,
      fetching: {
        ...this.app.state.fetching,
        domains: true,
      },
    };
  };
  //VISITORS
  //COMBINE INTO ONE API
  loadCompanies = async (filter: IFilterState) => {
    if (!this.app.state.domain) {
      await (this.setData = {
        fetching: { ...this.app.state.fetching, visitors: false },
      });
      return { companies: [], limitsExceeded: false };
    }
    await (this.setData = {
      fetching: { ...this.app.state.fetching, visitors: true },
    });
    const { start, end } = filter;
    const body: PostBody = {
      domain: this.app.state.domain.key,
      type: filter.type,
      end: start,
      start: end,
      pagination: filter.pagination,
      searchField: filter.keyword.toLocaleLowerCase(),
      sortKey: filter.sortBy,
      sortOrder: filter.sortAsc ? -1 : 1,
    };

    const data = await fetch(`${this.API_URL}/visitors`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    const companies = await data.json();
    const { limitsExceeded } = companies;
    const companyKeys = companies.vistorsByDate
      .filter((c: IVisitor) => c.companyKey)
      .map((c: IVisitor) => c.companyKey);
    const requestsData = await fetch(
      `${this.APIV2_URL}/companies/countRequests/${this.app.state.domain.key}`,
      {
        method: 'POST',
        body: JSON.stringify(companyKeys),
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    const requests = await requestsData.json();
    await (this.setData = {
      fetching: { ...this.app.state.fetching, visitors: false },
    });
    const result = companies.vistorsByDate.map((company: IVisitor) => {
      const count = requests.find((req: any) => req.companyKey === company.companyKey);
      return {
        ...company,
        allVisits: count ? count.countRequests : 0,
      };
    });
    return {
      companies: result,
      limitsExceeded,
    };
  };

  getCompany = async (key: string) => {
    try {
      const response = await fetch(`${this.API_URL}/companies/${key}/${this.getData.domain.key}`);
      return await response.json();
    } catch (error) {
      console.error(error);
    }
  };
  getDocCompany = async (companyKey: string) => {
    try {
      const response = await fetch(`${this.APIV2_URL}/documents/company/${companyKey}`);
      return await response.json();
    } catch (error) {
      console.error(error);
    }
  };
  getCompanyTypes = async () => {
    try {
      const response = await fetch(`${this.API_URL}/companies/types`);
      const companyTypes = await response.json();
      this.setData = { companyTypes };
      return companyTypes;
    } catch (error) {
      console.error(error);
    }
  };

  //NAV
  toggleNav = async () => {
    await (this.setData = {
      isNavOpen: !this.app.state.isNavOpen,
    });
    localStorage.setItem('isNavOpen', this.app.state.isNavOpen.toString());
  };
  //DYNAMIC CONTENT
  switchOffContentAboveTheLimit = async (content: DynamicContentInterface[]) => {
    const { content: limit } = this.app.state.limits;
    if (limit < 0) {
      return content;
    }
    const activeContent = content.filter((c: DynamicContentInterface) => !c.disabled).length;
    if (activeContent > limit) {
      content.slice(limit).forEach((c) => (c.disabled = true));
      await (this.setData = { dynamicContent: content });
      await this.setDynamicContent();
    }

    return content;
  };
  getDynamicContent = async () => {
    if (!this.app.state.domain) {
      return;
    }
    try {
      await (this.setData = {
        fetching: { ...this.app.state.fetching, dynamicContent: true },
      });
      const response = await fetch(`${this.APIV2_URL}/dynamic-content/${this.app.state.domain.key}`);
      if (response.ok) {
        const responseContent = await response.json();
        const content = await this.switchOffContentAboveTheLimit(responseContent);
        const dynamicContent = content.map((content: any) => {
          content.website = content.website || '';
          return content;
        });
        await (this.setData = {
          dynamicContent,
          fetching: { ...this.app.state.fetching, dynamicContent: false },
        });
      }
    } catch (error) {
      console.error(error);
      await (this.setData = {
        fetching: { ...this.app.state.fetching, dynamicContent: false },
      });
    }
  };
  setDynamicContent = async () => {
    try {
      await fetch(`/app-api-v1/dynamic-content/${this.app.state.domain.key}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.app.state.dynamicContent),
      });
    } catch (error) {
      console.error(error);
    }
  };
  removeDynamicContent = async (el: any) => {
    try {
      await fetch(`/app-api-v1/dynamic-content/${el._id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      console.error(error);
    }
  };
  getDynamicByCompany = async (companyKey: string) => {
    const domainKey = this.app.state.domain.key;
    try {
      const response = await fetch(`/app-api-v1/dynamic-content/${domainKey}/${companyKey}`);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error);
    }
  };
  getUrlFallback = async (domainKey: string) => {
    if (!domainKey) return;
    const response = await fetch(`/app-api-v1/dynamic-content-test/${domainKey}`, {credentials:"include"});
    const data = await response.json();
    return data.url;
  };
  //REQUESTS
  getRequestsDetails = async (requests: any, pageIndex?: number) => {
    try {
      await (this.setData = {
        fetching: { ...this.app.state.fetching, requests: true },
      });
      const request = {
        ids: requests,
        pagination: {
          pageIndex: pageIndex || 0,
          pageSize: 25,
        },
      };
      const response = await fetch(`${this.API_URL}/request/${this.app.state.domain.key}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
        credentials:"include"
      });
      const data = await response.json();
      await (this.setData = {
        fetching: { ...this.app.state.fetching, requests: false },
      });
      return data;
    } catch (error) {
      console.error(error);
    }
  };
  //SCREENSHOT
  getImage = (url: string): string => {
    return `${this.API_URL}/screenshot/${btoa(url)}`;
  };
  //NOTES
  setNote = async (body: any) => {
    await fetch(`${this.API_URL}/note`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials:"include",
      body: JSON.stringify(body),
    });
    this.getAllNotes();
  };
  getNote = (key: string) => {
    if (!key) {
      return;
    }
    const note = this.app.state.notes.find((note: any) => {
      return note.ipInfoId === key;
    });
    return note || { status:"", ipInfoId: key, notes:""};
  };
  getAllNotes = async () => {
    const response = await fetch(`${this.API_URL}/note`,{credentials:"include"});
    const notes = await response.json();
    this.setData = { notes };
    return notes;
  };
  getDashboardData = async () => {
    return this.app.state.dashboard;
  };
  //DASHBOARD
  fetchDashboardData = async () => {
    if (!this.app.state.domain) {
      return;
    }

    const endDate = new Date(); // isLocalhost() ? new Date(2019, 5, 30) :
    const startDate = subtractDays(endDate, 10); // isLocalhost() ? new Date(2019, 5, 1) :
    const body = {
      domain: this.app.state.domain.key,
      type: 'companys',
      start: endDate.toISOString(),
      end: startDate.toISOString(),
      sortKey: 'day',
      sortOrder: 1,
    };
    try {
      const response = await fetch(`${this.API_URL}/visitors/dashboard`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
        credentials:"include"
      });
      if (response.ok) {
        return await response.json();
      }
    } catch (error) {
      await (this.setData = {
        fetching: { ...this.app.state.fetching, dashboard: false, visitors: false },
      });
      console.log(error);
      return null;
    }
  };
  //VIDEO
  fetchVideoData = async (reqId: string, doc?: boolean) => {
    try {
      let response;
      if (doc) {
        response = await fetch(`/app-api/v2/document/video/${reqId}`,{credentials:"include"});
        if (response.ok) {
          return await response.json();
        }
      } else {
        response = await fetch(`/api/v1/video/${this.app.state.domain.key}`, {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({ reqId }),
          credentials:"include"
        });
        return await response.json();
      }
    } catch (error:any) {
      console.error(error.message);
    }
  };
  //SUBSCRIPTION
  verifyVoucher = async (voucher: string) => {
    const response = await fetch(`/app-v1/payment/coupon${voucher}`,{credentials:"include"});
    return await response.json();
  };
  getPlanName = (plan_id: string) => {
    switch (plan_id) {
      case Plans.M:
        return 'M';
      case Plans.L:
        return 'L';
      case Plans.XL:
        return 'XL';
      case Plans.PDF:
        return 'PDF';
      default:
        return 'Other plan';
    }
  };
  paymentClicked = (plan_id: string) => {
    fetch('/app-api/v1/mail/tracking-info', {
      method: 'POST',
      body: JSON.stringify({
        content: `User ${
          this.app.state.userData.emails[0].value
        } just clicked payment button of ${plan_id} (${this.getPlanName(plan_id)})`,
      }),
      headers: {
        'content-type': 'application/json',
      },
      credentials:"include"
    });
  };
  switchOffSpylinksAboveTheLimit = async (spylinks: ISpyLink[]) => {
    const { spylink: limit } = this.app.state.limits;
    if (limit < 0) {
      return spylinks;
    }
    const activeSpylinks = spylinks.filter((s: ISpyLink) => !s.disabled).length;
    if (activeSpylinks > limit) {
      spylinks.slice(limit).forEach((s) => (s.disabled = true));
      await (this.setData = { spylinks });
      await this.setSpylink();
    }
    return spylinks;
  };
  //SPYLINKS
  getSpylinks = async () => {
    if (!this.app.state.domain) {
      return [];
    }
    await (this.setData = {
      fetching: {
        ...this.app.state.fetching,
        spylinks: true,
      },
    });
    const response = await fetch(`${this.API_URL}/spylinks/${this.app.state.domain.key}`,{credentials:"include"});
    const spylinksResponse = await response.json();
    const spylinks = await this.switchOffSpylinksAboveTheLimit(spylinksResponse);
    await (this.setData = {
      spylinks,
      fetching: {
        ...this.app.state.fetching,
        spylinks: false,
      },
    });
  };
  setSpylink = async () => {
    await fetch(`${this.API_URL}/spylinks/${this.app.state.domain.key}`, {
      method: 'POST',
      body: JSON.stringify(this.app.state.spylinks),
      headers: {
        'content-type': 'application/json',
      },
      credentials:"include"
    });
  };
  removeSpylink = async (link: ISpyLink) => {
    await fetch(`${this.API_URL}/spylinks/${this.app.state.domain.key}/${link._id}`, {
      method: 'DELETE',
      credentials:"include"
    });
  };
  //MARK AS ISP
  markCompanyISP = async (companyKey: string) => {
    const response = await fetch(`${this.API_URL}/company/change/exclude-proven/${companyKey}`,{credentials:"include"});
    if (response.status === 401) {
      return await response.text();
    } else {
      return await response.json();
    }
  };
  //GET ALL COMPANIES
  getAllCompanies = async (filter: any) => {
    if (!this.app.state.domain) return;
    await (this.setData = {
      fetching: { ...this.app.state.fetching, companies: true },
    });
    const body: PostBody = {
      type: VisitorTypes.AllCompanies,
      domain: this.app.state.domain.key,
      end: filter.startDate,
      start: filter.endDate,
      pagination: filter.pagination,
      sortKey: filter.sortBy,
      sortOrder: filter.sortAsc,
      searchField: filter.searchField,
    };

    const data = await fetch(`${this.API_URL}/visitors`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
      credentials:"include"
    });
    const companies = await data.json();
    await (this.setData = {
      fetching: { ...this.app.state.fetching, companies: false },
    });
    return companies.vistorsByDate;
  };
  //GET ALL COMPANIES
  getCompaniesByPage = async () => {
    if (!this.app.state.domain) return;
    await (this.setData = {
      fetching: { ...this.app.state.fetching, byPage: true },
    });
    const { start, end } = defaultFilter;
    const body: PostBody = {
      type: VisitorTypes.CompanysByPage,
      domain: this.app.state.domain.key,
      end: start,
      start: end,
      pagination: { pageIndex: 0, pageSize: 30 },
      searchField: '',
      sortKey: 'day',
      sortOrder: 1,
    };

    const data = await fetch(`/app-api/v1/pages`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
      credentials:"include"
    });
    const companies = await data.json();
    await (this.setData = {
      fetching: { ...this.app.state.fetching, byPage: false },
    });
    return companies;
  };

  //VERIFY SCRIPT
  verifyScript = async (domain: string) => {
    try {
      const response = await fetch(`/app/v1/${domain}`);
      const result = await response.json();
      console.log(result);
      return result.installed;
    } catch (error) {
      return false;
    }
  };
  //GET REMINDERS

  getReminders = async (body: ReminderPostBody, companyKey?: string) => {
    try {
      body = { ...defaultReminderPostBody, ...body, companyKey } as any;
      const response = await fetch(`${this.APIV2_URL}/reminders`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'content-type': 'application/json',
        },
        credentials:"include"
      });
      const reminders = await response.json();
      return reminders;
    } catch (error) {
      console.error(error);
    }
  };
  updateReminder = async (reminders: Reminder[]) => {
    try {
      await fetch(`${this.APIV2_URL}/reminders`, {
        method: 'PATCH',
        body: JSON.stringify(reminders),
        headers: {
          'content-type': 'application/json',
        },
        credentials:"include"
      });
    } catch (error) {
      console.error(error);
    }
  };
  addReminder = async (reminder: Reminder) => {
    try {
      await fetch(`${this.APIV2_URL}/reminders/add`, {
        method: 'POST',
        body: JSON.stringify(reminder),
        headers: {
          'content-type': 'application/json',
        },
        credentials:"include"
      });
    } catch (error) {
      console.error(error);
    }
  };
  removeReminder = async (reminder: Reminder) => {
    try {
      await fetch(`${this.APIV2_URL}/reminders/${reminder._id}`, {
        method: 'DELETE',
        credentials:"include"
      });
    } catch (error) {
      console.error(error);
    }
  };
  // INVOICES
  getAllInvoices = async () => {
    try {
      const response = await fetch(`${this.APIV2_URL}/payment/invoices`,{credentials:"include"});
      const invoices = await response.json();
      return invoices;
    } catch (error) {
      console.error(error);
      return [];
    }
  };
  getPaymentIntent = async (intentId: string) => {
    try {
      const response = await fetch(`${this.APIV2_URL}/payment/intent/${intentId}`,{credentials:"include"});
      const intent = await response.json();
      return intent;
    } catch (error) {
      console.error(error);
      return error;
    }
  };
  getStripeSession = async (planId: string) => {
    const response = await fetch(`${this.APIV2_URL}/payment/session`, {
      method: 'POST',
      body: JSON.stringify({ planId }),
      headers: {
        'content-type': 'application/json',
      },
      credentials:"include"
    });
    const sessionId = await response.text();
    return sessionId;
  };
  getPlanDetails = async (planId: string) => {
    try {
      const response = await fetch(`${this.APIV2_URL}/payment/planDetails/${planId}`,{credentials:"include"});
      return await response.json();
    } catch (error) {
      console.error(error);
      return error;
    }
  };
  addPaymentMethod = async (planId: string, methodId: string) => {
    const response = await fetch(`${this.APIV2_URL}/payment/intent`, {
      method: 'POST',
      body: JSON.stringify({ planId, methodId }),
      headers: {
        'content-type': 'application/json',
      },
      credentials:"include"
    });
    return await response.json();
  };
  // URL WHERE VIDEO IS NOT RECORDED
  getExcludedUrls = async () => {
    try {
      const urlsResponse = await fetch(`${this.APIV2_URL}/excludedUrls`,{credentials:"include"});
      const urls = await urlsResponse.json();
      return urls;
    } catch (error) {
      console.error(error);
      return [];
    }
  };
  saveExcludedUrls = async (urls: ExcludedUrl[]) => {
    try {
      await fetch(`${this.APIV2_URL}/excludedUrls`, {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(urls.filter((u: ExcludedUrl) => u.url)),
        credentials:"include"
      });
    } catch (error) {
      console.error(error);
    }
  };
  removeExcludedUrl = async (id: string) => {
    try {
      await fetch(`${this.APIV2_URL}/excludedUrls/${id}`, {
        method: 'DELETE',
        credentials:"include"
      });
    } catch (error) {
      console.error(error);
    }
  };
  updateExcludedUrl = async (url: ExcludedUrl) => {
    try {
      await fetch(`${this.APIV2_URL}/excludedUrls/${url._id}`, {
        method: 'PATCH',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          url: url.url,
          domain: url.domain,
          wildcard: url.wildcard,
          userId: url.userId,
        }),
        credentials:"include"
      });
    } catch (error) {
      console.error(error);
    }
  };
  // ADMIN
  getAdminCompanies = async (options: CompaniesFilter) => {
    const response = await fetch(
      `${this.API_URL}/admin/companies?helper=${options.helper}&hideblack=${options.hideblack}&hideproven=${options.hideproven}&sort=${options.sort}&pageSize=${options.pageSize}&pageIndex=${options.pageIndex}&keyword=${options.keyword}&usersWithPlan=${options.usersWithPlan}&premiumUser=${options.premiumUser}&user=${options.user}`,
      {credentials:"include"}
      );
    const companies = await response.json();
    return companies;
  };
  fetchGoogleApiData = async (companyIds: string[]) => {
    try {
      const response = await fetch(`${this.APIV2_URL}/admin/fetchGoogleApi`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(companyIds),
        credentials:"include"
      });
      if (response.ok) {
        const companies = await response.json();
        return companies;
      }
      return [];
    } catch (error:any) {
      console.log(error.message);
      return [];
    }
  };

  // RESET LAST NOTIFICATION ON EVERY EMAIL
  resetLastNotifications = async (usersIds: string[]) => {
    try {
      await fetch(`${this.APIV2_URL}/mail-reset`, {
        method: 'POST',
        body: JSON.stringify(usersIds),
        headers: {
          'content-type': 'application/json',
        },
        credentials:"include"
      });
    } catch (error) {}
  };
  transferDomain = async (domainKey: string, toUser: string) => {
    try {
      const result = await fetch(`${this.APIV2_URL}/admin/domain-transfer/${domainKey}/${toUser}`,{credentials:"include"});
      if (result.ok) {
        return true;
      }
      return true;
    } catch (error:any) {
      console.error(error.message);
      return false;
    }
  };
  renameDomain = async (oldKey: string, newKey: string) => {
    try {
      const result = await fetch(`${this.APIV2_URL}/admin/domain-rename/${oldKey}/${newKey}`,{credentials:"include"});
      if (result.ok) {
        return true;
      }
      return true;
    } catch (error:any) {
      console.error(error.message);
      return false;
    }
  };
  // ASSIGN IP TO COMPANY
  assignIpToCompany = async (ip: string, companyName: string) => {
    try {
      const response = await fetch(`${this.APIV2_URL}/admin/company-add`, {
        method: 'POST',
        body: JSON.stringify({ ip, companyName }),
        headers: {
          'content-type': 'application/json',
        },
        credentials:"include"
      });
      if (response.ok) {
        return true;
      }
      return true;
    } catch (error) {
      return false;
    }
  };
  fetchManualIps = async () => {
    const result = await fetch(`${this.APIV2_URL}/admin/manual-ips`);
    return await result.json();
  };
  removeManualIp = async (ip: string) => {
    try {
      await fetch(`${this.APIV2_URL}/admin/remove-manual-ip`, {
        method: 'PATCH',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({ ip: ip }),
        credentials:"include"
      });
    } catch (error) {
      console.error(error);
    }
  };
  updateCompanies = async (companies: AdminCompany[]) => {
    try {
      const response = await fetch(`${this.APIV2_URL}/admin/update-multiple`, {
        method: 'PATCH',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(
          companies.map(
            (c: AdminCompany) =>
              ({
                _id: c._id,
                adress: c.adress,
                tele: c.tele,
                domain: c.domain,
                name: c.name,
                type: c.type,
                proven: c.proven,
                exclude: c.exclude,
                version: c.version,
              } as AdminCompany)
          )
        ),
        credentials:"include"
      });
      if (!response.ok) {
        const { error } = await response.json();
        throw new Error(error);
      }
      return 'Companies updated';
    } catch (error:any) {
      return { error: error.message };
    }
  };
  mergeCompanies = async (companyKey: string, toMerge: string[]) => {
    await fetch(`${this.APIV2_URL}/admin/merge-companies`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({ companyKey, toMerge }),
      credentials:"include"
    });
  };
  updateNotExcludedProven = async () => {
    try {
      await fetch(`${this.API_URL}/update-exclude`);
    } catch (error) {
      console.error(error);
    }
  };
  signUpForNewsletter = async () => {
    try {
      const response = await fetch(`${this.APIV2_URL}/newsletter/subscribe`,{credentials:"include"});
      if (response.ok) {
        const { userData } = this.app.state;
        userData.newsletter = 'subscribed';
        await (this.setData = {
          userData,
        });
        return { response: 'successSubscribed' };
      }
    } catch (error) {
      console.error(error);
      return { error: 'errorSubscribed' };
    }
  };
  signOutFromNewsletter = async () => {
    try {
      const response = await fetch(`${this.APIV2_URL}/newsletter/unsubscribe`,{credentials:"include"});
      if (response.ok) {
        const { userData } = this.app.state;
        userData.newsletter = false;
        await (this.setData = {
          userData,
        });
        return { response: 'successUnsubscribed' };
      }
    } catch (error) {
      console.error(error);
      return { error };
    }
  };
  getHelperData = async (companyKeys: string[]) => {
    try {
      const response = await fetch(`${this.APIV2_URL}/admin/crawlCompanies`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(companyKeys),
        credentials:"include"
      });
      if (response.ok) {
        return await response.json();
      }
      return [];
    } catch (error) {
      console.error(error);
      return { error };
    }
  };
  // UPLOAD PDF FEATURE
  fetchUserDocuments = async (): Promise<DocumentFile[]> => {
    try {
      const { key } = this.getData.domain;
      const response = await fetch(`${this.APIV2_URL}/document/list/${key}`,{credentials:"include"});
      if (response.ok) {
        return await response.json();
      }
      return [];
    } catch (error:any) {
      console.error(error.message);
      return [];
    }
  };
  fetchUserDocumentById = async (docId: string): Promise<DocumentFile | null> => {
    try {
      const { key } = this.getData.domain || {};
      if (!key) {
        return null;
      }
      const response = await fetch(`${this.APIV2_URL}/document/list/${key}/${docId}`,{credentials:"include"});
      if (response.ok) {
        return await response.json();
      }
      return null;
    } catch (error:any) {
      console.error(error.message);
      return null;
    }
  };
  uploadNewDocument = async (file: File) => {
    try {
      const { key } = this.getData.domain;

      const blob = file.slice(0, file.size, file.type);

      const newFile = new File([blob], `${Date.now()}-${file.name}`, { type: file.type });
      const { name, type } = newFile;
      // GET LINK FROM AWS
      const awsLink = await fetch(`${this.APIV2_URL}/document/s3`, {
        method: 'POST',
        body: JSON.stringify({ type, name }),
        headers: {
          'content-type': 'application/json',
        },
        credentials:"include"
      });
      const url = await awsLink.text();
      // UPLOAD TO AWS
      await fetch(url, {
        method: 'PUT',
        body: newFile,
        headers: {
          'Content-type': type,
        },
      });
      // SEND URL TO CREATE DOCUMENT
      const response = await fetch(`${this.APIV2_URL}/document/list/${key}`, {
        method: 'POST',
        body: JSON.stringify({ name }),
        headers: {
          'content-type': 'application/json',
        },
        credentials:"include"
      });
      if (response.ok) {
        return await response.json();
      }
      return null;
    } catch (error:any) {
      console.error(error.message);
      return null;
    }
  };
  updateDocument = async (document: DocumentFile) => {
    try {
      const response = await fetch(`${this.APIV2_URL}/document/list/${document._id}`, {
        method: 'PATCH',
        body: JSON.stringify(document),
        headers: {
          'content-type': 'application/json',
        },
        credentials:"include"
      });
      if (response.ok) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };
  removeDocument = async (docId: string) => {
    try {
      const response = await fetch(`${this.APIV2_URL}/document/list/${docId}`, {
        method: 'DELETE',
        credentials:"include"
      });
      if (response.ok) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };
  // LOGS
  getLogs = async () => {
    try {
      const response = await fetch(`${this.APIV2_URL}/admin/logs`,{credentials:"include"});
      if (response.ok) {
        return await response.json();
      }
      return [];
    } catch (error) {
      return [];
    }
  };
  clearLogs = async () => {
    try {
      const response = await fetch(`${this.APIV2_URL}/admin/logs`, {
        method: 'DELETE',
        credentials:"include"
      });
      if (response.ok) {
        return true;
      }
      return true;
    } catch (error) {
      return false;
    }
  };
  // PDF ENDPOINT
  updatePdfEndpoint = async (endpoint: string, domainKey: string) => {
    const { domains } = this.app.state.userData;
    const domain = domains.find((d) => d.key === domainKey);
    if (domain) {
      domain.pdfEndpoint = endpoint;
      await (this.setData = { userData: { ...this.app.state.userData, domains }, domain });
      this.setUser();
    }
  };
  // INTEGRATIONS
  generateTokenFromCode = async (code: string) => {
    try {
      const response = await fetch(`${this.APIV2_URL}/integrations/hubspot/${code}`,{credentials:"include"});
      if (response.ok) {
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  };
  getIntegrationsOfUser = async () => {
    try {
      const response = await fetch(`${this.APIV2_URL}/integrations`,{credentials:"include"});
      if (response.ok) {
        return await response.json();
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };
  getHubspotLink = async () => {
    try {
      const response = await fetch(`${this.APIV2_URL}/integrations/hubspot/url`,{credentials:"include"});
      if (response.ok) {
        return await response.text();
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  };
  disconnectHubspot = async () => {
    try {
      const response = await fetch(`${this.APIV2_URL}/integrations/hubspot/disconnect`,{credentials:"include"});
      if (response.ok) {
        return await response.text();
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  };
  updateDomainsForHubspot = async (provider: string, domains: string[]) => {
    try {
      const response = await fetch(`${this.APIV2_URL}/integrations/domains/${provider}`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({ domains }),
        credentials:"include"
      });
      if (response.ok) {
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  // CUSTOM PLAN
  getCustomPlanInfo = async (planId:number) => {
    try {
      const response = await fetch(`${this.APIV2_URL}/payment/customPlan/${planId}`, {
        credentials:"include"
      });
      if(response.ok){
        return response.json()
      }
      return undefined;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  };
}

export default DataProvider;

import { WithTranslation } from 'react-i18next';
import { WithStyles } from '@material-ui/core';
import { IDashboardState } from '../components/dashboard/Dashboard';
import { ISpyLink } from '../components/spylink/Spylink';

export interface AuthUser {
  displayName: string;
  company?: string;
  date: string;
  id: string;
  user_id: string;
  name: any;
  emails: { value: string }[];
  picture: string;
  locale: string;
  nickname: string;
  domains: IDomain[];
  plz?: string;
  phone?: string;
  city?: string;
  street?: string;
  country?: string;
  notificationMails: { mail: string; name: string }[];
  pushFrequency?: number;
  stripe: {
    customer_id: string;
    domains: number;
    history: number;
    plan_id: string;
    companyLimit: number;
    spylink: number;
    content: number;
  };
  lastActivity: string;
  newsletter: boolean | string;
  deactivated: boolean;
  role?: UserRole;
}
export enum UserRole {
  Admin = 'admin',
  User = 'user',
}
export enum Plans {
  M = 'BASIC',
  L = 'PREMIUM',
  XL = 'ENTERPRISE',
  PDF = 'PDF',
}
export enum VisitorTypes {
  Companys = 'companys',
  CompanysByPage = 'companysByPage',
  All = 'all',
  CompanysNotInterested = 'notInteresting',
  Customers = 'isCustomer',
  isFavorite = 'isFavorite',
  competitor = 'competitor',
  New = 'new',
  isHidden = 'hidden',
  AllCompanies = 'allCompanies',
}

export interface PageEvent {
  pageIndex: number;
  pageSize: number;
}

export interface IDomain {
  _id?: string;
  key: string;
  name: string;
  created: string;
  disabled?: boolean;
  allowedUsers?: { email: string; role: string; created: Date }[]; // list of emails
  pdfEndpoint?: string;
  disabledSetTimeApi?: boolean;
  disabledVideoApi?: boolean;
}
export interface IAllowedUser {
  _id: string;
  email: string;
  created: string;
  role: string;
}
export interface INotificationMail {
  id: string;
  name: string;
  mail: string;
  notification_new: string;
  notification_last_send: string | null;
}
export interface IVisitor {
  day: string;
  time: string;
  referrers: any[];
  companyKey: string;
  companyName: string;
  type: string;
  domain: string;
  adress: string;
  city: string;
  country: string;
  urls: string[];
  requests: string[];
  videos: string[];
  allVisits?: number;
  lastDate: string;
  hidden?: boolean;
}

export interface IComponentProps extends WithStyles, WithTranslation {}

export interface IFilterState {
  start: Date;
  end: Date;
  focusedInput: 'startDate' | 'endDate' | null;
  keyword: string;
  pagination: PageEvent;
  sortBy: 'day' | 'companyName' | 'city' | 'time';
  sortAsc: boolean;
  type: VisitorTypes;
}

export interface IState {
  loginFailed: boolean;
  userData: AuthUser;
  readOnly: boolean;
  domains: IDomain[];
  domain: IDomain;
  domainOwnerPlan: string;
  isNavOpen: boolean;
  detectedUrl: string;
  fetching: {
    dashboard: boolean;
    visitors: boolean;
    dynamicContent: boolean;
    domains: boolean;
    spylinks: boolean;
    companies: boolean;
  };
  limits: {
    domains: number;
    spylink: number;
    content: number;
    companyLimit: number;
    history: number;
  };
  plans: any;
  dynamicContent: any; //TODO
  companyTypes: any;
  spylinks: ISpyLink[];
  notes: any;
  selected: null | IVisitor;
  dashboard: IDashboardState;
  allUsers: AuthUser[];
}

export interface ICompany {
  _id: string | any;
  name: string;
  adress: string;
  tele: string;
  domain: string;
  type: string;
  date: Date;
  hostname: string;
  asn: object;
  carrier: object;
  googleData_name?: any;
  googleData_domain?: any;
  googleData_address?: any;
  googleData_tele?: any;
  kununu?: any;
  version: string;
  exclude?: boolean;
  proven?: boolean;
  helperData?: {
    name?: string;
    tele?: string;
    companyType?: string;
    adress?: string;
    website?: string;
  };
}

export interface IpMapInterface {
  date: Date;
  ip: string;
  companyKey: string;
  city: string;
  region: string;
  country: string;
  loc: string;
  postal: string;
  raw: Response;
  version: string; // used for nowing when the api changed
  exclude?: boolean;
}

export interface ReminderPostBody {
  keyword: string;
  sortBy: 'companyName' | 'validTill' | 'title' | 'status';
  order: number;
  hideCompleted: boolean;
  showOnlyImportant: boolean;
  createdBy: '' | 'shared' | 'own';
  showHidden: boolean;
}

export interface ExcludedUrl {
  _id: string;
  userId: string;
  url: string;
  editMode?: boolean;
  domain: string;
  wildcard: boolean;
}

export interface DomainInfo {
  domainKey: string;
  name: string;
  disabledSetTimeApi?: boolean;
  disabledVideoApi?: boolean;
}

export interface DocumentFile {
  _id: string;
  domain: string;
  slug: string;
  author: string;
  filePath: string;
  thumbnailPath: string;
  title: string;
  description: string;
  addedAt: Date;
  protection?: {
    disabled: boolean;
    password: string;
  };
  stats: {
    displays: number;
    visitors: DocVisitor[];
    downloads: number;
  };
  notification: {
    active: boolean;
    email: string;
    frequency: string;
    lastNotification: string;
  };
  published: {
    status: boolean;
    timeLimit: boolean;
    from: string | null;
    to: string | null;
  };
}
export interface DocRequest {
  _id: string;
  date: string;
  leave: string;
  downloaded: boolean;
  time: string;
  referer: string;
  hasVideo: boolean;
}
export interface DocVisitor {
  _id: string;
  ip: string;
  companyKey: string;
  companyName: string;
  companyAdress: string;
  companyUrl: string;
  time: string;
  requests: DocRequest[];
  date: Date;
  hasVideo: boolean;
  visits: number;
  downloaded: boolean;
}

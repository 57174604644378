import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  Box,
  Grid,
  Paper,
  Typography,
  MenuList,
  MenuItem,
  Fab,
  Theme,
  createStyles,
  withStyles,
  Snackbar,
  Grow,
  Link as HLink,
  FormControlLabel,
  Button,
  Checkbox,
} from '@material-ui/core';
import Onboarding from './Onboarding';
import AppContext from '../../data/AppContext';
import VisitorsTable from '../visitors/visitorsTable/VisitorsTable';
import { Link, RouteComponentProps } from 'react-router-dom';
import TopBranchesBar from '../charts/topBranchesBar';
import VisitsPerDayTotalChart from '../charts/visitsPerDayTotal';
import { TypoPopover, WrappedIcon, DevMode } from '../../helpers';
import MuiAlert from '@material-ui/lab/Alert';
import { IComponentProps, IDomain } from '../../models/app.model';
import LoadingBox from '../LoadingBox';

import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PersonIcon from '@material-ui/icons/Person';
import LoopIcon from '@material-ui/icons/Loop';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Alert from '@material-ui/lab/Alert';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTypography-h5': {
        margin: theme.spacing(2, 0),
      },
    },
    header: {
      backgroundColor: theme.palette.grey[100],
    },
    row: {
      'backgroundColor': '#fcfcfc',
      'borderTop': `1px solid ${theme.palette.grey[200]}`,
      'cursor': 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.grey[50],
      },
    },
    formcontrollabel: {
      alignItems: 'flex-start',
    },
    checkbox: {
      color: theme.palette.secondary.main,
      marginRight: 10,
    },
  });
export interface IDashboardState {
  companys: any;
  companylist: any;
  recurringCompanies: any;
  branches: any;
  statsList: any;
  companysByPages: any;
  last10visits: any[];
  domain?: IDomain;
  justSubscribed: {
    status: boolean;
    limit: number;
  };
  unique: number;
}
export interface DashboardProps extends IComponentProps, RouteComponentProps {}
class Dashboard extends React.Component<DashboardProps, IDashboardState> {
  static contextType = AppContext;
  private mounted: boolean;
  constructor(props: any) {
    super(props);
    this.state = {
      last10visits: [] as any[],
      justSubscribed: {
        status: false,
        limit: 1,
      },
    } as IDashboardState;
    this.mounted = false;
  }
  loadDashboardData = async () => {
    // HANDLE REDIRECTION FROM CHECKOUT
    const url = new URLSearchParams(this.props.location.search);
    const status = url.get('successfulPayment') === 'true' ? true : false;
    if (status === true) {
      const limit = +this.context.getData.userData.stripe.domains;
      await this.setState({
        ...this.state,
        justSubscribed: {
          ...this.state.justSubscribed,
          status,
          limit,
        },
      });
    }
    // LOAD DATA AND COMPANIES
    const dashboard = await this.context.fetchDashboardData();
    if (dashboard && dashboard.last10visits) {
      dashboard.last10visits = dashboard.last10visits.slice(0, 5);
    }

    // LOAD RESULTS
    if (this.mounted) {
      await (this.context.setData = {
        fetching: { ...this.context.getData.fetching, dashboard: false, visitors: false },
      });
      this.setState({ ...this.state, ...dashboard, domain: this.context.getData.domain });
    }
  };
  async componentDidMount() {
    this.mounted = true;
    this.context.setData = {
      fetching: { ...this.context.getData.fetching, dashboard: true, visitors: true },
    };
    this.loadDashboardData();
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  async componentDidUpdate(prevProps: DashboardProps, prevState: IDashboardState) {
    if (this.state.domain && prevState.domain && this.context.getData.domain !== this.state.domain) {
      this.context.setData = {
        fetching: { ...this.context.getData.fetching, dashboard: true, visitors: true },
      };
      await this.setState({
        ...this.state,
        domain: this.context.getData.domain,
        last10visits: [],
        statsList: [],
      });
      this.loadDashboardData();
    }
  }
  getVisitorsByDate = () => (
    <>
      <Typography variant='h5'>{this.props.t('visitsByDate')}</Typography>
      <LoadingBox
        condition={this.context.getData.fetching.dashboard}
        empty={!this.state.companys || this.state.companys.length === 0}
      >
        <VisitsPerDayTotalChart data={this.state.companys} />
      </LoadingBox>
    </>
  );

  getMostVisitedSites = () => {
    const { dashboard } = this.context.getData.fetching;
    const { companysByPages } = this.state;
    return (
      <>
        <Typography variant='h5'>{this.props.t('mostVisitedPages')}</Typography>
        <LoadingBox condition={dashboard} empty={companysByPages.pages.length === 0}>
          {companysByPages && companysByPages.pages.length > 0 && (
            <>
              <Grid container className={this.props.classes.header}>
                <Grid item xs={6} md={6} component={Box} px={2} py={1} display='flex'>
                  <Typography variant='h6'>{this.props.t('page')}</Typography>
                </Grid>
                <Grid item xs={2} md={2} component={Box} px={2} py={1} display='flex'>
                  <Typography variant='h6'>{this.props.t('visits')}</Typography>
                </Grid>
                <Grid item component={Box} xs={2} md={2} px={2} py={1} display='flex'>
                  <Typography variant='h6'>{this.props.t('companies')}</Typography>
                </Grid>
                <Grid item xs={2} md={2} component={Box} px={2} py={1} display='flex'>
                  <Typography variant='h6'>{this.props.t('users')}</Typography>
                </Grid>
              </Grid>

              {companysByPages &&
                companysByPages.pages.map((page: any, i: number) => (
                  <Box key={i} className={this.props.classes.row}>
                    <Grid container>
                      <Grid item component={Box} px={2} py={1} display='flex' xs={6} md={6}>
                        <TypoPopover variant='body2' noWrap text={page.url}>
                          <HLink>{page.url}</HLink>
                        </TypoPopover>
                      </Grid>
                      <Grid item component={Box} px={2} py={1} display='flex' xs={2} md={2}>
                        <Typography variant='h6'>{page.requests}</Typography>
                      </Grid>
                      <Grid item component={Box} px={2} py={1} display='flex' xs={2} md={2}>
                        <Typography variant='h6'>{page.companies.length}</Typography>
                      </Grid>
                      <Grid item component={Box} px={2} py={1} display='flex' xs={2} md={2}>
                        <Typography variant='h6'>{page.users.length}</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
            </>
          )}
        </LoadingBox>
      </>
    );
  };

  getStatIcon = (name: String) => {
    const icons = [
      { text: 'Unternehmen', icon: <BusinessCenterIcon /> },
      { text: 'Standorte', icon: <LocationOnIcon /> },
      { text: 'Seitenaufrufe', icon: <VisibilityIcon /> },
      { text: 'Wiederkehrer', icon: <LoopIcon /> },
      { text: 'Kunden', icon: <PersonIcon /> },
      { text: 'Favoriten', icon: <FavoriteIcon /> },
    ];
    const { icon } = (icons.find((icon) => icon.text === name) as any) || {};
    return (
      <WrappedIcon color='secondary' fontSize='large'>
        {icon}
      </WrappedIcon>
    );
  };

  getOverallStats = () => {
    const { statsList = [] as any } = this.state;
    return (
      <>
        <Typography variant='h5'>{this.props.t('lastWeek')}</Typography>
        <LoadingBox condition={this.context.getData.fetching.dashboard} empty={statsList.length === 0}>
          <Box p={3}>
            <Grid container spacing={2}>
              {statsList.map((stat: any, i: number) => (
                <Grow key={i} in={true} style={{ transitionDelay: `${i * 0.05}s` }}>
                  <Grid item xs={12} sm={6}>
                    <Box display='flex'>
                      {this.getStatIcon(stat.text)}
                      <Box ml={1}>
                        <Typography variant='h4'>{stat.value}</Typography>
                        <Typography variant='subtitle1' color='textSecondary'>
                          {this.props.t('' + stat.text.toLocaleLowerCase())}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grow>
              ))}
            </Grid>
          </Box>
        </LoadingBox>
      </>
    );
  };
  getOnboarding = () => <Onboarding />;
  getLast5Visits = () => (
    <>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Typography variant='h5'>{this.props.t('last5visits')}</Typography>

        <Fab variant='extended' color='primary' size='small' component={Link} to='/visitors'>
          <Typography variant='button'>{this.props.t('viewAll')}</Typography>
        </Fab>
      </Box>
      <LoadingBox
        condition={this.context.getData.fetching.dashboard}
        empty={this.state.last10visits.length === 0}
      >
        <VisitorsTable companies={this.state.last10visits} />
      </LoadingBox>
      <DevMode onlyAdmin={true}>
        {!this.context.getData.fetching.dashboard && this.state.unique > 0 && (
          <Typography
            variant='caption'
            color='textSecondary'
            dangerouslySetInnerHTML={{
              __html: `${this.props.t('uniqueVisitor', { count: this.state.unique })} ${this.props.t(
                'seeAll'
              )}`,
            }}
          />
        )}
      </DevMode>
    </>
  );
  getTopBranches = () => (
    <>
      <Typography variant='h5'>{this.props.t('topVisitorsByType')}</Typography>
      <LoadingBox
        condition={this.context.getData.fetching.dashboard}
        empty={!this.state.branches || this.state.branches.length === 0}
      >
        <TopBranchesBar data={this.state.branches} />
      </LoadingBox>
    </>
  );
  getTopCompanies = () => (
    <>
      <Typography variant='h5'>{this.props.t('top10Visitors')}</Typography>
      <LoadingBox
        condition={this.context.getData.fetching.dashboard}
        empty={!this.state.companylist || this.state.companylist.length === 0}
      >
        <MenuList disablePadding>
          {this.state.companylist &&
            this.state.companylist.length > 0 &&
            this.state.companylist.slice(0, 10).map((company: any, i: number) => (
              <MenuItem
                key={i}
                component={Link}
                to={`/companies/${company.companyKey}`}
                children={
                  <Box style={{ width: '100%' }}>
                    <TypoPopover variant='h6' noWrap text={company.name}>
                      {company.name}
                    </TypoPopover>
                    <Typography variant='body2' color='textSecondary' noWrap={true}>
                      {company.type} /{' '}
                      <Typography variant='inherit' color='primary' component='span'>
                        {company.count} {this.props.t('common:visits')}
                      </Typography>
                    </Typography>
                  </Box>
                }
              />
            ))}
        </MenuList>
      </LoadingBox>
    </>
  );
  signUpForNewsletter = async (e: React.FormEvent) => {
    e.preventDefault();
    await this.context.signUpForNewsletter();
  };
  getNewsletterForm = () => {
    const { userData } = this.context.getData;
    const { newsletter } = userData;
    if (newsletter === 'subscribed') {
      return <Alert severity='success'>{this.props.t('common:successSubscribe')}</Alert>;
    }
    return newsletter === true ? null : (
      <>
        <Typography variant='h5'>{this.props.t('newsletter')}</Typography>
        <LoadingBox standOut={true} condition={false} empty={false}>
          <form onSubmit={this.signUpForNewsletter}>
            <Box p={3}>
              <FormControlLabel
                classes={{ root: this.props.classes.formcontrollabel }}
                control={<Checkbox required classes={{ root: this.props.classes.checkbox }} />}
                label={
                  <>
                    <Box mb={2}>
                      <Typography variant='body2' gutterBottom>
                        {this.props.t('newsletterDescription')}
                      </Typography>
                    </Box>
                    <Button type='submit' variant='contained' color='secondary'>
                      {this.props.t('newsletterButton')}
                    </Button>
                  </>
                }
              />
            </Box>
          </form>
        </LoadingBox>
      </>
    );
  };
  render() {
    const { t, classes } = this.props;
    return (
      <Box p={5}>
        {!this.context.getData.domain ? (
          <Box component={Paper} mb={2} px={10}>
            {this.getOnboarding()}
          </Box>
        ) : (
          <Grid container spacing={5} className={classes.root}>
            <Grid item xs={12} lg={8}>
              {this.getLast5Visits()}
              {this.getVisitorsByDate()}
              {/* {this.getMostVisitedSites()} */}
            </Grid>
            <Grid item xs={12} lg={4}>
              {this.getOverallStats()}
              {this.getNewsletterForm()}
              {this.getTopCompanies()}
              {this.getTopBranches()}
            </Grid>
          </Grid>
        )}
        <Snackbar
          open={this.state.justSubscribed.status}
          autoHideDuration={10000}
          onClose={() =>
            this.setState({
              ...this.state,
              justSubscribed: { ...this.state.justSubscribed, status: false },
            })
          }
        >
          <MuiAlert variant='filled' severity='success'>
            {t('common:purchased', {
              limit: this.state.justSubscribed.limit,
            })}
          </MuiAlert>
        </Snackbar>
      </Box>
    );
  }
}

export default withStyles(styles)(withTranslation(['dashboard', 'common'])(Dashboard));

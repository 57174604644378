import React from 'react';
import * as d3 from 'd3';
import { Tooltip, SvgIcon, IconButton } from '@material-ui/core';
import { WithTranslation, withTranslation } from 'react-i18next';

export interface ColorsProps extends WithTranslation {
  data: number;
  width: number;
  height: number;
  x?: number;
  y?: number;
}

const Swatch = ({ color, width, height, x, y }: any) => (
  <rect width={width} height={height} x={x} y={y} style={{ fill: color }} />
);

class Colors extends React.Component<ColorsProps> {
  colors = d3.interpolateRgb('#90f542', '#f5bc42');
  width = d3.scaleQuantize();

  componentWillMount() {
    this.updateD3(this.props);
  }

  componentWillUpdate(newProps: any) {
    this.updateD3(newProps);
  }

  updateD3(props: any) {
    this.width.domain([1, 40]).range(d3.range(1, 5));
  }

  render() {
    const { data, height, width, t } = this.props;
    const score = this.width(data);
    const tooltipText = ['scale1', 'scale2', 'scale3', 'scale4'];
    return (
      <Tooltip title={t('scale.' + tooltipText[score - 1]) as string}>
        <IconButton style={{ marginLeft: 5 }}>
          <SvgIcon width={16} height={12} viewBox='0 0 16 12'>
            <g>
              {d3.range(1, 5).map((i: number) => {
                return (
                  <Swatch
                    key={i}
                    color={i <= score ? this.colors((i - 1) / 4) : '#ddd'}
                    width={width / 4}
                    height={(height / 4) * i}
                    x={(i - 1) * (width / 4)}
                    y={height - i * (height / 4)}
                  />
                );
              })}
            </g>
          </SvgIcon>
        </IconButton>
      </Tooltip>
    );
  }
}
export const ColorsT = withTranslation('visitors')(Colors);

export const Scale = ({ data }: { data: number }) => {
  return <ColorsT data={data} width={16} height={12} />;
};

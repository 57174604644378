import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import AppContext from '../../data/AppContext';
import { withStyles } from '@material-ui/styles';
import styles from './Settings.style';
import SaveSnackbar from '../SaveSnackbar';
import { withTranslation } from 'react-i18next';
import { IComponentProps, IDomain } from '../../models/app.model';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import DoneAllIcon from '@material-ui/icons/DoneAll';

interface IntegrationProps extends IComponentProps, RouteComponentProps {}
interface IntegrationState {
  isSaving: boolean;
  connecting: boolean;
  integrations: {
    [provider: string]: {
      active: boolean;
      domains: string[];
    };
  };
}

class Integration extends React.Component<IntegrationProps, IntegrationState> {
  static contextType = AppContext;
  constructor(props: IntegrationProps) {
    super(props);
    this.state = {
      isSaving: false,
      connecting: false,
      integrations: {
        hubspot: {
          active: false,
          domains: [],
        },
      },
    } as IntegrationState;
  }
  toggleDomain = (provider: string, domain: string) => {
    return () => {
      const { integrations } = this.state;
      const domains = integrations[provider]?.domains || [];
      if (domains.includes(domain)) {
        domains.splice(domains.indexOf(domain), 1);
      } else {
        domains.push(domain);
      }
      integrations[provider].domains = domains;
      this.setState({ integrations });
    };
  };
  toggleSelectAll = (provider: string) => {
    const { integrations } = this.state;
    const { domains } = integrations[provider];
    const { domains: userDomains } = this.context.getData.userData;
    if (domains.length > 0) {
      domains.splice(0, domains.length);
    } else {
      userDomains.map((d: IDomain) => domains.push(d.key));
    }
    this.setState({ integrations });
  };
  getDomainsList(provider: string) {
    const { t } = this.props;
    const domains = this.state.integrations[provider].domains || [];
    return (
      <Box mb={1}>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography variant='h6'>{t('integrations.chooseDomains')}</Typography>
          <Tooltip title={t('integrations.selectAll') as string}>
            <IconButton onClick={() => this.toggleSelectAll(provider)}>
              <DoneAllIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Grid container>
          {this.context.getData.userData.domains.map((d: IDomain) => (
            <Grid item xs={12} sm={6} md={3} key={d._id}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={domains.includes(d.key)}
                    onChange={this.toggleDomain(provider, d.key)}
                  />
                }
                label={<Typography variant='body2'>{d.name}</Typography>}
                name='domains'
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }

  handleHubspotConnect = async () => {
    try {
      const link = await this.context.getHubspotLink();
      console.log(link);
      window.location.href = link;
    } catch (error) {
      return error;
    }
  };
  handleHubspotDisconnect = async () => {
    const response = await this.context.disconnectHubspot();
    if (response) {
      const { integrations } = this.state;
      integrations.hubspot = { ...integrations.hubspot, active: false };
      this.setState({ integrations });
    }
  };
  handleHubspotCode = async () => {
    const search = new URLSearchParams(this.props.location.search);
    const code = search.get('code');
    const hubspot = search.get('hubspot');
    if (code && hubspot) {
      const response = await this.context.generateTokenFromCode(code);
      if (response) {
        const { integrations } = this.state;
        integrations.hubspot = {
          ...integrations.hubspot,
          active: true,
        };
        this.setState({ integrations });
      }
    }
  };
  componentDidMount = async () => {
    const userIntegrations = await this.context.getIntegrationsOfUser();
    console.log(userIntegrations);
    this.setState(
      (state) => ({ integrations: { ...state.integrations, ...userIntegrations } }),
      async () => {
        await this.handleHubspotCode();
      }
    );

    // GET INTEGRATIONS URL FROM SERVER
    // HERE FETCH THE DATA FROM DB, CHECK IF STATUS OF EACH INTEGRATION IS ACTIVE
  };

  updateDomainsForHubspot = () => {
    console.log(this.state.integrations.hubspot.domains);
    this.context.updateDomainsForHubspot('hubspot', this.state.integrations.hubspot.domains);
  };

  private get hubspot() {
    const { hubspot } = this.state.integrations;
    const { active } = hubspot || {};
    const { t } = this.props;
    return (
      <Box my={4}>
        <Typography variant='h5' gutterBottom>
          Hubspot
        </Typography>
        <Typography variant='body2' gutterBottom>
          {t('integrations.hubspot.description')}
        </Typography>

        <Box>
          {active ? (
            <>
              {this.getDomainsList('hubspot')}
              <Box display='flex' justifyContent='space-between'>
                <Button variant='outlined' onClick={this.updateDomainsForHubspot}>
                  {t('integrations.updateDomains')}
                </Button>
                <Button
                  variant='outlined'
                  color='secondary'
                  onClick={this.handleHubspotDisconnect}
                  style={{ marginRight: 10 }}
                >
                  {t('integrations.disconnect')}
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Button variant='contained' color='primary' onClick={this.handleHubspotConnect}>
                {t('integrations.connect')}
              </Button>
            </>
          )}
        </Box>
      </Box>
    );
  }

  private get headers() {
    const { t } = this.props;
    return (
      <Box mb={2}>
        <Typography variant='h4'>{t('integrations.title')}</Typography>
      </Box>
    );
  }

  render() {
    const { t } = this.props;
    console.log(this.state);
    return (
      <Box p={5}>
        {this.headers}
        {this.hubspot}
        <SaveSnackbar open={this.state.isSaving} />
      </Box>
    );
  }
}
export default withStyles(styles)(withTranslation(['settings', 'common'])(withRouter(Integration)));

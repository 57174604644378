import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en';
import de from './locales/de';
import LanguageDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';

i18next
  .use(LanguageDetector)
  .use(intervalPlural)
  .use(initReactI18next)
  .init({
    fallbackLng: 'de',
    debug: false,
    defaultNS: 'common',
    resources: {
      en,
      de,
    },
    returnNull: false,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['cookie'],
      lookupCookie: 'i18next',
      caches: ['cookie'],
    },
  });
export default i18next;

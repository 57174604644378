import { SignUps } from '../langTemplate';

const signup: SignUps = {
  login: {
    forgotPassword: 'Forgot your password?',
    noAccount: 'No account yet? Create one for free'
  },
  register: {
    alreadyGot: 'I already got an account',
    companyName: 'Company name',
    fullName: 'Name or nickname',
    firstName: 'First name',
    lastName: 'Last name',
    minimum: 'Min. {{chars}} characters',
    rules:
      'By registering, you agree to our <a target="_blank" href="{{nutzungsbedingungen}}">Terms of Use</a> and <a target="_blank" href="{{datenschutz}}">Privacy Policy.</a>',
    newsletter: 'Sign  me up to newsletter and receive the latest updates and exclusive offers.'
  },
  emailSent: 'Email with reset link was sent. Please check your mailbox.',
  errors: {
    credentials: 'Incorrect credentials.',
    emailCredentials: 'Email address not found.',
    something: 'Something went wrong.',
    exists: 'User already exists.',
    failed: 'Failed to log in.',
    incorrect: 'Incorrect credentials'
  },
  protectedBy: 'Protected by Auth0',
  signIn: 'Sign in',
  signUp: 'Sign up',
  confirmEmail: 'Please confirm your email address',
  getLink: 'Reset password',
  pleaseConfirm: 'Please confirm your email address, and get a link to reset your password.',
  password: 'Password',
  email: 'Email'
};
export default signup;

import { Companies } from '../langTemplate';

const companies: Companies = {
  title: 'Unternehmen',
  description: 'Liste aller Unternehmen, die Sie kürzlich besucht haben',
  explanation: 'Die angezeigten Statistiken enthalten nur Daten aus dem ausgewählten Datumsbereich',
  details: 'Unternehmens-Details',
  type: 'Branche',
  requests: 'Anfragen',
  lastDate: 'Letzter Besuch',
  showOnlyRecognised: 'Ausblenden unerkannt',
  totalTime: 'Zeit insg.',
  actions: 'Aktionen',
  visitPage: 'Seite besuchen',
  visitProfile: 'Besuchen Firmenprofil',
  searchButton: 'Suche nach Unternehmen',
  addDynamic: 'Dynamic content hinzufügen',
  filter: {
    sortBy: 'Sortiere nach',
    order: 'Sortierreihenfolge',
    keyword: 'Unternehmen filtern',
    keywordPlaceholder: 'Schreiben Sie, um die Ergebnisse zu filtern',
    dateRange: 'Datumsbereich',
    numberRequests: 'Anzahl der Anfragen',
    alphabetically: 'Alphabetisch',
    lastVisit: 'Letzter Besuch',
    ascending: 'Aufsteigend',
    descending: 'Absteigend',
    lastDays: 'Letzte {{days}} Tage',
    location: 'Standort',
    totalTime: 'Zeit auf der Website'
  },
  company: {
    details: 'Details',
    visits: 'Besuche',
    content: 'Dynamic Content',
    addContent: 'Dynamic Content hinzufügen',
    companyType: 'Unternehmensart',
    address: 'Addresse',
    phone: 'Telefonnummer',
    website: 'Webseite',
    notes: 'Anmerkungen',
    contentTriggered: 'Dynamic Content ausgelöst durch',
    numberTriggers: 'Anzahl der Trigger',
    numberRequests: 'Anzahl der Anfragen',
    date: 'Datum',
    time: 'Dauer',
    visitedSite: 'Besuchte seiten',
    device: 'Gerät',
    video: 'Video',
    source: 'Quellen',
    copied: 'Firmendaten in die Zwischenablage kopiert',
    createContent: 'Dynamic content erstellen',
    moreDetails: 'Weitere Details'
  }
};
export default companies;

import { withStyles, createStyles, Theme } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import Visits from '../Visits';
const styles = createStyles((theme: Theme) => ({
  videoFrame: {
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
  },
}));
class SpylinkVisits extends Visits {}

export default withStyles(styles)(withTranslation(['spylink', 'common'])(SpylinkVisits));

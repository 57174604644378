import { DynamicContent } from '../langTemplate';

const dynamicContent: DynamicContent = {
  title: 'Dynamic content',
  description: 'Customize your website content for different visitors.',
  titleAdding: 'Creating dynamic content',
  confirmChange: 'Changing URL will remove all selectors. Would you like to continue?',
  addRule: 'Add new rule',
  filterRules: 'Filter existing rules',
  modifiedElements: 'Modified elements',
  deleteElement: 'Delete element',
  deleteRule: 'Delete rule',
  editRules: 'Edit rules',
  editElement: 'Edit element',
  replacedWith: 'Replaced with',
  addContent: 'Add new content',
  typeUrl: 'Type URL of your website, to see preview',
  enableRule: 'Enable rule',
  disableRule: 'Disable rule',
  confirmRemove: 'Please confirm removing this rule',
  noEmpty: 'Please fill the rule name field',
  duplicate: 'Duplicate selected rule',
  visibleOnly: 'Visible only for companies fulfilling those conditions',
  multipleRules: 'Display conditions',
  multipleDescription: 'Here you can specify which company should see your customized content',
  uploadImage: 'Upload image',
  contentDetails: 'Dynamic Content summary',
  limitTitle: 'Limit of rules exceeded',
  limitExceeded: 'Please upgrade your plan to enable more rules. Your current limit is {{limit}}.',
  explanation:
    'Each condition can have multiple rules. Company will see the content, if at least one rule of each provided condition will match. Click button on the right to load sample data and see how it works.',
  labels: {
    'branch': 'Branch',
    'location': 'Location',
    'city': 'City',
    'status': 'Company status',
    'markierung': 'Note',
    'ruleName': 'Name of rule',
    'content': 'Content of message',
    'contentType': 'Content type',
    'website': 'Website',
    'ruleType': 'Type of rule',
    'companyName': 'Company name',
    'branchName': 'Branch name',
    'companyStatus': 'Company status',
    'dynamic-text': 'Dynamic text (custom HTML elements)',
    'dynamic-message': 'Dynamic message (top of site)',
    'company-name': 'Company name',
    'company-type': 'Company branch',
    'company-note-status': 'Company status',
    'isCustomer': 'Customer',
    'isFavorite': 'Favourite',
    'notInteresting': 'Not interesting',
    'competitor': 'Competitor',
    'spylink': 'Spylink',
  },
  steps: {
    contentType: 'Content type',
    contentTypeInfo: 'How would you like to display the content to visitors?',
    target: 'Target the companies',
    targetInfo: 'Specify condition that has to be fullfiled by visitor',
    companyNameTitle: 'Filter by company name',
    companyNameInfo:
      'Display messages only to companies that name matches provided pattern. (case insensitive)',
    branchTitle: 'Filter by company branch',
    branchInfo: 'Display content to all visitors from specific business branch.',
    locationTitle: 'Filter by location',
    locationInfo: 'Display content to visitors from specific city. (case insensitive)',
    spylinkTitle: 'Filter by spylink entries',
    spylinkInfo: 'Display content only to visitors who entered through one of your spylinks',
    statusTitle: 'Filter by company note',
    statusInfo:
      'Display contetn to companies that are marked with one of available statuses (favourite, competitor, not interested in, customer)',
    urlChange: 'Modifying URL will delete all currently selected elements. Do you want to proceed?',
    message: 'Message on top of the site',
    messageInfo: 'Message will be displayed on the top of your site, as soon as it loads. ',
    customHtml: 'Custom HTML elements',
    customHtmlInfo:
      'This option allows you to select multiple HTML elements on your site, and provide a replacement content for each one of them.',
    customise: 'Customise the content',
    customiseInfo: 'Here you can type the content of message or select HTML elements to customise.',
    summary: 'Overview',
    summaryInfo:
      'This is Dynamic Content you have just created. It will be visible to companies fulfilling those conditions. In order to save it, please click finish button.',
    companyPattern: 'Company name pattern',
    pleaseChooseOption: 'Click to expand the options',
    pleaseChooseStatus: 'Please choose a company status',
    pleaseChooseType: 'Please choose a company type',
    pleaseChooseName: 'Please choose a company name',
    pleaseTypeUrl: 'Please type the URL of the website',
    pleaseTypeMessage: 'Please type a message for the visitor',
    typeCompanyPlaceholder: 'Please type a company name ',
    urlPlaceholder: 'Type URL address here',
    dynamicContentFor: 'Dynamic content for',
    chooseExisting: 'Please choose existing Spylink, or create new one',
    spylinkCreated: 'Spylink named "{{name}}" was just created and added as rule',
    conditions: '{{count}} Condition',
    conditions_plural: '{{count}} Conditions',
  },
  form: {
    conditions: 'Conditions to fulfill',
    companyName: 'Name must include',
    status: 'Company note has to equal',
    branch: 'Type has to equal',
    location: 'City name must include',
    spylink: 'Has to enter through spylink',
    sampleCompanies: 'Sample companies',
    sampleConditions: 'Sample conditions',
  },
  editor: {
    title: 'Selected elements',
    notFoundTitle: 'Elements not found anymore',
    notFound: 'Some of the elements were not found anymore.',
    editElements: 'Edit selectors',
    replacedWith: 'Replaced with',
    deleteElement: 'Delete element',
    confirmMessage: 'Please confirm removing the element',
  },
};
export default dynamicContent;

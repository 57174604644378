import React, { Fragment } from 'react';
//CONTEXT & MODELS
import AppContext from '../data/AppContext';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { IComponentProps } from '../models/app.model';
//STYLES
import { Theme, withStyles, withTheme, WithTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
//COMPONENTS
import {
  Drawer,
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Link as HLink,
  Grow,
  createStyles,
  Badge,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { WrappedIcon, isAdmin } from '../helpers';
import { Location } from 'history';
//ICONS
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import BarChartIcon from '@material-ui/icons/BarChart';
import GroupIcon from '@material-ui/icons/Group';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LinkIcon from '@material-ui/icons/Link';
import BusinessIcon from '@material-ui/icons/Business';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import DescriptionIcon from '@material-ui/icons/Description';
//import LinkIcon from "@material-ui/icons/Link";
import NotificationsIcon from '@material-ui/icons/Notifications';
import HelpIcon from '@material-ui/icons/Help';
import SettingsIcon from '@material-ui/icons/Settings';
//ASSETS
import logo from '../assets/images/logo.png';
import NewFeatures, { FeatureProp } from './NewFeatures';
import { features } from './features';

export interface ISidebarProps extends IComponentProps, WithTheme, RouteComponentProps {
  location: Location;
}
export interface ISidebarState {
  features: FeatureProp[];
  showFeatures: boolean;
}

export interface INavigationItem {
  text: string;
  path: string;
  icon: React.ReactElement;
  tooltip?: string;
  divider?: boolean;
  exclude?: boolean;
  devMode?: boolean;
}

export const drawerWidth = 270;

const styles = (theme: Theme) =>
  createStyles({
    drawer: {
      'width': `${drawerWidth}px`,
      'flexShrink': 0,
      'color': theme.palette.grey[200],
      'padding': 0,
      '& .Mui-selected': {
        backgroundColor: theme.palette.primary.dark,
        position: 'relative',
        borderLeft: `5px solid ${theme.palette.secondary.main}`,
      },
    },
    drawerOpen: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      'transition': theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      'overflowX': 'hidden',
      'width': theme.spacing(9) + 2,
      '& .MuiTypography-body1': {
        display: 'none',
      },
      '& .MuiListItemIcon-root': {
        justifyContent: 'center',
      },
      '& .MuiListItem-root': {
        justifyContent: 'center',
      },
      [theme.breakpoints.down('xs')]: {
        width: 0,
        border: 0,
      },
    },
    icon: {
      color: theme.palette.grey[200],
      fontSize: '2rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    logo: {
      display: 'block',
      width: '50%',
      margin: '0 auto',
      height: 'auto',
      [theme.breakpoints.only('sm')]: {},
    },
    hide: {
      display: 'none',
    },
    link: {
      textDecoration: 'none!important',
    },
    disabled: {
      'opacity': 0.3,
      'transition': 'opacity .5s ease',
      '&:hover': {
        opacity: 0.4,
      },
    },
    disabledTooltip: {
      backgroundColor: theme.palette.secondary.main,
    },
  });

class Sidebar extends React.Component<ISidebarProps, ISidebarState> {
  static contextType = AppContext;
  private nav: INavigationItem[] = [];
  constructor(props: ISidebarProps) {
    super(props);
    this.state = {
      features: [],
      showFeatures: false,
    } as ISidebarState;
  }
  componentDidMount() {
    const features = this.getNewFeatures();
    this.nav = [
      { text: 'dashboard', path: '/', icon: <BarChartIcon />, exclude: this.isExcluded() },

      { text: 'visitors', path: '/visitors', icon: <GroupIcon />, exclude: this.isExcluded() },

      {
        text: 'companies',
        path: '/companies',
        icon: <BusinessIcon />,
        divider: true,
      },
      {
        text: 'shared-documents',
        path: '/documents',
        icon: <DescriptionIcon />,
        exclude: !this.isPdfIncluded()
      },
      {
        text: 'dynamicContent',
        path: '/dynamic-content',
        icon: <DynamicFeedIcon />,
        exclude: this.isExcluded(),
      },
      {
        text: 'spylink',
        path: '/spylink',
        icon: <LinkIcon />,
        divider: true,
        exclude: this.isExcluded(),
      },
      {
        text: 'reminders',
        path: '/reminders',
        icon: <NotificationsIcon />,
        devMode: true,
      },
      {
        text: 'settings',
        path: '/settings',
        icon: <SettingsIcon />,
      },
    ];
    this.setState({ ...this.state, features });
  }
  hideNav = () => {
    if (this.context.getData.isNavOpen && this.props.theme.breakpoints.values.md >= window.innerWidth)
      this.context.toggleNav();
  };
  isExcluded(): boolean {

    /* const { plans, userData } = this.context.getData; */
    /* const { plan_id } = userData.stripe; */
    /* return (plans['PDF'] && plans['PDF'].includes(plan_id)); */
    return false;
  }

  isPdfIncluded = () => {
    const { userData } = this.context?.getData || {};
    const {role, stripe = {}} = userData;
    const { pdf } = stripe;
    console.log("PDF",pdf)
    return !!pdf || role==="admin";
  }

  getNewFeatures = () => {
    const alreadySeen = JSON.parse(localStorage.getItem('featuresSeen') || 'false');
    // WE CHECK IF NEW FEATURE HAS BEEN RELEASED SINCE THE LAST TIME USER CLOSED THE SNACKBAR
    const newFeatures = alreadySeen
      ? features.some((feature: FeatureProp) => {
          //IF ANY FEATURE DATE RELEASE IS BIGGER THEN DATE IN LOCAL STORAGE, THEN RETURN TRUE, SO SNACKBAR WILL BE SHOWED AGAIN, OTHERWISE FALSE
          return feature.addedOn.getTime() > new Date(alreadySeen).getTime();
        })
      : //IF NOTHING IN LOCAL STORAGE THEN SHOW SNACKBAR
        true;
    if (newFeatures) {
      localStorage.removeItem('featuresSeen');
    }
    return features.filter((feature: FeatureProp) => {
      //NEW FEATURES WILL BE DISPLAYED FOR THE NEXT 2 WEEKS SINCE RELEASE
      return feature.addedOn.getTime() + 1000 * 60 * 60 * 24 * 14 > new Date().getTime();
    });
  };
  render() {
    const { classes, t } = this.props;
    const { isNavOpen: open, userData } = this.context.getData;
    return (
      <>
        <Drawer
          variant='permanent'
          classes={{
            paper: clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          open={open}
        >
          <Box display='flex' justifyContent='center' mt={2}>
            {!open && (
              <Box display={{ xs: 'none', sm: 'block' }}>
                <IconButton onClick={this.context.toggleNav}>
                  <MenuIcon className={classes.icon} />
                </IconButton>
              </Box>
            )}
            {open && (
              <IconButton onClick={this.context.toggleNav}>
                <CloseIcon className={classes.icon} />
              </IconButton>
            )}
          </Box>
          <Grow
            in={open}
            unmountOnExit
            mountOnEnter
            style={{ transformOrigin: '0 50% 0' }}
            {...(open ? { timeout: 500 } : {})}
          >
            <img className={clsx(classes.logo)} src={logo} alt='Primeleads' />
          </Grow>

          <Divider />

          <List disablePadding component={Box} flexGrow='1' display='flex' flexDirection='column'>
            <Box flexGrow='1'>
              {this.nav
                .filter((item) => (item.devMode ? isAdmin(userData) : true))
                .map((item, i) => (
                  <Fragment key={i}>
                    <ListItem
                      button
                      component={Link}
                      to={item.path}
                      onClick={this.hideNav}
                      selected={this.props.location.pathname === item.path}
                      disabled={!!item.exclude}
                    >
                      <ListItemIcon>
                        <WrappedIcon className={clsx(classes.icon)}>{item.icon}</WrappedIcon>
                      </ListItemIcon>
                      <ListItemText primary={t('nav.' + item.text)} />
                    </ListItem>
                    {item.divider && <Divider />}
                  </Fragment>
                ))}
            </Box>
            <ListItem
              button
              color='inherit'
              onClick={() => this.setState({ ...this.state, showFeatures: !this.state.showFeatures })}
              className={classes.link}
              disabled={this.getNewFeatures().length === 0}
            >
              <ListItemIcon>
                <Badge
                  badgeContent={this.getNewFeatures().length}
                  color='secondary'
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <WrappedIcon className={classes.icon}>
                    <NewReleasesIcon />
                  </WrappedIcon>
                </Badge>
              </ListItemIcon>
              <ListItemText primary={t('nav.newFeatures')} />
            </ListItem>
            <ListItem
              button
              component={HLink}
              href='https://primeleads.de/hilfe'
              target='_blank'
              color='inherit'
              className={classes.link}
              onClick={this.hideNav}
            >
              <ListItemIcon>
                <WrappedIcon className={classes.icon}>
                  <HelpIcon />
                </WrappedIcon>
              </ListItemIcon>
              <ListItemText primary={t('nav.help')} />
            </ListItem>
            <ListItem button color='inherit' className={classes.link} component={HLink} href='/logout'>
              <ListItemIcon>
                <WrappedIcon className={classes.icon}>
                  <ExitToAppIcon />
                </WrappedIcon>
              </ListItemIcon>
              <ListItemText primary={t('nav.logout')} />
            </ListItem>
          </List>
        </Drawer>
        <NewFeatures
          isNavOpen={open}
          open={this.state.showFeatures}
          features={this.state.features}
          onClose={() => this.setState({ ...this.state, showFeatures: false })}
        />
      </>
    );
  }
}

export default withStyles(styles)(withTheme(withTranslation()(withRouter(Sidebar))));

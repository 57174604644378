import { Common } from '../langTemplate';

const common: Common = {
  saveChanges: 'Änderung speichern',
  saveShort: 'Speichern',
  scriptCopied: 'Skript wurde in die Zwischenablage kopiert',
  createContent: 'Content erstellen',
  visits: 'Besuche',
  noData: 'Keine Daten verfügbar',
  preview: 'Vorschau',
  saving: 'Speichern von Änderungen an Datenbank',
  back: 'Zurück',
  availableActions: 'Verfügbare Aktionen',
  next: 'Nächster',
  previous: 'Vorher',
  edit: 'Bearbeiten',
  finish: 'Fertig',
  selectors: 'Selectoren',
  loading: 'Wir analysieren Ihren Datenverkehr',
  videoLoading: 'Video wird geladen',
  requestsLoading: 'Besuche werden geladen',
  copyToClipboard: 'Kopieren in die Zwischenablage',
  options: 'Optionen',
  visit: 'Besuch',
  duplicate: 'Kopieren',
  remove: 'Entfernen',
  date: 'Datum',
  shared: 'Geteilt',
  oldInterface: 'Altes design',
  pleaseChoose: 'Bitte wählen Sie eine Option',
  noScreenshot: 'Screenshot der Website konnte nicht abgerufen werden',
  error: 'Leider ist ein Fehler aufgetreten. Bitte werden Sie sich an den Support.',
  or: 'oder',
  fetchingMore: 'Laden Unternehmen...',
  purchased:
    'Die Zahlung war erfolgreich. Vielen Dank für den Kauf. Ihr Domain-Limit wurde auf {{limit}} geändert.',
  newWindow: 'In neuem Tab öffnen',
  copyOf: 'Kopie von ',
  connecting: 'Verbindung zur Datenbank',
  notAvailableInPlan: 'Diese Feature ist in Ihrem Plan nicht verfügbar',
  comingSoon: 'Diese Feature wird in Kürze verfügbar sein',
  optional: 'Optional',
  clear: 'Löschen',
  example: 'Beispiel',
  here: 'hier',
  previewTab: 'Vorschau',
  settingsTab: 'Einstellungen',
  analyticsTab: 'Analytik',
  visitsTab: 'Besuche',
  days: '{{days}} Tage',
  rules: '{{rules}} Regeln',
  companies: '{{companies}} Unternehmen',
  unlimited: 'Unbegrenzt',
  upgradePlan: 'Upgrade plan',
  errorSubscribe: 'Es ist ein Fehler aufgetreten. Bitte wenden Sie sich an unseren Support.',
  successSubscribe:
    'Erfolgreich angemeldet. Bitte überprüfen Sie Ihre Mailbox und bestätigen Sie das Newsletter.',
  analytics: {
    timeline: 'Zeitleiste mit allen Besuchen',
    triggeredBy: 'Ausgelöst von folgenden Firmen',
    spylinkEntries: 'Einträge über Dynamic Link',
    allVisits: 'Alle Auslöser',
    companyVisits: 'Ausgelöst von Unternehmen',
    uniqueCompanies: 'Einzigartige Unternehmen',
    userVisits: 'Auslöser von privaten Nutzern',
    privateUser: 'Wird vom ISP ausgelöst und entspricht dem Besuch eines privaten Benutzers',
    companies: 'Wird von einer anderen Firma als dem ISP ausgelöst',
    isp: '<b>ISP</b> - Internetdienstanbieter',
  },
  dialogs: {
    emptyName: 'Leere Domain Name gefunden',
    domainRemove: 'Entfernen Domains',
    domainLimit: 'Domainlimit erreicht',
    userRemove: 'Benutzer entfernen',
    cancel: 'Abbrechen',
    confirm: 'Bestätigen',
  },
  header: {
    loggedInAs: 'Angemeldet als',
    changeLanguage: 'Sprache ändern',
    availableDomains: 'Verfügbare Domains',
  },
  noDomain: {
    title: 'Keine Domain gefunden',
    subtitle: 'Sie haben noch keine Domain hinzugefügt. Es ist Zeit, es zu ändern!',
    button: 'Start Onboarding',
  },
  featureDisabled: {
    title: 'Ihr Plan enthält diese Funktion nicht',
    subtitle: 'Jetzt aktualisieren, und starten Sie es',
    button: 'Abonnieren',
  },
  trial: {
    ended: 'Ihre Testphase ist beendet. Jetzt Account abonnieren.',
    subscribe: 'Abonnieren',
    daysLeft: 'Ihre Testphase läuft in {{days}} Tagen aus. ',
    choosePlan: 'Pläne anzeigen.',
  },
  nav: {
    'dashboard': 'Dashboard',
    'visitors': 'Besucher',
    'dynamicContent': 'Dynamic content',
    'reminders': 'Erinnerungen',
    'settings': 'Einstellungen',
    'help': 'Hilfe',
    'logout': 'Abmelden',
    'companies': 'Unternehmen',
    'newFeatures': 'Neue Features',
    'spylink': 'Dynamic Link',
    'shared-documents': 'PDF-Tracking',
  },
};

export default common;

import React, { Component, FormEvent } from 'react';
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Fab,
  Switch,
  FormControlLabel
} from '@material-ui/core';
import { Reminder } from './Reminders';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import moment from 'moment';

export interface ReminderAddProps {
  onCancel: () => any;
  onSave: (reminder: Reminder) => any;
}
export interface ReminderAddState {
  reminder: Reminder;
}
const defaultReminder = {
  title: '',
  content: '',
  important: false,
  validTill: new Date(),
  notifications: {
    email: false,
    push: false
  }
} as Reminder;

export default class ReminderAdd extends Component<ReminderAddProps, ReminderAddState> {
  constructor(props: ReminderAddProps) {
    super(props);
    this.state = {
      reminder: defaultReminder
    };
    console.log(this.state);
  }
  updateField = (name: string, value: any) => {
    console.log(value);
    this.setState({ ...this.state, reminder: { ...this.state.reminder, [name]: value } });
  };
  handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    this.props.onSave(this.state.reminder);
  };

  cancelSubmit = () => {
    this.setState({ ...this.state, reminder: defaultReminder });
    this.props.onCancel();
  };

  render() {
    const { onCancel } = this.props;
    console.log(this.state);
    return (
      <form onSubmit={this.handleSubmit}>
        <Typography variant='h6' gutterBottom>
          Add new reminder
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <TextField
                variant='outlined'
                fullWidth
                label='title'
                name='title'
                value={this.state.reminder.title}
                margin='normal'
                required
                onChange={(e: any) => this.updateField(e.target.name, e.target.value)}
              />
              <DateTimePicker
                inputVariant='outlined'
                fullWidth
                label='Valid till'
                value={this.state.reminder.validTill}
                onChange={(value: any) => this.updateField('validTill', value.toDate())}
                margin='normal'
                required
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={7}>
            <TextField
              multiline
              rows='5'
              variant='outlined'
              fullWidth
              label='content'
              name='content'
              margin='normal'
              value={this.state.reminder.content}
              onChange={(e: any) => this.updateField(e.target.name, e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.reminder.important}
                  name='important'
                  onChange={(e: any) => this.updateField(e.target.name, e.target.checked)}
                />
              }
              label={<Typography variant='caption'>Mark as important</Typography>}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.reminder.notifications.email}
                  name='notifications["email"]'
                  onChange={(e: any) => this.updateField(e.target.name, e.target.checked)}
                />
              }
              label={<Typography variant='caption'>Email notifications</Typography>}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.reminder.notifications.push}
                  name='notifications.push'
                  onChange={(e: any) => this.updateField(e.target.name, e.target.checked)}
                />
              }
              label={<Typography variant='caption'>Push notifications</Typography>}
            />
          </Grid>
          <Grid item xs={12}>
            <Fab variant='extended' color='primary' type='submit' style={{ marginRight: 10 }}>
              Add new
            </Fab>
            <Fab variant='extended' onClick={this.cancelSubmit}>
              Cancel
            </Fab>
          </Grid>
        </Grid>
      </form>
    );
  }
}

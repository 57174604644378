import React from 'react';
import { Box, Paper, Grid } from '@material-ui/core';
import { DynamicContentInterface, DynamicContentSelector } from '../Dynamic';
import ContentEditor from './DynamicContentEditor';
import AppContext from '../../../data/AppContext';
import SaveSnackbar from '../../SaveSnackbar';
import { readOnlyMode } from '../../../helpers';

export default class EditorView extends React.Component<any, DynamicContentInterface> {
  mounted: boolean;
  static contextType = AppContext;
  isSaving: boolean;
  domain: string;
  constructor(props: any) {
    super(props);
    this.state = {
      selectors: [] as DynamicContentSelector[]
    } as any;
    this.mounted = false;
    this.isSaving = false;
    this.domain = '';
  }
  redirectToContent = () => {
    this.props.history.push('/dynamic-content');
  };
  componentDidMount = async () => {
    this.mounted = true;
    this.domain = this.context.getData.domain;
    await this.context.getDynamicContent();
    const content = this.context.getData.dynamicContent.find(
      (content: DynamicContentInterface) => content._id === this.props.match.params.id
    );
    if (!content) {
      this.redirectToContent();
    }
    if (this.mounted) this.setState(content);
  };
  componentDidUpdate() {
    if (this.domain && this.domain !== this.context.getData.domain) {
      this.redirectToContent();
    }
  }
  componentWillUnmount() {
    this.mounted = false;
  }

  saveChanges = async () => {
    this.isSaving = true;
    const dynamicContent = await this.context.getData.dynamicContent;
    dynamicContent.find(
      (content: any) => content._id === this.state._id
    ).selectors = this.state.selectors.filter((el: any) => el);
    await (this.context.setData = { dynamicContent });
    await this.context.setDynamicContent();
    this.isSaving = false;
  };
  deleteElement = (elementToRemove: DynamicContentSelector) => {
    const selectors = this.state.selectors.filter(
      (element: DynamicContentSelector) => element !== elementToRemove
    );
    console.log(selectors);
    this.setState({ ...this.state, selectors });
  };
  render() {
    return (
      <Box p={5}>
        {this.state && (
          <Grid container component={Paper}>
            <ContentEditor
              selectors={this.state.selectors}
              onChange={(selectors) => this.setState({ ...this.state, selectors })}
              website={this.state.website}
              editMode={!readOnlyMode(this.context.getData.userData, this.context.getData.domain)}
              onSave={this.saveChanges}
              onDelete={this.deleteElement}
            />
          </Grid>
        )}
        <SaveSnackbar open={this.isSaving} />
      </Box>
    );
  }
}

import React, { Component } from 'react';
import { IComponentProps, DocumentFile } from '../../models/app.model';
import { RouteComponentProps, Link } from 'react-router-dom';
import {
  createStyles,
  Theme,
  withStyles,
  Grid,
  Box,
  Typography,
  FormControlLabel,
  Switch,
  TextField,
  Tooltip,
  IconButton,
  Tabs,
  Tab,
  Paper,
  Fab,
  Snackbar,
} from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import AppContext from '../../data/AppContext';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import BackspaceIcon from '@material-ui/icons/Backspace';
import LinkIcon from '@material-ui/icons/Link';
import { copyToClipboard, TypoPopover } from '../../helpers';
import SaveSnackbar from '../SaveSnackbar';
import PdfVisits from './PdfVisits';
import { Alert } from '@material-ui/lab';
import moment from 'moment';

const styles = createStyles((theme: Theme) => ({
  thumbnail: {
    display: 'block',
    width: '100%',
    boxShadow: '0px 10px 15px #ccc',
  },
  tabs: {
    backgroundColor: '#fff',
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  linkContainer: {
    'backgroundColor': theme.palette.primary.main,
    'color': theme.palette.primary.contrastText,
    'boxShadow': `0 0 10px -5px ${theme.palette.grey[500]}`,
    'borderBottom': `3px solid ${theme.palette.secondary.main}`,
    'cursor': 'pointer',
    'transition': 'all .25s ease',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      boxShadow: `0 0 20px -5px ${theme.palette.grey[500]}`,
      borderBottom: `3px solid ${theme.palette.secondary.dark}`,
    },
  },
  link: {
    margin: theme.spacing(1, 0),
    backgroundColor: theme.palette.grey[200],
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: `5px`,
    width: '85%',
  },
  tooltip: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export interface PdfDetailsProps extends IComponentProps, RouteComponentProps {}
export interface PdfDetailsState {
  document: DocumentFile;
  activeTab: number;
  isSaving: boolean;
  showPassword: boolean;
  isCopied: boolean;
  focusedInput: string | null;
  domain: string;
}
class PdfDetails extends Component<PdfDetailsProps, PdfDetailsState> {
  static contextType = AppContext;
  state = {
    activeTab: 0,
    isSaving: false,
    showPassword: false,
    focusedInput: null,
  } as PdfDetailsState;
  tabs = ['visits', 'settings'];
  componentDidMount = async () => {
    const { id } = (this.props.match as any).params;
    const { state }: { state: any } = this.props.location;
    if (state && !!state.activeTab) {
      await this.setState({ ...this.state, activeTab: state.activeTab });
    }
    if (!id) {
      return this.props.history.push('/documents');
    }
    const document = await this.context.fetchUserDocumentById(id);
    if (!document) {
      return this.props.history.push('/documents');
    }
    this.setState({ ...this.state, document });
  };
  componentDidUpdate() {
    if (this.state.document && this.state.document.domain !== this.context.getData.domain.key) {
      return this.props.history.push('/documents');
    }
  }
  displayThumbnail = () => {
    return (
      <img
        className={this.props.classes.thumbnail}
        src={'/' + this.state.document.thumbnailPath}
        alt='Document thumbnail'
      />
    );
  };
  copyToClipboard = (text: string) => {
    this.setState({ ...this.state, isCopied: true });
    copyToClipboard(text);
  };
  displayInfo = () => {
    const { document } = this.state;
    const { domain, detectedUrl } = this.context.getData;
    const { pdfEndpoint } = domain;
    const link = `https://share.primeleads.de/${document.domain}/${document.slug}`;
    const customLink = `${detectedUrl}/${pdfEndpoint}/${document.slug}`;
    return (
      <Box mt={2}>
        <Typography variant='h4' gutterBottom>
          {document.title}
        </Typography>
        <Typography variant='subtitle1' gutterBottom>
          {document.description}
        </Typography>
        <Typography variant='caption' color='textSecondary'>
          {`${this.props.t('addedOn')} `}
          <Typography component='span' color='textPrimary' variant='caption'>
            {new Date(document.addedAt).toLocaleString()}
          </Typography>
        </Typography>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Box display='flex' alignItems='center' p={1} className={this.props.classes.link} mr={1}>
            <LinkIcon style={{ marginRight: 10 }} />
            <Box style={{ width: '85%' }}>
              <TypoPopover variant='body2' text={link} noWrap>
                {link}
              </TypoPopover>
            </Box>
          </Box>
          <Tooltip title={this.props.t('common:copyToClipboard') as string}>
            <IconButton
              onClick={() => {
                this.copyToClipboard(link);
              }}
            >
              <FileCopyIcon color='secondary' />
            </IconButton>
          </Tooltip>
        </Box>
        {this.context.getData.domain.pdfEndpoint && (
          <Box display='flex' alignItems='center' justifyContent='space-between'>
            <Box display='flex' alignItems='center' p={1} className={this.props.classes.link} mr={1}>
              <LinkIcon style={{ marginRight: 10 }} />
              <Box style={{ width: '85%' }}>
                <TypoPopover variant='body2' text={customLink} noWrap>
                  {customLink}
                </TypoPopover>
              </Box>
            </Box>
            <Tooltip title={this.props.t('common:copyToClipboard') as string}>
              <IconButton
                onClick={() => {
                  this.copyToClipboard(customLink);
                }}
              >
                <FileCopyIcon color='secondary' />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        <Box my={2}>
          <Link to='/documents/custom-domain'>How to set up a custom domain?</Link>
        </Box>
      </Box>
    );
  };
  togglePasswordVisibility = () => {
    this.setState({ ...this.state, showPassword: !this.state.showPassword });
  };
  updatePropByName = (e: React.ChangeEvent<HTMLInputElement>, bool?: boolean) => {
    const checked = (this.state.document as any)[e.target.name];
    this.setState({
      ...this.state,
      document: {
        ...this.state.document,
        [e.target.name]: bool ? !checked : e.target.value,
      },
    });
  };
  updateNestedPropByName = (e: React.ChangeEvent<any>, name: string, bool?: boolean) => {
    const checked = (this.state.document as any)[name][e.target.name];
    this.setState({
      ...this.state,
      document: {
        ...this.state.document,
        [name]: {
          ...(this.state.document as any)[name],
          [e.target.name]: bool ? !checked : e.target.value,
        },
      },
    });
  };
  displaySettings = () => {
    const { document } = this.state;
    const { t, classes } = this.props;
    return (
      <form onSubmit={this.saveChanges}>
        <Box p={3} position='relative'>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box mb={3}>
                <TextField
                  label={t('document.title')}
                  fullWidth
                  variant='outlined'
                  value={document.title}
                  margin='normal'
                  name='title'
                  onChange={this.updatePropByName}
                />
                <TextField
                  fullWidth
                  variant='outlined'
                  label={t('document.description')}
                  name='description'
                  value={document.description || ''}
                  multiline
                  rows={3}
                  margin='normal'
                  InputLabelProps={{ shrink: true }}
                  onChange={this.updatePropByName}
                />
                <TextField
                  label={t('document.slug')}
                  fullWidth
                  variant='outlined'
                  value={document.slug}
                  margin='normal'
                  name='slug'
                  helperText={
                    <Typography variant='caption'>
                      {t('document.linkPreview')}
                      {` `}
                      <Typography component='span' variant='caption' color='secondary'>
                        https://share.primeleads.de/{document.domain}/{document.slug}
                      </Typography>
                    </Typography>
                  }
                  onChange={this.updatePropByName}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box>
                <Typography variant='h6'>{t('document.passwordProtected')}</Typography>
                <FormControlLabel
                  label={
                    document.protection?.disabled
                      ? t('document.protectionDisabled')
                      : t('document.protectionEnabled')
                  }
                  control={
                    <Switch
                      checked={!document.protection?.disabled || false}
                      name='disabled'
                      onChange={(e) => this.updateNestedPropByName(e, 'protection', true)}
                    />
                  }
                />
                {!document.protection?.disabled && (
                  <Box mt={1}>
                    <TextField
                      variant='outlined'
                      value={document.protection?.password}
                      type={this.state.showPassword ? 'text' : 'password'}
                      name='password'
                      label={t('document.password')}
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => this.updateNestedPropByName(e, 'protection')}
                      inputProps={{
                        minLength: 3,
                      }}
                      required
                      InputProps={{
                        endAdornment: (
                          <Tooltip title={this.state.showPassword ? 'Hide password' : 'Show password'}>
                            <IconButton onClick={this.togglePasswordVisibility}>
                              {this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                          </Tooltip>
                        ),
                      }}
                    />
                  </Box>
                )}
                <Typography variant='h6'>{t('document.notification')}</Typography>
                <FormControlLabel
                  label={
                    document.notification?.active
                      ? t('document.notificationEnabled')
                      : t('document.notificationDisabled')
                  }
                  control={
                    <Switch
                      checked={document.notification?.active || false}
                      name='active'
                      onChange={(e) => this.updateNestedPropByName(e, 'notification', true)}
                    />
                  }
                />
              </Box>
            </Grid>
            {document.notification.active && this.getNotificationTab()}
            <Grid item xs={12} md={6}>
              <Box mb={3}>
                <Typography variant='h6'>{t('document.status')}</Typography>
                <FormControlLabel
                  label={t('document.published')}
                  control={
                    <Switch
                      checked={document.published.status || false}
                      name='status'
                      onChange={(e) => this.updateNestedPropByName(e, 'published', true)}
                    />
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant='h6'>{t('document.publishDateRange')}</Typography>
              <FormControlLabel
                label={t('document.dateRange')}
                control={
                  <Switch
                    checked={document.published.timeLimit || false}
                    name='timeLimit'
                    onChange={(e) => this.updateNestedPropByName(e, 'published', true)}
                  />
                }
              />
            </Grid>
            {this.state.document.published.timeLimit && this.getPublishDateRange()}
          </Grid>
          <Box
            p={3}
            my={3}
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            className={classes.linkContainer}
          >
            <Typography>{`https://share.primeleads.de/${document.domain}/${document.slug}`}</Typography>
            <Tooltip title={t('common:copyToClipboard') as string} classes={{ tooltip: classes.tooltip }}>
              <IconButton
                onClick={() => {
                  this.copyToClipboard(
                    `https://share.primeleads.de/${document.domain}/${document.slug}`
                  );
                }}
              >
                <FileCopyIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box position='absolute' left='0' top='100%' my={1}>
            <Fab variant='extended' type='submit' size='small' color='primary'>
              {this.props.t('common:save') as string}
            </Fab>
          </Box>
        </Box>
      </form>
    );
  };
  getPublishDateRange = () => {
    const { document } = this.state;
    const { t } = this.props;
    console.log(document.published);
    const handleDateChange = (stateKey: string) => (e: any) => {
      console.log('change triggered');
      (document.published as any)[stateKey] = e ? moment(e.target.value).toISOString(true) : '';
      this.setState({ ...this.state, document });
    };
    const clearDate = (stateKey: string) => {
      console.log('clear triggered');
      (document.published as any)[stateKey] = '';
      this.setState({ ...this.state, document });
    };
    const { from, to } = document.published;
    return (
      <>
        <Grid item xs={12} md={6}>
          <Box display='flex' alignItems='center'>
            <TextField
              label={t('document.publishedFrom')}
              type='datetime-local'
              value={from ? from.slice(0, 16) : ''}
              InputLabelProps={{
                shrink: true,
              }}
              variant='outlined'
              onChange={handleDateChange('from')}
            />
            <IconButton onClick={() => clearDate('from')}>
              <BackspaceIcon />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box display='flex' alignItems='center'>
            <TextField
              label={t('document.publishedTill')}
              type='datetime-local'
              value={to ? to.slice(0, 16) : ''}
              InputLabelProps={{
                shrink: true,
              }}
              variant='outlined'
              onChange={handleDateChange('to')}
            />
            <IconButton onClick={() => clearDate('to')}>
              <BackspaceIcon />
            </IconButton>
          </Box>
        </Grid>
      </>
    );
  };
  getNotificationTab = () => {
    const options = ['visit', 'daily', 'weekly'];
    return (
      <Grid item xs={12} container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant='h6'>Email</Typography>
          <TextField
            value={this.state.document.notification.email}
            variant='outlined'
            name='email'
            onChange={(e) => this.updateNestedPropByName(e, 'notification', false)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant='h6'>Frequency</Typography>
          <TextField
            value={this.state.document.notification.frequency}
            variant='outlined'
            name='frequency'
            onChange={(e) => this.updateNestedPropByName(e, 'notification', false)}
            fullWidth
            select
            SelectProps={{ native: true }}
            InputLabelProps={{
              shrink: true,
            }}
          >
            <option value=''>Choose frequency</option>
            {options.map((o, i) => (
              <option key={i} value={o}>
                {this.props.t('document.frequency.' + o)}
              </option>
            ))}
          </TextField>
        </Grid>
      </Grid>
    );
  };
  getActiveTab = () => {
    switch (this.state.activeTab) {
      case 0:
        return <PdfVisits visitors={this.state.document.stats.visitors} />;
      case 1:
        return this.displaySettings();
    }
  };
  saveChanges = async (e: React.FormEvent) => {
    e.preventDefault();
    await this.setState({ ...this.state, isSaving: true });
    await this.context.updateDocument(this.state.document);
    this.setState({ ...this.state, isSaving: false });
  };

  displayDocument = () => {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            {this.displayThumbnail()}
            {this.displayInfo()}
          </Grid>
          <Grid item xs={12} sm={8}>
            <Box component={Paper} mb={1}>
              <Tabs
                value={this.state.activeTab}
                onChange={(e, value) => this.setState({ ...this.state, activeTab: value })}
                className={this.props.classes.tabs}
              >
                {this.tabs.map((tab, i) => {
                  return <Tab key={i} label={this.props.t('tabs.' + tab)} />;
                })}
              </Tabs>
              {this.getActiveTab()}
            </Box>
          </Grid>
        </Grid>
        <SaveSnackbar open={this.state.isSaving} />
        <Snackbar
          open={this.state.isCopied}
          autoHideDuration={3000}
          onClose={() => this.setState({ ...this.state, isCopied: false })}
        >
          <Alert severity='info' variant='filled'>
            {this.props.t('linkCopied')}
          </Alert>
        </Snackbar>
      </>
    );
  };
  render() {
    return (
      <Box mb={10} p={5}>
        {this.state.document ? this.displayDocument() : null}
      </Box>
    );
  }
}

export default withStyles(styles)(withTranslation(['uploadPdf', 'common'])(PdfDetails));

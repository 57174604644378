import React, { ChangeEvent } from 'react';
//CONTEXT
import AppContext from '../data/AppContext';
//COMPONENTS
import DomainSelect from './DomainSelect';
import LanguageSelect from './LanguageSelect';
import {
  Box,
  Typography,
  IconButton,
  makeStyles,
  Grid,
  Avatar,
  Hidden,
  TextField,
  Button,
  Popper,
  Fade,
  Paper,
  ClickAwayListener,
  Container,
} from '@material-ui/core';
//ICONS
import MenuIcon from '@material-ui/icons/Menu';
import BuildIcon from '@material-ui/icons/Build';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//MODELS
import { useTranslation } from 'react-i18next';

import { Theme } from '@material-ui/core/styles';
import { isAdmin, getCookieByName } from '../helpers';
import { Autocomplete } from '@material-ui/lab';
import { AuthUser } from '../models/app.model';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
const styles = makeStyles((theme: Theme) => ({
  header: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1, 5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row-reverse',
      padding: theme.spacing(2, 5),
    },
  },
  select: {
    [theme.breakpoints.down('sm')]: {
      order: 1,
      marginTop: theme.spacing(1),
    },
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  menu: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  icon: {
    margin: theme.spacing(0, 1),
  },
  paperRoot: {
    '&.MuiPaper-root': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  rotate: {
    transform: 'rotate(180deg)',
  },
}));

const Header: React.FC<{}> = () => {
  const context = React.useContext(AppContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [details, setDetails] = React.useState({} as any);
  const classes = styles();
  const { t } = useTranslation(['common', 'settings']);
  const [userData] = React.useState(context.getData.userData);
  const [allUsers, setAllUsers] = React.useState([] as AuthUser[]);
  const fetchDetails = async ()=>{
    const details = context.getUserInfo();
    setDetails(details);
  }
  const fetchAllUsers = async () => {
    if (!isAdmin(userData)) {
      return;
    }
    
    const users = context.getData.allUsers
      .filter((user: AuthUser) => user.emails && user.emails.length > 0)
      .sort((userA: AuthUser, userB: AuthUser) =>
        userA.emails[0].value > userB.emails[0].value ? 1 : -1
      );
    setAllUsers(users);
  }
  React.useEffect(() => {
    fetchAllUsers();
    fetchDetails();
  }, []);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box>
        <Grid container className={classes.header}>
          {context.getData.domains.length > 0 && (
            <Grid item xs={12} md={4} className={classes.select}>
              <DomainSelect />
            </Grid>
          )}
          <Grid item xs={12} md={'auto'}>
            <Box display='flex' alignItems='center' justifyContent='space-between' flexWrap='wrap'>
              <Hidden smUp>
                <IconButton onClick={context.toggleNav}>
                  <MenuIcon color='primary' fontSize='large' className={classes.icon} />
                </IconButton>
              </Hidden>
              <Box
                display='flex'
                alignItems='center'
                justifyContent='flex-end'
                flexGrow='1'
                flexWrap='wrap'
                my={2}
              >
                {isAdmin(userData) && (
                  <>
                    <Box mr={2}>
                      <IconButton component={Link} to='/admin'>
                        <BuildIcon />
                      </IconButton>
                    </Box>
                    <Autocomplete
                      options={allUsers}
                      value={userData}
                      getOptionLabel={(option) =>
                        option.emails.length > 0 ? option.emails[0].value : ''
                      }
                      selectOnFocus={true}
                      onChange={async (e: ChangeEvent<{}>, value: AuthUser | null) => {
                        if (value && context.getData.userData !== value) {
                          await context.changeUser((value as AuthUser).id);
                        }
                      }}
                      renderOption={(option: AuthUser) => {
                        return <Typography variant='body2'>
                        {option.emails.length > 0 ? (
                          <>
                          {option.stripe?.plan_id &&
                                !context.getData.plans.FREE.includes(option.stripe.plan_id) && (
                                  <>&#11088;</>
                                )}
                            {option.emails[0].value}
                          </>
                        ) : (
                          ''
                        )}
                      </Typography>
                        
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant='outlined' style={{ minWidth: '300px' }} />
                      )}
                    />
                  </>
                )}
                {getCookieByName('adminAuth') && (
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={context.clearUser}
                    style={{ margin: '0 10px' }}
                  >
                    Clear user
                  </Button>
                )}
                <Box display='flex' alignItems='center' mx={3} position='relative'>
                  <Avatar src={userData.picture} className={classes.avatar} />
                  <Box display='flex' flexDirection='column' mx={2}>
                    <Typography variant='caption' color='textSecondary'>
                      {t('header.loggedInAs')}
                    </Typography>
                    <Typography variant='h6' component='span'>
                      <b>{userData.displayName}</b>
                    </Typography>
                  </Box>
                  <IconButton
                    size='small'
                    onClick={handleClick}
                    className={clsx(open && classes.rotate)}
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                </Box>

                <div>
                  <Popper
                    open={open}
                    anchorEl={anchorEl}
                    placement='bottom-end'
                    role={undefined}
                    style={{ zIndex: 10 }}
                    transition
                  >
                    {({ TransitionProps }) => (
                      <ClickAwayListener onClickAway={handleClose}>
                        <Fade {...TransitionProps} timeout={350}>
                          <Paper classes={{ root: classes.paperRoot }}>
                            <Container maxWidth='xs'>
                              <Box display='flex' my={2} px={3} py={2} flexWrap='wrap'>
                                <Box flexBasis='50%'>
                                  <Typography variant='caption' color='secondary'>
                                    Plan
                                  </Typography>
                                  <Typography variant='h6' gutterBottom>
                                    {details.plan}
                                  </Typography>
                                </Box>
                                <Box flexBasis='50%'>
                                  <Typography variant='caption' color='secondary'>
                                    {t('settings:upgrade.features.domains')}
                                  </Typography>
                                  <Typography variant='h6' gutterBottom>
                                    {details.domains}
                                  </Typography>
                                </Box>
                                <Box flexBasis='50%'>
                                  <Typography variant='caption' color='secondary'>
                                    {t('settings:upgrade.features.companyLimit')}
                                  </Typography>
                                  <Typography variant='h6' gutterBottom>
                                    {details.companyLimit > -1
                                      ? t('companies', { companies: details.companyLimit })
                                      : t('unlimited')}
                                  </Typography>
                                </Box>
                                <Box flexBasis='50%'>
                                  <Typography variant='caption' color='secondary'>
                                    {t('settings:upgrade.features.history')}
                                  </Typography>
                                  <Typography variant='h6' gutterBottom>
                                    {details.history > -1
                                      ? t('days', { days: details.history })
                                      : t('unlimited')}
                                  </Typography>
                                </Box>
                                <Box flexBasis='50%'>
                                  <Typography variant='caption' color='secondary'>
                                    {t('settings:upgrade.features.content')}
                                  </Typography>
                                  <Typography variant='h6' gutterBottom>
                                    {details.content > -1
                                      ? t('rules', { rules: details.content })
                                      : t('unlimited')}
                                  </Typography>
                                </Box>
                                <Box flexBasis='50%'>
                                  <Typography variant='caption' color='secondary'>
                                    {t('settings:upgrade.features.spylink')}
                                  </Typography>
                                  <Typography variant='h6' gutterBottom>
                                    {details.spylink > -1
                                      ? t('rules', { rules: details.spylink })
                                      : t('unlimited')}
                                  </Typography>
                                </Box>
                                <Box flexBasis='100%' my={1}>
                                  <Button
                                    component={Link}
                                    to={{
                                      pathname: '/settings',
                                      state: {
                                        activeTab: 5,
                                      },
                                    }}
                                    variant='contained'
                                    color='secondary'
                                    fullWidth
                                  >
                                    {t('upgradePlan')}
                                  </Button>
                                </Box>
                              </Box>
                            </Container>
                          </Paper>
                        </Fade>
                      </ClickAwayListener>
                    )}
                  </Popper>
                </div>
                <LanguageSelect />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Header;

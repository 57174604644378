import React, { Component, Fragment } from 'react';
import { Reminder } from './Reminders';
import { Box, Theme, Typography, Tooltip, IconButton, Toolbar } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/styles';
import { withTranslation } from 'react-i18next';
import { IComponentProps } from '../../models/app.model';
//ICONS
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import TimerOffIcon from '@material-ui/icons/TimerOff';
import TodayIcon from '@material-ui/icons/Today';
import GroupIcon from '@material-ui/icons/Group';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import EditIcon from '@material-ui/icons/Edit';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DoneIcon from '@material-ui/icons/Done';

//HELPERS
import { getCompanyIcon } from '../../helpers';
import moment from 'moment';
import clsx from 'clsx';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      'backgroundColor': '#fdfdfd',
      'cursor': 'pointer',
      'transition': 'background 500ms ease',
      'borderWidth': 0,
      'borderLeftWidth': '5px',
      'borderStyle': 'solid',
      'boxShadow': 'inset 0 -10px 15px -15px #aaa',
      '&:hover': {
        backgroundColor: theme.palette.grey[200]
      }
    },
    selected: {
      backgroundColor: theme.palette.grey[100]
    },
    expired: {
      borderColor: theme.palette.error.main
    },
    done: {
      borderColor: theme.palette.success.main
    },
    open: {
      borderColor: theme.palette.info.main
    },
    top: {
      borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    textError: {
      color: theme.palette.error.main
    },
    textSuccess: {
      color: theme.palette.success.main
    },
    textInfo: {
      color: theme.palette.info.main
    }
  });

export interface RemindersListProps extends IComponentProps {
  reminders: Reminder[];
  onSelect?: (e: Reminder) => any;
  selected?: Reminder;
  onUpdate: (reminder: Reminder, name: string, value: any) => any;
  onRemove: (reminder: Reminder) => any;
  onEdit?: (reminder: Reminder) => any;
  companyView?: boolean;
}
export interface RemindersListState {}
class RemindersList extends Component<RemindersListProps, RemindersListState> {
  getBoxClass = (reminder: Reminder) => {
    if (reminder.status === 'done') {
      return this.props.classes.done;
    }
    if (!reminder.expired && reminder.status === 'open') {
      return this.props.classes.open;
    }
    if (reminder.expired && reminder.status === 'open') {
      return this.props.classes.expired;
    }
  };
  getStatus = (reminder: Reminder) => {
    let status = 'Completed';
    let color = 'Success';
    if (reminder.expired && reminder.status === 'open') {
      status = 'Expired ' + moment(new Date(reminder.validTill)).fromNow();
      color = 'Error';
    } else if (!reminder.expired && reminder.status === 'open') {
      status = 'Finishing in ' + moment(new Date(reminder.validTill)).fromNow();
      color = 'Info';
    }
    return (
      <Typography
        variant='caption'
        className={this.props.classes['text' + color]}
        style={{ marginRight: 10 }}
      >
        {status}
      </Typography>
    );
  };
  getCompanyName = (reminder: Reminder) => {
    if (!this.props.companyView) {
      return (
        <Box display='flex' alignItems='center' className={this.props.classes.top} px={2} py={1}>
          {getCompanyIcon(reminder.company.companyDomain)}
          <Typography variant='h6' color='primary'>
            {reminder.company.companyName}
          </Typography>
        </Box>
      );
    }
  };
  getControls = (reminder: Reminder) => {
    const { onUpdate, onRemove } = this.props;
    return (
      <>
        <Tooltip title={reminder.status === 'open' ? 'Mark as completed' : 'Mark as open'}>
          <IconButton
            onClick={(e: any) => {
              e.stopPropagation();
              onUpdate(reminder, 'status', reminder.status === 'open' ? 'done' : 'open');
            }}
          >
            {reminder.status === 'open' ? <DoneIcon /> : <HourglassEmptyIcon />}
          </IconButton>
        </Tooltip>
        {this.props.companyView && (
          <Tooltip title='Edit reminder'>
            <IconButton>
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}

        {reminder.owner ? (
          <Tooltip title='Remove'>
            <IconButton
              onClick={(e: any) => {
                e.stopPropagation();
                onRemove(reminder);
              }}
            >
              <DeleteForeverIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <IconButton disabled={true}>
            <DeleteForeverIcon />
          </IconButton>
        )}
      </>
    );
  };
  getReminderBox = (reminder: Reminder) => {
    const { t, classes, onSelect = () => {}, selected } = this.props;
    return (
      <Box
        className={clsx(
          classes.root,
          this.getBoxClass(reminder),
          selected === reminder && classes.selected
        )}
        onClick={() => onSelect(reminder)}
      >
        <Box display='flex' alignItems='center' px={2} py={1} justifyContent='flex-end'>
          {reminder.important && (
            <Box flexGrow='1'>
              <Typography variant='caption'>Important</Typography>
              <PriorityHighIcon />
            </Box>
          )}
          <Box display='flex' alignItems='center'>
            {reminder.sharedWith.length > 0 && (
              <Tooltip title={`Shared with ${reminder.sharedWith.length} users`}>
                <IconButton size='small' style={{ marginRight: 10 }}>
                  <GroupIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            )}
            {this.getStatus(reminder)}
          </Box>
        </Box>
        {this.getCompanyName(reminder)}

        <Box display='flex' justifyContent='space-between' py={1} px={2}>
          <Box mb={1}>
            <Typography variant='h6' gutterBottom>
              {reminder.title}
            </Typography>

            <Typography variant='body2' gutterBottom>
              {reminder.content}
            </Typography>
          </Box>

          <Box>{this.getControls(reminder)}</Box>
        </Box>
      </Box>
    );
  };
  render() {
    return this.props.reminders.map((reminder: Reminder, i: number) => {
      return <Fragment key={i}>{this.getReminderBox(reminder)}</Fragment>;
    });
  }
}

export default withStyles(styles)(withTranslation()(RemindersList));

import React from 'react';
import {
  Typography,
  Box,
  Switch,
  FormControlLabel,
  IconButton,
  makeStyles,
  Slider,
  Tooltip,
  TooltipProps,
} from '@material-ui/core';
import { Replayer, EventType } from 'rrweb';
import clsx from 'clsx';
import {
  formatTime,
  openFullscreen,
  isFullscreen,
  exitFullscreen,
  onFullscreenChange,
} from './videoUtils';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import SkipNextIcon from '@material-ui/icons/SkipNext';
export interface CustomPlayerOptions {
  autoPlay?: boolean;
  target: HTMLElement;
  speed?: number;
  onLoad?: (current: number) => any;
  onSnapshotRebuild?: () => any;
  rerender: () => any;
  events: any[];
  width?: number;
  height?: number;
  allVideos: number;
  current: number;
  meta: {
    company: any;
    visitDate: string;
  };
}

class CustomPlayer {
  private options: any;
  private replayer: Replayer;
  private localState: any = {
    isFinished: false,
    isPlaying: false,
    currentTime: 0,
    meta: null,
    timer: null,
    isSkipping: false,
  };

  constructor(props: CustomPlayerOptions) {
    // DEFAULT OPTIONS
    const defaultOptions = {
      speed: 1,
      autoPlay: true,
      width: 1024,
      height: 576,
      showController: true,
      skipInactive: true,
    };

    // OPTIONS FROM THE USER
    const { events, ...rest } = props;
    this.options = Object.assign({}, defaultOptions, rest);
    // INIT
    this.replayer = new Replayer(events, {
      speed: this.options.speed,
      root: this.options.target,
      skipInactive: this.options.skipInactive,
    });
    this.localState.meta = this.replayer.getMetaData();
    this.localState.currentTime = this.options.timeOffset || 0;
    this.replayer.on('custom-event', this.castListener);
    this.replayer.on('finish', this.handleFinish);
    this.replayer.on('fullsnapshot-rebuilded', this.handleSnapshotRebuild);
    this.replayer.on('resize', (dimension) => this.updateScale(this.replayer.wrapper, dimension));
    this.replayer.on('skip-start', (payload: any) => this.handleSkip(payload, true));
    this.replayer.on('skip-end', (payload: any) => this.handleSkip(payload, false));
    this.replayer.disableInteract();
    // AUTOPLAY
    if (this.options.autoPlay) {
      this.localState.isPlaying = true;
      this.localState.isFinished = false;
      this.replayer.play(this.localState.currentTime);
      this.loopTimer();
    }
  }
  handleSkip = (payload: any, isSkipping: boolean) => {
    this.replayer.setConfig(payload);
    this.localState.isSkipping = isSkipping;
    this.options.rerender();
  };
  loopTimer = () => {
    const self = this;

    function update() {
      if (!self.localState.isPlaying) {
        self.localState.timer = null;
        self.options.rerender();
        return;
      }
      self.localState.currentTime = self.replayer.timer.timeOffset + self.replayer.getTimeOffset();
      if (self.localState.currentTime <= self.localState.meta.totalTime) {
        requestAnimationFrame(update);
        self.options.rerender();
      }
    }
    this.localState.timer = requestAnimationFrame(update);
  };
  handleClose = () => {
    this.replayer.pause();
  };
  hasNext = () => {
    return this.options.current < this.options.allVideos - 1;
  };
  hasPrev = () => {
    return this.options.current > 0;
  };
  handleNext = () => {
    if (this.hasNext()) {
      this.localState.isPlaying = false;
      this.replayer.pause();
      const newOptions = {
        skipInactive: this.options.skipInactive,
        speed: this.options.speed,
      };
      this.options.onLoad(this.options.current + 1, newOptions);
    }
  };
  handlePrev = () => {
    if (this.hasPrev()) {
      this.localState.isPlaying = false;
      this.replayer.pause();
      const newOptions = {
        skipInactive: this.options.skipInactive,
        speed: this.options.speed,
      };
      this.options.onLoad(this.options.current - 1, newOptions);
    }
  };
  handleFinish = () => {
    if (isFullscreen()) {
      exitFullscreen();
    }
    this.localState.isFinished = true;
    this.localState.isPlaying = false;
    this.localState.currentTime = 0;
    this.replayer.pause();
    if (this.options.onFinish) {
      this.options.onFinish();
    }
    this.handlePrev();
  };
  handleSnapshotRebuild = () => {
    if (this.options.onSnapshotRebuild) {
      this.options.onSnapshotRebuild();
    }
  };
  handleTimeline = (event: any) => {
    const { meta, isSkipping } = this.localState;
    if (isSkipping) {
      return;
    }
    const progressRect = document.getElementById('timeline')?.getBoundingClientRect();
    if (!progressRect) {
      return;
    }
    const x = event.clientX - progressRect.left;
    let percent = x / progressRect.width;
    if (percent < 0) {
      percent = 0;
    } else if (percent > 1) {
      percent = 1;
    }
    const timeOffset = meta.totalTime * percent;
    this.replayer.timer.timeOffset = timeOffset;
    this.replayer.pause();
    this.localState.isPlaying = false;
    const newOptions = {
      skipInactive: this.options.skipInactive,
      speed: this.options.speed,
      timeOffset,
    };
    this.options.onLoad(this.options.current, newOptions);
  };
  updateScale(el: HTMLElement, frameDimension: any) {
    const { width, height } = this.options;
    const widthScale = width / frameDimension.width;
    const heightScale = height / frameDimension.height;
    el.style.transform = `scale(${Math.min(widthScale, heightScale)}) translate(-50%, -50%)`;
  }
  togglePlay = () => {
    if (this.localState.isPlaying) {
      this.replayer.pause();
      this.localState.isPlaying = false;
      this.loopTimer();
    } else {
      if (this.localState.isFinished) {
        this.options.onLoad(this.options.current, {
          speed: this.options.speed,
          skipInactive: this.options.skipInactive,
        });
      } else {
        this.replayer.resume(this.localState.currentTime);
        this.localState.isPlaying = true;
        this.loopTimer();
      }
    }
  };
  toggleSwitch = (e: any) => {
    this.options[e.target.name] = e.target.checked;
    const { skipInactive } = this.options;
    this.replayer.setConfig({ skipInactive });
    this.options.rerender();
  };
  handleFullScreen = () => {
    if (this.replayer && this.replayer.wrapper) {
      isFullscreen() ? exitFullscreen() : openFullscreen(this.replayer.wrapper);
    }
  };
  fullscreenListener = onFullscreenChange(() => {
    if (isFullscreen()) {
      setTimeout(() => {
        const dimension = {
          width: this.replayer.wrapper.offsetWidth,
          height: this.replayer.wrapper.offsetHeight,
        };
        this.replayer.iframe.height = dimension.height + 'px';
        this.replayer.iframe.width = dimension.width + 'px';
      }, 0);
    }
  });
  getDimension = () => {
    return {
      width: this.options.width,
      height: this.options.height,
    };
  };
  castListener = (e: any) => {
    if (e.type === EventType.Custom) {
      const {
        startIndex,
        startOffset,
        startTextNode,
        endIndex,
        endOffset,
        endTextNode,
      }: {
        commonAncestorContainer: any;
        commonAncestorIndex: any;
        startContainer: any;
        startIndex: number;
        startOffset: number;
        startTextNode: boolean;
        endContainer: any;
        endIndex: number;
        endOffset: number;
        endTextNode: boolean;
      } = e.data.payload;
      const win = this.options.target.querySelector('iframe').contentWindow;
      const doc = this.options.target.querySelector('iframe').contentDocument;
      const allElements = Array.from(doc.body.querySelectorAll('*')) as HTMLElement[];
      const range = document.createRange();
      const startNode = allElements[startIndex];
      const endNode = allElements[endIndex];
      range.setStart(startTextNode ? startNode.childNodes[0] : startNode, startOffset);
      range.setEnd(endTextNode ? endNode.childNodes[0] : endNode, endOffset);
      win.getSelection()?.removeAllRanges();
      win.getSelection()?.addRange(range);
    }
  };
  isControllerVisible = () => this.options.showController;
  changeSpeed = (event: any, value: any) => {
    this.options.speed = value;
    this.replayer.setConfig({ speed: value });
    this.options.rerender();
  };
  percentage(currentTime: number) {
    const percent = Math.min(1, currentTime / this.localState.meta.totalTime);
    return `${100 * percent}%`;
  }

  getControls() {
    const { company, visitDate } = this.options.meta;
    const styles = {
      icon: {
        color: '#fff',
      },
      progressBar: {},
    };
    const marks = [
      { value: 1, label: '1' },
      { value: 2, label: '2' },
      { value: 4, label: '4' },
      { value: 8, label: '8' },
    ];
    return (
      <Box p={3} pt={10}>
        <Box display='flex'>
          <Typography variant='h5'>
            {this.options.allVideos - this.options.current} / {this.options.allVideos}
          </Typography>
          <Box ml={2}>
            <Typography variant='h4'>{company.name}</Typography>
            <Typography variant='caption'>Date: {visitDate}</Typography>
          </Box>
        </Box>

        <Box id='controls'>
          <PlayerTooltip title='Previous video'>
            <IconButton onClick={this.handleNext}>
              <SkipPreviousIcon fontSize='small' style={styles.icon} />
            </IconButton>
          </PlayerTooltip>
          <PlayerTooltip title={this.localState.isPlaying ? 'Pause' : 'Play'}>
            <IconButton onClick={this.togglePlay}>
              {this.localState.isPlaying ? (
                <PauseCircleOutlineIcon style={styles.icon} fontSize='large' />
              ) : (
                <PlayCircleOutlineIcon style={styles.icon} fontSize='large' />
              )}
            </IconButton>
          </PlayerTooltip>
          <PlayerTooltip title='Next video'>
            <IconButton onClick={this.handlePrev}>
              <SkipNextIcon style={styles.icon} fontSize='small' />
            </IconButton>
          </PlayerTooltip>
          <Box style={{ width: 100 }} display='flex' alignItems='center' mx={2}>
            <Slider
              value={this.options.speed}
              getAriaValueText={(value) => value.toString()}
              step={null}
              valueLabelDisplay='auto'
              marks={marks}
              color='secondary'
              max={8}
              min={1}
              style={{ margin: 0 }}
              onChange={this.changeSpeed}
            />
          </Box>
          <div id='timer'>
            <div>{formatTime(this.localState.currentTime)}</div>
            <div
              id='timeline'
              className={clsx(this.localState.isSkipping && 'skipping')}
              onClick={this.handleTimeline}
            >
              <Box
                bgcolor='secondary.main'
                id='progress'
                style={{ width: this.percentage(this.localState.currentTime) }}
              ></Box>
            </div>
            <div>{formatTime(this.replayer.getMetaData().totalTime)}</div>
          </div>

          <Box mx={2}>
            <FormControlLabel
              control={
                <Switch
                  size='small'
                  checked={this.options.skipInactive}
                  value={this.options.skipInactive}
                  onChange={this.toggleSwitch}
                  name='skipInactive'
                />
              }
              label={<Typography variant='body2'>Skip inactive</Typography>}
            />
          </Box>
          <PlayerTooltip title='Full screen'>
            <IconButton onClick={this.handleFullScreen}>
              <FullscreenIcon style={styles.icon} />
            </IconButton>
          </PlayerTooltip>
        </Box>
      </Box>
    );
  }
}

const tooltipStyle = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.secondary.main,
  },
}));
export function PlayerTooltip({ children, ...props }: TooltipProps) {
  const classes = tooltipStyle();
  return (
    <Tooltip classes={{ tooltip: classes.tooltip }} {...props}>
      {children}
    </Tooltip>
  );
}

export default CustomPlayer;

import { Settings } from '../langTemplate';

const settings: Settings = {
  title: 'Settings',
  accountItem: 'Account',
  domainsItem: 'Domains',
  usersItem: 'Users management',
  upgradeItem: 'Upgrade account',
  notificationsItem: 'Notifications',
  integrationsItem: 'Integrations',
  excludedUrls: 'Video tracking',
  cancel: 'Cancel',
  confirmSubscription: 'Confirm subscription',
  paymentInProgress: 'Payment in progress',
  paymentSuccessful: 'Payment successful',
  goBack: 'Back to homepage',
  personalDetails: 'Personal details',
  sepaDebit: 'SEPA Direct Debit',
  creditCard: 'Credit Card',
  sepaRules: `By providing your IBAN and confirming this payment, you are authorizing Spyla GmbH and
  Stripe, our payment service provider, to send instructions to your bank to debit your
  account and your bank to debit your account in accordance with those instructions. You
  are entitled to a refund from your bank under the terms and conditions of your agreement
  with your bank. A refund must be claimed within 8 weeks starting from the date on which
  your account was debited.`,
  cardRules: `By confirming this payment, you allow Spyla UG to charge your card for this payment
  and future payments in accordance with their terms.`,
  bankDetails: 'Bank details',
  total: 'Total amount',
  summary: 'Summary',
  tax: 'Sales tax',
  bankAccount: 'Bank account number',
  cardNumber: 'Credit card number',
  cvcCode: 'CVC code',
  expires: 'Expires',
  firstName: 'First name',
  lastName: 'Last name',
  downgradeTitle: 'Free plan',
  downgrade:
    'Free plan is set by default. In case you would like to downgrade, please contact our support.',
  planNames: {
    'plan-l': 'Premium plan',
    'plan-m': 'Basic plan',
    'plan-xl': 'Enterprise plan',
    'pl-xl-20': 'Test plan',
    'FREE_PLAN': 'Free plan',
  },
  details: {
    accountDetails: 'Account details',
    invoiceAddress: 'Invoice address',
    company: 'Company name',
    address: 'Address',
    code: 'Post code',
    city: 'City',
    domainUsage: 'Domain usage',
    download: 'Download PDF',
    extraData: 'Additional information',
    invoices: 'Invoices',
    paidOn: 'Paid on',
    phone: 'Phone number',
    newsletter: 'Newsletter',
    newsletterAlready: 'You have already signed up.',
    newsletterDescription: 'Get latest updates and exclusive offers only for newsletter participants.',
    newsletterJoin: `You haven't joined yet.`,
    newsletterToggle: 'Use this button to join or leave our newsletter.',
    country: 'Country',
    chooseCountry: 'Please choose a country',
  },
  integrations: {
    title: 'Integrations with extarnal apps',
    description: 'Here you can connect primeleads with other apps.',
    chooseDomains: 'Choose domains',
    updateDomains: 'Update',
    selectAll: 'Toggle select all',
    connect: 'Connect',
    disconnect: 'Disconnect',
    hubspot: {
      description:
        'Whenever we identify company visiting your page, we will send the data to the hub connected with your account.',
    },
  },
  notifications: {
    title: 'Notifications',
    name: 'Name',
    email: 'Email address',
    description: 'Manage email notifications',
    everyDay: 'Daily',
    every2Weeks: 'Every two weeks',
    everyWeek: 'Once a week',
    everyMonth: 'Monthly',
    addNew: 'Add new email',
    delete: 'Remove email',
    confirmMessage: 'Please confirm removing this email',
    frequency: 'Frequency',
    disabled: 'Disabled',
  },
  domains: {
    domains: 'Domains',
    confirm: 'Are you sure you want to remove this domain?',
    reminder: "Don't forget: in order to start tracking you need to paste the script into your website.",
    addDomain: 'Add new domain',
    removeDomain: 'Remove this domain',
    urlAlias: 'Domain name (url or alias)',
    nameLabel: 'Domain name',
    scriptLabel: 'Script (Copy and paste it into your website)',
    pleaseUpgrade: 'Please upgrade your account to get more domains',
    allNamesFill: 'Please make sure that all domains have name',
  },
  users: {
    manageUsers: 'Manage users',
    description: 'Grant acces to your domains',
    addUser: 'Add user',
    saveUsers: 'Save users',
    removeUser: 'Remove user',
    typeEmail: 'Type your email here',
    confirm: 'Are you sure you want to remove this user?',
    readAccess: 'Read only',
    writeAccess: 'Read & Write',
    editRights: 'Edit user access rights',
    userNotFound: 'User not found. Click button on the right to send an invitation.',
    invitationSent: 'Invitation has been sent on {{date}}',
    inviteLimit:
      "Invitation has been already sent 3 times. Please contact our {{support}} in case email didn't arrive",
    invite: 'Invite',
  },
  excluded: {
    excludedTitle: 'Disable video tracking',
    excludedDescription: 'Here you can prevent video recording of visitor activities on specific sites.',
    excludedSubPaths: 'All sub-pages excluded',
    excludeSubPaths: 'Exclude this one and all pages below it',
    add: 'Add excluded site',
    domain: 'Primeleads domain to which this site belongs',
    url: 'URL Adress of site',
    confirmRemove: 'Removing URL',
    pleaseConfirm: 'Please confirm removing chosen URL.',
  },
  upgrade: {
    upgrade: 'Upgrade',
    choosePlan: 'Choose your plan',
    redeemVoucher: 'Redeem voucher',
    voucher: 'Voucher',
    typeVoucher: 'Type your voucher here',
    perMonth: 'monthly',
    allPrices: 'Prices do not include 19% VAT',
    otherMethods: 'In case of questions regarding other payment methods, pleaser contact our support',
    notAvailable: '-',
    onlyPdf: 'Only on PDF documents',
    included: 'Included',
    features: {
      companyLimit: 'Monthly identifications',
      content: 'Dynamic Content rules',
      domains: 'Domains limit',
      history: 'Data kept for',
      price: 'Price',
      spylink: 'Dynamic link limit',
      pdf: 'PDF files limit',
      hubspot: 'Hubspot integration',
    },
    unlimited: 'Unlimited',
    days: 'Days',
    plans: {
      free: 'Free',
      basic: 'Basic',
      premium: 'Premium',
      enterprise: 'Enterprise',
      pdf: 'PDF',
    },
  },
};
export default settings;

import React from 'react';
import {
  Box,
  Typography,
  IconButton,
  Backdrop,
  Modal,
  Grid,
  Fade,
  Link,
  Tooltip,
} from '@material-ui/core';
import { IComponentProps } from '../models/app.model';
import { UAParser } from 'ua-parser-js';
import { formatDate, TypoPopover, filterURL, getCompanyIcon } from '../helpers';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import { Skeleton } from '@material-ui/lab';
import Video from './video/Video';
import AppContext from '../data/AppContext';

export interface VisitProp {
  _id: string;
  date: string;
  leave: string;
  day: string;
  time: string;
  referer: string;
  hasVideo: boolean;
  name?: string;
  companyKey?: string;
  blacklisted?: boolean;
  rawData?: any;
}

export interface VisitsProps extends IComponentProps {
  requests: VisitProp[];
  company?: any;
}
export interface VisitsState {
  isModalVisible: boolean;
  selected: number;
}

class Visits extends React.Component<VisitsProps, VisitsState> {
  static contextType = AppContext;
  constructor(props: VisitsProps) {
    super(props);
    this.state = {
      selected: -1,
      isModalVisible: false,
    };
  }
  componentDidMount = () => {};
  toggleModal = (e: React.SyntheticEvent, i: number) => {
    this.setState({
      ...this.state,
      selected: i,
      isModalVisible: !this.state.isModalVisible,
    });
  };
  getDevice = (rawData: any, companyKey?: string, companyName?: string) => {
    const agent = new UAParser();
    agent.setUA(rawData['user-agent'] || rawData['x-sfg-data']);
    const result = agent.getResult();
    return (
      <Grid item xs={12} sm={4} md={3} component={Box} display='flex'>
        <Box display='flex' justifyContent='center' flexDirection='column' px={2}>
          {this.getCompany(companyKey, companyName)}
          <Typography variant='body2'>
            {(result.os.name || '?') + ' / ' + (result.browser.name || '?')}
          </Typography>
        </Box>
      </Grid>
    );
  };
  getDate = (date: string) => {
    const fdate = formatDate(date);
    return (
      <Grid item xs={12} sm={4} md={2} component={Box} display='flex' flexWrap='wrap'>
        <Box py={1} px={2} display='flex' alignItems='center'>
          <Typography variant='body2'>
            {`${fdate.day} ${fdate.month} `}
            <Typography variant='inherit' component='span'>
              {fdate.hour}:{fdate.minute}
            </Typography>
          </Typography>
        </Box>
      </Grid>
    );
  };
  getTime = (time: string) => {
    return (
      <Box py={1} px={1} display='flex' alignItems='center'>
        <Typography variant='body2'>{time}</Typography>
      </Box>
    );
  };
  getVideo = (hasVideo: boolean, i: number) => {
    return (
      <Box py={1} px={1} display='flex' alignItems='center'>
        {hasVideo ? (
          <Tooltip title={this.props.t('visitors:watchActivity') as string}>
            <IconButton onClick={(e: React.SyntheticEvent) => this.toggleModal(e, i)}>
              <VideocamIcon color='error' />
            </IconButton>
          </Tooltip>
        ) : (
          <IconButton disabled>
            <VideocamOffIcon color='disabled' />
          </IconButton>
        )}
      </Box>
    );
  };
  getVisits = (url: string) => {
    return (
      <Grid item xs={12} sm={6} md={2} component={Box} display='flex'>
        <Box py={1} px={2} display='flex' alignItems='center' style={{ width: '95%' }}>
          <TypoPopover text={url} variant='body2' noWrap>
            <Link href={url} target='_blank'>
              {url}
            </Link>
          </TypoPopover>
        </Box>
      </Grid>
    );
  };
  getSource = (referer: string) => {
    return (
      <Grid item xs={12} sm={6} md={3} component={Box} display='flex'>
        <Box py={1} px={2} display='flex' alignItems='center' style={{ width: '95%' }}>
          {referer ? (
            <Box display='flex' alignItems='center' style={{ width: '95%' }}>
              {getCompanyIcon(referer)}
              <TypoPopover text={referer} variant='body2' noWrap>
                <Link href={referer} target='_blank'>
                  {filterURL(referer, false, true, true)}
                </Link>
              </TypoPopover>
            </Box>
          ) : (
            <Box display='flex' alignItems='center' style={{ width: '95%' }}>
              <OpenInBrowserIcon color='primary' fontSize='small' style={{ marginRight: 10 }} />
              <Typography variant='body2' noWrap>
                {this.props.t('visitors:tableHeaders.directEntry')}
              </Typography>
            </Box>
          )}
        </Box>
      </Grid>
    );
  };
  getCompany = (companyKey?: string, companyName?: string) => {
    return (
      companyKey &&
      companyName && (
        <TypoPopover text={companyName} variant='body2' noWrap>
          <Link href={`companies/${companyKey}`} target='_blank'>
            {companyName}
          </Link>
        </TypoPopover>
      )
    );
  };
  displayVisits = () => {
    const { t, requests, classes } = this.props;
    return (
      <>
        {this.context.getData.fetching.requests && (
          <Skeleton variant='rect' height={200} width="100%">
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              style={{ height: 'inherit' }}
              p={5}
            >
              <Typography variant='h6'>{t('common:requestsLoading')}...</Typography>
            </Box>
          </Skeleton>
        )}
        {requests.map((r, i) => {
          const { date, time, hasVideo, rawData, referer, companyKey, name } = r;
          const { referer: url } = rawData;
          return (
            <Fade in={true} timeout={500} style={{ transitionDelay: `${(i % 25) * 0.05}s` }} key={i}>
              <Grid container className={classes.row}>
                {this.getDate(date)}
                {this.getDevice(rawData, companyKey, name)}
                <Grid item xs={12} sm={4} md={2} component={Box} display='flex'>
                  {this.getVideo(hasVideo, i)}
                  {this.getTime(time)}
                </Grid>
                {this.getVisits(url)}
                {this.getSource(referer)}
              </Grid>
            </Fade>
          );
        })}
        <Modal
          open={this.state.isModalVisible}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          onClose={() => this.setState({ ...this.state, isModalVisible: false })}
        >
          <Fade in={this.state.isModalVisible}>
            <Box className={classes.videoFrame}>
              <Video
                req={this.props.requests}
                selected={this.state.selected}
                onClose={(e: React.SyntheticEvent) => this.toggleModal(e, -1)}
                company={this.props.company || this.props.requests[this.state.selected]}
              />
            </Box>
          </Fade>
        </Modal>
      </>
    );
  };
  noData = () => <Box p={5}>{this.props.t('common:noData')}</Box>;
  render() {
    return this.props.requests.length === 0 ? this.noData() : this.displayVisits();
  }
}

export default Visits;

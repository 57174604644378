import * as React from 'react';

import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as TooltipCharts,
  ResponsiveContainer,
  Area,
  AreaChart,
  Brush
} from 'recharts';
import { useTheme, Typography, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export default (props: any) => {
  const { data } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    (data && data.length > 0 && (
      <Box p={5}>
        <ResponsiveContainer width='100%' height={300}>
          <AreaChart data={data} margin={{ left: -30 }}>
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='day' />
            <YAxis />
            <TooltipCharts />
            <Area
              type='monotone'
              dataKey='visits'
              stroke={theme.palette.primary.main}
              fill={theme.palette.primary.light}
            />
            <Brush />
          </AreaChart>
        </ResponsiveContainer>
      </Box>
    )) ||
    null
  );
};

import React from 'react';
import { Box, Grid, Typography, useTheme } from '@material-ui/core';
import VisitsPerDay from '../charts/visitsPerDay';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import groupArray from 'group-array';
import BusinessIcon from '@material-ui/icons/Business';
import PersonIcon from '@material-ui/icons/Person';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LinkIcon from '@material-ui/icons/Link';
import { Alert } from '@material-ui/lab';

export interface AnalyticsProps {
  data: any;
}

export const DynamicAnalytics: React.FC<AnalyticsProps> = (props) => {
  const { t } = useTranslation(['dynamicContent', 'common']);
  const theme = useTheme();
  const { analytics } = props.data;
  const transformRequests = (requests: any) => {
    let result: any = groupArray(
      requests.map((req: any) => ({ ...req, date: req.date.slice(0, 10) })),
      'date'
    );
    const finalResult = [] as any[];
    const name = props.data.name;
    for (let prop in result) {
      finalResult.push({
        date: prop,
        [name]: result[prop].length,
      });
    }
    return finalResult;
  };

  const displayCompanies = (companies: any[]) => {
    if (!companies || companies.length === 0) {
      return (
        <Box py={2}>
          <Typography variant='body2'>{t('common:noData')}</Typography>
        </Box>
      );
    }
    return companies
      .reduce((acc, c) => {
        if (!acc.some((co: any) => co.companyKey === c.companyKey)) {
          acc.push(c);
        }
        return acc;
      }, [])
      .map((company: any, i: number) => {
        const visits = analytics.companies.filter((c: any) => c.companyKey === company.companyKey)
          .length;
        return (
          <Box
            key={i}
            py={1}
            style={{ backgroundColor: i % 2 === 1 ? theme.palette.grey[100] : 'auto' }}
          >
            <Link
              to={`/companies/${company.companyKey}`}
              style={{ textDecoration: 'none' }}
              target='_blank'
            >
              <Box display='flex' alignItems='center'>
                <Box mr={1}>{company.excluded ? <PersonIcon /> : <BusinessIcon />}</Box>
                <Typography color='primary' variant='body2'>
                  {`${company.companyName} (${visits} ${
                    visits === 1 ? t('common:visit') : t('common:visits')
                  })`}
                </Typography>
              </Box>
            </Link>
          </Box>
        );
      });
  };
  return (
    <Box p={5}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Typography variant='h6'>{t('common:analytics.timeline')}</Typography>
          <VisitsPerDay
            data={transformRequests(analytics.companies)}
            dynamicContents={[props.data]}
            hideLegend={true}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant='h6'>{t('common:analytics.triggeredBy')}</Typography>
          {displayCompanies(analytics.companies)}
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant='h6'>{t('common:analytics.allVisits')}</Typography>
          <Box display='flex' alignItems='center' mb={2}>
            <VisibilityIcon style={{ marginRight: 10 }} />
            <Typography variant='h4'>{analytics.allVisits}</Typography>
          </Box>

          <Typography variant='h6'>{t('common:analytics.companyVisits')}</Typography>
          <Box display='flex' alignItems='center' mb={2}>
            <BusinessIcon style={{ marginRight: 10 }} />
            <Typography variant='h4'>{analytics.companyVisits}</Typography>
          </Box>

          <Typography variant='h6'>{t('common:analytics.userVisits')}</Typography>
          <Box display='flex' alignItems='center' mb={2}>
            <PersonIcon />
            <Typography variant='h4'>{analytics.userVisits}</Typography>
          </Box>

          <Typography variant='h6'>{t('common:analytics.uniqueCompanies')}</Typography>
          <Box display='flex' alignItems='center' mb={2}>
            <BusinessIcon style={{ marginRight: 10 }} />
            <Typography variant='h4'>{analytics.uniqueCompanies.length}</Typography>
          </Box>

          <Typography variant='h6'>{t('common:analytics.spylinkEntries')}</Typography>
          <Box display='flex' alignItems='center' mb={2}>
            <LinkIcon style={{ marginRight: 10 }} />
            <Typography variant='h4'>{analytics.spylinkVisits}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Alert severity='info'>
            <Box display='flex' alignItems='center'>
              <Typography
                variant='caption'
                dangerouslySetInnerHTML={{ __html: t('common:analytics.isp') }}
              />
            </Box>
            <Box display='flex' alignItems='center'>
              <PersonIcon style={{ marginRight: 5 }} fontSize='small' />
              <Typography variant='caption'>{t('common:analytics.privateUser')}</Typography>
            </Box>

            <Box display='flex' alignItems='center'>
              <BusinessIcon style={{ marginRight: 5 }} fontSize='small' />
              <Typography variant='caption'>{t('common:analytics.companies')}</Typography>
            </Box>
          </Alert>
        </Grid>
      </Grid>
    </Box>
  );
};

import React from 'react';
import AppContext from '../../data/AppContext';
import { withRouter, RouteComponentProps } from 'react-router';
import { Box, Typography, Grid, Fab } from '@material-ui/core';
import styles from './Company.style';
import { withStyles } from '@material-ui/styles';
import VisitsPerDayChart from '../charts/visitsPerDay';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import { IComponentProps } from '../../models/app.model';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
interface ICompanyContentState {
  requests: [];
  dynamicContents: [];
  totalTime: number;
  totalVisits: number;
  totalRequestsDC: number;
  visitsPerDay: [];
}
interface ICompanyContentProps extends IComponentProps, RouteComponentProps<{ key: string }> {
  company: any;
  companyKey: string;
}
class Content extends React.Component<ICompanyContentProps, ICompanyContentState> {
  static contextType = AppContext;
  constructor(props: ICompanyContentProps) {
    super(props);
    this.state = {
      requests: [],
      dynamicContents: [],
      totalTime: 0,
      totalVisits: 0,
      totalRequestsDC: 0,
      visitsPerDay: []
    };
  }
  async componentDidMount() {
    this.loadContent();
  }

  loadContent = async () => {
    const response = await this.context.getDynamicByCompany(this.props.companyKey);
    const visitsPerDay = this.transformRequests(response.dynamicContents, response.requests);
    this.setState({
      ...this.state,
      dynamicContents: response.dynamicContents,
      requests: response.requests,
      visitsPerDay
    });
  };
  getIcon = (type: String) => {
    switch (type) {
      case 'dynamic-text':
        return <DynamicFeedIcon />;
      case 'dynamic-message':
        return <TextFieldsIcon />;
    }
  };
  transformRequests(dynamicContents: any, requests: any) {
    //TRANSFORM
    let result = [] as any;
    const dates = requests.reduce((all: any, item: any) => {
      const exists = all.includes(item.date.slice(0, 10));
      if (!exists) all.push(item.date.slice(0, 10));
      return all;
    }, []);

    let totalRequestsDC = 0;
    dates.sort().forEach((date: string) => {
      let subresult = {} as any;

      dynamicContents.forEach((content: any) => {
        const count = requests.filter((req: any) => {
          return req.dynamicContents.includes(content._id) && date === req.date.slice(0, 10);
        }).length;
        subresult[content.name] = count;
        totalRequestsDC += count;
      });
      result.push({ date, ...subresult });
      this.setState({ ...this.state, totalRequestsDC });
    });
    console.log(result);
    return result;
  }
  render() {
    const { company, t } = this.props;
    return (
      <Box p={5}>
        <Grid container spacing={5}>
          {this.state.requests.length > 0 ? (
            <>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant='h3' gutterBottom>
                  {this.state.dynamicContents.length}
                </Typography>
                <Typography variant='body2'>{t('company.numberTriggers')}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant='h3' gutterBottom>
                  {this.state.totalRequestsDC}
                </Typography>
                <Typography variant='body2'>{t('company.numberRequests')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6' gutterBottom>
                  {t('company.contentTriggered')} {company.name}
                </Typography>
                <VisitsPerDayChart
                  data={this.state.visitsPerDay}
                  dynamicContents={this.state.dynamicContents}
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Typography variant='h6' style={{ marginBottom: 20 }}>
                {t('common:noData')}
              </Typography>
              <Fab
                variant='extended'
                color='primary'
                component={Link}
                disabled={this.context.getData.readOnly}
                to={{
                  pathname: '/dynamic-content/add',
                  state: {
                    companyName: company.companyName || company.name,
                    city: company.city || '',
                    type: company.type || ''
                  }
                }}
              >
                {t('company.addContent') as string}
              </Fab>
            </Grid>
          )}
        </Grid>
      </Box>
    );
  }
}
export default withStyles(styles)(withTranslation(['companies', 'common'])(withRouter(Content)));

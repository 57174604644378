import { createTheme, Theme, alpha } from '@material-ui/core/styles';
import { AlertClassKey } from '@material-ui/lab/Alert';

declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey {
    MuiAlert: AlertClassKey;
  }
}

const palette = {
  primary: {
    main: '#34586E'
  },
  secondary: {
    main: '#E1A55A',
    contrastText: '#34586E'
  },
  error: {
    main: '#E43D40'
  },
  background: {
    default: '#fff'
  }
};

const defaultTheme: Theme = createTheme({
  palette
});

const primeLeadsTheme: Theme = createTheme({
  ...defaultTheme,
  palette: defaultTheme.palette,
  typography: {
    fontFamily: 'Open Sans'
  },
  overrides: {
    MuiTypography: {
      root: {
        fontSize: 16
      },
      h6: {
        fontWeight: 600,
        fontSize: '1rem'
      },
      h5: {
        fontWeight: 700,
        fontSize: '1.2rem'
      },
      h4: {
        fontWeight: 700,
        fontSize: '1.5rem'
      },
      h3: {
        fontWeight: 700,
        fontSize: '2rem'
      },
      subtitle1: {
        fontWeight: 300,
        fontSize: '1rem'
      }
    },
    MuiDrawer: {
      paper: {
        backgroundColor: defaultTheme.palette.primary.main,
        color: defaultTheme.palette.primary.contrastText
      }
    },
    MuiTable: {
      root: {
        overflowX: 'scroll'
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: '#fafafa'
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: defaultTheme.palette.primary.main
      }
    },
    MuiStepper: {
      root: {
        backgroundColor: 'transparent'
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: defaultTheme.spacing(2)
      }
    },
    MuiInputBase: {
      root: {
        'background': defaultTheme.palette.common.white,
        '& fieldset': {
          borderColor: defaultTheme.palette.grey[300]
        }
      }
    },
    MuiFab: {
      label: {
        padding: defaultTheme.spacing(0, 1),
        fontSize: 13
      }
    },
    MuiIconButton: {
      root: {
        zIndex: 0
      }
    },
    MuiAlert: {
      root: { border: `1px solid` },
      standardSuccess: { borderColor: alpha(defaultTheme.palette.success.main, 0.15) },
      standardError: { borderColor: alpha(defaultTheme.palette.error.main, 0.15) },
      standardInfo: { borderColor: alpha(defaultTheme.palette.info.main, 0.15) }
    },
    MuiSelect: {
      root: {
        'background': defaultTheme.palette.common.white,
        '& fieldset': {
          borderColor: defaultTheme.palette.grey[300]
        }
      },
      icon: {
        position: 'relative'
      }
    }
  }
});

export default primeLeadsTheme;

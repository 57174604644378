import common from './common';
import companies from './companies';
import dashboard from './dashboard';
import dynamicContent from './dynamicContent';
import settings from './settings';
import spylink from './spylink';
import visitors from './visitors';
import newFeatures from './newFeatures';
import signup from './signup';
import uploadPdf from './uploadPdf';

export default {
  common,
  companies,
  dashboard,
  dynamicContent,
  settings,
  spylink,
  visitors,
  newFeatures,
  signup,
  uploadPdf,
};

import React, { SyntheticEvent } from 'react';
import styles from '../Visitors.style';
import AppContext from '../../../data/AppContext';
import { formatDate, filterURL, TypoPopover, getCompanyIcon } from '../../../helpers';
//COMPONENTS
import {
  Typography,
  IconButton,
  Box,
  Hidden,
  Link as HLink,
  Grow,
  Grid,
  withStyles,
  Tooltip,
  Fade,
} from '@material-ui/core';
import { Scale } from '../../../helpers/Scale';
import adwords from '../../../assets/images/adwords.svg';
//ICONS
import FavoriteIcon from '@material-ui/icons/Favorite';
import BlockIcon from '@material-ui/icons/Block';
import PersonIcon from '@material-ui/icons/Person';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import CommentIcon from '@material-ui/icons/Comment';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { IVisitor, IComponentProps } from '../../../models/app.model';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { defaultFilter } from '../../../data/DataProvider';

export interface IVisitorsTableBodyProps extends IComponentProps, RouteComponentProps<any> {
  companies: IVisitor[];
  full: boolean | undefined;
}
export interface IVisitorsTableBodyState {
  expanded: number[];
}

class VisitorsTableBody extends React.Component<IVisitorsTableBodyProps, IVisitorsTableBodyState> {
  static contextType = AppContext;
  constructor(props: IVisitorsTableBodyProps) {
    super(props);
    this.state = {
      expanded: [] as number[],
    };
  }
  getTableHeaders = () => (
    <>
      <Hidden smDown>
        <Grid container className={clsx(this.props.classes.header)}>
          <Grid item xs={2} md={1} component={Box} display='flex'>
            <Typography variant='h6'>{this.props.t('tableHeaders.day')}</Typography>
          </Grid>

          <Grid item xs={10} md={5} component={Box} display='flex'>
            <Typography variant='h6'>{this.props.t('tableHeaders.company')}</Typography>
          </Grid>
          <Grid item xs={2} md={'auto'}></Grid>
          <Grid item xs={10} md={3} component={Box} display='flex'>
            <Typography variant='h6'>
              {`${this.props.t('tableHeaders.visits')} \u0026 ${this.props.t('tableHeaders.target')}`}
            </Typography>
          </Grid>

          <Grid item xs={'auto'} md={2} component={Box} display='flex'>
            <Typography variant='h6'>{this.props.t('tableHeaders.time')}</Typography>
          </Grid>
          <Grid item xs={'auto'} md={1} component={Box} display='flex'>
            <Typography variant='h6'>{this.props.t('tableHeaders.referer')}</Typography>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid container className={clsx(this.props.classes.header)}>
          <Grid item xs={12} component={Box} display='flex'>
            <Typography variant='h6'>{this.props.t('tableHeaders.visitDetails')}</Typography>
          </Grid>
        </Grid>
      </Hidden>
    </>
  );
  getStatusIcon = (note: any) => {
    if (note) {
      switch (note.status) {
        case 'isFavorite':
          return <FavoriteIcon color='error' fontSize='small' />;
        case 'isCustomer':
          return <PersonIcon color='error' fontSize='small' />;
        case 'notInteresting':
          return <BlockIcon color='error' fontSize='small' />;
        case 'competitor':
          return <GpsFixedIcon color='error' fontSize='small' />;
      }
    }
  };

  getRequestsLength = (requests: string[]) => {
    return (
      <Box display='flex' alignItems='center' pr={1}>
        <Typography variant='h6'>{requests.length}</Typography>
      </Box>
    );
  };
  getEngagement = (allVisits: number) => {
    return <Scale data={allVisits || 0} />;
  };
  getCityName = (city: any) =>
    isNaN(+city) ? (
      <>
        <Typography variant='inherit' component='b'>
          <LocationOnIcon fontSize='inherit' color='primary' style={{ marginRight: 5 }} />
          {city}
        </Typography>
      </>
    ) : (
      'N/A'
    );
  getTime = (time: string, i: number, videos: string[], company: IVisitor, hidden?: boolean) =>
    time ? (
      <Box display='flex' alignItems='center' pr={2}>
        <Typography variant='body2' style={{ marginRight: 10 }}>
          {time.slice(0, 5)}
        </Typography>
        {videos.length > 0 ? (
          <Tooltip title={this.props.t('watchActivity') as string}>
            <IconButton
              onClick={(e: SyntheticEvent) => {
                e.stopPropagation();
                if (hidden) {
                  return;
                }
                this.context.setData = { selected: company };
                this.props.history.push({
                  pathname: '/visitors',
                  state: { activeTab: 1 },
                });
              }}
            >
              <VideocamIcon color='error' />
            </IconButton>
          </Tooltip>
        ) : (
          <IconButton disabled>
            <VideocamOffIcon color='disabled' />
          </IconButton>
        )}
      </Box>
    ) : (
      '00:00'
    );
  getDate = (date: any) => (
    <Box
      px={1}
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      flexWrap='wrap'
    >
      <Typography variant='body2'>{`${date.day} ${date.month}`}</Typography>
      <Typography variant='body2'>{`${date.hour}:${date.minute}`}</Typography>
    </Box>
  );
  getReferers = (urls: string[], referrers: string[]) => {
    return (
      <Box display='flex' alignItems='center'>
        {!referrers || referrers.length === 0 ? (
          <TypoPopover text={this.props.t('tableHeaders.directEntry')}>
            <OpenInBrowserIcon fontSize='small' color='primary' style={{ marginRight: 8 }} />
          </TypoPopover>
        ) : (
          <TypoPopover text={referrers[0]}>
            {getCompanyIcon(referrers[0].startsWith('http') ? referrers[0] : 'http://' + referrers[0])}
          </TypoPopover>
        )}
        {this.comesFromAdwords(urls) && (
          <TypoPopover text='Google Ads'>
            <img src={adwords} alt='Ad words icon' width={20} height={20} />
          </TypoPopover>
        )}
      </Box>
    );
  };

  comesFromAdwords = (urls: string[]) => {
    return urls && urls.some((url: string) => url && url.indexOf('gclid=') !== -1);
  };

  getCompanyDetails = (
    domain: string,
    companyName: string,
    city: string,
    type: string,
    note: any,
    allVisits: number,
    hidden?: boolean
  ) => (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='space-between'
      style={{ width: '100%' }}
      pr={2}
    >
      <Box width='70%'>
        {hidden ? (
          <Typography variant='h6' noWrap className={clsx(hidden && this.props.classes.blurred)}>
            {getCompanyIcon(domain)}
            {companyName}
          </Typography>
        ) : (
          <TypoPopover variant='h6' text={companyName} noWrap>
            {getCompanyIcon(domain ? (domain.startsWith('http') ? domain : 'http://' + domain) : '')}
            {companyName}
          </TypoPopover>
        )}

        <Typography variant='body2' color='textSecondary'>
          {this.getCityName(city)}
          {' / '}
          {type}
        </Typography>
      </Box>

      <Box display='flex' alignItems='center'>
        {this.getStatusIcon(note)}
        {note && note.notes && <CommentIcon color='action' fontSize='small' />}
        {this.getEngagement(allVisits)}
      </Box>
    </Box>
  );
  getVisits = (urls: string[], i: number, hidden?: boolean) => {
    return (
      <Box
        display='flex'
        alignItems='center'
        flexWrap='wrap'
        alignContent='center'
        pr={1}
        style={{ width: '90%' }}
      >
        <Box display='flex' alignItems='center' flexBasis='100%' style={{ width: '100%' }}>
          <Box style={{ width: '80%' }}>
            <TypoPopover variant='body2' text={this.getLink(urls[0])} noWrap>
              {this.getLink(urls[0])}
            </TypoPopover>
          </Box>
          {urls.length > 1 && (
            <IconButton
              size='small'
              style={
                this.isExpanded(i)
                  ? {
                      transform: 'rotate(180deg)',
                      transition: 'all .5s ease',
                    }
                  : undefined
              }
              onClick={(e: SyntheticEvent) => (hidden ? () => {} : this.expandMenu(e, i))}
            >
              <ExpandMoreIcon fontSize='small' />
            </IconButton>
          )}
        </Box>

        {urls.length > 1 && (
          <Grow in={this.isExpanded(i)} unmountOnExit>
            <Box style={{ width: '80%' }}>
              {urls.slice(1).map((url: string, j: number) => (
                <TypoPopover key={j} variant='body2' text={this.getLink(url)} noWrap>
                  {this.getLink(url)}
                </TypoPopover>
              ))}
            </Box>
          </Grow>
        )}
      </Box>
    );
  };
  getLink = (url: string, i?: number) => (
    <HLink
      onClick={(e: SyntheticEvent) => {
        e.stopPropagation();
      }}
      target='_blank'
      href={url}
      color='primary'
      key={i && i >= 0 ? i : -1}
    >
      {filterURL(url, false, true, true)}
    </HLink>
  );
  expandMenu = (e: SyntheticEvent, i: number) => {
    e.stopPropagation();
    if (this.state.expanded.includes(i)) {
      this.setState({
        ...this.state,
        expanded: this.state.expanded.filter((num: number) => num !== i),
      });
    } else {
      this.setState({
        ...this.state,
        expanded: [...this.state.expanded, i],
      });
    }
  };
  isExpanded = (i: number) => this.state.expanded.includes(i);
  getCompanyRow = (company: IVisitor, i: number) => {
    const {
      day,
      companyName,
      companyKey,
      type,
      city,
      requests,
      time,
      referrers,
      urls,
      domain,
      videos,
      allVisits = 0,
      hidden = false,
    } = company;
    const date = formatDate(day);
    const { selected } = this.context.getData;
    const note = this.context.getNote(companyKey);
    const { classes } = this.props;
    return (
      <Fade
        in={true}
        key={i}
        timeout={500}
        style={{
          transitionDelay: `${(i % defaultFilter.pagination.pageSize) * 0.025}s`,
        }}
      >
        <Grid
          container
          key={i}
          className={clsx(hidden && 'hidden', classes.row, company === selected && classes.selected)}
          component={Box}
          p={1}
          onClick={async (e: SyntheticEvent) => {
            e.stopPropagation();
            if (company.hidden) {
              return;
            }
            this.context.setData = { selected: company };
            this.props.history.push({
              pathname: '/visitors',
            });
          }}
        >
          <Grid item xs={2} md={1} component={Box} display='flex'>
            {this.getDate(date)}
          </Grid>

          <Grid item xs={10} md={5} component={Box} display='flex'>
            {this.getCompanyDetails(domain, companyName, city, type, note, allVisits, hidden)}
          </Grid>
          <Grid item xs={2} md={'auto'}></Grid>
          <Grid item xs={10} md={3}>
            <Box display="flex" justifyContent="space-between">
            {this.getRequestsLength(requests)}
            {this.getVisits(urls, i, hidden)}
            </Box>
          </Grid>
          <Grid item xs={2} md={'auto'}></Grid>
          <Grid item xs={3} md={2} component={Box} display='flex'>
            {this.getTime(time, i, videos, company, hidden)}
          </Grid>
          <Grid item xs={3} md={1} component={Box} display='flex'>
            {this.getReferers(urls, referrers)}
          </Grid>
        </Grid>
      </Fade>
    );
  };
  render() {
    return (
      <Box style={{ minHeight: this.props.full && this.props.companies.length > 0 ? '100vh' : 'auto' }}>
        {this.getTableHeaders()}
        {this.props.companies.map((company: IVisitor, i: number) => this.getCompanyRow(company, i))}
      </Box>
    );
  }
}

export default withStyles(styles)(
  withTranslation(['visitors', 'common'])(withRouter(VisitorsTableBody))
);

import React, { SyntheticEvent } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Box,
  IconButton,
  Typography,
  createStyles,
  Theme,
  withStyles,
  ClickAwayListener,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AppContext from '../../data/AppContext';
// import '../../../node_modules/rrweb-player/dist/style.css';
import CustomPlayer, { PlayerTooltip } from './CustomPlayer';
import { IComponentProps } from '../../models/app.model';
import './video.scss';
import { Skeleton } from '@material-ui/lab';
const styles = createStyles((theme: Theme) => ({
  closeIcon: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: theme.spacing(2),
    color: theme.palette.common.white,
  },
}));

export interface VideoProps extends IComponentProps {
  req: any;
  onClose: (e: SyntheticEvent) => any;
  company: any;
  selected: number;
  doc?: boolean;
}
export interface VideoState {
  current: number;
  requests: any[];
}

export const controllerHeight = 80;

class Video extends React.Component<VideoProps, VideoState> {
  static contextType = AppContext;
  wrapper: any;
  private mounted: boolean = false;
  private customPlayer: CustomPlayer | null;
  constructor(props: VideoProps) {
    super(props);
    this.state = {
      requests: props.req.filter((req: any) => req.hasVideo),
      current: 0,
    };
    this.wrapper = React.createRef<HTMLElement>();
    this.customPlayer = null;
  }
  async componentDidMount() {
    this.mounted = true;
    this.loadVideo(this.state.requests.indexOf(this.props.req[this.props.selected]));
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  rerender = () => {
    if (this.mounted) {
      this.forceUpdate();
    }
  };
  closeVideo = (e: any) => {
    this.customPlayer?.handleClose();
    this.props.onClose(e);
  };
  startVideo = (events: any[], current: number, options?: any) => {
    this.wrapper.current.innerHTML = '';
    const fsr = () => {
      console.log("THIS IS NOT DOC")
      if (this.props.doc) {
        console.log("THIS IS DOC")
        this.wrapper.current.querySelector('iframe').contentDocument.body.dataset.primeleads = 'true';
      }
    };
    this.customPlayer = new CustomPlayer({
      events,
      target: this.wrapper.current,
      onSnapshotRebuild: fsr,
      rerender: this.rerender,
      onLoad: (current: number, options?: any) => this.loadVideo(current, options),
      allVideos: this.state.requests.length,
      current,
      meta: {
        company: this.props.company,
        visitDate: this.getDate(current),
      },
      ...options,
    });
  };
  loadVideo = async (i: number, options?: any) => {
    let events = [] as any[];
    const data = await this.context.fetchVideoData(this.state.requests[i]._id, this.props.doc);
    console.log("DATA", data)

    data.videos.forEach((event: any) => (events = events.concat(this.props.doc ? JSON.parse(window.atob(event.data)) :event.data)));
    console.log("EVENTS", events);
    console.log("MOUNTED", this.mounted);
    console.log("OPTIONS", options)
    if (events.length < 2 || !this.wrapper.current) {
      return;
    }
    if (this.mounted) {
      this.startVideo(events, i, options);
      this.setState({ ...this.state, current: i });
    }
  };

  getDate = (i: number) => {
    const date = new Date(this.state.requests[i].date);
    return date.toLocaleString();
  };
  style = ({ width, height }: any = {}) => {
    return {
      width: `${width}px`,
      height: `${height}px`,
    };
  };
  playerStyle = ({ width, height }: any = {}) => {
    return {
      width: `${width}px`,
      height: `${height}px`,
    };
  };
  render() {
    return (
      <ClickAwayListener onClickAway={this.closeVideo}>
        <div className='rr-player' style={this.playerStyle(this.customPlayer?.getDimension())}>
          <div
            className='rr-player__frame'
            ref={this.wrapper}
            style={this.style(this.customPlayer?.getDimension())}
          >
            <Skeleton variant='rect' height='30vh'>
              <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                style={{ height: 'inherit' }}
                p={5}
              >
                <Typography variant='h5'>{this.props.t('videoLoading')}...</Typography>
              </Box>
            </Skeleton>
          </div>
          <PlayerTooltip title='Close video'>
            <IconButton onClick={this.closeVideo} className={this.props.classes.closeIcon}>
              <CloseIcon />
            </IconButton>
          </PlayerTooltip>
          <div className='rr-player__controls'>
            {this.customPlayer && this.customPlayer.getControls()}
          </div>
        </div>
      </ClickAwayListener>
    );
  }
}

export default withStyles(styles)(withTranslation()(Video));

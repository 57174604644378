import { Visitors } from '../langTemplate';

const visitors: Visitors = {
  title: 'Besucher',
  watchActivity: 'Aktivität beobachten',
  downloadExcel: 'Excel-Export',
  generatingExcel: 'Excel-Datei generieren. Es kann einen Moment dauern.',
  exportLimited: 'Datenexport nur innerhalb des ausgewählten Datumsbereichs',
  limitExceeded:
    'Ihr aktuelles Limit beträgt <b>{{limit}} einzigartige Unternehmensanerkennungen</b> pro Monat. Um alle Daten anzuzeigen, aktualisieren Sie bitte Ihren Plan.',
  scale: {
    scale1: '1-10 besuche',
    scale2: '11-20 besuche',
    scale3: '21-30 besuche',
    scale4: '40+ besuche'
  },
  tableHeaders: {
    day: 'Datum',
    company: 'Firma',
    city: 'Stadt',
    target: 'Besuchte Seiten',
    visits: 'Aufrufe',
    time: 'Dauer',
    referer: 'Quellen',
    visitDetails: 'Details des Besuchs',
    directEntry: 'Direkter Zugang'
  },
  filters: {
    hideFilters: 'Filter ausblenden',
    showFilters: 'Filter anzeigen',
    order: 'Folge',
    sortBy: 'Sortiere nach',
    visitorsType: 'Besuchertyp',
    dateRange: 'Datumsbereich',
    keyword: 'Stichwort',
    ascending: 'Aufsteigend',
    descending: 'Absteigend',
    companyName: 'Name der Firma',
    day: 'Datum des Besuchs',
    city: 'Stadt',
    time: 'Dauer',
    companies: 'Unternehmen',
    all: 'Alle',
    competitors: 'Wettbewerber',
    notInterestedIn: 'Nicht interessiert an',
    customers: 'Kunden',
    favorite: 'Favorit',
    startDate: 'Anfangsdatum',
    endDate: 'Enddatum',
    typeToFilter: 'Filtern nach Stichworten',
    applyFilter: 'Filter anwenden',
    resetFilter: 'Filter zurücksetzen',
    historyLimit: 'Nutzer mit kostenlosen Plan, können Besuchshistorie bis zu 30 Tage sehen vor'
  }
};

export default visitors;

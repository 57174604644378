import React from 'react';
import {
  Box,
  createStyles,
  Theme,
  withStyles,
  Typography,
  TextField,
  Fab,
  Link as HLink,
  CircularProgress
} from '@material-ui/core';
import { IComponentProps } from '../../models/app.model';
import { withTranslation } from 'react-i18next';
import logo from '../../assets/images/logo2.png';
import auth0logo from '../../assets/images/auth0.svg';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import ResetPassword from './ResetPassword';
import { Alert } from '@material-ui/lab';
import LanguageSelect from '../LanguageSelect';
const styles = createStyles((theme: Theme) => ({
  wrapper: {
    'display': 'flex',
    'margin': '0 auto',
    'minHeight': '100vh',
    'maxWidth': '400px',
    'flexWrap': 'wrap',
    'justifyContent': 'center',
    'alignItems': 'flex-start',
    'alignContent': 'flex-start',
    '& form, & > div': {
      width: '100%'
    }
  },
  box: {
    padding: theme.spacing(2, 10)
  },
  bottomBox: {
    'position': 'relative',
    'borderTop': `1px solid ${theme.palette.grey[200]}`,
    '&::before': {
      content: '""',
      width: theme.spacing(5),
      height: 1,
      backgroundColor: theme.palette.primary.main,
      position: 'absolute',
      top: -1,
      left: '50%',
      transform: 'translateX(-50%)'
    }
  },
  cursor: {
    cursor: 'pointer'
  },
  logo: {
    'flexBasis': '100%',
    'margin': theme.spacing(3, 0),
    '& img': { width: '10rem', margin: '0 auto', display: 'block' }
  },
  auth0logo: {
    width: '1.5rem',
    marginRight: theme.spacing(1)
  }
}));
interface LoginProps extends IComponentProps, RouteComponentProps {}
interface LoginState {}
class LoginForm extends React.Component<LoginProps, LoginState> {
  state = {
    data: {
      email: '',
      password: ''
    },
    submited: false,
    error: '',
    passwordReset: false
  };
  validateData = () => {};
  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value
      },
      error: ''
    });
  };
  handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await this.setState({ ...this.state, submited: true, error: '' });
    // TODO CHANGE URL
    try {
      const response = await fetch(`/app-api/v2/login`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        credentials:"include",
        body: JSON.stringify({
          email: this.state.data.email,
          password: this.state.data.password
        })
      });
      if (!response.ok) {
        throw new Error('incorrect');
      }
      window.location.reload();
    } catch (error:any) {
      console.error('ERROR IN CATCH', error);
      this.setState({
        ...this.state,
        error: error.message,
        submited: false
      });
    }
  };
  togglePasswordReset = () => {
    this.setState({ ...this.state, passwordReset: !this.state.passwordReset });
  };
  render() {
    const { classes, t } = this.props;
    return (
      <Box className={classes.wrapper}>
        <Box className={classes.logo}>
          <img src={logo} alt='Primeleads logo' />
        </Box>
        <form onSubmit={this.handleSubmit}>
          <Box display='flex' justifyContent='space-between' alignItems='center' mb={3}>
            <Typography variant='h4'>{t('signIn')}</Typography>
            <LanguageSelect />
          </Box>

          <Box mb={2}>
            <TextField
              variant='outlined'
              fullWidth
              label={t('email')}
              name='email'
              type='email'
              value={this.state.data.email}
              onChange={this.handleChange}
              required
            />
          </Box>
          <Box mb={2}>
            <TextField
              variant='outlined'
              fullWidth
              label={t('password')}
              name='password'
              type='password'
              value={this.state.data.password}
              onChange={this.handleChange}
              required
            />
          </Box>
          {this.state.error && (
            <Box mb={2}>
              <Alert severity='error'>
                <Typography variant='body2'>{t('errors.' + this.state.error)}</Typography>
              </Alert>
            </Box>
          )}
          <Box mb={2} display='flex' alignItems='center' justifyContent='center'>
            <Fab type='submit' variant='extended' color='primary' size='small'>
              {t('signIn') as string}
            </Fab>
            {this.state.submited && (
              <CircularProgress size={20} thickness={5} style={{ marginLeft: 10 }} />
            )}
          </Box>
        </form>
        <Box textAlign='center' mb={2}>
          <HLink className={classes.cursor} onClick={this.togglePasswordReset}>
            <Typography variant='body2'>{t('login.forgotPassword')}</Typography>
          </HLink>
          {this.state.passwordReset && (
            <Box my={2}>
              <ResetPassword />
            </Box>
          )}
        </Box>

        <Box my={3} py={3} borderTop={1} className={classes.bottomBox} textAlign='center'>
          <Typography variant='h5' gutterBottom>
            {t('login.noAccount')}
          </Typography>
          <HLink component={Link} to='/signup'>
            <Typography variant='body2'>{t('signUp')}</Typography>
          </HLink>
        </Box>
        <Box display='flex' alignItems='center' justifyContent='center' mb={4}>
          <img src={auth0logo} className={classes.auth0logo} />
          <Typography variant='caption' color='textSecondary'>
            {t('protectedBy')}
          </Typography>
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(withTranslation(['signup'])(withRouter(LoginForm)));

import React, { Component } from 'react';
import {
  withStyles,
  createStyles,
  Theme,
  Grid,
  Typography,
  Box,
  Button,
  TextField,
  WithStyles,
  IconButton,
} from '@material-ui/core';
import AppContext from '../../data/AppContext';
import BackspaceIcon from '@material-ui/icons/Backspace';
import clsx from 'clsx';
const styles = createStyles((theme: Theme) => ({
  type: {
    'borderLeft': '5px solid #ddd',
    '&.info': {
      borderColor: theme.palette.info.main,
    },
    '&.error': {
      borderColor: theme.palette.error.main,
    },
    '&.warning': {
      borderColor: theme.palette.warning.main,
    },
  },
}));
interface Log {
  _id: string;
  message: string;
  type: string;
  date: string;
  source: string;
}
interface LogsProps extends WithStyles {}
interface LogsState {
  logs: Log[];
  filter: {
    keyword: string;
    date: string;
    type: string;
    source: string;
  };
}

class Logs extends Component<LogsProps, LogsState> {
  static contextType = AppContext;
  constructor(props: LogsProps) {
    super(props);
    this.state = {
      logs: [],
      filter: {
        keyword: '',
        date: '',
        type: '',
        source: '',
      },
    };
  }

  async componentDidMount() {
    const logs: Log[] = await this.context.getLogs();
    this.setState({ ...this.state, logs });
  }
  clearLogs = async () => {
    const confirm = window.confirm('Please confirm');
    if (!confirm) {
      return;
    }
    const removed = await this.context.clearLogs();
    if (removed) {
      this.setState({ ...this.state, logs: [] });
    }
  };
  changeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ ...this.state, filter: { ...this.state.filter, [e.target.name]: e.target.value } });
  };
  getFilteredLogs = () =>
    this.state.logs
      .filter((l) => l.message.toLocaleLowerCase().includes(this.state.filter.keyword))
      .filter((l) => (this.state.filter.type ? l.type === this.state.filter.type : true))
      .filter((l) => (this.state.filter.date ? this.sameDay(l.date, this.state.filter.date) : true));
  getOptions = () => {
    return (
      <Box>
        <Box mb={2}>
          <TextField
            value={this.state.filter.keyword}
            name='keyword'
            onChange={this.changeFilter}
            variant='outlined'
            label='Filter by keyword'
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </Box>
        <Box mb={2} display='flex'>
          <TextField
            value={this.state.filter.date}
            name='date'
            type='date'
            onChange={this.changeFilter}
            variant='outlined'
            label='Date range'
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
          <IconButton
            onClick={() => this.setState({ ...this.state, filter: { ...this.state.filter, date: '' } })}
          >
            <BackspaceIcon />
          </IconButton>
        </Box>

        <Box mb={2}>
          <TextField
            value={this.state.filter.source}
            name='source'
            onChange={this.changeFilter}
            variant='outlined'
            label='Source'
            InputLabelProps={{ shrink: true }}
            select
            SelectProps={{
              native: true,
            }}
            fullWidth
          >
            <option value=''>Choose source</option>
            {[...new Set(this.getFilteredLogs().map((l) => l.source))].map((l, j) => (
              <option key={j} value={l}>
                {l}
              </option>
            ))}
          </TextField>
        </Box>
        <Box mb={2}>
          <TextField
            value={this.state.filter.type}
            name='type'
            onChange={this.changeFilter}
            variant='outlined'
            label='Type'
            InputLabelProps={{ shrink: true }}
            select
            SelectProps={{
              native: true,
            }}
            fullWidth
          >
            <option value=''>Type</option>
            {[...new Set(this.getFilteredLogs().map((l) => l.type))].map((l, j) => (
              <option key={j} value={l}>
                {l}
              </option>
            ))}
          </TextField>
        </Box>
        <Button variant='contained' color='secondary' onClick={this.clearLogs}>
          Clear logs
        </Button>
      </Box>
    );
  };
  sameDay = (d1: string, d2: string) => {
    const date1 = new Date(d1);
    date1.setHours(0, 0, 0, 0);
    const date2 = new Date(d2);
    date2.setHours(0, 0, 0, 0);
    return date1.getTime() === date2.getTime();
  };
  getLogs = () => {
    return this.getFilteredLogs().map((l, i) => (
      <Box
        display='flex'
        key={i}
        p={2}
        alignItems='center'
        className={clsx(this.props.classes.type, l.type)}
        style={i % 2 === 0 ? { backgroundColor: '#eee' } : {}}
      >
        <Box mr={2}>
          <Typography variant='body2'>{l._id}</Typography>
          <Typography variant='body2' color='textSecondary'>
            {new Date(l.date).toLocaleString()}
          </Typography>
        </Box>

        <Box flexGrow='1'>
          <Typography variant='body2'>{l.message}</Typography>
          <Typography variant='body2' color='secondary'>
            {l.source}
          </Typography>
        </Box>
      </Box>
    ));
  };
  render() {
    return (
      <Box p={5}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={9}>
            {this.getLogs()}
          </Grid>
          <Grid item xs={12} md={3}>
            {this.getOptions()}
          </Grid>
        </Grid>
      </Box>
    );
  }
}
export default withStyles(styles)(Logs);

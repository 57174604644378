import React, { Component, ChangeEvent } from 'react';
import AppContext from '../../data/AppContext';
import { ISpyLink } from './Spylink';
import {
  Stepper,
  StepContent,
  StepLabel,
  Step,
  TextField,
  Box,
  Typography,
  Button,
  createStyles,
  withStyles,
  Theme,
} from '@material-ui/core';
import SaveSnackbar from '../SaveSnackbar';
import LoadingBox from '../LoadingBox';
import { withTranslation } from 'react-i18next';
import { IComponentProps } from '../../models/app.model';
import { Redirect } from 'react-router';
import { createRandomString } from '../../helpers';

export interface SpylinkAddProps extends IComponentProps {}
export interface SpylinkAddState {
  isSaving: boolean;
  activeStep: number;
  link: ISpyLink;
}

const styles = (theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  });

class SpylinkAdd extends Component<SpylinkAddProps, SpylinkAddState> {
  steps: any[];
  static contextType = AppContext;
  constructor(props: SpylinkAddProps) {
    super(props);
    this.state = {
      isSaving: false,
      activeStep: 0,
      link: {} as ISpyLink,
    };
    this.steps = [
      {
        label: 'steps.link',
        description: 'steps.linkDescription',
        placeholder: 'steps.linkPlaceholder',
        body: this.getLinkInput,
      },
      {
        label: 'steps.notifications',
        description: 'steps.notificationsDescription',
        placeholder: 'steps.notificationsPlaceholder',
        body: this.getNotificationsInput,
      },
      {
        label: 'steps.summary',
        description: 'steps.summaryDescription',
        body: this.getNameInput,
        placeholder: 'steps.summaryPlaceholder',
      },
    ];
  }
  addLink = async () => {
    const linkId = createRandomString();
    await this.context.getSpylinks();
    const links = this.context.getData.spylinks;
    await (this.context.setData = {
      spylinks: [
        ...links,
        {
          ...this.state.link,
          linkId,
          disabled: false,
          notification: !!this.state.link.mail,
        },
      ],
    });
    await this.context.setSpylink();
  };
  updateInput = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      link: {
        ...this.state.link,
        [e.target.name]: e.target.value,
      },
    });
  };
  getLinkInput = () => {
    return (
      <TextField
        variant='outlined'
        fullWidth
        value={this.state.link.linkUrl || ''}
        name='linkUrl'
        onChange={this.updateInput}
        type='url'
        placeholder={this.props.t('steps.linkPlaceholder')}
        required
      />
    );
  };
  getNotificationsInput = () => {
    return (
      <TextField
        variant='outlined'
        fullWidth
        value={this.state.link.mail || ''}
        name='mail'
        onChange={this.updateInput}
        type='email'
        placeholder={this.props.t('steps.notificationsPlaceholder')}
      />
    );
  };
  getNameInput = () => {
    return (
      <TextField
        variant='outlined'
        fullWidth
        value={this.state.link.name || ''}
        name='name'
        onChange={this.updateInput}
        placeholder={this.props.t('steps.summaryPlaceholder')}
        required
      />
    );
  };
  getSteps = () => {
    return this.steps.map((step: any, i: number) => (
      <Step key={i}>
        <StepLabel>
          <Typography variant='h6'>{this.props.t('' + step.label)}</Typography>
        </StepLabel>
        <StepContent>
          <Box p={5}>
            <Typography variant='h4'>{this.props.t('' + step.label)}</Typography>
            <Typography variant='subtitle1'>{this.props.t('' + step.description)}</Typography>
            <Box mt={3}>{step.body()}</Box>

            <Box my={3}>
              <Button
                disabled={this.state.activeStep === 0}
                onClick={this.handleBack}
                className={this.props.classes.button}
                variant='contained'
                type='button'
              >
                {this.props.t('common:back')}
              </Button>
              <Button
                variant='contained'
                color='primary'
                type='submit'
                className={this.props.classes.button}
              >
                {this.state.activeStep === this.steps.length - 1
                  ? this.props.t('common:finish')
                  : this.props.t('common:next')}
              </Button>
            </Box>
          </Box>
        </StepContent>
      </Step>
    ));
  };
  handleBack = () => {
    this.setState({ ...this.state, activeStep: this.state.activeStep - 1 });
  };
  handleNext = async () => {
    if (this.state.activeStep === this.steps.length - 1) {
      await this.addLink();
    }
    this.setState({ ...this.state, activeStep: this.state.activeStep + 1 });
  };
  handleSubmit = (e: any) => {
    e.preventDefault();
    if (e.target.checkValidity()) {
      this.handleNext();
    }
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <Box m={5}>
          <Box pb={3}>
            <Typography variant='h4'>{this.props.t('titleAdding')}</Typography>
          </Box>
          <LoadingBox condition={false} p={5} empty={this.steps.length === 0}>
            <Stepper activeStep={this.state.activeStep} orientation='vertical'>
              {this.getSteps()}
            </Stepper>
            {this.state.activeStep === this.steps.length && <Redirect to='/spylink' />}
            <SaveSnackbar open={this.state.isSaving} />
          </LoadingBox>
        </Box>
      </form>
    );
  }
}

export default withStyles(styles)(withTranslation(['spylink', 'common'])(SpylinkAdd));

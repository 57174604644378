import * as React from 'react';

import {
  BarChart,
  XAxis,
  YAxis,
  Bar,
  CartesianGrid,
  Tooltip as TooltipCharts,
  ResponsiveContainer,
  LabelList
} from 'recharts';
import { useTheme, Typography, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const TopBranchesBar = (props: any) => {
  const theme = useTheme();
  const { t } = useTranslation('common');
  const CustomizedMostPopularLabel = (props: any) => {
    const { x, y, width, height, value } = props;
    return (
      <g y={y - 15 + height}>
        <text x={x} y={y - 5} fill={theme.palette.common.black} textAnchor='midddle'>
          {value}
        </text>
      </g>
    );
  };
  return (
    (props.data && props.data.length > 0 && (
      <Box p={5}>
        <ResponsiveContainer width='100%' height={props.data.length * 50 + 30}>
          <BarChart
            layout='vertical'
            data={props.data}
            margin={{ top: 20, right: 0, left: 0, bottom: 0 }}
          >
            <CartesianGrid stroke='#f5f5f5' />
            <YAxis dataKey='text' type='category' hide />
            <XAxis type='number' />
            <TooltipCharts cursor={{ fill: '#eee' }} />
            <Bar dataKey='value' fill={theme.palette.primary.light} fontSize='small' barSize={15}>
              <LabelList dataKey='text' content={<CustomizedMostPopularLabel />} data={props.data} />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Box>
    )) ||
    null
  );
};

export default TopBranchesBar;
import { Theme } from '@material-ui/core/styles';

const styles: any = (theme: Theme) => ({
  root: {
    '& .DateRangePickerInput': {
      'fontFamily': theme.typography.fontFamily,
      'borderColor': theme.palette.grey[400],
      '& input': {
        'fontWeight': '400',
        'fontSize': '1rem',
        '&:focus': {
          borderColor: theme.palette.primary.main
        }
      } //default styles include a border
    }
  },
  blurred: {
    filter: 'blur(4px)',
    color: theme.palette.grey[600]
  },
  image: {
    display: 'block',
    maxWidth: '100%'
  },
  details: {
    backgroundColor: theme.palette.common.white,
    boxShadow: '0 0 15px -10px rgba(0,0,0,.4)',
    transition: theme.transitions.create(['all'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard
    }),
    position: 'absolute',
    height: '100%',
    width: '60%',
    right: 0,
    top: 0,
    [theme.breakpoints.only('xs')]: {
      width: '100%'
    }
  },
  hideOverflow: {
    overflow: 'hidden'
  },
  sticky: {
    position: 'sticky',
    top: 0,
    height: '100vh'
  },
  visible: {
    opacity: 1,
    flexBasis: '100%'
  },
  paper: {
    width: '100%'
  },
  row: {
    'backgroundColor': '#fcfcfc',
    'borderTop': `1px solid ${theme.palette.grey[200]}`,
    '&.hidden': {
      cursor: 'no-drop',
      opacity: '0.5!important'
    },
    '&:not(.hidden)': {
      'cursor': 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.grey[100]
      }
    }
  },
  selected: {
    backgroundColor: theme.palette.grey[100]
  },
  header: {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(1, 2)
  },
  companyCellFull: {
    maxWidth: '500px'
  },
  companyCellSmall: {
    maxWidth: '270px'
  },
  active: {
    borderColor: `${theme.palette.error.light}!important`
  },
  icon: {
    marginRight: theme.spacing(2),
    color: theme.palette.grey[400]
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: '1px'
  },
  sectionTitle: {
    padding: theme.spacing(3),
    borderBottom: `1px solid ${theme.palette.secondary.main}`
  },
  detailsHeader: {
    'backgroundColor': theme.palette.common.white,
    'zIndex': theme.zIndex.appBar,
    'position': 'sticky',
    'top': 0,
    '& h2': {
      marginBottom: 0
    }
  },
  buttons: {
    '& a': {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  },
  companyLogo: {
    marginRight: theme.spacing(2)
  },
  controls: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`
  },
  thumbnail: {
    display: 'block',
    width: '100%',
    border: `10px solid ${theme.palette.grey[200]}`,
    minHeight: '10vh'
  }
});
export default styles;

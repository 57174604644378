import React, { Component, ChangeEvent, FormEvent, SyntheticEvent } from 'react';
import AppContext from '../../data/AppContext';
import {
  TextField,
  Switch,
  Grid,
  Box,
  Typography,
  IconButton,
  createStyles,
  withStyles,
  Theme,
  Fab,
  FormControlLabel,
  Snackbar,
  Tooltip
} from '@material-ui/core';
import { ISpyLink } from './Spylink';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import { Autocomplete } from '@material-ui/lab';
import { IComponentProps } from '../../models/app.model';
import { withTranslation } from 'react-i18next';
import { copyToClipboard } from '../../helpers';
import MuiAlert from '@material-ui/lab/Alert';

const styles = (theme: Theme) =>
  createStyles({
    linkContainer: {
      'backgroundColor': theme.palette.primary.main,
      'color': theme.palette.primary.contrastText,
      'boxShadow': `0 0 10px -5px ${theme.palette.grey[500]}`,
      'borderBottom': `3px solid ${theme.palette.secondary.main}`,
      'cursor': 'pointer',
      'transition': 'all .25s ease',
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
        boxShadow: `0 0 20px -5px ${theme.palette.grey[500]}`,
        borderBottom: `3px solid ${theme.palette.secondary.dark}`
      }
    }
  });

export interface SpyLinkFormProps extends IComponentProps {
  link: ISpyLink;
  websites: string[];
  onInputUpdate: (e: ChangeEvent<HTMLInputElement>) => any;
  onSwitchUpdate: (e: ChangeEvent) => any;
  onSave: () => any;
  onRemove: () => any;
  updateLinkUrl: (value: string) => any;
}
class SpylinkForm extends Component<SpyLinkFormProps, { isLinkValid: boolean; copied: boolean }> {
  static contextType = AppContext;
  constructor(props: SpyLinkFormProps) {
    super(props);
    this.state = {
      isLinkValid: false,
      copied: false
    };
  }
  componentDidMount() {
    this.validateLink(this.props.link.linkUrl || '');
  }
  componentDidUpdate(prevProps: SpyLinkFormProps) {
    if (prevProps.link !== this.props.link) {
      this.validateLink(this.props.link.linkUrl || '');
    }
  }
  validateLink = (link: string) => {
    try {
      const url = new URL(link);
      this.setState({ ...this.state, isLinkValid: true });
    } catch (error) {
      this.setState({ ...this.state, isLinkValid: false });
    }
  };
  linkTouched = () => {
    this.setState({ ...this.state, isLinkValid: false });
  };
  generateSpylinkUrl = (linkUrl: string, linkId: string) => {
    try {
      const url = new URL(linkUrl);
      url.searchParams.append('sl', linkId);
      return url.href;
    } catch (error) {
      return '';
    }
  };
  getAutocomplete = (linkUrl: string, websites: string[]) => {
    return (
      <Box mt={3}>
        <Autocomplete
          options={websites}
          getOptionLabel={(option) => option}
          value={linkUrl}
          selectOnFocus={true}
          onChange={(e: React.ChangeEvent<{}>, value: string | null) => {
            //TODO onChange
            this.linkTouched();
            this.props.updateLinkUrl(value || '');
          }}
          onBlur={() => this.validateLink(linkUrl)}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant='outlined'
              label={this.props.t('label')}
              required
              type='url'
              name='linkUrl'
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                this.linkTouched();
                this.props.onInputUpdate(e);
              }}
            />
          )}
        />
      </Box>
    );
  };
  getCopyLink = (linkUrl: string, linkId: string) => {
    const link = this.generateSpylinkUrl(linkUrl, linkId);
    return (
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        flexWrap='wrap'
        className={this.props.classes.linkContainer}
        my={3}
        mb={1}
        p={3}
        onClick={() => {
          this.setState({ ...this.state, copied: true });
          copyToClipboard(link);
        }}
      >
        <Typography variant='body2' noWrap>
          {link}
        </Typography>
        <Tooltip title={this.props.t('common:copyToClipboard') as string} color='inherit'>
          <IconButton
            color='inherit'
            onClick={(e: SyntheticEvent) => {
              e.stopPropagation();
              this.setState({ ...this.state, copied: true });
              copyToClipboard(link);
            }}
          >
            <FileCopyIcon color='inherit' />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };
  render() {
    const { t, websites, link, onInputUpdate, onSwitchUpdate } = this.props;
    const { linkUrl = '', name = '', mail = '', disabled = false, notification = false, linkId } = link;

    return link ? (
      <>
        <Box p={5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant='outlined'
                label={t('name')}
                value={name}
                name='name'
                onChange={onInputUpdate}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant='outlined'
                label={t('notification')}
                value={mail}
                name='mail'
                onChange={onInputUpdate}
                type='email'
                required={notification}
              />
            </Grid>

            <Grid item xs={12} component={Box} py={5}>
              <Typography variant='body2'>{t('linkDescription')}</Typography>
              {this.getAutocomplete(linkUrl, websites)}
            </Grid>

            <Grid item xs={12}></Grid>
            {this.state.isLinkValid && (
              <Grid item xs={12} component={Box} py={5}>
                <Typography variant='h5'>{t('linkToCopy')}</Typography>
                <Typography variant='body2'>{t('linkToCopyDescription')}</Typography>
                {this.getCopyLink(linkUrl, linkId)}
              </Grid>
            )}
            <Grid item xs={12} sm={6} component={Box} py={5}>
              <FormControlLabel
                control={
                  <Switch
                    name='notification'
                    checked={notification}
                    onChange={onSwitchUpdate}
                    disabled={!!disabled}
                  />
                }
                label={notification ? t('notificationsEnabled') : t('notificationsDisabled')}
              />
            </Grid>
            <Grid item xs={12} sm={6} component={Box} py={5}>
              <FormControlLabel
                control={<Switch name='disabled' checked={!disabled} onChange={onSwitchUpdate} />}
                label={disabled ? t('linkDisabled') : t('linkEnabled')}
              />
            </Grid>
          </Grid>
        </Box>
        <Snackbar
          open={this.state.copied}
          autoHideDuration={3000}
          onClose={() => this.setState({ ...this.state, copied: false })}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <MuiAlert variant='filled' severity='info'>
            {this.props.t('copied')}
          </MuiAlert>
        </Snackbar>
      </>
    ) : null;
  }
}

export default withStyles(styles)(withTranslation(['spylink', 'common'])(SpylinkForm));
